import { InjectionToken, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { NgxDiffModule } from 'ngx-diff';
import { HttpClientModule } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { MatRadioModule } from '@angular/material/radio';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatSortModule } from '@angular/material/sort';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AppRoutingModule } from './app-routing.module';
import { OnlineStatusModule } from 'ngx-online-status';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { DateFnsModule } from 'ngx-date-fns';
import { NgPipesModule } from 'ngx-pipes';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipeModule } from '@guramrit/ngx-filter-pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AngularSplitModule } from 'angular-split';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MeDateFilterPipe } from './_pipes/filter-past-only.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NullToArrayPipe } from './_pipes/null-to-array.pipe';
import { WidgetComponent } from './widget/widget.component';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { FilterOutKeysPipe } from './_pipes/filter-out-keys.pipe';
import { SortNodesPipe } from './_pipes/sort-nodes.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WidgetDetailComponent } from './widget-detail/widget-detail.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { SafePipe } from './_pipes/safe.pipe';
import { ConfirmModalComponent } from './shared/components/confirm-modal/confirm-modal.component';
import { SelectFilterComponent } from './shared/components/select-filter/select-filter.component';
import { AngularInputFocusModule } from 'angular-input-focus';
import { BulkUpdateComponent } from './shared/components/bulk-update/bulk-update.component';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { BUCKET } from '@angular/fire/compat/storage';
import { ImageViewerComponent } from './shared/components/image-viewer/image-viewer.component';
import { SvgViewerModalComponent } from './svg-viewer-modal/svg-viewer-modal.component';
import { DatePipe } from '@angular/common';
import { WatchersPreviewComponent } from './shared/components/watchers-preview/watchers-preview.component';
import { WatchersSelectComponent } from './shared/components/watchers-select/watchers-select.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { LinkIconButtonComponent } from './shared/components/link-icon-button/link-icon-button.component';
import { ClearIconButtonComponent } from './shared/components/clear-icon-button/clear-icon-button.component';
import { EffortViewerComponent } from './effort-viewer/effort-viewer.component';
import { EffortTasksComponent } from './effort-tasks/effort-tasks.component';
import { WidgetDetailFingerprintsComponent } from './widget-detail-fingerprints/widget-detail-fingerprints.component';
import { DailyPlanComponent } from './daily-plan/daily-plan.component';
import { DailyPlanDetailComponent } from './daily-plan-detail/daily-plan-detail.component';
import { StatusCountsComponent } from './shared/components/status-counts/status-counts.component';
import { StatusUpdatesComponent } from './status-updates/status-updates.component';
import { PreviewsComponent } from './previews/previews.component';
import { TagsPreviewComponent } from './tags-preview/tags-preview.component';
import { SafeHtmlPipe } from './_pipes/safe-html.pipe';
import { SvgViewerComponent } from './svg-viewer/svg-viewer.component';
import { ColorTableComponent } from './color-table/color-table.component';
import { TranslationTableComponent } from './translation-table/translation-table.component';
import { TranslationDetailsModalComponent } from './translation-details-modal/translation-details-modal.component';
import { TranslationVariableComponent } from './translation-variable/translation-variable.component';
import { TranslationTextComponent } from './translation-text/translation-text.component';
import { ListSpecsOfViewComponent } from './list-specs-of-view/list-specs-of-view.component';
import { SpecTypeCountsOfViewComponent } from './spec-type-counts-of-view/spec-type-counts-of-view.component';
import { TranslationStatusCountsOfViewComponent } from './translation-status-counts-of-view/translation-status-counts-of-view.component';
import { ListTranslationsOfViewComponent } from './list-translations-of-view/list-translations-of-view.component';
import { TasksTableComponent } from './tasks-table/tasks-table.component';
import { TaskDetailsModalComponent } from './task-details-modal/task-details-modal.component';
import { SpecStatusCountsOfTaskComponent } from './spec-status-counts-of-task/spec-status-counts-of-task.component';
import { TranslationStatusCountsOfTaskComponent } from './translation-status-counts-of-task/translation-status-counts-of-task.component';
import { ViewBlockComponent } from './view-block/view-block.component';
import { ViewBlocksOfTaskComponent } from './view-blocks-of-task/view-blocks-of-task.component';
import { ViewStatusCountsOfTaskComponent } from './view-status-counts-of-task/view-status-counts-of-task.component';
import { SpecStatusCountsOfViewComponent } from './spec-status-counts-of-view/spec-status-counts-of-view.component';
import { ListDailysOfTaskComponent } from './list-dailys-of-task/list-dailys-of-task.component';
import { ListSpecsOfTaskComponent } from './list-specs-of-task/list-specs-of-task.component';
import { ListTranslationsOfTaskComponent } from './list-translations-of-task/list-translations-of-task.component';
import { SelectAutocompleteComponent } from './shared/components/select-autocomplete/select-autocomplete.component';
import { SpecTaskBlockComponent } from './spec-task-block/spec-task-block.component';
import { UserTaskHoursComponent } from './user-task-hours/user-task-hours.component';
import { ListPublicationsComponent } from './list-publications/list-publications.component';
import { ReleaseFeatureFlagModalComponent } from './release-feature-flag-modal/release-feature-flag-modal.component';
import { DiagramTableComponent } from './diagram-table/diagram-table.component';
import { WidgetDetailEditComponent } from './widget-detail-edit/widget-detail-edit.component';
import { ColorsEditModalComponent } from './colors-edit-modal/colors-edit-modal.component';
import { ListPreviewsComponent } from './list-previews/list-previews.component';
import { TaskStatusCountOfTasksComponent } from './task-status-count-of-tasks/task-status-count-of-tasks.component';
import { SpecStatusCountOfTasksComponent } from './spec-status-count-of-tasks/spec-status-count-of-tasks.component';
import { OwnerBadgesComponent } from './owner-badges/owner-badges.component';
import { TaskProgressModalComponent } from './task-progress-modal/task-progress-modal.component';
import { TaskListModalComponent } from './task-list-modal/task-list-modal.component';
import { SpecListModalComponent } from './spec-list-modal/spec-list-modal.component';
import { PreviewHistoryComponent } from './preview-history/preview-history.component';
import { SvgDiffComponent } from './svg-diff/svg-diff.component';
import { DailyPlanCardComponent } from './daily-plan-card/daily-plan-card.component';
import { TodoStatusCountOfTodoComponent } from './todos/todo-status-count-of-todo/todo-status-count-of-todo.component';
import { OnlyLowercaseDirective } from './_directives/only-lowercase.directive';
import { dbConfig } from './_configs/db-config';

@NgModule({
  declarations: [
    AppComponent,
    MeDateFilterPipe,
    NullToArrayPipe,
    WidgetComponent,
    FilterOutKeysPipe,
    SortNodesPipe,
    WidgetDetailComponent,
    ModalComponent,
    SafePipe,
    ConfirmModalComponent,
    SelectFilterComponent,
    BulkUpdateComponent,
    ImageViewerComponent,
    SvgViewerModalComponent,
    WatchersSelectComponent,
    NotificationsComponent,
    LinkIconButtonComponent,
    ClearIconButtonComponent,
    EffortViewerComponent,
    EffortTasksComponent,
    WidgetDetailFingerprintsComponent,
    DailyPlanComponent,
    DailyPlanDetailComponent,
    DailyPlanCardComponent,
    StatusCountsComponent,
    StatusUpdatesComponent,
    PreviewsComponent,
    TagsPreviewComponent,
    ColorTableComponent,
    TranslationTableComponent,
    TranslationDetailsModalComponent,
    TranslationVariableComponent,
    TranslationTextComponent,
    ListSpecsOfViewComponent,
    ListSpecsOfTaskComponent,
    ListTranslationsOfViewComponent,
    ListTranslationsOfTaskComponent,
    TasksTableComponent,
    TaskDetailsModalComponent,
    ViewBlockComponent,
    ViewBlocksOfTaskComponent,
    ListDailysOfTaskComponent,
    SelectAutocompleteComponent,
    SpecTaskBlockComponent,
    UserTaskHoursComponent,
    ListPublicationsComponent,
    ReleaseFeatureFlagModalComponent,
    DiagramTableComponent,
    WidgetDetailEditComponent,
    ColorsEditModalComponent,
    ListPreviewsComponent,
    TaskProgressModalComponent,
    TaskListModalComponent,
    SpecListModalComponent,
    PreviewHistoryComponent,
    SvgDiffComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxMatFileInputModule,
    DragDropModule,
    MatGridListModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatRadioModule,
    MatListModule,
    MatTableModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatSortModule,
    ScrollingModule,
    NgxDiffModule,
    MatButtonToggleModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    OnlineStatusModule,
    DateFnsModule,
    NgPipesModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    AngularSplitModule,
    MatTabsModule,
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    ClipboardModule,
    FilterPipeModule,
    AngularInputFocusModule,
    TaskStatusCountOfTasksComponent,
    SpecStatusCountOfTasksComponent,
    TodoStatusCountOfTodoComponent,
    OnlyLowercaseDirective,
    SpecTypeCountsOfViewComponent,
    TranslationStatusCountsOfViewComponent,
    SpecStatusCountsOfViewComponent,
    WatchersPreviewComponent,
    SvgViewerComponent,
    SafeHtmlPipe,
    OwnerBadgesComponent,
    SpecStatusCountsOfTaskComponent,
    ViewStatusCountsOfTaskComponent,
    TranslationStatusCountsOfTaskComponent,
    NgxIndexedDBModule.forRoot(dbConfig),
    MatDatepickerModule,
    MatSnackBarModule,
    MatChipsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    FontAwesomeModule
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 8081] : undefined
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 5001] : undefined
    },
    {
      provide: BUCKET,
      useValue: environment.production ? 'mevolve-project-assets' : 'mevolve-project-dev-assets'
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DatePipe,
    ScreenTrackingService,
    UserTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
