<span class="ri-pointer" *ngIf="!isEmpty; else noSpecs" (click)="showSpecList(viewId, $event)">
    <span class="badge rounded-pill text-bg-warning me-1" matTooltip="State" [ngClass]="{ 'opacity-25': !state }">
        {{ state || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-review me-1" matTooltip="Action" [ngClass]="{ 'opacity-25': !action }">
        {{ action || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-info me-1" matTooltip="Rule" [ngClass]="{ 'opacity-25': !rule }">
        {{ rule || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-danger me-1" matTooltip="Bug" [ngClass]="{ 'opacity-25': !bug }">
        {{ bug || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-dark me-1" matTooltip="Total" [ngClass]="{ 'opacity-25': !total }">
        {{ total }}
    </span>
</span>
<ng-template #noSpecs>
    <span>- - -</span>
</ng-template>
