<main class="px-3 pb-3 ri-min-w-1000">
  <!-- list all translations using flex bootstrap 5 -->
  <div class="sticky-top bg-body p-2 d-flex align-items-center justify-content-between">
    <span class="fs-6">Translations for viewId - <span class="text-primary">{{data.viewId}}</span></span>
    <div class="d-flex">
      <mat-form-field class="select-control ri-max-w-200 ri-min-w-200 ms-2" appearance="fill" [class]="{ edited: filterOptions.langFilter.selected !== 'en' }">
        <mat-label>Language</mat-label>
        <mat-select [(value)]="filterOptions.langFilter.selected" placeholder="Status">
            <mat-option *ngFor="let item of filterOptions.langFilter.options" [value]="item">
                {{ item }}
            </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="select-control ri-max-w-200 ri-min-w-200 ms-2" appearance="fill" [class]="{ edited: filterOptions.statusFilter.selected !== 'All' }">
          <mat-label>Status</mat-label>
          <mat-select [(value)]="filterOptions.statusFilter.selected" placeholder="Status" (selectionChange)="onStatusChange()">
              <mat-option *ngFor="let item of filterOptions.statusFilter.options" [value]="item">
                  {{ item }}
              </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="ri-min-h-300 mt-2 px-2" *ngIf="translations?.length;else noTranslations">
    <table class="table translations-list">
      <tbody>
        <tr class="ri-pointer" *ngFor="let translation of translations" (click)="openTranslation(translation)">
          <td scope="row" valign="middle"><span class="badge round-pill text-capitalize" [class]="statusClassMap[translation.status]">{{translation.status}}</span></td>
          <td valign="middle">
            <div [cdkCopyToClipboard]="translation.translationId" (click)="snackbar.show('Translation ID copied to clipboard','Close',1000, $event)"> {{translation.translationId}}</div>
          </td>
          <td class="en" valign="middle">
            <div>{{ translation.text[filterOptions.langFilter.selected] || '- - -' }}</div>
            <div class="text-muted opacity-50">{{ translation.designText || '- - -' }}</div>
          </td>
          <td valign="middle">
            <span *ngIf="(translation.variables | keys).length" matTooltip="Variables" class="badge round-pill text-bg-primary">{{(translation.variables | keys).length}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="m-2">
    <button *ngIf="data.viewId !== 'screen_common'" class="btn btn-warning btn-sm m-1"
      (click)="showCommonTranslations($event)">
      Common Translations
    </button>
    <button class="btn btn-primary btn-sm m-1 float-end" (click)="addTranslation()">
      Add Translation
    </button>
  </div>
</main>
<ng-template #noTranslations>
  <div class="text-center text-muted py-5 my-5">No Translations</div>
</ng-template>