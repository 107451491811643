import { Component, EventEmitter, Inject, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { serverTimestamp } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { JsTask } from '../_interfaces/Task';
import { CacheService } from '../_services/cache.service';
import { ConfirmService } from '../_services/confirm.service';
import { FirebaseOptimisticService } from '../_services/firebase-optimistic.service';
import { FirebaseService } from '../_services/firebase.service';
import { getNewId } from '../shared/utils';
import { SnackbarService } from '../_services/snackbar.service';
import { Timestamp } from '@angular/fire/firestore';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { ListDailysOfTaskComponent } from '../list-dailys-of-task/list-dailys-of-task.component';
import { SelectFilterComponent } from '../shared/components/select-filter/select-filter.component';
import { OnDestroy } from '@angular/core';
import { DailyPlanDetailComponent } from '../daily-plan-detail/daily-plan-detail.component';
import { SharedFunctionService } from '../_services/shared-function.service';
import { ActivityService } from '../_services/activity.service';
import { IdbServiceService } from '../_services/idb-service.service';
import { Subject, takeUntil } from 'rxjs';
import { JsTodo } from '../_interfaces/Todo';
import { TodoListComponent } from '../todos/todo-list/todo-list.component';

@Component({
  selector: 'app-task-details-modal',
  templateUrl: './task-details-modal.component.html',
  styleUrls: ['./task-details-modal.component.scss']
})
export class TaskDetailsModalComponent implements OnInit, OnDestroy {
  @ViewChild('blockingTasksFilter')
  blockingTasksFilter: SelectFilterComponent | null = null;
  @ViewChild('relatedTasksFilter')
  relatedTasksFilter: SelectFilterComponent | null = null;
  initialTask!: JsTask;
  taskToEdit!: JsTask;
  mode: 'add' | 'edit' = 'add';
  taskTypes = ['Feature', 'Optimisation', 'Routine', 'Bug', 'Other'];
  taskPriorities = ['Low', 'Default', 'High', 'Critical'];
  taskStatuses = ['Backlog', 'Design', 'Review', 'Develop', 'Test', 'Failed', 'Mergeable', 'Passed', 'Approved'];
  taskSubStatuses = ['To Do', 'In Progress', 'Blocked', 'Done'];
  filterOptions = {
    blockedByTids: {
      title: 'Blocked By Features',
      options: [] as { label: string; value: number }[],
      selectedEmitter: new EventEmitter<number[]>(),
      initialSelection: [] as number[]
    },
    relatedTids: {
      title: 'Related Features',
      options: [] as { label: string; value: number }[],
      selectedEmitter: new EventEmitter<number[]>(),
      initialSelection: [] as number[]
    }
  };
  updationInProgress = false;
  deletionInProgress = false;
  errorMessage = '';
  showTimeLogs = false;
  unSubscribe = new Subject<void>();
  relatedTodos: WritableSignal<JsTodo[]> = signal([]);
  taskInitial!: JsTask;

  constructor(
    public fbs: FirebaseService,
    public cc: CacheService,
    private fbo: FirebaseOptimisticService,
    private fns: AngularFireFunctions,
    private snackbar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: JsTask | undefined,
    public dialogRef: MatDialogRef<TaskDetailsModalComponent>,
    private confirmService: ConfirmService,
    private _dialog: DialogManagerService,
    public dialog: MatDialog,
    public sharedFunc: SharedFunctionService,
    private as: ActivityService,
    private idb: IdbServiceService
  ) {
    if (this.data) {
      this.taskInitial = this.data;
      this.initialTask = _.cloneDeep(this.data);
      this.taskToEdit = _.cloneDeep(this.data);
      this.mode = 'edit';
    } else {
      this.taskToEdit = {
        updatedAt: new Date(),
        cloudUpdatedAt: serverTimestamp(),
        createdAt: new Date(),
        deletedAt: null,
        createdBy: this.fbs.getCurrentUserId(),
        uid: this.fbs.getCurrentUserId(),
        assignedTo: '',
        id: getNewId(),
        priority: 'Default',
        status: 'Backlog',
        subStatus: 'To Do',
        type: 'Feature',
        title: '',
        description: '',
        currentScope: '',
        tid: this.getNextTaskId(),
        order: this.getNextOrderId(),
        includePatterns: '',
        excludePatterns: '',
        blockedByTids: [],
        relatedTids: [],
        committedAt: null,
        timeLogs: [],
        productOwner: '',
        designOwner: '',
        devOwner: '',
        testOwner: '',
        fromRelease: this.cc.config?.nextRelease || null,
        toRelease: null,
        deliverable: '',
        latestComment: null
      };
      this.initialTask = _.cloneDeep(this.taskToEdit);
      this.mode = 'add';
    }

    this.filterOptions.blockedByTids.initialSelection = this.taskToEdit.blockedByTids;
    this.filterOptions.relatedTids.initialSelection = this.taskToEdit.relatedTids;

    const options = this.cc.allTasks
      .filter(task => task.tid !== this.taskToEdit.tid)
      .map(task => ({
        label: `F-${task.tid} ${task.title}`,
        value: task.tid
      }));

    this.filterOptions.blockedByTids.options = _.cloneDeep(options);
    this.filterOptions.relatedTids.options = _.cloneDeep(options);
  }

  getActivity() {
    this.as.getActivity('task', this.taskToEdit);
  }

  ngOnDestroy() {
    this.filterOptions.blockedByTids.selectedEmitter?.unsubscribe();
    this.filterOptions.relatedTids.selectedEmitter?.unsubscribe();
    this.unSubscribe.complete();
    this.unSubscribe.next();
  }

  ngOnInit(): void {
    this.filterOptions.blockedByTids.selectedEmitter.subscribe(value => {
      this.taskToEdit.blockedByTids = value;
    });
    this.filterOptions.relatedTids.selectedEmitter.subscribe(value => {
      this.taskToEdit.relatedTids = value;
    });

    this.getRelatedTodos()
  }

  getRelatedTodos() {
    this.idb.todos$.pipe(takeUntil(this.unSubscribe)).subscribe(res => {
      const filteredTodos = res.filter(todo => todo.relatedTids.includes(this.taskToEdit.id) && !todo.deletedAt)
      this.relatedTodos.set(filteredTodos);
    });
  }

  viewRelatedTodosList(task: JsTask) {
    if(!task.id) {
      return
    }
    this.dialog.open(TodoListComponent, {
      data: {
        task: task
      }
    });
  }

  getNextTaskId(): number {
    const maxTaskId = _.max(this.cc.allTasks.map(task => task.tid)) || 0;
    return maxTaskId + 1;
  }

  getNextOrderId(): number {
    const maxOrderId = _.max(this.cc.allTasks.map(task => task.order)) || 0;
    return maxOrderId + 1;
  }

  setFilteredValues(src: string, value: string, result: string): void {
    const filterValue = value.toLowerCase();
    // @ts-ignore
    this[result] = this[src].filter(option => option.toLowerCase().includes(filterValue));
  }

  hasChanges(): boolean {
    return !_.isEqual(this.taskToEdit, this.initialTask);
  }

  isValid(): boolean {
    this.errorMessage = '';
    let result = true;
    const { title, description, fromRelease, toRelease } = this.taskToEdit;
    if (!title || !description) {
      result = false;
      this.errorMessage = 'Title and Description are mandatory fields';
    }

    // If fromRelease is set to 0 or greater, then toRelease should be either null or undefined or greater than fromRelease
    if (fromRelease != null && toRelease != null && toRelease < fromRelease) {
      result = false;
      this.errorMessage = 'toRelease cannot be less than fromRelease';
    }

    // If fromRelease is not set, then toRelease should be null or undefined
    if (fromRelease == null && toRelease != null) {
      result = false;
      this.errorMessage = 'fromRelease should be set if toRelease is set';
    }
    return result;
  }

  resetHandler(): void {
    this.taskToEdit = _.cloneDeep(this.initialTask);
    this.blockingTasksFilter?.reset();
    this.filterOptions.blockedByTids.selectedEmitter.emit(this.taskToEdit.blockedByTids);
    this.relatedTasksFilter?.reset();
    this.filterOptions.relatedTids.selectedEmitter.emit(this.taskToEdit.relatedTids);
    this.isValid();
  }

  updateHandler() {
    this.updationInProgress = true;
    const updatedTask = _.cloneDeep(this.taskToEdit);
    updatedTask.updatedAt = new Date();
    updatedTask.cloudUpdatedAt = serverTimestamp();
    updatedTask.uid = this.fbs.getCurrentUserId();
    updatedTask.deliverable = updatedTask.title.split(' - ').length > 1 ? updatedTask.title.split(' - ')[0] : '';
    if (this.mode === 'add') {
      updatedTask.createdAt = new Date();
      this.fbo.createItemsOptimistic<JsTask>([updatedTask], 'tasks').then(() => {
        this.updationInProgress = false;
      });
    } else {
      this.fbo.updateItemsOptimistic<JsTask>([updatedTask], 'tasks').then(() => {
        this.updationInProgress = false;
      });
    }
    this.dialogRef.close();
  }

  async deleteHandler() {
    const confirmed = await this.confirmService.confirm('Delete Feature', 'Are you sure you want to delete this feature? This action cannot be undone.');
    if (!confirmed) {
      return;
    }
    this.deletionInProgress = true;
    const deletedTask = _.cloneDeep(this.taskToEdit);
    deletedTask.deletedAt = new Date();
    deletedTask.cloudUpdatedAt = serverTimestamp();
    deletedTask.uid = this.fbs.getCurrentUserId();
    await this.fbo.updateItemsOptimistic<JsTask>([deletedTask], 'tasks');
    this.deletionInProgress = false;
    this.dialogRef.close();
  }

  getTimeDistance(time: Date): string {
    const timeDiff = new Date().getTime() - time.getTime();
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    if (hours > 24) {
      const days = Math.floor(hours / 24);
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  }

  showTaskDailys(task: JsTask) {
    // Show app-task-dailys component in bottom sheet
    this._dialog.openDialog(ListDailysOfTaskComponent, {
      data: { task: task }
    });
  }

  getTotalTimeSpent(task: JsTask): number {
    return task.timeLogs.reduce((acc, curr) => acc + curr.hours, 0);
  }

  openTask(task: JsTask) {
    // Open the translation details modal
    const confirmDialog = this._dialog.openDialog(TaskDetailsModalComponent, {
      data: task
    });
    return confirmDialog.afterClosed();
  }

  openDailyPlanModal(isEdit: boolean) {
    const planDialog = this.dialog.open(DailyPlanDetailComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        from: 'model',
        selectedDaily: isEdit ? this.cc.getDailyByTid(this.taskToEdit?.tid, 'Feature') : this.sharedFunc.createNewDailyTask(this.taskToEdit?.tid, 'Feature'),
        mode: isEdit ? 'edit' : 'new'
      },
      autoFocus: false
    });
  }

}
