import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirebaseService } from '../_services/firebase.service';
import { JsDaily } from '../_interfaces/Daily';
import { CacheService } from '../_services/cache.service';
import * as _ from 'lodash';
import { JsTask } from '../_interfaces/Task';

@Component({
  selector: 'app-list-dailys-of-task',
  templateUrl: './list-dailys-of-task.component.html',
  styleUrls: ['./list-dailys-of-task.component.scss'],
})
export class ListDailysOfTaskComponent implements OnInit {
  dailys: JsDaily[] = [];
  public mapClass = {
    'Not Started': 'bg-info',
    'In Progress': 'bg-primary',
    Blocked: 'bg-warning',
    Completed: 'bg-success',
  };
  constructor(
    private _bottomSheetRef: MatDialogRef<ListDailysOfTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { task: JsTask },
    public fbs: FirebaseService,
    public cc: CacheService
  ) {}

  ngOnInit(): void {
    this.fbs.getDailysOfTask(this.data.task.tid).subscribe((dailys) => {
      this.dailys = dailys;
    });
  }
}
