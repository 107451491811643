<mat-form-field appearance="fill" class="w-100 h-100" [ngClass]="{ 'edited': hasChanged, 'ri-disabled' : disabled }">
    <mat-label>{{title}}</mat-label>
    <mat-select [formControl]="control" [multiple]="multiple" [placeholder]="title" (selectionChange)="selectEvent.emit()" [disabled]="disabled">
        <mat-select-trigger *ngIf="multiple">
            {{getFirstIndexValue() || ''}}
            <span *ngIf="(control.value?.length || 0) > 1">
                + {{(control.value?.length || 0) - 1}}
            </span>
        </mat-select-trigger>
        <mat-option *ngIf="noneOption" value = ''>None</mat-option>
        <mat-option *ngIf="allOption" value = 'All'>ALL</mat-option>
        <mat-option *ngFor="let item of options" [value]="value ? item[value] : item" [disabled]="disabledOptions.includes(value ? item[value] : item)" [hidden]="hiddenOptions.includes(value ? item[value] : item)">
            <span class="text-primary" *ngIf="prefix">{{prefix}}</span>
            <span class="text-primary" *ngIf="prefixValue">{{item[prefixValue]}} &nbsp;</span>
            <span>{{ label ? item[label] : item }}</span>
        </mat-option>
    </mat-select>
</mat-form-field>