import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppThemeConfig } from '../_interfaces/Config';
import * as _ from 'lodash';
import { FirebaseService } from '../_services/firebase.service';
import { sortNestedColors, sortObjectKeys } from '../shared/utils';

@Component({
  selector: 'app-colors-edit-modal',
  templateUrl: './colors-edit-modal.component.html',
  styleUrls: ['./colors-edit-modal.component.scss'],
})
export class ColorsEditModalComponent implements OnInit {
  initialThemeInfo: AppThemeConfig = {} as any;
  initialThemeInfoJson = '';
  themeInfoToEdit: AppThemeConfig = {} as any;
  themeInfoJsonToEdit = '';
  invalidJson = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AppThemeConfig,
    public dialogRef: MatDialogRef<ColorsEditModalComponent>,
    private fbs: FirebaseService
  ) {}

  ngOnInit(): void {
    this.initialThemeInfo = _.cloneDeep(this.data);
    this.initialThemeInfoJson = this.stringify(this.initialThemeInfo);
    this.themeInfoToEdit = _.cloneDeep(this.data);
    this.themeInfoJsonToEdit = this.stringify(this.themeInfoToEdit);
  }

  getLatestThemeInfoToEdit() {
    this.invalidJson = false;
    // wrap in try catch
    try {
      return JSON.parse(this.themeInfoJsonToEdit);
    } catch (error) {
      this.invalidJson = true;
      console.error('Error parsing JSON', error);
    }
  }

  isSame() {
    return _.isEqual(this.initialThemeInfo, this.getLatestThemeInfoToEdit());
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  save() {
    this.themeInfoToEdit = this.getLatestThemeInfoToEdit();
    this.fbs.updateThemeInfo(this.themeInfoToEdit).then(() => {
      this.dialogRef.close(this.themeInfoToEdit);
    });
  }

  reset() {
    this.themeInfoToEdit = _.cloneDeep(this.initialThemeInfo);
    this.themeInfoJsonToEdit = this.stringify(this.themeInfoToEdit);
  }

  stringify(obj: any) {
    const sorted = sortObjectKeys(obj);
    const data = sortNestedColors(sorted);
    return JSON.stringify(data, null, 2);
  }
}
