<main class="leave-form p-3 ri-w-600" [ngClass]="{'pe-none': leaveInitial.deletedAt}">
    <h2 class="fw-semibold mb-3">Leave {{ mode === 'new' ? 'Apply' : 'Update' }}</h2>

    <div class="ri-dialog-content">
        <form [formGroup]="leaveForm" #lvForm="ngForm">
            <app-select [options]="fbs.users" [title]="'Staff Name'" class="mb-1 d-block" [control]="getFc('uid')"
                [defaultValue]="leaveInitial.uid" [label]="'label'" [disabled]="true" [value]="'value'"></app-select>

            <div class="row g-1 mb-1">
                <div class="col-6">
                    <mat-form-field appearance="fill" class="w-100"
                        [class]="{ edited: leaveForm.value.startDate != leaveInitial.startDate }">
                        <mat-label>Start Date</mat-label>
                        <input matInput [matDatepicker]="matRiDatePicker" formControlName="startDate" name="riLeaveStartDate" (dateChange)="getEndDate()">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="matRiDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #matRiDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" class="w-100"
                        [class]="{ edited: leaveForm.value.workDays != leaveInitial.workDays }">
                        <mat-label>Number of Work Days</mat-label>
                        <input type="number" placeholder="2" matInput formControlName="workDays"
                            name="riLeaveWorkDays" (input)="getEndDate()" />
                    </mat-form-field>
                </div>
            </div>
            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : leaveForm.value.reason !== leaveInitial.reason}">
                <mat-label>Reason</mat-label>
                <textarea matInput formControlName="reason" name="riLeaveReason" cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>
            <p class="mb-0 text-center py-3 text-warning" *ngIf="leaveForm.value.endDate">Leave Ends in {{ leaveForm.value.endDate | date: 'dd/MM/yyyy' }}</p>
        </form>
    </div>

    <div class="ri-dialog-footer mt-4">
        <p class="ri-fs-12px text-danger">Please ensure that it cannot be edited or deleted after saving.*</p>
        <div class="d-flex justify-content-between" *ngIf="!leaveInitial.deletedAt">
            <div>
                <button *ngIf="mode === 'edit'" type="button" class="btn btn-sm text-bg-danger ri-fs-16px lh-sm"
                    (click)="deleteLeave()" [disabled]="leaveInitial.deletedAt" matTooltip="Delete">
                    <i class="bi bi-trash"></i>
                </button>
            </div>
            <div>
                <button type="button" class="btn btn-sm btn-outline-secondary" (click)="cancelChanges()"
                    [disabled]="!hasChanges()">Reset</button>
                <button type="submit" class="btn btn-sm btn-primary ms-2" (click)="saveChanges()"
                    [disabled]="!hasChanges() || leaveForm.invalid">Save</button>
            </div>
        </div>
    </div>
</main>