import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-link-icon-button',
  templateUrl: './link-icon-button.component.html',
  styleUrls: ['./link-icon-button.component.scss'],
})
export class LinkIconButtonComponent implements OnInit, OnChanges {
  public linkIcon = faArrowUpRightFromSquare;
  @Input() link: string | null = null;
  @Input() tooltip: string = '';
  @Input() noPadding: boolean = false;
  hasValidLink = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['link'] &&
      changes['link'].currentValue &&
      changes['link'].currentValue.split(' ').length === 1 &&
      changes['link'].currentValue.startsWith('https://')
    ) {
      this.hasValidLink = true;
    } else {
      this.hasValidLink = false;
    }
  }

  openLink() {
    if (this.hasValidLink && this.link) {
      window.open(this.link, '_blank');
    }
  }
}
