<span class="ri-pointer" *ngIf="!isEmpty; else noSpecs" (click)="showSpecsListOfTask(task, $event)">
    <span class="badge rounded-pill text-bg-info me-1" matTooltip="Backlog" [ngClass]="{ 'opacity-25': !Backlog }">
        {{ Backlog || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-secondary me-1" matTooltip="Design" [ngClass]="{ 'opacity-25': !Design }">
        {{ Design || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-review me-1" matTooltip="Review" [ngClass]="{ 'opacity-25': !Review }">
        {{ Review || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-primary me-1" matTooltip="Develop" [ngClass]="{ 'opacity-25': !Develop }">
        {{ Develop || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-warning me-1" matTooltip="Test" [ngClass]="{ 'opacity-25': !Test }">
        {{ Test || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-danger me-1" matTooltip="Failed" [ngClass]="{ 'opacity-25': !Failed }">
        {{ Failed || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-success me-1" matTooltip="Approve" [ngClass]="{ 'opacity-25': !Approve }">
        {{ Approve || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-dark" matTooltip="Total" [ngClass]="{ 'opacity-25': !Total }">
        {{ Total }}
    </span>
</span>
<ng-template #noSpecs>
    <span class="d-inline-block">- - -</span>
</ng-template>
