<main class="ri-w-900 p-3">
  <!-- list all widgets using flex bootstrap 5 -->
  <div class="sticky-top bg-body pb-2">
    <span class="fs-6">Feature Progress</span>
  </div>
  <div class="titles">
    <div class="w-20 py-3 fw-bolder">Name</div>
    <div class="w-40 py-3 fw-bolder">Up</div>
    <div class="w-40 py-3 fw-bolder">Down</div>
  </div>
  <div class="contents">
    @for (uid of fbs.orderedUserIds; track uid) {
    <div class="w-20 py-2">{{ fbs.getDisplayName(uid) }}</div>
    <div class="w-40 py-2">
      <ng-container *ngTemplateOutlet="statusBadges; context: { uid: uid, direction: 'up' }"></ng-container>
    </div>
    <div class="w-40 py-2">
      <ng-container *ngTemplateOutlet="statusBadges; context: { uid: uid, direction: 'down' }"></ng-container>
    </div>
    }
  </div>
</main>

<ng-template #statusBadges let-uid="uid" let-direction="direction">
  <span class="ri-pointer" (click)="showTasks(uid, direction)">
    <span
      *ngFor="let status of statusList"
      class="badge rounded-pill {{ statusClassMap[status] }} me-1"
      [matTooltip]="getTooltip(status, uid)"
      [ngClass]="{ 'opacity-25': !getNumber(status, uid, direction) }">
      {{ getNumber(status, uid, direction) || '-' }}
    </span>
  </span>
</ng-template>
