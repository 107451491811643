import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'app-select-config',
  standalone: true,
  imports: [
    CommonModule,
    MatCheckbox
  ],
  templateUrl: './select-config.component.html',
  styleUrl: './select-config.component.scss'
})

export class SelectConfigComponent {

  title: string;
  options: string[];
  selectedOptions: string[] = [];
  selectedOptionsInitial: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, options: string[], selectedOptions: string[] },
    public dialogRef: MatDialogRef<SelectConfigComponent>,
  ) {
    this.title = data.title;
    this.options = data.options;
    this.selectedOptions = _.cloneDeep(this.data.selectedOptions);
    this.selectedOptionsInitial = _.cloneDeep(this.data.selectedOptions);
  }

  toggleCheckBox(e: any, value: string) {
    const isChecked = e.checked;
    if (isChecked && !this.selectedOptions.includes(value)) {
      this.selectedOptions.push(value);
    } else if (!isChecked && this.selectedOptions.includes(value)) {
      const index = this.selectedOptions.indexOf(value);
      this.selectedOptions.splice(index, 1);
    } else {
      return;
    }
  }

  reset() {
    this.selectedOptions = this.selectedOptionsInitial;
  }

  update() {
    this.dialogRef.close(this.selectedOptions)
  }
}
