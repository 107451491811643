import { Component, OnDestroy, OnInit } from '@angular/core';
import { IdbServiceService } from '../_services/idb-service.service';
import { format, startOfWeek } from 'date-fns';
import { JsActivity } from '../_interfaces/Activity';
import { FirebaseService } from '../_services/firebase.service';
import {
  DateEffort,
  Effort,
  GrandTotalEffort,
  UserEffort,
} from '../_interfaces/Other';

@Component({
  selector: 'app-effort-viewer',
  templateUrl: './effort-viewer.component.html',
  styleUrls: ['./effort-viewer.component.scss'],
})
export class EffortViewerComponent implements OnInit, OnDestroy {
  private idbSubscription: any;
  public selectedStatusChange: Effort | null = null;
  public selectedWho: string | null = null;
  public selectedWhen: string | null = null;
  public showTasksList = false;
  public activities: JsActivity[] = [];
  public selectedWeek: 'current' | 'previous' | 'secondLast' = 'current';
  public userEffort: UserEffort = {};
  public dateEffort: DateEffort = {};
  public grandTotalEffort: GrandTotalEffort = {
    before: {},
    after: {},
  };
  private startOfCurrentWeek: Date = startOfWeek(new Date(), {
    weekStartsOn: 0,
  });
  public selectedWeekString: string[] = [];
  constructor(private idb: IdbServiceService, public fbs: FirebaseService) {}

  ngOnInit(): void {
    this.idbSubscription = this.idb.statusUpdates$.subscribe(
      (activities: JsActivity[]) => {
        this.activities = activities;
        this.initialise();
      }
    );
  }

  ngOnDestroy() {
    this.idbSubscription?.unsubscribe();
  }

  initialise() {
    let offset = 0;
    switch (this.selectedWeek) {
      case 'current':
        offset = 0;
        break;
      case 'previous':
        offset = -7;
        break;
      case 'secondLast':
        offset = -14;
        break;
    }

    this.selectedWeekString = [0, 1, 2, 3, 4, 5, 6].map((day) => {
      const date = new Date(this.startOfCurrentWeek);
      date.setDate(date.getDate() + day + offset);
      return format(date, 'yyyy-MM-dd');
    });
    this.userEffort = {};
    this.dateEffort = {};
    this.grandTotalEffort = {
      before: {},
      after: {},
    };
    this.activities.forEach((activity) => {
      const { createdBy, createdAt, before, after } = activity;
      const date = format(new Date(createdAt), 'yyyy-MM-dd');
      // @ts-ignore
      const beforeStatus = before?.status || null;
      // @ts-ignore
      const afterStatus = after?.status || null;
      // @ts-ignore
      const beforePath = before?.path || null;
      // @ts-ignore
      const afterPath = after?.path || null;

      // Remove unwanted activities out of date range
      if (!this.selectedWeekString.includes(date)) {
        return;
      }

      // User Effort
      if (!this.userEffort?.[createdBy]) {
        this.userEffort[createdBy] = {
          [date]: {
            before: {},
            after: {},
          },
          total: {
            before: {},
            after: {},
          },
        };
      }
      if (!this.userEffort?.[createdBy]?.[date]) {
        this.userEffort[createdBy][date] = {
          before: {},
          after: {},
        };
      }
      if (!this.userEffort?.[createdBy]?.[date]?.before?.[beforeStatus]) {
        this.userEffort[createdBy][date].before[beforeStatus] = {};
      }
      if (!this.userEffort?.[createdBy]?.[date]?.after?.[afterStatus]) {
        this.userEffort[createdBy][date].after[afterStatus] = {};
      }
      if (
        !this.userEffort?.[createdBy]?.[date]?.before?.[beforeStatus]?.[
          beforePath
        ]
      ) {
        this.userEffort[createdBy][date].before[beforeStatus][beforePath] = [
          activity,
        ];
      } else {
        this.userEffort[createdBy][date].before[beforeStatus][beforePath].push(
          activity
        );
      }
      if (
        !this.userEffort?.[createdBy]?.[date]?.after?.[afterStatus]?.[afterPath]
      ) {
        this.userEffort[createdBy][date].after[afterStatus][afterPath] = [
          activity,
        ];
      } else {
        this.userEffort[createdBy][date].after[afterStatus][afterPath].push(
          activity
        );
      }
      // Total Effort
      if (!this.userEffort?.[createdBy]?.total) {
        this.userEffort[createdBy].total = {
          before: {},
          after: {},
        };
      }
      if (!this.userEffort?.[createdBy]?.total?.before?.[beforeStatus]) {
        this.userEffort[createdBy].total.before[beforeStatus] = {};
      }
      if (!this.userEffort?.[createdBy]?.total?.after?.[afterStatus]) {
        this.userEffort[createdBy].total.after[afterStatus] = {};
      }
      if (
        !this.userEffort?.[createdBy]?.total?.before?.[beforeStatus]?.[
          beforePath
        ]
      ) {
        this.userEffort[createdBy].total.before[beforeStatus][beforePath] = [
          activity,
        ];
      } else {
        this.userEffort[createdBy].total.before[beforeStatus][beforePath].push(
          activity
        );
      }
      if (
        !this.userEffort?.[createdBy]?.total?.after?.[afterStatus]?.[afterPath]
      ) {
        this.userEffort[createdBy].total.after[afterStatus][afterPath] = [
          activity,
        ];
      } else {
        this.userEffort[createdBy].total.after[afterStatus][afterPath].push(
          activity
        );
      }

      // Date Effort
      if (!this.dateEffort?.[date]) {
        this.dateEffort[date] = {
          [createdBy]: {
            before: {},
            after: {},
          },
          total: {
            before: {},
            after: {},
          },
        };
      }
      if (!this.dateEffort?.[date]?.[createdBy]) {
        this.dateEffort[date][createdBy] = {
          before: {},
          after: {},
        };
      }
      if (!this.dateEffort?.[date]?.[createdBy]?.before?.[beforeStatus]) {
        this.dateEffort[date][createdBy].before[beforeStatus] = {};
      }
      if (!this.dateEffort?.[date]?.[createdBy]?.after?.[afterStatus]) {
        this.dateEffort[date][createdBy].after[afterStatus] = {};
      }
      if (
        !this.dateEffort?.[date]?.[createdBy]?.before?.[beforeStatus]?.[
          beforePath
        ]
      ) {
        this.dateEffort[date][createdBy].before[beforeStatus][beforePath] = [
          activity,
        ];
      } else {
        this.dateEffort[date][createdBy].before[beforeStatus][beforePath].push(
          activity
        );
      }
      if (
        !this.dateEffort?.[date]?.[createdBy]?.after?.[afterStatus]?.[afterPath]
      ) {
        this.dateEffort[date][createdBy].after[afterStatus][afterPath] = [
          activity,
        ];
      } else {
        this.dateEffort[date][createdBy].after[afterStatus][afterPath].push(
          activity
        );
      }

      // Total Effort
      if (!this.dateEffort?.[date]?.total) {
        this.dateEffort[date].total = {
          before: {},
          after: {},
        };
      }
      if (!this.dateEffort?.[date]?.total?.before?.[beforeStatus]) {
        this.dateEffort[date].total.before[beforeStatus] = {};
      }
      if (!this.dateEffort?.[date]?.total?.after?.[afterStatus]) {
        this.dateEffort[date].total.after[afterStatus] = {};
      }
      if (
        !this.dateEffort?.[date]?.total?.before?.[beforeStatus]?.[beforePath]
      ) {
        this.dateEffort[date].total.before[beforeStatus][beforePath] = [
          activity,
        ];
      } else {
        this.dateEffort[date].total.before[beforeStatus][beforePath].push(
          activity
        );
      }
      if (!this.dateEffort?.[date]?.total?.after?.[afterStatus]?.[afterPath]) {
        this.dateEffort[date].total.after[afterStatus][afterPath] = [activity];
      } else {
        this.dateEffort[date].total.after[afterStatus][afterPath].push(
          activity
        );
      }

      // Grand Total Effort
      if (!this.grandTotalEffort?.before?.[beforeStatus]) {
        this.grandTotalEffort.before[beforeStatus] = {};
      }
      if (!this.grandTotalEffort?.after?.[afterStatus]) {
        this.grandTotalEffort.after[afterStatus] = {};
      }
      if (!this.grandTotalEffort?.before?.[beforeStatus]?.[beforePath]) {
        this.grandTotalEffort.before[beforeStatus][beforePath] = [activity];
      } else {
        this.grandTotalEffort.before[beforeStatus][beforePath].push(activity);
      }
      if (!this.grandTotalEffort?.after?.[afterStatus]?.[afterPath]) {
        this.grandTotalEffort.after[afterStatus][afterPath] = [activity];
      } else {
        this.grandTotalEffort.after[afterStatus][afterPath].push(activity);
      }
    });
  }

  setSelectedWeek(e: any) {
    console.log('setSelectedWeek', e.value);
    if (e.value && this.selectedWeek !== e.value) {
      this.selectedWeek = e.value;
      this.initialise();
    }
  }

  getUserStatusCount(
    user: string,
    date: string,
    status: string,
    type: 'before' | 'after'
  ) {
    if (this.userEffort?.[user]?.[date]?.[type]?.[status]) {
      let count = 0;
      for (const path in this.userEffort[user][date][type][status]) {
        if (this.userEffort[user][date][type][status].hasOwnProperty(path)) {
          const items = this.userEffort[user][date][type][status][path];
          count += items.length;
        }
      }
      return count;
    } else {
      return 0;
    }
  }

  getUserStatusCountObject(user: string | unknown, date: string) {
    const statusCounts = {
      before: {
        Backlog: 0,
        Design: 0,
        Review: 0,
        Develop: 0,
        Test: 0,
        Failed: 0,
        Approve: 0,
        Total: 0,
      },
      after: {
        Backlog: 0,
        Design: 0,
        Review: 0,
        Develop: 0,
        Test: 0,
        Failed: 0,
        Approve: 0,
        Total: 0,
        Points: 0,
      },
    };
    const statuses = [
      'Backlog',
      'Design',
      'Review',
      'Develop',
      'Test',
      'Failed',
      'Approve',
    ];

    statuses.forEach((status) => {
      const beforeCount = this.getUserStatusCount(
        user as string,
        date,
        status,
        'before'
      );
      const afterCount = this.getUserStatusCount(
        user as string,
        date,
        status,
        'after'
      );
      // @ts-ignore
      statusCounts.before[status] = beforeCount;
      statusCounts.before.Total += beforeCount;
      // @ts-ignore
      statusCounts.after[status] = afterCount;
      statusCounts.after.Total += afterCount;
      statusCounts.after.Points = this.getPointsFromStatusCounts(statusCounts);
    });
    return statusCounts;
  }

  getPointsFromActivity(activity: JsActivity) {
    const points = {
      Backlog: 1,
      Design: 2,
      Review: 3,
      Develop: 4,
      Test: 5,
      Failed: 6,
      Approve: 7,
    };
    // @ts-ignore
    const beforePoints = points?.[activity?.before?.status] || 0;
    // @ts-ignore
    const afterPoints = points?.[activity?.after?.status] || 0;
    return afterPoints - beforePoints;
  }

  getPointsFromStatusCounts(statusCounts: {
    before: { [key: string]: number };
    after: { [key: string]: number };
  }) {
    const points = {
      Backlog: 1,
      Design: 2,
      Review: 3,
      Develop: 4,
      Test: 5,
      Failed: 6,
      Approve: 7,
    };
    let totalPoints = 0;
    for (const status in points) {
      const afterCount = statusCounts.after?.[status] || 0;
      const beforeCount = statusCounts.before?.[status] || 0;
      // @ts-ignore
      const pointValue = points[status];
      totalPoints += (afterCount - beforeCount) * pointValue;
    }
    return totalPoints;
  }

  getDateStatusCountObject(date: string) {
    const statusCounts = {
      before: {
        Backlog: 0,
        Design: 0,
        Review: 0,
        Develop: 0,
        Test: 0,
        Failed: 0,
        Approve: 0,
        Total: 0,
      },
      after: {
        Backlog: 0,
        Design: 0,
        Review: 0,
        Develop: 0,
        Test: 0,
        Failed: 0,
        Approve: 0,
        Total: 0,
        Points: 0,
      },
    };
    const statuses = [
      'Backlog',
      'Design',
      'Review',
      'Develop',
      'Test',
      'Failed',
      'Approve',
    ];

    statuses.forEach((status) => {
      const beforeCount = this.getDateStatusCount(date, status, 'before');
      const afterCount = this.getDateStatusCount(date, status, 'after');
      // @ts-ignore
      statusCounts.before[status] = beforeCount;
      statusCounts.before.Total += beforeCount;
      // @ts-ignore
      statusCounts.after[status] = afterCount;
      statusCounts.after.Total += afterCount;
      statusCounts.after.Points = this.getPointsFromStatusCounts(statusCounts);
    });
    return statusCounts;
  }

  getDateStatusCount(date: string, status: string, type: 'before' | 'after') {
    if (this.dateEffort?.[date]?.['total']?.[type]?.[status]) {
      let count = 0;
      for (const path in this.dateEffort[date]['total'][type][status]) {
        if (this.dateEffort[date]['total'][type][status].hasOwnProperty(path)) {
          const items = this.dateEffort[date]['total'][type][status][path];
          count += items.length;
        }
      }
      return count;
    } else {
      return 0;
    }
  }

  getGrandTotalStatusCountObject() {
    const statusCounts = {
      before: {
        Backlog: 0,
        Design: 0,
        Review: 0,
        Develop: 0,
        Test: 0,
        Failed: 0,
        Approve: 0,
        Total: 0,
      },
      after: {
        Backlog: 0,
        Design: 0,
        Review: 0,
        Develop: 0,
        Test: 0,
        Failed: 0,
        Approve: 0,
        Total: 0,
        Points: 0,
      },
    };
    const statuses = [
      'Backlog',
      'Design',
      'Review',
      'Develop',
      'Test',
      'Failed',
      'Approve',
    ];

    statuses.forEach((status) => {
      const beforeCount = this.getGrandTotalStatusCount(status, 'before');
      const afterCount = this.getGrandTotalStatusCount(status, 'after');
      // @ts-ignore
      statusCounts.before[status] = beforeCount;
      statusCounts.before.Total += beforeCount;
      // @ts-ignore
      statusCounts.after[status] = afterCount;
      statusCounts.after.Total += afterCount;
      statusCounts.after.Points = this.getPointsFromStatusCounts(statusCounts);
    });
    return statusCounts;
  }

  getGrandTotalStatusCount(status: string, type: 'before' | 'after') {
    let count = 0;
    for (const date in this.dateEffort) {
      if (this.dateEffort.hasOwnProperty(date)) {
        const dateStatus = this.dateEffort[date]['total'][type][status];
        for (const path in dateStatus) {
          if (dateStatus.hasOwnProperty(path)) {
            const items = dateStatus[path];
            count += items.length;
          }
        }
      }
    }
    return count;
  }

  onClick(userId: string, date: string) {
    this.selectedStatusChange = this.userEffort[userId][date];
    this.showTasksList = !this.showTasksList;
    this.selectedWho = this.fbs.getUserNameFromId(userId) || null;
    this.selectedWhen = date;
  }

  onDateClick(date: string) {
    this.selectedStatusChange = this.dateEffort[date]['total'];
    this.showTasksList = !this.showTasksList;
    this.selectedWho = 'Daily';
    this.selectedWhen = date;
  }

  onGrandTotalClick() {
    this.selectedStatusChange = this.grandTotalEffort;
    this.showTasksList = !this.showTasksList;
    this.selectedWho = 'Weekly';
    this.selectedWhen = '';
  }

  onTaskViewClose($event: any) {
    this.showTasksList = false;
  }
}
