export enum entityEnum {
    'task' = 'tasks',
    'widget' = 'widgets',
    'view' = 'views',
    'translation' = 'translations',
    'param' = 'params',
    'user' = 'users',
    'comment' = 'comments',
    'notification' = 'notifications',
    'daily' = 'dailys',
    'release' = 'releases',
    'event' = 'events',
    'log' = 'logs',
    'todo' = 'todos',
    'leave' = 'leaves'
}