<div class="m-2 view-details" *ngIf="relatedTasks.length">
  <div class="view-row p-2 text-center text-bg-secondary">
    Features Linked</div>
  <div class="status-row d-flex justify-content-between p-2 ri-pointer" *ngFor="let task of relatedTasks"
    (click)="editTask(task)">
    <span>
      <span class="text-primary">F-{{task.tid}}</span>
      {{task.title}}
    </span>
    <span class="badge rounded-pill {{statusStyleMap[task.status]}}">{{task.status}}</span>
  </div>
</div>