<div class="p-3 ri-w-600">
  <h2 class="text-center">Watchers</h2>
  <mat-selection-list (selectionChange)="onSelectionChange($event)" #watchersSelect>
    <mat-list-option [value]="userId" color="primary" [selected]="selectedUserIds.includes(userId)"
      *ngFor="let userId of allUserIds">
      <span [ngClass]="{'text-danger': currentUserId === userId}">{{fbs.getUserNameFromId(userId)}}</span>
    </mat-list-option>
  </mat-selection-list>
  <div class="d-flex justify-content-between">
    <div class="fw-bolder text-primary ps-3 total-watchers">{{this.selectedUserIds.length}} watchers</div>
    <div class="d-flex justify-content-end px-3 pt-3">
      <button (click)="resetHandler()" [disabled]="!hasChanges" class="btn btn-outline-secondary me-2">Reset</button>
      <button (click)="updateHandler()" [disabled]="!hasChanges" class="btn btn-primary">Update</button>
    </div>
  </div>
</div>