<main class="release-form p-3" [ngClass]="{'pe-none': releaseInitial.deletedAt}">
  <div class="d-flex justify-content-between mb-1">
    <div class="d-flex align-items-center">
      <h2 class="fw-semibold mb-0 pe-2">{{ mode === 'new' ? 'New ' : '' }}Release Details</h2>
      <span *ngIf="mode === 'edit'" class="badge bg-review ri-pointer ms-2 py-2" (click)="sharedFunc.viewJson('Release Detials', releaseForm.value)" matTooltip="View Json">JSON</span>
      <span class="badge bg-review ri-pointer ms-2 p-2 " (click)="clone()" matTooltip="Clone Release" *ngIf="mode === 'edit'">
        <fa-icon class="icon" [icon]="faClone"></fa-icon>
      </span>
      <span class="badge bg-review ri-pointer ms-2 p-2" (click)="download()" matTooltip="Download Release JSON" *ngIf="mode === 'edit'">
        <fa-icon class="icon" [icon]="faDownload"></fa-icon>
      </span>
    </div>
    <div class="d-flex" *ngIf="mode === 'edit'">
      <button class="btn btn-comment btn-sm btn-success d-flex align-items-center h-100 me-2" (click)="sharedFunc.getComments('release', releaseInitial)" matTooltip="Comment">
          <mat-icon class="pe-0">question_answer</mat-icon>
          <span class="ps-2" *ngIf="cc.idToReleaseMap[releaseInitial.id]?.latestComment">{{ cc.idToReleaseMap[releaseInitial.id].latestComment?.updatedAt | dfnsFormatDistanceToNowStrict }} ago</span>
      </button>
      <button matTooltip="Get Activity" class="btn btn-info btn-sm btn-activity d-flex align-items-center" (click)="getActivity()"><mat-icon class="text-light">restore</mat-icon></button>
    </div>
  </div>

  <div class="ri-dialog-content">
    <form [formGroup]="releaseForm" #relConfigForm="ngForm">
      <h5 class="fw-semibold mb-2">Core</h5>

      <div class="row g-1 py-2">
        <div class="col-6">
          <mat-form-field appearance="fill" class="w-100 mb-1" [class]="{ edited: releaseForm.value.rid != releaseInitial.rid }">
            <mat-label>rid</mat-label>
            <input #rid type="number" placeholder="1.3" matInput  formControlName="rid" name="riReleaseRid" (input)="isDuplicateRid = false"/>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="w-100 mb-1" [class]="{ edited: releaseForm.value.dbVersion != releaseInitial.dbVersion }">
            <mat-label>dbVersion</mat-label>
            <input #dbv type="number" placeholder="22" matInput formControlName="dbVersion" name="riReleaseRid" appNumbersOnly />
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex pb-2 justify-content-between align-items-end">
        <h5 class="fw-semibold mb-0">Description</h5>
        <button type="button" *ngIf="isTranslated; else translateExternalDescBtn" class="btn btn-outline-secondary ri-fs-12px"
          (click)="viewTranslatedTexts()">View Translated Descriptions</button>
        <ng-template #translateExternalDescBtn>
          <button type="button" class="btn btn-sm btn-dark ri-fs-12px" (click)="translateExternalDesc()"
            [disabled]="translateInProgress || !releaseForm.value.externalDescription['en']">
            <span *ngIf="!translateInProgress">Translate External Description</span>
            <span *ngIf="translateInProgress">Translating now...</span>
          </button>
        </ng-template>
      </div>

      <div class="row g-1">
        <div class="col-6">
          <mat-form-field appearance="fill" class="w-100 mb-1"
            [class]="{'edited' : releaseForm.value.internalDescription !== releaseInitial.internalDescription}">
            <mat-label>Internal Description</mat-label>
            <textarea matInput formControlName="internalDescription" name="riTodoInternalDescription"
              cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="10"></textarea>
          </mat-form-field>
        </div>
        <div class="col-6">
          <div [formGroup]="externalDescGroup">
            <ng-container *ngFor="let key of objectKeys(externalDescGroup.controls)">
              <mat-form-field *ngIf="key === 'en'" appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : releaseForm.value.externalDescription[key] !== releaseInitial.externalDescription[key]}">
                <mat-label>External Description</mat-label>
                <textarea matInput [formControlName]="key" [attr.name]="'riTodoExternalDescription'+key"
                  cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="10"></textarea>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="release-envs row pt-2">
        <div class="col" *ngFor="let env of getConvertedArray().env">
          <h4 class="fw-semibold mb-2">{{ env }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col" *ngFor="let plat of getConvertedArray().enabledPlatforms; let i = index">
          <div class="release-value-field ri-pointer py-1 px-2 rounded-2 bg-light" [ngClass]="{'edited' : hasPlatformsChanged(plat.env)}" (click)="selectValues(plat.env, 'PLAT')">
            <span class="text-muted ri-fs-10px">Enabled Platforms</span>
            <p class="mb-0">
              <ng-container *ngIf="getPlatformFc(plat.env).value && getPlatformFc(plat.env).value.length !== 0;else releaseDetailsEmpty">
                <span *ngFor="let platform of getPlatformFc(plat.env).value; let i = index">{{ platform }}{{ i === getPlatformFc(plat.env).value.length - 1 ? '' : ', ' }}</span>
              </ng-container>
            </p>
          </div>
        </div>
      </div>

      <div class="row pt-2">
        <div class="col" *ngFor="let seg of getConvertedArray().enabledSegments; let i = index">
          <div class="release-value-field ri-pointer py-1 px-2 rounded-2 bg-light" [ngClass]="{'edited' : hasSegmentsChanged(seg.env)}" (click)="selectValues(seg.env, 'SEG')">
            <span class="text-muted ri-fs-10px">Enabled Segments</span>
            <p class="mb-0">
              <ng-container *ngIf="getSegmentsFc(seg.env).value && getSegmentsFc(seg.env).value.length !== 0;else releaseDetailsEmpty">
                <span *ngFor="let segment of getSegmentsFc(seg.env).value; let i = index">{{ segment }}{{ i === getSegmentsFc(seg.env).value.length - 1 ? '' : ', ' }}</span>
              </ng-container>
            </p>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col" *ngFor="let env of supportedEnvs()">
          <div class="d-flex justify-content-between">
            <h5 class="fw-semibold mb-0">Feature Flags</h5>
            <button type="button" class="btn btn-secondary btn-sm ri-fs-10px" (click)="editJson(env, 'FLAG')">Edit</button>
          </div>
         </div>
      </div>
      <div class="row" *ngFor="let flagArr of getConvertedArray().featureFlags; let i = index">
        <div class="col" *ngFor="let flag of flagArr; let fi = index">
          <div class="release-feature-flags mb-2 pt-2">
            <ng-container *ngIf="flag.key && flag.value;else releaseDetailsEmpty">
              <div class="release-value-field py-1 px-2 rounded-2 bg-light mb-2 ri-pointer" [ngClass]="{'edited' : hasObjectChanged(flag.env,flag.key, flag.value,'FLAG'),'invalid' : isInvalidFeatureFlag(flag.value)}" (click)="selectValues(flag.env, 'FLAG', flag.key)">
                <span class="text-muted ri-fs-10px">{{ flag.key }}</span>
                <p class="mb-0">{{(flag.value === null || flag.value.length === 0) ? '---' : flag.value }}</p>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col" *ngFor="let env of supportedEnvs()">
          <div class="d-flex justify-content-between">
            <h5 class="fw-semibold mb-0">Parameters</h5>
            <button type="button" class="btn btn-secondary btn-sm ri-fs-10px" (click)="editJson(env, 'PARAM')">Edit</button>
          </div>
         </div>
      </div>
      <div class="row" *ngFor="let paramArr of getConvertedArray().parameters; let i = index">
        <div class="col" *ngFor="let param of paramArr; let pi = index">
          <div class="release-parameters mb-2 pt-2">
            <ng-container *ngIf="param.key;else releaseDetailsEmpty">
              <div class="release-value-field py-1 px-2 rounded-2 bg-light mb-2" [ngClass]="{'edited' : hasObjectChanged(param.env,param.key, param.value,'PARAM'),'invalid' : isInValidParameter(param.env, param.key, param.value)}">
                <span class="text-muted ri-fs-10px">{{ param.key }}</span>
                <p class="mb-0">{{ (param.value === null || param.value.length === 0) ? '---' : param.value }}</p>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <mat-error class="text-center pt-4" *ngIf="releaseForm.touched && (releaseForm.invalid || (releaseForm.valid && !isTranslated) || isDuplicateRid)">
        <span *ngIf="releaseForm.invalid">Please enter only valid values</span>
        <span *ngIf="releaseForm.valid && !isTranslated">Translate the external description to proceed</span>
        <span *ngIf="isDuplicateRid">Entered rid is already exists.</span>
      </mat-error>
      
      <ng-template #releaseDetailsEmpty><span class="px-2"> --- </span></ng-template>

    </form>
  </div>
  <div class="ri-dialog-footer" *ngIf="!releaseInitial.deletedAt">
    <div class="d-flex justify-content-between align-items-center mt-4">
      <div class="d-flex">
        <button type="button" matTooltip="Delete" [disabled]="deletionInProgress || releaseInitial.deletedAt" class="btn btn-danger btn-sm me-2 ri-fs-16px lh-sm my-auto ri-btn-delete" (click)="deleteHandler()" *ngIf="mode === 'edit'" style="height: 30px;">
          <i class="bi bi-trash"></i>
        </button>
        <app-select [options]="cc.releases" [label]="'rid'" [value]="'rid'" title="Show Diff" [control]="diffWith" [defaultValue]="diffWith.value" (selectEvent)="onDiffWithChanged()" [disabledOptions]="[releaseForm.value.rid]"></app-select>
      </div>
      <div>
        <button type="button" class="btn btn-outline-secondary btn-sm me-2" (click)="resetHandler()"
          [disabled]="!hasChanges()">Reset</button>
        <button type="submit" class="btn btn-primary btn-sm" (click)="updateHandler()"
          [disabled]="updationInProgress || releaseForm.invalid || !hasChanges() || !isTranslated || isDuplicateRid">
          <span *ngIf="!updationInProgress">Save</span>
        </button>
      </div>
    </div>
  </div>

</main>