<main>
  <div class="sticky-top bg-body p-3">
    <span class="fs-6">Timelog's on <span class="text-primary">F-{{data.task.tid}}</span></span>
  </div>
  <div *ngFor="let item of dailys" class="card m-2 d-flex justify-content-between flex-column cursor">

    <!-- Title -->
    <div class="d-flex flex-row title-block">
      <div class="text-start w-50 px-1 py-2">
        <span class="text-capitalize badge rounded-pill me-2 text-black-50">{{
          (item.updatedAt|
          dfnsFormatDistanceToNowStrict) + ' ago'}}</span>
      </div>
      <div class="text-end w-50 p-2 d-flex flex-row-reverse">
        <span class="text-capitalize badge rounded-pill text-black-50">{{item.percentage}}%</span>
        <span class="badge rounded-pill me-2 {{mapClass[item.status]}}">{{item.status}}</span>

        <span class="badge rounded-pill text-black-50">{{ item.time }} - {{ item.updateTime
          }}</span>
      </div>
    </div>

    <!-- Content -->
    <div class="w-100 content-block">
      <div class="w-25 d-inline-block p-2 align-top">
        <span class="badge rounded-pill text-bg-dark">{{ item.hours }} hr</span>&nbsp;&nbsp;
        <span class="text-primary">{{this.fbs.getDisplayName(item.uid)}}</span>
      </div>
      <div class="w-75 d-inline-block p-2 align-top" [innerText]="item.eodNote"></div>
    </div>
  </div>
</main>