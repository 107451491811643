import { Component, Inject, OnInit } from '@angular/core';
import { JsWidget } from '../_interfaces/Widget';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-list-previews',
  templateUrl: './list-previews.component.html',
  styleUrls: ['./list-previews.component.scss'],
})
export class ListPreviewsComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { widgets: JsWidget[]; title: string },
    private dialogRef: MatDialogRef<ListPreviewsComponent>
  ) {}

  ngOnInit(): void {}

  onClose() {
    this.dialogRef.close();
  }

  onSelect() {
    console.log('onSelect');
  }
}
