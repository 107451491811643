import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import * as _ from 'lodash';
import { sortObjectKeys } from '../../utils';
import { checkInvalidFlags, checkInvalidParameter } from 'src/app/_configs/form-validator';

@Component({
  selector: 'app-json-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTextareaAutosize,
    MatFormField,
    MatInput,
    MatLabel,
    MatError
  ],
  templateUrl: './json-edit.component.html',
  styleUrl: './json-edit.component.scss'
})

export class JsonEditComponent {

  invalidJson = false;
  jsonControl: FormControl;
  jsonInitial: Object;
  title: string = '';
  hint?: string = '';
  example?: string = '';
  invalidData = false;
  invalidKeys: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, type: 'FLAG' | 'PARAM', json: Object, control: FormControl, hint?: string, example?: string, segments?: string[] },
    public dialogRef: MatDialogRef<JsonEditComponent>,
  ) {
    this.title = data.title;
    this.jsonInitial = data.json;
    this.jsonControl = data.control;
    this.jsonControl.setValue(this.stringify(this.jsonInitial));
    this.hint = data.hint;
    this.example = data.example;
  }

  stringify(obj: any) {
    const sorted = sortObjectKeys(obj);
    return JSON.stringify(sorted, null, 2);
  }

  getLatestThemeInfoToEdit() {
    this.invalidJson = false;
    // wrap in try catch
    try {
      return JSON.parse(this.jsonControl.value);
    } catch (error) {
      this.invalidJson = true;
      // console.error('Error parsing JSON', error);
    }
  }

  isSame() {
    return _.isEqual(this.jsonInitial, this.getLatestThemeInfoToEdit());
  }

  update() {
    if (this.jsonControl.valid) {
      const updatedJson = this.getLatestThemeInfoToEdit();
      const sorted = sortObjectKeys(updatedJson);
      this.invalidData = false;
      this.dialogRef.close(sorted);
    } else {
      this.invalidData = true;
      if (this.jsonControl.value) {
        const json = _.cloneDeep(JSON.parse(this.jsonControl.value));
        const jsonKeys = Object.keys(json);
        this.invalidKeys = [];
        jsonKeys.forEach(key => {
          const value = json[key];
          if (this.data.type === 'PARAM' && checkInvalidParameter(key, value)) {
            this.invalidKeys.push(key);
          }
          if (this.data.type === 'FLAG' && checkInvalidFlags(this.data.segments || [], value)) {
            this.invalidKeys.push(key);
          }
        });
      }
    }
  }

  reset() {
    this.jsonControl.setValue(this.stringify(this.jsonInitial));
    this.invalidData = false;
  }
}
