import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { JsTranslation } from '../_interfaces/Translation';
import * as _ from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ListSpecsOfTaskComponent } from '../list-specs-of-task/list-specs-of-task.component';
import { CacheService } from '../_services/cache.service';
import { UserSessionStorageService } from '../_services/user-session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslationDetailsModalComponent } from '../translation-details-modal/translation-details-modal.component';
import { JsTask } from '../_interfaces/Task';
import { ListTranslationsOfViewComponent } from '../list-translations-of-view/list-translations-of-view.component';
import { SnackbarService } from '../_services/snackbar.service';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { translationStatusList } from '../shared/status';

@Component({
  selector: 'app-list-translations-of-task',
  templateUrl: './list-translations-of-task.component.html',
  styleUrls: ['./list-translations-of-task.component.scss']
})
export class ListTranslationsOfTaskComponent implements OnInit, OnDestroy {
  translations: JsTranslation[] = [];
  statusClassMap = {
    Draft: 'text-bg-light',
    Paused: 'text-bg-dark',
    Reviewed: 'text-bg-review',
    Translated: 'text-bg-primary',
    Ready: 'text-bg-info',
    Implemented: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Passed: 'text-bg-success',
    Deprecated: 'text-bg-secondary',
    Removed: 'text-bg-dark'
  };
  translationSubscription: any;
  public filterOptions = {
    statusFilter : {
      options: ['All', ...translationStatusList],
      selected: 'All'
    },

    langFilter : {
      options: [...this.cc.config.supportedLanguageCodes,'test'],
      selected: 'en'
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { task: JsTask },
    private cc: CacheService,
    private uss: UserSessionStorageService,
    private router: Router,
    public dialog: MatDialog,
    private _dialog: DialogManagerService,
    public snackbar: SnackbarService
  ) {}

  ngOnInit(): void {
    this.initialise();
  }

  initialise() {
    this.translationSubscription = this.cc.translations$.subscribe(res => {
      // Get all the viewIds of the task by first getting all the widgets of the task using prefix. Then getting the unique viewIds of the widgets.
      // Get specPrefixes from the task
      this.translations = this.cc.getTranslationsOfTask(this.data.task);
    });
  }

  onStatusChange() {
    const alltranslations = _.cloneDeep(this.cc.getTranslationsOfTask(this.data.task));
    if(this.filterOptions.statusFilter.selected == 'All') {
      this.translations = alltranslations;
    } else {
      this.translations = alltranslations.filter(translations => translations.status === this.filterOptions.statusFilter.selected);
    }
  }

  ngOnDestroy() {
    this.translationSubscription?.unsubscribe();
  }

  openTranslation(translation: JsTranslation) {
    // Open the translation details modal
    const confirmDialog = this.dialog.open(TranslationDetailsModalComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        translationToEdit: translation
      },
      autoFocus: false
    });
    return confirmDialog.afterClosed();
  }

  showCommonTranslations(e: any) {
    this._dialog.openDialog(ListTranslationsOfViewComponent, {
      data: {
        viewId: 'screen_common',
        previousTask: this.data.task
      }
    });
    e.stopPropagation();
  }

  addTranslation() {
    // Open the translation details modal
    const confirmDialog = this.dialog.open(TranslationDetailsModalComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      autoFocus: false
    });
    return confirmDialog.afterClosed();
  }
}
