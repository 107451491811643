import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadJsonService {
  constructor(private fbs: FirebaseService) {}

  downloadJson(title: string, data: any, skipMetaAddition = false) {
    // download using file-saver
    let jsonObject: any = {};
    if (skipMetaAddition) {
      jsonObject = data;
    } else {
      jsonObject.title = title;
      jsonObject.version = environment.version;
      jsonObject.downloadedBy = this.fbs.currentUser.label;
      jsonObject.downloadedAt = new Date().toISOString();
      jsonObject.data = data;
    }
    console.log('jsonObject', jsonObject);
    const blob = new Blob([JSON.stringify(jsonObject, null, 2)], {
      type: 'application/json'
    });
    saveAs(blob, `${title}.json`);
  }
}
