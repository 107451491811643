<main>
  <div class="sticky-top bg-body p-3">
    <span class="fs-6"><span class="text-primary">F-{{data.task.tid}}</span> {{data.task.title}}</span>
  </div>
  <div class="text-center">
    <span [class]="{'opacity-50' : hideTranslate}" class="badge rounded-pill text-bg-dark me-1 ri-pointer p-2"
      (click)="hideTranslate = !hideTranslate">
      Translation Status
    </span>
    <span [class]="{'opacity-50' : hideSpecStatus}" class="badge rounded-pill text-bg-dark me-1 ri-pointer p-2"
      (click)="hideSpecStatus = !hideSpecStatus">
      Spec Status
    </span>
    <span [class]="{'opacity-50' : hideSpecType}" class="badge rounded-pill text-bg-dark ri-pointer p-2"
      (click)="hideSpecType = !hideSpecType">
      Spec Type
    </span>
  </div>
  <div *ngFor="let viewId of viewIds">
    <app-view-block [hideTranslate]="hideTranslate" [hideSpecStatus]="hideSpecStatus" [hideSpecType]="hideSpecType"
      [viewId]="viewId || ''"></app-view-block>
  </div>
</main>