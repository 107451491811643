import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyLowercase]',
  standalone: true,
})
export class OnlyLowercaseDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const key = event.key;

    if (
      key.length === 1 && // Check if the key is a single character
      !/[a-z_]/.test(key) // Allow only letters (a-z, _)
    ) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');

    if (
      /[^a-z_]/.test(pastedText) // Block characters that are not lower case letters and _
    ) {
      event.preventDefault();
    }
  }
}