import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { saveAs } from 'file-saver';
import { FirebaseService } from './firebase.service';
import { lastValueFrom, map } from 'rxjs';
import { FirebaseOptimisticService } from './firebase-optimistic.service';
import { type } from '../_interfaces/Publication';
import { getNewId } from '../shared/utils';
import { CacheService } from './cache.service';
import { MatDialog } from '@angular/material/dialog';
import { last } from 'lodash';
import { environment } from 'src/environments/environment';
import { JsonViewComponent } from '../shared/components/json-view/json-view.component';
import { TitleCasePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class JsonService {
  constructor(
    private storage: AngularFireStorage,
    private fbs: FirebaseService,
    private fbo: FirebaseOptimisticService,
    private cc: CacheService,
    public dialog: MatDialog
  ) {
    this.fetchLatestJson('colors').then(() => {
      console.log('FETCHED LATEST PUBLICATION - Colors');
    });
    this.fetchLatestJson('views').then(() => {
      console.log('FETCHED LATEST PUBLICATION - Views');
    });
    this.fetchLatestJson('specs').then(() => {
      console.log('FETCHED LATEST PUBLICATION - Specs');
    });
    this.fetchLatestJson('translations').then(() => {
      console.log('FETCHED LATEST PUBLICATION - Translations');
    });
  }

  async publishJson(
    folder: type,
    data: any,
    errors?: string[],
    strict = false
  ): Promise<string> {
    // download using file-saver
    const newPid = this.cc.getNextPid(folder);
    const jsonObject = {} as any;
    jsonObject.title = folder;
    jsonObject.pid = newPid;
    jsonObject.publishedBy = this.fbs.currentUser.label;
    jsonObject.publishedAt = new Date().toISOString();
    jsonObject.errors = errors || [];
    jsonObject.data = data;

    this.fetchLatestJson(folder).then(() => {
      console.log('FETCHED LATEST PUBLICATION');
    });
    const dialogResult = await this.confirmDiff(
      folder,
      this.cc.latestPublications[folder],
      jsonObject,
      errors,
      strict
    );
    if (dialogResult?.action === 'cancel') {
      return 'cancel';
    }
    if (dialogResult?.action === 'download') {
      jsonObject.note = dialogResult?.note || '';
      const { data, ...rest } = jsonObject;
      const newObj = { ...rest };
      newObj.data = data;
      this.downloadJson(folder, newObj);
      return 'download';
    }
    if (dialogResult?.action === 'publish') {
      jsonObject.note = dialogResult?.note || '';
      const { data, ...rest } = jsonObject;
      const newObj = { ...rest };
      newObj.data = data;
      const jsonString = JSON.stringify(newObj, null, 2);

      const filePath = `${folder}/${newPid}.json`;

      const fileRef = this.storage.ref(filePath);
      const blob = new Blob([jsonString], { type: 'application/json' });

      try {
        await this.storage.upload(filePath, blob);
        this.fetchLatestJson(folder).then(() => {
          console.log('FETCHED LATEST PUBLICATION');
        });
        await this.fbo.createItemsOptimistic(
          [
            {
              createdAt: new Date(),
              createdBy: this.fbs.currentUser.id,
              id: getNewId(),
              uid: this.fbs.currentUser.id,
              updatedAt: new Date(),
              cloudUpdatedAt: new Date(),
              deletedAt: null,
              type: folder,
              pid: newPid,
              note: dialogResult?.note || '',
            },
          ],
          'publications'
        );
        return 'success';
      } catch (error) {
        console.error('Error uploading file:', error);
        return 'error';
      }
    } else {
      return "Didn't get a valid response from the confirm dialog";
    }
  }

  async fetchJson(folder: type, pid: number): Promise<any> {
    let url;
    if (environment.production) {
      url = `https://assets.mevolve.app/${folder}`;
    } else {
      url = `https://dev.project.internal.mevolve.app/api/${folder}`;
    }
    url = `${url}/${pid}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      } else {
        const result = await response.json();
        return result;
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      throw error;
    }
  }

  async fetchLatestJson(folder: type): Promise<any> {
    // Get the latest publication
    // Delay to load publications in cc first
    setTimeout(async () => {
      const latestPid = this.cc.getLatestPid(folder);
      console.log('latestPid', latestPid);
      const latestPublication = await this.fetchJson(folder, latestPid);
      this.cc.latestPublications[folder] = latestPublication;
    }, 3000);
  }

  downloadJson(title: string, data: any) {
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: 'application/json',
    });
    saveAs(blob, `${title}.json`);
  }

  listFiles(path: string) {
    return this.storage
      .ref(path)
      .listAll()
      .pipe(
        map((res) => {
          return res.items.map((item) => {
            return item.name;
          });
        })
      );
  }

  async confirmDiff(
    type: type,
    leftData: any,
    rightData: any,
    errors?: string[],
    strict = false
  ): Promise<any> {

    const titleCasePipe = new TitleCasePipe();
    const jsonDiffDialog = this.dialog.open(JsonViewComponent, {
      autoFocus: false,
      data: {
        entity: type,
        mode: 'add',
        title:`Ready to publish new version ${titleCasePipe.transform(type)} ${rightData.pid}`,
        type: 'JSONDIFF',
        oldJson: leftData || {},
        newJson: rightData || {},
        errors,
        strict,
      },
    });

    return jsonDiffDialog.afterClosed().toPromise();
  }
}
