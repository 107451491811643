import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]',
  standalone: true,
})
export class NumbersOnlyDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const key = event.key;

    if (
      key.length === 1 && // Check if the key is a single character
      !/[0-9]/.test(key) // Allow only numbers (0-9)
    ) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');

    if (
      /[^0-9]/.test(pastedText) // Block characters that are not numbers
    ) {
      event.preventDefault();
    }
  }
}