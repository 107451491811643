import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOutKeys',
})
export class FilterOutKeysPipe implements PipeTransform {
  transform(arr: any[], key: string): any[] {
    if (!arr) return [];
    return arr.filter((item: any) => {
      return item.key !== key;
    });
  }
}
