import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import * as _ from 'lodash';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FirebaseService } from '../_services/firebase.service';
import { JsDaily } from '../_interfaces/Daily';
import { FirebaseOptimisticService } from '../_services/firebase-optimistic.service';
import {
  getcurrentLocalDateAsString,
  getcurrentLocalTimeAsString,
} from '../shared/utils';
import { CacheService } from '../_services/cache.service';
import { JsTask } from '../_interfaces/Task';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityService } from '../_services/activity.service';
import { SharedFunctionService } from '../_services/shared-function.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { JsTodo } from '../_interfaces/Todo';

@Component({
  selector: 'app-daily-plan-detail',
  templateUrl: './daily-plan-detail.component.html',
  styleUrls: ['./daily-plan-detail.component.scss'],
})
export class DailyPlanDetailComponent implements OnChanges {
  @Input() dailyInput: JsDaily | null = null;
  public dailyInitial: JsDaily | null = null;
  public mode: 'new' | 'edit' | 'readonly' | 'none' = 'new';
  public filteredTasks: JsTask[] = [];
  public filteredTodos: JsTodo[] = [];
  selectTaskInput: string = '';

  public dailyToEdit: JsDaily | null = null;
  faTrash = faTrash;
  dailyPlanType: 'model' | '' = '';

  public statusOptions = [
    { value: 'Not Started', label: 'Not Started' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Blocked', label: 'Blocked' },
    { value: 'Completed', label: 'Completed' },
  ];

  constructor(
    public fbService: FirebaseService,
    public fbo: FirebaseOptimisticService,
    public cc: CacheService,
    private as: ActivityService,
    public sharedFunc: SharedFunctionService,
    @Optional() public dialogRef: MatDialogRef<DailyPlanDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: { from: 'model', selectedDaily: JsDaily, mode: 'new' | 'edit' },
  ) {
    if (this.data) {
      this.dailyPlanType = this.data.from;
      this.mode = this.data.mode;
      this.dailyInput = this.data.selectedDaily;
      this.dailyInput.planFor = this.dailyInput.planFor === 'Todo' ? 'Todo' : 'Feature';
      this.dailyInput.tid = this.data.selectedDaily.tid;
      this.dailyInitial = _.cloneDeep(this.dailyInput);
      this.dailyToEdit = _.cloneDeep(this.dailyInput);
      this.selectTaskInput = this.dailyToEdit?.tid ? this.getSelectedTask(this.dailyToEdit?.tid, this.dailyToEdit.planFor) : '';
    }
  }

  getActivity() {
    this.as.getActivity('daily', this.dailyToEdit);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filteredTasks = _.cloneDeep(this.cc.activeTasks).filter((task: JsTask) => !this.cc.userDaily().some(daily => daily.planFor === 'Feature' && task.tid === daily.tid) );
    this.filteredTodos = _.cloneDeep(this.cc.activeTodos).filter((todo: JsTodo) => !this.cc.userDaily().some(daily => daily.planFor === 'Todo' && todo.tid === daily.tid) );
    if (!changes['dailyInput']?.currentValue) {
      this.dailyInitial = null;
      this.dailyToEdit = null;
      this.mode = 'none';
      return;
    } else {
      this.dailyInitial = _.cloneDeep(changes['dailyInput']?.currentValue);
    }
    if (!changes['dailyInput']?.currentValue) {
      this.mode = 'none';
    } else if (!changes['dailyInput']?.currentValue?.cloudUpdatedAt) {
      this.mode = 'new';
    } else if (
      changes['dailyInput']?.currentValue.assignedTo !==
      this.fbService.getCurrentUserId() ||
      changes['dailyInput']?.currentValue.date !== getcurrentLocalDateAsString()
    ) {
      this.mode = 'readonly';
    } else {
      this.mode = 'edit';
    }
    this.dailyToEdit = _.cloneDeep(this.dailyInitial);
    this.selectTaskInput = this.dailyToEdit?.tid ? this.getSelectedTask(this.dailyToEdit.tid, this.dailyToEdit.planFor) : '';
  }

  planTypeChange(event:MatButtonToggleChange) {
    this.selectTaskInput = '';
    this.dailyToEdit!.tid = 0;
  }

  getSelectedTask(taskId: number, type: 'Feature' | 'Todo') {
    return 'F-' + taskId + '  ' + this.getTitle(taskId, type);
  }

  hasChanges() {
    return !_.isEqual(this.dailyInitial, this.dailyToEdit);
  }

  hasError() {
    let hasError = false;
    if (!this.dailyToEdit) hasError = true;
    if (!this.dailyToEdit?.tid) hasError = true;
    if (
      this.dailyToEdit?.hours === null ||
      Number.isNaN(this.dailyToEdit?.hours) ||
      this.dailyToEdit?.hours === undefined
    ) {
      hasError = true;
    }
    if (
      this.dailyToEdit &&
      (this.dailyToEdit.hours < 0 || this.dailyToEdit.hours > 8)
    ) {
      hasError = true;
    }
    if (
      this.dailyToEdit?.status !== 'Not Started' &&
      !this.dailyToEdit?.eodNote
    )
      hasError = true;

    if (
      this.dailyToEdit &&
      ['Not Started', 'In Progress', 'Blocked'].includes(
        this.dailyToEdit.status
      ) &&
      this.dailyToEdit.hoursLeft <= 0
    ) {
      hasError = true;
    }
    return hasError;
  }

  async updateDaily() {
    // Take a snapshot of the current daily
    const dailyBackup = _.cloneDeep(this.dailyInitial);
    let updateStatus = null;

    const updateId = this.dailyToEdit?.id;
    if (!this.dailyToEdit) return;
    this.dailyToEdit.updateTime = getcurrentLocalTimeAsString();
    if (this.mode === 'new') {
      this.dailyToEdit.time = getcurrentLocalTimeAsString();
      this.dailyToEdit.date = getcurrentLocalDateAsString();
      this.dailyInitial = _.cloneDeep(this.dailyToEdit);
      console.log("I am called daily=======>>>",this.dailyInitial)
      updateStatus = await this.fbo.createItemsOptimistic<JsDaily>(
        [this.dailyToEdit],
        'dailys'
      );
      if (updateStatus && updateId === this.dailyInitial?.id) {
        this.mode = 'edit';
      }
    } else if (this.mode === 'edit') {
      this.dailyInitial = _.cloneDeep(this.dailyToEdit);
      updateStatus = await this.fbo.updateItemsOptimistic<JsDaily>(
        [this.dailyToEdit],
        'dailys'
      );
    }
    if (this.dailyPlanType == 'model') {
      this.dialogRef.close();
      this.cc.emitDailyChange(this.dailyToEdit);
    }

    if (!updateStatus) {
      this.dailyInitial = _.cloneDeep(dailyBackup);
    }
  }

  resetDaily() {
    this.dailyToEdit = _.cloneDeep(this.dailyInitial);
  }

  async deleteDaily() {
    if (!this.dailyToEdit) return;
    this.dailyToEdit.deletedAt = new Date();
    await this.fbo.updateItemsOptimistic<JsDaily>([this.dailyToEdit], 'dailys');
    this.dailyInitial = null;
    this.dailyToEdit = null;
    this.dailyInput = null;
    if (this.dailyPlanType == 'model') {
      this.dialogRef.close();
      this.cc.emitDailyChange(null);
    }
  }

  setFilteredTasks() {
    if (!this.dailyToEdit) return;
    this.dailyToEdit.tid = 0;
    this.filteredTasks = _.cloneDeep(this.cc.activeTasks).filter(
      (task: JsTask) =>
        (task.title.toLowerCase().includes(this.selectTaskInput.toLowerCase()) ||
        task.tid.toString().includes(this.selectTaskInput)) && 
        !this.cc.userDaily().some(daily => task.tid === daily.tid)
    );
  }

  setFilteredTodos() {
    if (!this.dailyToEdit) return;
    this.dailyToEdit.tid = 0;
    this.filteredTodos = _.cloneDeep(this.cc.activeTodos).filter(
      (todo: JsTodo) =>
        (todo.title.toLowerCase().includes(this.selectTaskInput.toLowerCase()) ||
        todo.tid.toString().includes(this.selectTaskInput)) && 
        !this.cc.userDaily().some(daily => todo.tid === daily.tid)
    );
  }

  onTaskSelected(e: any) {
    this.dailyToEdit!.tid = e.option.value;
    this.selectTaskInput = e.option.viewValue;
  }

  getTitle(tid: number, type: 'Feature' | 'Todo') {
    const task =  type === 'Feature' ? this.cc.tidTaskMap[tid] : this.cc.tidToTodoMap[tid];
    if (task) return task.title;
    return '';
  }
}
