import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullToArray',
})
export class NullToArrayPipe implements PipeTransform {
  transform(value: any): [] {
    if (value) return value;
    return [];
  }
}
