<main class="edit-json p-3 ri-w-800">
    <div class="d-flex justify-content-between pb-1">
        <h3 class="fw-semibold mb-0">Edit {{ title }}</h3>
    </div>

    <div class="ri-dialog-content">
        <p class="mb-2 ri-fs-12px text-muted" *ngIf="hint">Hint:- {{ hint }} <span class="text-info" *ngIf="data.type === 'FLAG'">{{data.segments?.join(', ')}}</span></p>
        <p class="mb-2 ri-fs-12px text-muted" *ngIf="example">eg:- {{ example }}</p>
        <mat-form-field appearance="fill" class="w-100" [ngClass]="{'edited' : !isSame() && !invalidJson, 'invalid': invalidJson}">
            <mat-label>JSON</mat-label>
            <textarea matInput [formControl]="jsonControl" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="10" cdkAutosizeMaxRows="1000" (keyup)="invalidData = false"></textarea>
        </mat-form-field>
        <mat-error class="text-center pt-3" *ngIf="invalidData">
            <span *ngIf="invalidKeys.length !== 0"><strong>{{invalidKeys.join(', ')}} </strong>{{ invalidKeys.length === 1 ? 'is' : 'are' }} invalid</span>
            <span *ngIf="invalidKeys.length === 0">Invalid JSON</span>
        </mat-error>
    </div>

    <div class="ri-dialog-actions pt-3 d-flex justify-content-end">
        <button class="btn btn-outline-secondary btn-sm me-2" (click)="reset()">Reset</button>
        <button class="btn btn-primary btn-sm" (click)="update()" [disabled]="isSame() || invalidJson || invalidData">Update Selection</button>
    </div>
</main>