<div class="d-flex flex-column">
    <div class="ri-filter-row" [ngClass]="{ 'disabled-top': currentMainView === 'notifications' }">
        <mat-form-field [class]="{ edited: searchModel }" floatLabel="always">
            <mat-label>Search</mat-label>
            <input
                #search
                matInput
                (keyup)="searchInput.emit(search.value)"
                placeholder="Spec path, status, subStatus, viewId, description etc..."
                [(ngModel)]="searchModel" />
            <button *ngIf="searchModel" matSuffix mat-icon-button aria-label="Clear" (click)="searchModel = ''; searchInput.emit('')">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <!-- <input type="text" #search class="fs-6" placeholder="Search Path, Component, Widget, State, Bug etc..."
      (keyup)="searchInput.emit(search.value)" [(ngModel)]="searchModel"> -->
        <button class="btn btn-warning btn-sm me-1" (click)="resetFilters()">Reset</button>
        <button class="btn btn-secondary btn-sm me-1" (click)="showMyTasks()">
            My Current Features
            <span class="badge text-bg-light">{{ cc.myTaskCount }}</span>
        </button>
        <button class="btn btn-secondary btn-sm me-1" (click)="collapseAll()">Collapse</button>
        <button
            [disabled]="!uss.currentUserSessionStorage.widgetPath"
            class="btn btn-secondary btn-sm"
            (click)="showFullPath(uss.currentUserSessionStorage.widgetPath)">
            View Path
        </button>
        <!-- <button class="btn btn-secondary float-end btn-sm me-2" (click)="showAssignedComments()">Assigned Comments
      <span class="badge text-bg-light">{{cc.myAssignedComments.length}}</span>
    </button> -->
        <!-- <button class="btn btn-danger float-end me-3" >Sync
      Updates </button> -->
    </div>
    <div class="col-wrapper d-flex flex-row flex-grow-1">
        <div class="flex-grow-1">
            <!-- STATUS FILTERS -->
            <div
                class="ri-filter-row ri-filter-row-background"
                [ngClass]="{
                    'disabled-top': currentMainView === 'notifications'
                }">
                <!-- <mat-form-field class="pe-1" appearance="fill" [class]="{'has-changed': filterOptions.mid.initialSelection}">
          <mat-label>{{filterOptions.mid.title}}</mat-label>
          <mat-select [(value)]="filterOptions.mid.initialSelection"
            (selectionChange)="filterOptions.mid.selectedEmitter.emit($event.value)">
            <mat-option value="All">All</mat-option>
            <mat-option value="Unassigned">Unassigned</mat-option>
            <mat-option *ngFor="let item of cc.roadmapFilterOptions" [value]="item.value">{{item.label}}
              <span *ngIf="item.inProd" class="badge rounded-pill bg-primary" matTooltip="In Production">P</span>
            </mat-option>
          </mat-select>
        </mat-form-field> -->

                <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.forRelease.initialSelection != null }">
                    <mat-label>{{ filterOptions.forRelease.title }}</mat-label>
                    <mat-select
                        [(value)]="filterOptions.forRelease.initialSelection"
                        (selectionChange)="filterOptions.forRelease.selectedEmitter.emit($event.value)">
                        <mat-option [value]="null">All</mat-option>
                        <mat-option *ngFor="let r of cc.availableMainReleaseNumbers()" [value]="r">{{ r }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.notForRelease.initialSelection != null }">
                    <mat-label>{{ filterOptions.notForRelease.title }}</mat-label>
                    <mat-select
                        [(value)]="filterOptions.notForRelease.initialSelection"
                        (selectionChange)="filterOptions.notForRelease.selectedEmitter.emit($event.value)">
                        <mat-option [value]="null">All</mat-option>
                        <mat-option *ngFor="let r of cc.availableMainReleaseNumbers()" [value]="r">{{ r }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.inDeliverable.initialSelection != null }">
                    <mat-label>{{ filterOptions.inDeliverable.title }}</mat-label>
                    <mat-select
                        [(value)]="filterOptions.inDeliverable.initialSelection"
                        (selectionChange)="filterOptions.inDeliverable.selectedEmitter.emit($event.value)">
                        <mat-option [value]="null">All</mat-option>
                        <mat-option *ngFor="let r of cc.currentDeliverables()" [value]="r">{{ r }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.notInDeliverable.initialSelection != null }">
                    <mat-label>{{ filterOptions.notInDeliverable.title }}</mat-label>
                    <mat-select
                        [(value)]="filterOptions.notInDeliverable.initialSelection"
                        (selectionChange)="filterOptions.notInDeliverable.selectedEmitter.emit($event.value)">
                        <mat-option [value]="null">All</mat-option>
                        <mat-option *ngFor="let r of cc.currentDeliverables()" [value]="r">{{ r }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.task.initialSelection }">
                    <mat-label>{{ filterOptions.task.title }}</mat-label>
                    <mat-select [(value)]="filterOptions.task.initialSelection" (selectionChange)="filterOptions.task.selectedEmitter.emit($event.value)">
                        <mat-option [value]="0">All</mat-option>
                        <mat-option *ngFor="let item of cc.allTasks" [value]="item.tid">
                            <span class="text-primary">F-{{ item.tid }}</span>
                            {{ item.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.viewId.initialSelection }">
                    <mat-label>{{ filterOptions.viewId.title }}</mat-label>
                    <mat-select [(value)]="filterOptions.viewId.initialSelection" (selectionChange)="filterOptions.viewId.selectedEmitter.emit($event.value)">
                        <mat-option [value]="''">All</mat-option>
                        <mat-option value="INVALID">INVALID</mat-option>
                        <mat-option *ngFor="let view of cc.views" [value]="view.viewId">{{ view.viewId }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <app-select-filter
                    #previewColorsFilter
                    [options]="filterOptions.previewColors.options"
                    class="me-1"
                    [title]="filterOptions.previewColors.title"
                    [initialSelection]="filterOptions.previewColors.initialSelection"
                    [selectedEmitter]="filterOptions.previewColors.selectedEmitter"></app-select-filter>
                <app-select-filter
                    #analyticsStatusFilter
                    [options]="filterOptions.analyticsStatus.options"
                    class="me-1"
                    [title]="filterOptions.analyticsStatus.title"
                    [initialSelection]="filterOptions.analyticsStatus.initialSelection"
                    [selectedEmitter]="filterOptions.analyticsStatus.selectedEmitter"></app-select-filter>
                <app-select-filter
                    #typeFilter
                    [options]="filterOptions.type.options"
                    [title]="filterOptions.type.title"
                    class="me-1"
                    [initialSelection]="filterOptions.type.initialSelection"
                    [selectedEmitter]="filterOptions.type.selectedEmitter"></app-select-filter>
                <app-select-filter
                    #statusFilter
                    [options]="filterOptions.status.options"
                    [title]="filterOptions.status.title"
                    class="me-1"
                    [initialSelection]="filterOptions.status.initialSelection"
                    [selectedEmitter]="filterOptions.status.selectedEmitter"></app-select-filter>
                <app-select-filter
                    #subStatusFilter
                    [options]="filterOptions.subStatus.options"
                    class="me-1"
                    [title]="filterOptions.subStatus.title"
                    [initialSelection]="filterOptions.subStatus.initialSelection"
                    [selectedEmitter]="filterOptions.subStatus.selectedEmitter"></app-select-filter>
                <app-select-filter
                    #tagsFilter
                    [options]="cc.activeTags"
                    [title]="filterOptions.tags.title"
                    class="me-1"
                    [initialSelection]="filterOptions.tags.initialSelection"
                    [selectedEmitter]="filterOptions.tags.selectedEmitter"></app-select-filter>
                <app-select-filter
                    #assignedToFilter
                    [options]="fbService.users"
                    [title]="filterOptions.assignedTo.title"
                    class="me-1"
                    [initialSelection]="filterOptions.assignedTo.initialSelection"
                    [selectedEmitter]="filterOptions.assignedTo.selectedEmitter"></app-select-filter>
                <!-- <app-select-filter #midFilter [options]="cc.activeReleases" [title]="filterOptions.mid.title"
          [initialSelection]="filterOptions.mid.initialSelection"
          [selectedEmitter]="filterOptions.mid.selectedEmitter"></app-select-filter> -->
                <app-select-filter
                    #uidFilter
                    [options]="fbService.users"
                    [title]="filterOptions.uid.title"
                    class="me-1"
                    [initialSelection]="filterOptions.uid.initialSelection"
                    [selectedEmitter]="filterOptions.uid.selectedEmitter"></app-select-filter>
                <app-select-filter
                    #viewFilter
                    [options]="filterOptions.viewFilter.options"
                    class="me-1"
                    [title]="filterOptions.viewFilter.title"
                    [initialSelection]="filterOptions.viewFilter.initialSelection"
                    [resetSelection]="filterOptions.viewFilter.resetSelection"
                    [selectedEmitter]="filterOptions.viewFilter.selectedEmitter"></app-select-filter>
                <app-select-filter
                    #emptyFilter
                    [options]="filterOptions.emptyFilter.options"
                    class="me-1"
                    [title]="filterOptions.emptyFilter.title"
                    [initialSelection]="filterOptions.emptyFilter.initialSelection"
                    [selectedEmitter]="filterOptions.emptyFilter.selectedEmitter"></app-select-filter>
                <button
                    class="btn btn-danger btn-sm text-nowrap"
                    (click)="bulkUpdateWidgets()"
                    [disabled]="widgets.length === filteredWidgets.length || filteredWidgets.length === 0">
                    Bulk Update
                    {{ widgets.length === filteredWidgets.length ? '' : '(' + filteredWidgets.length + ')' }}
                </button>
                <button class="btn btn-review btn-sm text-nowrap ms-1" (click)="publishSpecs()">Publish Specs</button>
                <button class="btn btn-primary btn-sm ms-1" (click)="showHistory($event)">
                    Version History
                    <span class="badge rounded-pill text-bg-light" *ngIf="this.cc.latestPublications['specs']">
                        {{ this.cc.latestPublications['specs'].pid }}
                    </span>
                </button>
            </div>
            <div class="h-100">
                <as-split unit="pixel" [gutterSize]="2">
                    <as-split-area class="pb-2" [ngSwitch]="currentMainView">
                        <div *ngSwitchCase="'tasks'">
                            <div class="total">
                                Total
                                <fa-icon
                                    class="icon"
                                    [icon]="faPlus"
                                    (click)="toggleRootAddInput()"
                                    [class]="{
                                        active: showRootAddInput
                                    }"></fa-icon>
                                <span
                                    class="mx-1 badge rounded-pill bg-warning cursor"
                                    (click)="toggleViewFilter('Assigned Features')"
                                    matTooltipPosition="above"
                                    matTooltip="Assigned Features">
                                    {{ totalObj.assignedWidgets }}
                                </span>
                                <span
                                    class="mx-1 badge rounded-pill bg-success cursor"
                                    (click)="toggleViewFilter('Assigned Comments')"
                                    matTooltipPosition="above"
                                    matTooltip="Assigned Comments">
                                    {{ totalObj.assignedComments }}
                                </span>
                                <span
                                    class="mx-1 badge rounded-pill bg-danger cursor"
                                    (click)="switchMainView('notifications')"
                                    matTooltipPosition="above"
                                    matTooltip="Notifications">
                                    <fa-icon class="py-2" [icon]="faBell"></fa-icon>
                                    {{ notifications.length }}
                                </span>
                                <span
                                    class="mx-1 badge rounded-pill bg-review cursor"
                                    (click)="switchMainView('previews')"
                                    matTooltipPosition="above"
                                    matTooltip="Show State Previews">
                                    <fa-icon class="py-2" [icon]="faImages"></fa-icon>
                                </span>
                                <span
                                    class="mx-1 badge rounded-pill bg-info cursor"
                                    *ngIf="cc.currentRegression"
                                    (click)="toggleRegressionCountViews('notStarted')"
                                    matTooltipPosition="above"
                                    matTooltip="Regression NOT STARTED">
                                    {{ totalObj.regressionNotStarted }}
                                </span>
                                <span
                                    class="mx-1 badge rounded-pill bg-success cursor"
                                    (click)="toggleRegressionCountViews('pass')"
                                    *ngIf="cc.currentRegression"
                                    matTooltipPosition="above"
                                    matTooltip="Regression PASS">
                                    {{ totalObj.regressionPass }}
                                </span>
                                <span
                                    class="mx-1 badge rounded-pill bg-danger cursor"
                                    (click)="toggleRegressionCountViews('fail')"
                                    *ngIf="cc.currentRegression"
                                    matTooltipPosition="above"
                                    matTooltip="Regression FAIL">
                                    {{ totalObj.regressionFail }}
                                </span>
                                <ng-container *ngTemplateOutlet="totalStatus; context: { $implicit: totalObj }"></ng-container>
                            </div>
                            <div class="scroll-container">
                                <!-- ROOT ITEM INPUT -->
                                <div *ngIf="showRootAddInput" class="ps-4">
                                    <input
                                        [libFocus]="true"
                                        type="text"
                                        class="form-control form-control-sm new-item-input"
                                        [(ngModel)]="rootAddItem"
                                        (keydown.enter)="addRootItem()"
                                        placeholder="eg: screenToday_widgetFabButton_stateDisabled"
                                        autofocus />
                                    <div class="text-danger">
                                        {{ errorMessage }}
                                    </div>
                                </div>
                                <!-- ENTRY POINT -->
                                <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: bigObj.data }"></ng-container>

                                <div class="extra-space position-relative">
                                    <div class="position-absolute top-50 start-50 translate-middle">
                                        <img class="logo" src="../../assets/images/mevolve.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngSwitchCase="'notifications'">
                            <app-notifications [notifications]="notifications" (onClose)="onNotificationsClose()"></app-notifications>
                        </div>

                        <div *ngSwitchCase="'previews'">
                            <app-previews
                                [filteredWidgets]="filteredWidgets"
                                (onClose)="switchMainView('tasks')"
                                (onSelect)="setSelectedPath($event); showFullPath($event)"></app-previews>
                        </div>

                        <div *ngSwitchCase="'history'">History</div>

                        <div *ngSwitchCase="'favorites'">Favorites</div>

                        <!-- RECURSION TEMPLATE -->
                        <ng-template #recursiveList let-bigO>
                            <!-- OBJ to AAR to LOOP -->
                            <div class="ps-4" *ngFor="let item of bigO | keyvalue | filterOutKeys : '_meta' | sortNodes; let i = index; trackBy: trackByFn">
                                <!-- SHOW EACH ITEM -->
                                <div class="block">
                                    <div
                                        class="d-flex justify-content-between position-relative non-expanded hover-row"
                                        [class]="{
                                            expanded: showChildrenSet.has(item.value._meta._uniqueId) || checkIfLeafNode(item.value),
                                            'text-primary': item.value._meta._pathIds.includes(uss.currentUserSessionStorage.widgetPath)
                                        }">
                                        <div class="top-level" *ngIf="$any(item.value)?._meta?._isTopLevel">&#x2022;</div>
                                        <!-- LEFT PART -->
                                        <div>
                                            <div
                                                class="cursor d-inline-block py-2"
                                                (click)="
                                                    toggleShowChildren(item.value._meta._uniqueId);
                                                    checkIfLeafNode(item.value) && setSelectedPath(item.value._meta._uniqueId)
                                                ">
                                                <span
                                                    *ngIf="checkIfLeafNode(item.value)"
                                                    class="badge text-capitalize"
                                                    [class]="
                                                        getTypeClass(
                                                            item.value.type,
                                                            item.value._meta._pathIds.includes(uss.currentUserSessionStorage.widgetPath)
                                                        )
                                                    ">
                                                    {{ item.value.type || 'X' }}
                                                </span>
                                                <span *ngIf="!checkIfLeafNode(item.value)" class="px-1">{{ item.key }}</span>
                                                <span *ngIf="checkIfLeafNode(item.value)" class="px-1">{{ item.key.split(item.value.type)[1] }}</span>
                                            </div>
                                            <!-- CRUD ICONS -->
                                            <fa-icon
                                                (click)="cloneWidgets(item.value._meta._pathIds, item.value._meta._uniqueId)"
                                                #tooltipClone="matTooltip"
                                                (mouseleave)="tooltipClone.hide()"
                                                matTooltipPosition="above"
                                                matTooltip="Clone"
                                                class="icon py-2"
                                                [icon]="faCopy"></fa-icon>
                                            <fa-icon
                                                (click)="deletePaths(item.value._meta._pathIds)"
                                                class="icon py-2"
                                                matTooltip="Delete"
                                                #tooltipDelete="matTooltip"
                                                (mouseleave)="tooltipDelete.hide()"
                                                matTooltipPosition="above"
                                                [icon]="faTrash"></fa-icon>
                                            <fa-icon
                                                (click)="toggleEditInput(item)"
                                                class="icon py-2"
                                                [icon]="faPencil"
                                                #tooltipEdit="matTooltip"
                                                (mouseleave)="tooltipEdit.hide()"
                                                matTooltipPosition="above"
                                                matTooltip="Edit/Move"
                                                [class]="{
                                                    active: item.value._meta._uniqueId === editUniqueId
                                                }"></fa-icon>
                                            <fa-icon
                                                *ngIf="!checkIfLeafNode(item.value)"
                                                class="icon py-2"
                                                [icon]="faPlus"
                                                matTooltip="Add"
                                                #tooltipAdd="matTooltip"
                                                (mouseleave)="tooltipAdd.hide()"
                                                matTooltipPosition="above"
                                                (click)="toggleAddInput(item)"
                                                [class]="{
                                                    active: item.value._meta._uniqueId === addUniqueId
                                                }"></fa-icon>
                                            <fa-icon
                                                class="icon py-2"
                                                [icon]="faFilter"
                                                [matTooltip]="searchModel === item.value._meta._uniqueId ? 'Clear Filter' : 'Filter'"
                                                #tooltipFilter="matTooltip"
                                                (mouseleave)="tooltipFilter.hide()"
                                                matTooltipPosition="above"
                                                [class]="{
                                                    active: searchModel === item.value._meta._uniqueId && searchModel !== ''
                                                }"
                                                (click)="toggleFilterFromHere(item.value._meta._uniqueId)"></fa-icon>
                                            <span
                                                *ngIf="
                                                    item.value._meta._assignedWidgets && filterOptions.viewFilter.initialSelection.includes('Assigned Features')
                                                "
                                                matTooltip="Assigned Features"
                                                #tooltipAssignedWidgets="matTooltip"
                                                (mouseleave)="tooltipAssignedWidgets.hide()"
                                                matTooltipPosition="above"
                                                (click)="
                                                    toggleShowChildren(item.value._meta._uniqueId);
                                                    checkIfLeafNode(item.value) && setSelectedPath(item.value._meta._uniqueId)
                                                "
                                                class="mx-1 badge rounded-pill bg-warning timelog-badge">
                                                {{ item.value._meta._assignedWidgets }}
                                            </span>
                                            <span
                                                *ngIf="
                                                    item.value._meta._assignedComments &&
                                                    filterOptions.viewFilter.initialSelection.includes('Assigned Comments')
                                                "
                                                matTooltip="Assigned Comments"
                                                #tooltipAssignedComments="matTooltip"
                                                (mouseleave)="tooltipAssignedComments.hide()"
                                                matTooltipPosition="above"
                                                (click)="
                                                    toggleShowChildren(item.value._meta._uniqueId);
                                                    checkIfLeafNode(item.value) && setSelectedPath(item.value._meta._uniqueId)
                                                "
                                                class="mx-1 badge rounded-pill bg-success timelog-badge">
                                                {{ item.value._meta._assignedComments }}
                                            </span>
                                            <span
                                                *ngIf="item.value._meta._regressionNotStarted && regressionCountViews.has('notStarted')"
                                                matTooltip="Regression NOT STARTED"
                                                #tooltipNotStarted="matTooltip"
                                                (mouseleave)="tooltipNotStarted.hide()"
                                                matTooltipPosition="above"
                                                (click)="
                                                    toggleShowChildren(item.value._meta._uniqueId);
                                                    checkIfLeafNode(item.value) && setSelectedPath(item.value._meta._uniqueId)
                                                "
                                                class="mx-1 badge rounded-pill bg-info timelog-badge">
                                                {{ item.value._meta._regressionNotStarted }}
                                            </span>
                                            <span
                                                *ngIf="item.value._meta._regressionPass && regressionCountViews.has('pass')"
                                                matTooltip="Regression PASS"
                                                #tooltipPass="matTooltip"
                                                (mouseleave)="tooltipPass.hide()"
                                                matTooltipPosition="above"
                                                (click)="
                                                    toggleShowChildren(item.value._meta._uniqueId);
                                                    checkIfLeafNode(item.value) && setSelectedPath(item.value._meta._uniqueId)
                                                "
                                                class="mx-1 badge rounded-pill bg-success timelog-badge">
                                                {{ item.value._meta._regressionPass }}
                                            </span>
                                            <span
                                                *ngIf="item.value._meta._regressionFail && regressionCountViews.has('fail')"
                                                matTooltip="Regression FAIL"
                                                #tooltipFail="matTooltip"
                                                (mouseleave)="tooltipFail.hide()"
                                                matTooltipPosition="above"
                                                (click)="
                                                    toggleShowChildren(item.value._meta._uniqueId);
                                                    checkIfLeafNode(item.value) && setSelectedPath(item.value._meta._uniqueId)
                                                "
                                                class="mx-1 badge rounded-pill bg-danger timelog-badge">
                                                {{ item.value._meta._regressionFail }}
                                            </span>
                                        </div>
                                        <!-- DIVIDER -->
                                        <div
                                            class="divider"
                                            (click)="
                                                toggleShowChildren(item.value._meta._uniqueId);
                                                checkIfLeafNode(item.value) && setSelectedPath(item.value._meta._uniqueId)
                                            ">
                                            <div
                                                [class]="{
                                                    selected:
                                                        item.value._meta._uniqueId === uss.currentUserSessionStorage.widgetPath && checkIfLeafNode(item.value)
                                                }"></div>
                                        </div>
                                        <!-- RIGHT PART -->
                                        <div
                                            class="d-flex cursor py-2"
                                            (click)="
                                                toggleShowChildren(item.value._meta._uniqueId);
                                                checkIfLeafNode(item.value) && setSelectedPath(item.value._meta._uniqueId)
                                            ">
                                            <span
                                                class="mx-1"
                                                *ngIf="item.value._meta._assignedTo && filterOptions.viewFilter.initialSelection.includes('Assigned To')">
                                                {{ item.value._meta._assignedTo }}
                                            </span>
                                            <ng-container *ngTemplateOutlet="status; context: { $implicit: item }"></ng-container>
                                            <!-- <div class="sub-status d-inline me-1"
                        *ngIf="checkIfLeafNode(item.value) && filterOptions.viewFilter.initialSelection.includes('Sub Status')"
                        [matTooltip]="item.value.subStatus"
                        [class]="{'in-progress' : item.value.subStatus === 'In Progress', 'done': item.value.subStatus === 'Done'}">
                        &#x2022;</div> -->
                                        </div>
                                    </div>
                                    <!-- ADD NEW ITEM -->
                                    <div *ngIf="item.value._meta._uniqueId === addUniqueId" class="ps-4">
                                        <input
                                            [libFocus]="true"
                                            type="text"
                                            class="form-control form-control-sm new-item-input"
                                            [(ngModel)]="addItem"
                                            (keydown.enter)="addNewItem(addUniqueId)"
                                            placeholder="eg: widgetApplyButton_stateDisabled"
                                            autofocus />
                                        <div class="text-danger">
                                            {{ errorMessage }}
                                        </div>
                                    </div>
                                    <!-- EDIT ITEM -->
                                    <div *ngIf="item.value._meta._uniqueId === editUniqueId">
                                        <input
                                            [libFocus]="true"
                                            type="text"
                                            class="form-control form-control-sm new-item-input"
                                            [(ngModel)]="editUniqueIdNew"
                                            (keydown.enter)="editPaths(item.value._meta._pathIds, editUniqueId, editUniqueIdNew, checkIfLeafNode(item.value))"
                                            placeholder="eg: screenOne_widgetApplyButton"
                                            autofocus />
                                        <div class="text-danger">
                                            {{ errorMessage }}
                                        </div>
                                    </div>

                                    <!-- RECURSION IF NOT LEAF NODE -->
                                    <div *ngIf="item.value && !checkIfLeafNode(item.value) && showChildrenSet.has(item.value._meta._uniqueId)">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                recursiveList;
                                                context: {
                                                    $implicit: item.value
                                                }
                                            "></ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </as-split-area>

                    <!-- // DETAIL FORM AREA -->
                    <as-split-area [size]="475" [maxSize]="850" [minSize]="475" *ngIf="filterOptions.viewFilter.initialSelection.includes('Detail View')">
                        <app-widget-detail
                            [widget]="selectedObjToEdit"
                            class="w-100 overflow-hidden"
                            (showWidget)="showWidget($event)"
                            [mode]="widgetDetailsMode"></app-widget-detail>
                    </as-split-area>
                </as-split>
            </div>
        </div>
    </div>
</div>

<!-- Status Badges -->
<ng-template #status let-item>
    <div class="cursor d-inline">
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="aDeprecated"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-dark"
            *ngIf="
                filterOptions.viewFilter.initialSelection.includes('aDeprecated') &&
                !(checkIfLeafNode(item.value) && item.value.analytics?.status !== 'Deprecated')
            "
            [class]="{
                'opacity-25': $any(item.value)?._meta?._aDeprecated === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Deprecated', $any(item.value)?._meta?._aDeprecated, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="aFailed"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-danger"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('aFailed') && !(checkIfLeafNode(item.value) && item.value.analytics?.status !== 'Failed')"
            [class]="{ 'opacity-25': $any(item.value)?._meta?._aFailed === 0 }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Failed', $any(item.value)?._meta?._aFailed, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="aApproved"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-success"
            *ngIf="
                filterOptions.viewFilter.initialSelection.includes('aApproved') && !(checkIfLeafNode(item.value) && item.value.analytics?.status !== 'Approved')
            "
            [class]="{
                'opacity-25': $any(item.value)?._meta?._aApproved === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Approved', $any(item.value)?._meta?._aApproved, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="aImplemented"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-review"
            *ngIf="
                filterOptions.viewFilter.initialSelection.includes('aImplemented') &&
                !(checkIfLeafNode(item.value) && item.value.analytics?.status !== 'Implemented')
            "
            [class]="{
                'opacity-25': $any(item.value)?._meta?._aImplemented === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Implemented', $any(item.value)?._meta?._aImplemented, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="aReady"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-primary"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('aReady') && !(checkIfLeafNode(item.value) && item.value.analytics?.status !== 'Ready')"
            [class]="{ 'opacity-25': $any(item.value)?._meta?._aReady === 0 }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Ready', $any(item.value)?._meta?._aReady, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="aDraft"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-info"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('aDraft') && !(checkIfLeafNode(item.value) && item.value.analytics?.status !== 'Draft')"
            [class]="{ 'opacity-25': $any(item.value)?._meta?._aDraft === 0 }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Draft', $any(item.value)?._meta?._aDraft, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="Done"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-success"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Done') && !(checkIfLeafNode(item.value) && item.value.subStatus !== 'Done')"
            [class]="{ 'opacity-25': $any(item.value)?._meta?._ssDone === 0 }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Done', $any(item.value)?._meta?._ssDone, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="Blocked"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-danger"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Blocked') && !(checkIfLeafNode(item.value) && item.value.subStatus !== 'Blocked')"
            [class]="{
                'opacity-25': $any(item.value)?._meta?._ssBlocked === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Blocked', $any(item.value)?._meta?._ssBlocked, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="In Progress"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-primary"
            *ngIf="
                filterOptions.viewFilter.initialSelection.includes('In Progress') && !(checkIfLeafNode(item.value) && item.value.subStatus !== 'In Progress')
            "
            [class]="{
                'opacity-25': $any(item.value)?._meta?._ssInProgress === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'In Progress', $any(item.value)?._meta?._ssInProgress, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="To Do"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-info"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('To Do') && !(checkIfLeafNode(item.value) && item.value.subStatus !== 'To Do')"
            [class]="{ 'opacity-25': $any(item.value)?._meta?._ssTask === 0 }">
            {{ getPillValue(checkIfLeafNode(item.value), 'To Do', $any(item.value)?._meta?._ssTask, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="Total"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-dark"
            *ngIf="
                !checkIfLeafNode(item.value) &&
                (filterOptions.viewFilter.initialSelection.length === 0 || filterOptions.viewFilter.initialSelection.includes('Total'))
            "
            [class]="{
                'opacity-25': $any(item.value)?._meta?._statusTotal === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Total', $any(item.value)?._meta?._statusTotal, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="Approve"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            *ngIf="
                !(checkIfLeafNode(item.value) && $any(item.value)?._meta?._statusToApprove === 0) &&
                filterOptions.viewFilter.initialSelection.includes('Approve')
            "
            class="mx-1 float-end badge rounded-pill bg-success"
            [class]="{
                'opacity-25': $any(item.value)?._meta?._statusToApprove === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Approve', $any(item.value)?._meta?._statusToApprove, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="Failed"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            *ngIf="
                !(checkIfLeafNode(item.value) && $any(item.value)?._meta?._statusToFailed === 0) && filterOptions.viewFilter.initialSelection.includes('Failed')
            "
            class="mx-1 float-end badge rounded-pill bg-danger"
            [class]="{
                'opacity-25': $any(item.value)?._meta?._statusToFailed === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Failed', $any(item.value)?._meta?._statusToFailed, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="Test"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            *ngIf="!(checkIfLeafNode(item.value) && $any(item.value)?._meta?._statusToTest === 0) && filterOptions.viewFilter.initialSelection.includes('Test')"
            class="mx-1 float-end badge rounded-pill bg-warning"
            [class]="{
                'opacity-25': $any(item.value)?._meta?._statusToTest === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Test', $any(item.value)?._meta?._statusToTest, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="Develop"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            *ngIf="
                !(checkIfLeafNode(item.value) && $any(item.value)?._meta?._statusToDevelop === 0) &&
                filterOptions.viewFilter.initialSelection.includes('Develop')
            "
            class="mx-1 float-end badge rounded-pill bg-primary"
            [class]="{
                'opacity-25': $any(item.value)?._meta?._statusToDevelop === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Develop', $any(item.value)?._meta?._statusToDevelop, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="Review"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            *ngIf="
                !(checkIfLeafNode(item.value) && $any(item.value)?._meta?._statusToReview === 0) && filterOptions.viewFilter.initialSelection.includes('Review')
            "
            class="mx-1 float-end badge rounded-pill bg-review"
            [class]="{
                'opacity-25': $any(item.value)?._meta?._statusToReview === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Review', $any(item.value)?._meta?._statusToReview, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="Design"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            *ngIf="
                !(checkIfLeafNode(item.value) && $any(item.value)?._meta?._statusToDesign === 0) && filterOptions.viewFilter.initialSelection.includes('Design')
            "
            class="mx-1 float-end badge rounded-pill bg-secondary"
            [class]="{
                'opacity-25': $any(item.value)?._meta?._statusToDesign === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Design', $any(item.value)?._meta?._statusToDesign, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="Backlog"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            class="mx-1 float-end badge rounded-pill bg-info"
            *ngIf="
                !(checkIfLeafNode(item.value) && $any(item.value)?._meta?._statusBacklog === 0) && filterOptions.viewFilter.initialSelection.includes('Backlog')
            "
            [class]="{
                'opacity-25': $any(item.value)?._meta?._statusBacklog === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), 'Backlog', $any(item.value)?._meta?._statusBacklog, totalObj.statusTotal) }}
        </span>
        <span
            [matTooltipDisabled]="checkIfLeafNode(item.value)"
            matTooltip="Unassigned"
            matTooltipPosition="above"
            #tooltip="matTooltip"
            (mouseleave)="tooltip.hide()"
            *ngIf="!checkIfLeafNode(item.value) && filterOptions.viewFilter.initialSelection.includes('Unassigned')"
            class="mx-1 float-end badge rounded-pill text-muted"
            [class]="{
                'opacity-25': $any(item.value)?._meta?._unassignedCount === 0
            }">
            {{ getPillValue(checkIfLeafNode(item.value), '', $any(item.value)?._meta?._unassignedCount, totalObj.statusTotal) }}
        </span>
    </div>
</ng-template>

<!-- Total Status Badges -->
<ng-template #totalStatus let-item>
    <div class="float-end cursor" (click)="showTotalAsPercentage = !showTotalAsPercentage">
        <span
            matTooltip="aDeprecated"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-dark"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('aDeprecated')"
            [class]="{ 'opacity-25': item.aDeprecated === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.aDeprecated, item.statusTotal) : item.aDeprecated }}
        </span>
        <span
            matTooltip="aFailed"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-danger"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('aFailed')"
            [class]="{ 'opacity-25': item.aFailed === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.aFailed, item.statusTotal) : item.aFailed }}
        </span>
        <span
            matTooltip="aApproved"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-success"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('aApproved')"
            [class]="{ 'opacity-25': item.aApproved === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.aApproved, item.statusTotal) : item.aApproved }}
        </span>
        <span
            matTooltip="aImplemented"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-review"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('aImplemented')"
            [class]="{ 'opacity-25': item.aImplemented === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.aImplemented, item.statusTotal) : item.aImplemented }}
        </span>
        <span
            matTooltip="aReady"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-primary"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('aReady')"
            [class]="{ 'opacity-25': item.aReady === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.aReady, item.statusTotal) : item.aReady }}
        </span>
        <span
            matTooltip="aDraft"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-info"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('aDraft')"
            [class]="{ 'opacity-25': item.aDraft === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.aDraft, item.statusTotal) : item.aDraft }}
        </span>
        <span
            matTooltip="Done"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-success"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Done')"
            [class]="{ 'opacity-25': item.ssDone === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.ssDone, item.statusTotal) : item.ssDone }}
        </span>
        <span
            matTooltip="Blocked"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-danger"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Blocked')"
            [class]="{ 'opacity-25': item.ssBlocked === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.ssBlocked, item.statusTotal) : item.ssBlocked }}
        </span>
        <span
            matTooltip="In Progress"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-primary"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('In Progress')"
            [class]="{ 'opacity-25': item.ssInProgress === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.ssInProgress, item.statusTotal) : item.ssInProgress }}
        </span>
        <span
            matTooltip="To Do"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-info"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('To Do')"
            [class]="{ 'opacity-25': item.ssTask === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.ssTask, item.statusTotal) : item.ssTask }}
        </span>
        <span
            matTooltip="Total"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-dark"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Total')"
            [class]="{ 'opacity-25': item.statusTotal === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.statusTotal, item.statusTotal) : item.statusTotal }}
        </span>
        <span
            matTooltip="Approve"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-success"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Approve')"
            [class]="{ 'opacity-25': item.statusToApprove === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.statusToApprove, item.statusTotal) : item.statusToApprove }}
        </span>
        <span
            matTooltip="Failed"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-danger"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Failed')"
            [class]="{ 'opacity-25': item.statusToFailed === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.statusToFailed, item.statusTotal) : item.statusToFailed }}
        </span>
        <span
            matTooltip="Test"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-warning"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Test')"
            [class]="{ 'opacity-25': item.statusToTest === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.statusToTest, item.statusTotal) : item.statusToTest }}
        </span>
        <span
            matTooltip="Develop"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-primary"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Develop')"
            [class]="{ 'opacity-25': item.statusToDevelop === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.statusToDevelop, item.statusTotal) : item.statusToDevelop }}
        </span>
        <span
            matTooltip="Review"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-review"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Review')"
            [class]="{ 'opacity-25': item.statusToReview === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.statusToReview, item.statusTotal) : item.statusToReview }}
        </span>
        <span
            matTooltip="Design"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-secondary"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Design')"
            [class]="{ 'opacity-25': item.statusToDesign === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.statusToDesign, item.statusTotal) : item.statusToDesign }}
        </span>
        <span
            matTooltip="Backlog"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill bg-info"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Backlog')"
            [class]="{ 'opacity-25': item.statusBacklog === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.statusBacklog, item.statusTotal) : item.statusBacklog }}
        </span>
        <span
            matTooltip="Unassigned"
            matTooltipPosition="above"
            class="mx-1 float-end badge rounded-pill text-muted"
            *ngIf="filterOptions.viewFilter.initialSelection.includes('Unassigned')"
            [class]="{ 'opacity-25': item.unassignedCount === 0 }">
            {{ showTotalAsPercentage ? getPercent(item.unassignedCount, item.statusTotal) : item.unassignedCount }}
        </span>
    </div>
</ng-template>
