import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FirebaseService } from '../_services/firebase.service';
import { JsActivity } from '../_interfaces/Activity';
import { SvgDiffComponent } from '../svg-diff/svg-diff.component';

@Component({
  selector: 'app-preview-history',
  templateUrl: './preview-history.component.html',
  styleUrl: './preview-history.component.scss'
})
export class PreviewHistoryComponent implements AfterViewInit {
  @ViewChild('previewContainer', { static: true })
  previewContainer!: ElementRef;
  activity: JsActivity[] = [];
  sortedPreviews: { url: string; updatedAt: Date }[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityId: string,
      previewKey: string,
      previewAtKey: string
    },
    public fbs: FirebaseService,
    public dialog: MatDialog
  ) {
    this.fbs
      .getActivities(15, [
        {
          field: 'entityId',
          operator: '==',
          value: this.data.entityId
        },
        {
          field: 'changed',
          operator: 'array-contains',
          value: this.data.previewKey
        }
      ])
      .subscribe(acts => {
        this.activity = acts;
        // Sorted preview - First filter and take only activities with valid after.stateFigmaPreviewUrl and after.stateFigmaPreviewAt
        // Then sort by after.stateFigmaPreviewAt in descending order. This will give us the latest preview first.
        this.sortedPreviews = this.activity
          // @ts-ignore
          .filter(a => a.after && a.after[this.data.previewKey] && a.after[this.data.previewAtKey])
          .sort((a, b) => {
            // @ts-ignore
            return a.after[this.data.previewAtKey]! > b.after[this.data.previewAtKey]! ? -1 : 1;
          })
          .map(a => {
            return {
              // @ts-ignore
              url: a.after[this.data.previewKey],
              // @ts-ignore
              updatedAt: a.after[this.data.previewAtKey]
            };
          });
      });
  }

  ngAfterViewInit(): void {
    this.previewContainer.nativeElement.scrollLeft = this.previewContainer.nativeElement.scrollWidth;
  }

  showDiff(i: number): void {
    const oldUrl = this.sortedPreviews[i + 1].url;
    const newUrl = this.sortedPreviews[i].url;

    if (oldUrl && newUrl) {
      this.dialog.open(SvgDiffComponent, {
        data: {
          oldSvgUrl: oldUrl,
          newSvgUrl: newUrl
        }
      });
    }
  }
}
