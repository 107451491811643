<span class="ri-pointer" *ngIf="!isEmpty; else noSpecs">
    <span class="badge rounded-pill text-bg-light me-1" matTooltip="Draft" [ngClass]="{ 'opacity-25': !Draft }">
        {{ Draft || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-review me-1" matTooltip="Reviewed" [ngClass]="{ 'opacity-25': !Reviewed }">
        {{ Reviewed || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-dark me-1" matTooltip="Approved" [ngClass]="{ 'opacity-25': !Approved }">
        {{ Approved || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-warning me-1" matTooltip="Implemented" [ngClass]="{ 'opacity-25': !Implemented }">
        {{ Implemented || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-dark me-1" matTooltip="Total" [ngClass]="{ 'opacity-25': !totalViews }">
        {{ totalViews }}
    </span>
</span>
<ng-template #noSpecs>
    <span class="me-2 d-inline-block">- - -</span>
</ng-template>
