import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, signal, ViewChild, WritableSignal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatOptgroup, MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatLabel, MatFormField, MatError, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { JsTodo } from 'src/app/_interfaces/Todo';
import { ActivityService } from 'src/app/_services/activity.service';
import { CacheService } from 'src/app/_services/cache.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { FirebaseOptimisticService } from 'src/app/_services/firebase-optimistic.service';
import { SharedFunctionService } from 'src/app/_services/shared-function.service';
import { getNewId } from 'src/app/shared/utils';
import _ from 'lodash';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { JsTask } from 'src/app/_interfaces/Task';
import { TaskListModalComponent } from 'src/app/task-list-modal/task-list-modal.component';
import { DialogManagerService } from 'src/app/_services/dialog-manager.service';
import { TaskStatusCountOfTasksComponent } from 'src/app/task-status-count-of-tasks/task-status-count-of-tasks.component';
import { todoStatusList } from 'src/app/shared/status';
import { SelectComponent } from 'src/app/shared/components/select/select.component';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { UrlInputDirective } from 'src/app/_directives/url-directive';
import { urlValidator } from 'src/app/_configs/form-validator';
import { DateFnsModule } from 'ngx-date-fns';
import { SvgViewComponent } from 'src/app/modules/shared/svg-view/svg-view.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faFigma } from '@fortawesome/free-brands-svg-icons';
import { faRetweet, faCheck, faXmark, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { PreviewHistoryComponent } from 'src/app/preview-history/preview-history.component';
import { DailyPlanDetailComponent } from 'src/app/daily-plan-detail/daily-plan-detail.component';
import { Subject, takeUntil } from 'rxjs';
import { IdbServiceService } from 'src/app/_services/idb-service.service';

@Component({
  selector: 'app-todo-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CdkTextareaAutosize,
    MatLabel,
    MatFormField,
    MatOption,
    MatInput,
    MatSelect,
    MatError,
    MatIcon,
    TaskStatusCountOfTasksComponent,
    SelectComponent,
    MatSelectTrigger,
    MatSuffix,
    MatIconButton,
    MatOptgroup,
    MatTooltip,
    UrlInputDirective,
    DateFnsModule,
    FaIconComponent
  ],
  templateUrl: './todo-form.component.html',
  styleUrl: './todo-form.component.scss'
})
export class TodoFormComponent {

  mode: 'new' | 'edit';
  todoStatuses: string[] = todoStatusList;
  todoType: string[] = ['Product', 'Design', 'Develop', 'Test', 'Other'];
  todoPriority: string[] = ['Low', 'Normal', 'High'];
  todoForm: FormGroup;
  @ViewChild('tdForm') tdForm!: NgForm;
  todoInitial: JsTodo;
  allTasks: { label: string, value: string }[] = [];
  relatedTasks: WritableSignal<JsTask[]> = signal<JsTask[]>([]);
  searchTextboxControl = new FormControl();
  selectedValues: any[] = [];
  @ViewChild('searchTasks') searchTextBox!: ElementRef;
  activeTasks: JsTask[] = this.cc.activeTasks;
  filteredTasks: JsTask[] = this.cc.activeTasks;
  faRetweet = faRetweet;
  faFigma = faFigma;
  faCheck = faCheck;
  faXmark = faXmark;
  faClockRotateLeft = faClockRotateLeft;
  unSubscribe = new Subject<void>();
  @ViewChild(MatSelect) matSelect!: MatSelect;

  constructor(
    public cc: CacheService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'new' | 'edit', todoValue: JsTodo },
    public dialogRef: MatDialogRef<TodoFormComponent>,
    public dialog: MatDialog,
    private fbo: FirebaseOptimisticService,
    public fbs: FirebaseService,
    public sharedFunc: SharedFunctionService,
    private confirm: ConfirmService,
    public as: ActivityService,
    private _dialog: DialogManagerService,
    private idb: IdbServiceService
  ) {

    this.mode = data.mode;
    this.todoInitial = data.mode == 'new' ? this.initiateTodoForm() : this.initiateTodoForm(data.todoValue);
    this.todoForm = this.fb.group({
      title: new FormControl(this.todoInitial.title, Validators.required),
      description: new FormControl(this.todoInitial.description, Validators.required),
      type: new FormControl(this.todoInitial.type, Validators.required),
      status: new FormControl(this.todoInitial.status, Validators.required),
      assignedTo: new FormControl(this.todoInitial.assignedTo),
      relatedTids: new FormControl(this.todoInitial.relatedTids),
      priority: new FormControl(this.todoInitial.priority, Validators.required),
      note: new FormControl(this.todoInitial.note),
      tid: new FormControl(this.todoInitial.tid, Validators.required),
      id: new FormControl(this.todoInitial.id, Validators.required),
      timeLogs: new FormControl(this.todoInitial.timeLogs),
      figmaSectionLink: new FormControl(this.todoInitial.figmaSectionLink, urlValidator()),
      figmaPreviewUrl: new FormControl(this.todoInitial.figmaPreviewUrl),
      figmaPreviewValidated: new FormControl(this.todoInitial.figmaPreviewValidated),
      figmaPreviewAt: new FormControl(this.todoInitial.figmaPreviewAt),
      figmaUsedColors: new FormControl(this.todoInitial.figmaUsedColors),
      skipPreviewCheck: new FormControl(this.todoInitial.skipPreviewCheck),
      deletedAt: new FormControl(this.todoInitial.deletedAt)
    });

    this.relatedTasks.set(this.todoInitial.relatedTids.length === 0 ? [] : this.cc.allTasks.filter(task => this.todoInitial.relatedTids.includes(task.id)));

    dialogRef.backdropClick().pipe(takeUntil(this.unSubscribe)).subscribe(async () => {
      if (this.hasChanges()) {
        const confirmed = await this.confirm.confirm('Alert', 'You have unsaved changes. Do you really want to discard them?', 'Discard', 'Cancel');
        if (!confirmed) {
          return;
        }
        this.dialogRef.close();
      } else {
        this.dialogRef.close(); // Close immediately if no unsaved changes
      }
    });
  }

  ngOnInit() {
    this.getTodosList();
  }

  getTodosList() {
    this.idb.todos$.pipe(takeUntil(this.unSubscribe)).subscribe((todos: JsTodo[]) => {
      if (todos.length !== 0) {
        const todo = this.cc.idToTodoMap[this.todoInitial.id];
        if(todo){
          this.todoInitial.figmaPreviewUrl = todo?.figmaPreviewUrl || '';
          this.todoInitial.figmaPreviewValidated = todo?.figmaPreviewValidated || false;
          this.todoInitial.figmaPreviewAt = todo?.figmaPreviewAt || null;
          this.todoInitial.figmaUsedColors = todo?.figmaUsedColors || [];
          this.todoInitial.skipPreviewCheck = todo?.skipPreviewCheck || false;
          this.todoForm.get('figmaPreviewUrl')?.setValue(this.todoInitial.figmaPreviewUrl);
          this.todoForm.get('figmaPreviewValidated')?.setValue(this.todoInitial.figmaPreviewValidated);
          this.todoForm.get('figmaPreviewAt')?.setValue(this.todoInitial.figmaPreviewAt);
          this.todoForm.get('figmaUsedColors')?.setValue(this.todoInitial.figmaUsedColors);
          this.todoForm.get('skipPreviewCheck')?.setValue(this.todoInitial.skipPreviewCheck);
        }
      }
    });
  }

  updateChanges() {
    // this.changeRef.detectChanges();
  }

  getFc(fcName: string): FormControl {
    return this.todoForm.get(fcName) as FormControl;
  }

  getActivity() {
    this.as.getActivity('todo', this.todoInitial)
  }

  openLink(link: string) {
    window.open(link, '_blank')
  }

  initiateTodoForm(todo?: JsTodo): JsTodo {
    return {
      title: todo ? todo.title : '',
      description: todo ? todo.description : '',
      type: todo ? todo.type : '',
      status: todo ? todo.status : 'Todo',
      assignedTo: todo ? todo.assignedTo : '',
      relatedTids: todo ? todo.relatedTids : [],
      priority: todo ? todo.priority : 'Normal',
      note: todo ? todo.note : '',
      tid: todo ? todo.tid : this.getNextTodoId(),
      id: todo ? todo.id : getNewId(),
      timeLogs: todo ? todo.timeLogs || [] : [],
      figmaSectionLink: todo ? todo.figmaSectionLink : '',
      figmaPreviewUrl: todo ? todo.figmaPreviewUrl : '',
      figmaPreviewValidated: todo ? todo.figmaPreviewValidated : false,
      figmaPreviewAt: todo ? todo.figmaPreviewAt : null,
      figmaUsedColors: todo ? todo.figmaUsedColors : [],
      skipPreviewCheck: todo ? todo.skipPreviewCheck : false,
      deletedAt: todo ? todo.deletedAt : null,
    }
  }

  getNextTodoId(): number {
    const maxEventId = _.max(this.cc.allTodos.map(todo => todo.tid)) || 0;
    return maxEventId + 1;
  }

  checkRelatedIdChanges() {
    const initial = _.cloneDeep(this.todoInitial);
    const current = _.cloneDeep(this.todoForm.value);

    if (initial.relatedTids?.length !== current.relatedTids?.length) return true;
    initial.relatedTids.sort((a: string, b: string) => (a > b ? 1 : -1));
    current.relatedTids.sort((a: string, b: string) => (a > b ? 1 : -1));
    return !_.isEqual(initial.relatedTids, current.relatedTids);
  }

  handleSearchKeydown(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  filterTasks() {
    const searchValue = this.searchTextboxControl.value.toLowerCase();
    const searchTerms = searchValue.split(/\s+/).filter((term: string) => term.length > 0);
    const selectedIds = this.todoForm.value.relatedTids || [];

    this.filteredTasks = this.cc.activeTasks.filter(task => {
      const taskTitle = task.title.toLowerCase();
      const isSelected = selectedIds.includes(task.id);
      const matchesSearch = searchTerms.every((term: string) => taskTitle.includes(term));

      return isSelected || matchesSearch;
    });

    // Optionally, sort to bring selected items to the top
    this.filteredTasks.sort((a, b) => {
      const aSelected = selectedIds.includes(a.id);
      const bSelected = selectedIds.includes(b.id);
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      return 0;
    });
  }

  getFirstIndexTaskValue(): JsTask | null {
    return this.cc.activeTasks.find(task => task.id === this.todoForm.value.relatedTids[0]) || null;
  }

  clearSearch(event: any) {
    this.searchTextboxControl.setValue('');
    this.filterTasks();
  }

  showTasksList() {
    if (this.relatedTasks().length === 0) {
      return
    }
    this._dialog.openDialog(TaskListModalComponent, {
      data: {
        title: `Related features of ${this.todoInitial.title}`,
        tasks: this.relatedTasks() || []
      }
    });
  }

  previewFigma(figmaUrl: string) {
    if (!figmaUrl) return;
    const viewSvgModel = this.dialog.open(SvgViewComponent, {
      width: '500px !important',
      height: '800px',
      maxWidth: '500px !important',
      maxHeight: '80vh',
      data: {
        figmaUrl: figmaUrl,
        figmaPrevUrl: figmaUrl,
        figmaPreviewValidated: this.todoInitial.figmaPreviewValidated,
        figmaUsedColors: this.todoInitial.figmaUsedColors,
        status: this.todoInitial.status,
        collection: 'todos',
        todoData: _.cloneDeep(this.todoInitial),
      },
    });
    this.updateChanges()
  }

  async resetFigmaPreview() {
    if (!this.todoInitial) return;
    this.todoForm.get('figmaPreviewUrl')?.setValue('');
    this.todoForm.get('figmaPreviewAt')?.setValue(null);
    await this.saveChanges(true);
  }

  openPreviewHistory(todo: JsTodo) {
    this.dialog.open(PreviewHistoryComponent, {
      closeOnNavigation: false,
      data: {
        entityId: todo.id,
        previewKey: 'figmaPreviewUrl',
        previewAtKey: 'figmaPreviewAt'
      }
    });
  }

  hasChanges() {
    const initial = _.cloneDeep(this.todoInitial);
    const current = _.cloneDeep(this.todoForm.value);

    if (initial.relatedTids?.length !== current.relatedTids?.length) return true;
    initial.relatedTids.sort((a: string, b: string) => (a > b ? 1 : -1));
    current.relatedTids.sort((a: string, b: string) => (a > b ? 1 : -1));
    return !_.isEqual(initial, current);
  }

  openDailyPlanModal(isEdit: boolean) {
    const planDialog = this.dialog.open(DailyPlanDetailComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        from: 'model',
        disableClose: true,
        selectedDaily: isEdit ? this.cc.getDailyByTid(this.todoInitial?.tid, 'Todo') : this.sharedFunc.createNewDailyTask(this.todoInitial?.tid, 'Todo'),
        mode: isEdit ? 'edit' : 'new'
      },
      autoFocus: false
    });
  }

  async saveChanges(refreshFigma: boolean = false) {
    const todoBackup = _.cloneDeep(this.todoForm.value);
    let updateStatus = null;

    const updateId = this.todoForm.value.id;
    if (!this.todoForm.value.id) return;
    if (this.mode === 'new') {
      updateStatus = await this.fbo.createItemsOptimistic<JsTodo>(
        [this.todoForm.value],
        'todos'
      );
      if (updateStatus && updateId === this.todoInitial.id) {
        this.mode = 'edit';
        this.todoInitial = _.cloneDeep(todoBackup);
        this.cancelChanges();
      }
    } else if (this.mode === 'edit') {
      updateStatus = await this.fbo.updateItemsOptimistic<JsTodo>(
        [this.todoForm.value],
        'todos'
      );
      this.todoInitial = _.cloneDeep(this.todoForm.value);
    }

    if (!updateStatus) {
      this.todoInitial = _.cloneDeep(todoBackup);
    }
    if (!refreshFigma) {
      this.dialogRef.close();
    }
  }

  async deleteHandler(isDelete: boolean) {
    const confirmed = await this.confirm.confirm(`${isDelete ? 'Delete' : 'Restore'} Todo`, `Are you sure you want to ${isDelete ? 'delete' : 'restore'} this todo?`);
    if (confirmed) {
      if (!this.todoForm.value.id || this.mode === 'new') return;
      this.todoInitial.deletedAt = isDelete ? new Date() : null;
      await this.fbo.updateItemsOptimistic<JsTodo>([this.todoInitial], 'todos');
      if (!isDelete) {
        this.cc.restoreEntitySubject.next('todo');
      }
      this.todoInitial = this.initiateTodoForm();
      this.cancelChanges();
      this.dialogRef.close();
    }
  }

  cancelChanges() {
    this.todoForm.reset();
    this.tdForm.resetForm()
    this.todoForm.patchValue(this.todoInitial);
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
