<main class="p-3 ri-min-w-800">
  <!-- list all widgets using flex bootstrap 5 -->
  <div class="sticky-top bg-body p-2">
    <span class="fs-6">{{ data.title || 'Spec List' }}</span>
  </div>
  <div class="d-flex flex-column" *ngIf="data.widgets?.length; else noWidgets">
    <div class="d-flex m-2 table-container" *ngFor="let widget of data.widgets">
      <div class="type">
        <span class="badge round-pill text-capitalize" [class]="typeClassMap[widget.type]">{{ widget.type }}</span>
      </div>
      <div class="path flex-grow-1 text-break">{{ widget.path }}</div>
      <div class="status">
        <span class="badge round-pill" [class]="statusClassMap[widget.status]">{{ widget.status }}</span>
      </div>
      <button class="btn btn-sm figma me-2" [class.text-bg-secondary]="widget.type === 'state' && widget.stateFigmaPreviewUrl" (click)="previewFigma(widget)">
        <fa-icon matTooltip="Preview" matTooltipPosition="above" *ngIf="widget.stateFigmaPreviewUrl" class="icon py-2 pe-2" [icon]="faImage"></fa-icon>
      </button>
      <button
        class="btn btn-sm figma"
        [class.text-bg-secondary]="widget.type === 'state' && widget.stateFigmaFrameUrl"
        (click)="openFigma(widget.stateFigmaFrameUrl)">
        <fa-icon *ngIf="widget.stateFigmaFrameUrl" class="icon py-2 pe-2" [icon]="faFigma" matTooltip="Open in Figma" matTooltipPosition="above"></fa-icon>
      </button>
      <button class="btn btn-sm open text-bg-info" (click)="openWidget(widget, $event)">
        <fa-icon class="icon py-2 pe-2" matTooltip="Edit Spec" matTooltipPosition="above" [icon]="linkIcon"></fa-icon>
      </button>
    </div>
  </div>
</main>
<ng-template #noWidgets>
  <div class="text-center text-muted">No Specs</div>
</ng-template>
