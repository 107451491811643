<main class="svg-viewer" [class.fit]="!showFullScale">
  <mat-dialog-content>
    <app-svg-viewer [srcUrl]="selectedSvgUrl" [currentColorMap]="currentColorMap" [hoverColor]="hoverColor"
      (onCurrentUsedColorsChange)="onCurrentUsedColorsChange($event)"></app-svg-viewer>
  </mat-dialog-content>
  <!-- <div mat-dialog-content>
    <app-svg-viewer [srcUrl]="selectedSvgUrl" [currentColorMap]="currentColorMap" [hoverColor]="hoverColor"
      (onCurrentUsedColorsChange)="onCurrentUsedColorsChange($event)"></app-svg-viewer>
  </div> -->
</main>

<!-- Corner buttons -->

<button mat-icon-button class="delete-button small-button" (click)="delete()" matTooltip="Delete"
  [disabled]="!['Backlog', 'Design', 'Review'].includes(status || '')"
  *ngIf="['Backlog', 'Design', 'Review'].includes(status || '') && !data.isMinimal">
  <mat-icon>delete</mat-icon>
</button>
<button mat-icon-button class="zoom-button small-button" (click)="showFullScale = !showFullScale" matTooltip="Zoom">
  <mat-icon>{{showFullScale ? 'zoom_out' : 'zoom_in'}}</mat-icon>
</button>
<button mat-icon-button class="validity-button small-button"
  [ngClass]="{'bg-success': selectedWidget.stateFigmaPreviewValidated, 'invalid': !selectedWidget.stateFigmaPreviewValidated}"
  (click)="toggleStatePreviewValidity($event)"
  [matTooltip]="(selectedWidget.stateFigmaPreviewValidated ? 'Valid Preview' : 'Invalid Preview') + '. Click to toggle.'">
  <mat-icon>{{selectedWidget.stateFigmaPreviewValidated ? 'check' : 'close'}}</mat-icon>
</button>
<button *ngIf="currentNameToColorMapExtra.length" mat-icon-button class="extra-button small-button"
  matTooltip="Extra Colors">
  {{currentNameToColorMapExtra.length}}
</button>



<!-- Top block -->

<div class="top-block d-flex overflow-scroll ri-hide-scrollbar w-100">
  <div class="top-block-item p-1 me-2 text-nowrap" *ngIf="data.widgetList?.length">{{this.selectedWidgetIndex +
    1}}/{{data.widgetList.length}}</div>
  <div class="top-block-item p-1 me-2 ri-pointer text-nowrap" *ngIf="viewId && !data.isMinimal"
    (click)="openWidget($event)">
    <span class="viewId mx-1">Spec:</span><span class="text-primary">{{selectedWidget.path}}</span>
  </div>

  <div class="top-block-item p-1 me-2 text-nowrap" *ngIf="viewId && !data.isMinimal">
    <span class="mx-1">All translations for viewId:</span>
    <span class="text-primary">{{viewId}}</span>&nbsp;&nbsp;
    <app-translation-status-counts-of-view class="ri-pointer" [viewId]="viewId"></app-translation-status-counts-of-view>
  </div>

  <div class="top-block-item p-1 me-2 text-nowrap" *ngIf="viewId && !data.isMinimal">
    <span class="viewId mx-1">All specs for viewId:</span> <span class="text-primary">{{viewId}}</span>&nbsp;&nbsp;
    <app-spec-type-counts-of-view class="ri-pointer" [viewId]="viewId"></app-spec-type-counts-of-view>
  </div>
</div>



<!-- Side navigation -->

<div class="next" (click)="nextSpec()" *ngIf="isNextEnabled()">
  <fa-icon class="icon fs-4" [icon]="faChevronRight"></fa-icon>
</div>

<div class="previous" (click)="previousSpec()" *ngIf="isPreviousEnabled()">
  <fa-icon class="icon fs-4" [icon]="faChevronLeft"></fa-icon>
</div>


<!-- Bottom block -->

<div class="d-flex color-container ri-hide-scrollbar">
  <div class="flex-grow">
    <div class="color-modes" *ngIf="!data.isMinimal">
      <mat-chip-listbox [value]="currentThemeMode">
        <mat-chip-option *ngFor="let mode of svgService.availableThemeModes" [value]="mode" [selectable]="true"
          [selected]="mode === currentThemeMode" [color]="mode === currentThemeMode ? 'primary' : ''"
          (click)="onColorModeChange(mode)">{{mode}}</mat-chip-option>
      </mat-chip-listbox>
    </div>
  </div>
  <div class="flex-grow ms-2">
    <div class="current-colors" *ngIf="!data.isMinimal">
      <mat-chip-listbox>
        <mat-chip-option [cdkCopyToClipboard]="item.value" (click)="onHexCopy()"
          *ngFor="let item of currentNameToColorMap" class="color" [ngStyle]="{'background-color': item.value}"
          (mouseenter)="onFocusColor(item.value)" (mouseleave)="onBlurColor()"
          [matTooltip]="(item.name.split(' ')[1] || 'Extra') + ' ' + item.value">
          {{item.name.split(' ')[1] || item.name}} <span>&nbsp;&nbsp; {{item.value}}</span>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </div>
</div>