import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  SvgViewerModalComponent,
  SvgViewerModel,
} from 'src/app/svg-viewer-modal/svg-viewer-modal.component';
import { AttachmentType } from '../_interfaces/Other';
import { JsWidget } from '../_interfaces/Widget';

@Injectable({
  providedIn: 'root',
})
export class SvgViewerModalService {
  private confirmDialog: any;
  constructor(public dialog: MatDialog) {}

  show(
    widget: JsWidget,
    isMinimal: boolean = false,
    widgetList: JsWidget[] = []
  ) {
    if (widget.stateFigmaPreviewUrl) {
      this.confirmDialog = this.dialog.open(SvgViewerModalComponent, {
        maxWidth: '90vw',
        maxHeight: '90vh',
        data: new SvgViewerModel(widget, isMinimal, widgetList),
      });
    }
    return this.confirmDialog.afterClosed();
  }
}
