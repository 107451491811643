<main>
  <div>
    <h2 class="d-inline-block text-primary">{{fbs.getUsername(data.uid)}}'s Effort</h2>
    <!-- <mat-form-field class="float-end" appearance="fill" [class]="{'edited': initialView.status != viewToEdit.status}">
      <mat-label>View Status</mat-label>
      <mat-select [(value)]="viewToEdit.status" (selectionChange)="onStatusChange($event.value)">
        <mat-option *ngFor="let status of viewStatuses" [value]="status">{{status}}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <span class="badge rounded-pill text-bg-dark float-end p-2" matTooltip="Total Hours spent by User">{{totalHours}}
      hrs</span>
  </div>

  <div *ngFor="let taskHour of taskHours" class="task-block p-2 mb-2 clearfix ri-pointer"
    (click)="listDailysOfTask(taskHour.task)">
    <div class="mb-3">
      <span class="text-primary">F-{{taskHour.tid}}</span> {{taskHour.taskName}}
    </div>
    <div>
      <span class="badge rounded-pill text-bg-dark float-end p-2" matTooltip="Hours spent by User">{{taskHour.hours}}
        hrs</span>
      <span class="badge rounded-pill {{statusStyleMap[taskHour.currentStatus]}} p-2 float-end me-2"
        matTooltip="Current Status">{{taskHour.currentStatus}}</span>
      <span class="badge p-2 text-dark text-muted float-end me-2" matTooltip="Last updated">{{taskHour.lastUpdated |
        dfnsFormatDistanceToNow}}
        ago</span>
    </div>
  </div>
</main>