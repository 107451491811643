<ng-container *ngTemplateOutlet="commentPannel; context: { $implicit: comment, main: true }"></ng-container>
<div class="comment-content px-2">
    <p class="mb-0">{{ comment.text }}</p>
    <div class="attachment-content d-flex flex-wrap pb-1" *ngIf="comment.attachmentNames.length !== 0" (click)="$event.stopPropagation()">
        <button class="btn btn-outline-secondary me-2 my-1 py-0" *ngFor="let attach of comment.attachmentNames" (click)="showImageFromCommentInput(attach)">{{ attach }}</button>
    </div>
</div>
<div class="px-2">
    <div class="comment-bottom bg-light rounded-2" (click)="$event.stopPropagation()">
        <div class="comment-reply" *ngIf="comment.replies.length !== 0 && isViewReply" (click)="$event.stopPropagation()">
            <div class="reply-content py-2">
                <ng-container *ngFor="let data of comment.replies">
                    <div class="reply-content-item rounded-3 py-1" (click)="editReply(data)" *ngIf="replyType !== 'edit' || replyToEdit && data.id !== replyToEdit.id">
                        <ng-container *ngTemplateOutlet="commentPannel; context: { $implicit: data, main: false }"></ng-container>
                        <div class="reply-text">
                            {{ data.text }}
                        </div>
                    </div>
                    <ng-container *ngIf="replyType === 'edit' && replyToEdit && data.id === replyToEdit.id">
                        <ng-container *ngTemplateOutlet="replyTextArea; context: { $implicit: data, type: 'edit' }"></ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="replyType !== 'new'; else createReplyTemplate">
            <div class="d-flex justify-content-between">
                <button class="btn btn-sm outline-none" [matTooltip]="isViewReply ? 'Hide all reply' : 'View all reply'" (click)="isViewReply = !isViewReply">
                    <span class="text-muted">{{ isViewReply ? 'Hide reply' : comment.replies.length + (comment.replies.length > 1 ? ' replies' : ' reply') }}</span>
                </button>
                <div class="d-flex">
                    <button class="btn btn-reply btn-sm outline-none" matTooltip="New Relply" (click)="replayComment($event)"><mat-icon class="text-info me-0">reply</mat-icon></button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #createReplyTemplate>
    <ng-container *ngTemplateOutlet="replyTextArea; context: { $implicit: null, type: 'new' }"></ng-container>
</ng-template>

<ng-template #replyTextArea let-replyData let-type="type">
    <mat-form-field appearance="fill" class="w-100 mb-1" [class]="{'edited' : (type === 'new' && replyControl.value) || (type === 'edit' && replyControl.value !== replyToEdit!.text) }">
        <mat-label>Reply Comment</mat-label>
        <textarea matInput [formControl]="replyControl" name="riCommentReply" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
    <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <a *ngIf="type === 'new'" class="text-primary ri-pointer text-decoration-none ps-2" matTooltip="Cancel Reply" (click)="cancelReply()">Cancel</a>
            <button *ngIf="type === 'edit'" class="btn btn-send btn-sm outline-none" matTooltip="Delete Reply" (click)="deleteReply(replyData)"><mat-icon class="text-danger me-0">delete</mat-icon></button>
        </div>
        <button class="btn btn-send btn-sm outline-none" (click)="sendReply($event)" [disabled]="!replyControl.value" matTooltip="Send Reply"><mat-icon class="text-primary me-0">send</mat-icon></button>
    </div>
</ng-template>

<ng-template #commentPannel let-data let-main="main">
    <div class="d-flex align-items-center px-2">
        <div class="user-image">
            <div class="user-profile-icon text-uppercase"><span class="mx-auto fw-bolder">{{ fbs.getUserFirstAndLastLetter(main ? data.uid : data.createdBy )}}</span></div>
        </div>
        <div class="d-inline-flex justify-content-between align-items-center w-100">
            <h4 class="mb-0 ps-2 fw-bold">{{fbs.getDisplayName(main ? data.uid : data.createdBy)}} <span class="ri-fs-12px text-muted">{{ data.createdAt | dfnsFormatDistanceToNowStrict }} ago</span></h4>
            <div class="d-flex" *ngIf="main">
                <span *ngIf="comment.resolvedBy" class="badge my-auto d-flex align-items-center text-bg-success me-2">Resolved</span>
                <span class="badge d-flex align-items-center my-auto" [ngClass]="{'text-bg-danger' : data.type === 'Bug', 'text-bg-warning' : data.type === 'Question', 'text-bg-review' : data.type === 'Other' }">{{ data.type }}</span>
                <app-watchers-preview class="d-flex align-items-center ms-2" [initialWatchers]="comment.watchers" size="small" (onUpdateWatchers)="updateCommentWatchers($event, comment)" (click)="$event.stopPropagation()"></app-watchers-preview>
            </div>
        </div>
    </div>
</ng-template>