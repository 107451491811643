import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-clear-icon-button',
  templateUrl: './clear-icon-button.component.html',
  styleUrls: ['./clear-icon-button.component.scss'],
})
export class ClearIconButtonComponent implements OnInit, OnChanges {
  @Input() tooltip: string = '';
  @Input() object: any;
  @Input() propertyName: string | null = null;
  public clearIcon = faXmark;
  hasValidProperty = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.object && this.propertyName) {
      if (
        this.object.hasOwnProperty(this.propertyName) &&
        this.object[this.propertyName]
      ) {
        this.hasValidProperty = true;
      } else {
        this.hasValidProperty = false;
      }
    }
  }

  clear() {
    if (this.hasValidProperty && this.propertyName) {
      this.object[this.propertyName] = '';
      this.hasValidProperty = false;
    }
  }
}
