import { Component, Input, OnChanges } from '@angular/core';
import { JsWidget } from '../_interfaces/Widget';

@Component({
  selector: 'app-tags-preview',
  templateUrl: './tags-preview.component.html',
  styleUrls: ['./tags-preview.component.scss'],
})
export class TagsPreviewComponent implements OnChanges {
  @Input() widget: JsWidget | null = null;
  tags: string[] = [];

  constructor() {}

  ngOnChanges(): void {
    if (this.widget) {
      this.tags = this.widget.tags || [];
    }
  }
}
