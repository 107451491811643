import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';
import { ConfirmService } from './confirm.service';
import * as _ from 'lodash';
import { IdbServiceService } from './idb-service.service';
import { CacheService } from './cache.service';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VersionServiceService {
  
  env = environment;
  minVersion: string | null = null;
  featureFlags: any = {};
  currentVersion = environment.version;
  db: 'prod' | 'dev' | 'local' = environment.production
    ? 'prod'
    : environment.useEmulators
    ? 'local'
    : 'dev';
  versionUpdateConfirm = null;
  isResyncing: boolean = false;
  unSubscribe = new Subject<void>();

  constructor(
    private fbService: FirebaseService,
    private confirmService: ConfirmService,
    private idb: IdbServiceService,
    private cc: CacheService
  ) {}

  check() {
    console.log('env', environment);
    this.fbService.getConfig().pipe(takeUntil(this.unSubscribe)).subscribe(async (config) => {
      console.log('config', config);
      this.cc.config = config[0];
      this.cc.setTags(config[0]);
      this.cc.setAvailableMainReleaseNumbers(config[0]);
      this.cc.setRegressions(config[0]);
      if (config.length) {
        this.minVersion = config[0].minVersion;
        this.featureFlags = config[0].featureFlags;
        console.log('featureFlags', this.featureFlags);

        console.log('Min Version', this.minVersion);
        console.log('Current Version', this.currentVersion);
        console.log('DB version', localStorage.getItem('version'));
        console.log('DB', localStorage.getItem('db'));
        // CHECK FOR VERSION UPGRADE
        if (
          this.currentVersion !== '##VERSION##' &&
          parseInt(this.currentVersion) < parseInt(this.minVersion)
        ) {
          window.location.reload();
        }
        if (
          this.currentVersion !== localStorage.getItem('version') ||
          this.db !== localStorage.getItem('db')
        ) {
          this.isResyncing = true;
          await this.idb.resync();
          localStorage.setItem('version', environment.version);
          localStorage.setItem('db', this.db);
          this.isResyncing = false;
        }
        await this.idb.initialise();
        // // CHECK FOR VERSION UPGRADE
        // if (
        //   this.currentVersion !== '##VERSION##' &&
        //   parseInt(this.currentVersion) < parseInt(this.minVersion)
        // ) {
        //   // this.versionUpdateConfirm = await this.confirmService.confirm(
        //   //   'New Version wants to meet you!!',
        //   //   `Irrespective of what button you press, it's going to happen anyways. I wish you had a choice... 🥸`
        //   // );
        //   window.location.reload();
        // } else {
        //   // CHECK FOR INDEXDB UPDATE
        //   console.log('Min Version', this.minVersion);
        //   console.log('Current Version', this.currentVersion);
        //   console.log('DB version', localStorage.getItem('version'));
        //   console.log('DB', localStorage.getItem('db'));
        //   if (
        //     this.currentVersion !== localStorage.getItem('version') ||
        //     this.db !== localStorage.getItem('db')
        //   ) {
        //     localStorage.setItem('version', environment.version);
        //     localStorage.setItem('db', this.db);
        //     await this.idb.resync();
        //   }
        //   await this.idb.initialise();
        // }
      }
    });
  }

  stopSubscription() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
