import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import * as _ from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmService } from '../_services/confirm.service';
import { SvgService } from '../_services/svg.service';
import { SnackbarService } from '../_services/snackbar.service';
import { JsWidget, status } from '../_interfaces/Widget';
import { CacheService } from '../_services/cache.service';
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FirebaseOptimisticService } from '../_services/firebase-optimistic.service';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { WidgetDetailEditComponent } from '../widget-detail-edit/widget-detail-edit.component';

@Component({
  selector: 'app-svg-viewer-modal',
  templateUrl: './svg-viewer-modal.component.html',
  styleUrls: ['./svg-viewer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgViewerModalComponent implements OnInit, OnDestroy {
  selectedSvgUrl: string = '';
  status = '';
  viewId = '';
  showFullScale = false;
  currentThemeMode: string = '';
  currentColorMap: { [key: string]: string } | undefined = undefined;
  currentNameToColorMap: { name: string; value: string }[] = [];
  currentNameToColorMapExtra: { name: string; value: string }[] = [];
  hoverColor: string = '';
  faCheck = faCheck;
  faXmark = faXmark;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  selectedWidget!: JsWidget;
  selectedWidgetIndex = 0;
  hasWidgetList = false;

  constructor(
    public dialogRef: MatDialogRef<SvgViewerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SvgViewerModel,
    private confirmService: ConfirmService,
    public svgService: SvgService,
    private snackbar: SnackbarService,
    private changeRef: ChangeDetectorRef,
    private cc: CacheService,
    private fbo: FirebaseOptimisticService,
    private _dialog: DialogManagerService
  ) {}

  ngOnInit(): void {
    this.cc.isSvgViewerModalOpen = true;
    this.selectedWidget = _.cloneDeep(this.data.widget);
    this.selectedWidgetIndex = this.data.widgetList.findIndex(
      (w) => w.id === this.selectedWidget.id
    );
    this.hasWidgetList = this.data.widgetList.length > 0;
    this.currentThemeMode = this.svgService.defaultThemeMode;
    // Update view with given values

    this.initialise();
  }

  initialise() {
    this.selectedSvgUrl = this.selectedWidget.stateFigmaPreviewUrl;
    this.status = this.selectedWidget.status;
    this.viewId = this.selectedWidget.viewId || '';
    this.currentColorMap = this.svgService.getColorMapByThemeMode(
      this.currentThemeMode
    );
    if (!this.currentThemeMode) {
      this.onColorModeChange(this.svgService.defaultThemeMode);
    }
  }

  ngOnDestroy() {
    this.cc.isSvgViewerModalOpen = false;
  }

  // Listen for keydown events
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
    if (event.key === 'ArrowRight' && this.isNextEnabled()) {
      this.nextSpec();
    }

    if (event.key === 'ArrowLeft' && this.isPreviousEnabled()) {
      this.previousSpec();
    }

    if (event.key === 'ArrowUp') {
      this.previousThemeMode();
    }

    if (event.key === 'ArrowDown') {
      this.nextThemeMode();
    }
  }

  async delete() {
    const confirmation = await this.confirmService.confirm(
      'Delete',
      `Are you sure? This is irreversible!!`
    );
    if (!confirmation) return;
    this.dialogRef.close({
      action: 'delete',
    });
  }

  onColorModeChange(mode: string) {
    this.currentThemeMode = mode;
    this.currentColorMap = this.svgService.getColorMapByThemeMode(mode);
    this.changeRef.detectChanges();
  }

  onCurrentUsedColorsChange(event: Set<string>) {
    // Current colors from Color map in Firestore
    const currentNameToColorMap: typeof this.currentNameToColorMap = [];
    this.currentNameToColorMapExtra = this.svgService.getExtraColors(
      this.selectedWidget.stateFigmaUsedColors || []
    );
    const currentModeColors =
      this.svgService.getThemeColorsAsArrayFromThemeMode(this.currentThemeMode);
    [...event].forEach((color) => {
      const colorName = currentModeColors.filter((c) => c.value === color)[0]
        .name;
      currentNameToColorMap.push({
        name: colorName,
        value: color,
      });
    });

    this.currentNameToColorMap = currentNameToColorMap.sort((a, b) => {
      return (
        parseInt(a.name.split(' ')[1], 10) - parseInt(b.name.split(' ')[1], 10)
      );
    });

    this.currentNameToColorMap = this.currentNameToColorMap.concat(
      this.currentNameToColorMapExtra
    );
  }

  onHexCopy() {
    this.snackbar.show('Hex code copied to clipboard');
  }

  onFocusColor(color: string) {
    if (color === this.hoverColor || !color) return;
    this.hoverColor = color;
    this.changeRef.detectChanges();
  }

  onBlurColor() {
    this.hoverColor = '';
    this.changeRef.detectChanges();
  }

  async toggleStatePreviewValidity($event: MouseEvent) {
    $event.stopPropagation();
    const title = this.selectedWidget.stateFigmaPreviewValidated
      ? 'Unapprove Previews'
      : 'Approve Previews';
    const confirmation = await this.confirmService.confirm(
      title,
      `Are you sure? This will change the state of the preview validity!!`
    );
    if (!confirmation) return;
    const newWidget = { ...this.selectedWidget };
    newWidget.stateFigmaPreviewValidated =
      !newWidget.stateFigmaPreviewValidated;

    // Optimistic update
    this.fbo
      .updateItemsOptimistic([newWidget], 'widgets')
      .then(() => {
        this.selectedWidget.stateFigmaPreviewValidated =
          !this.selectedWidget.stateFigmaPreviewValidated;
        this.changeRef.detectChanges();
      })
      .catch((error) => {
        this.snackbar.show('Failed to update');
      });
  }

  isNextEnabled() {
    return this.selectedWidgetIndex < this.data.widgetList.length - 1;
  }

  isPreviousEnabled() {
    return this.selectedWidgetIndex > 0;
  }

  nextSpec() {
    if (this.selectedWidgetIndex === this.data.widgetList.length - 1) {
      return;
    }
    this.selectedWidgetIndex = this.selectedWidgetIndex + 1;
    this.selectedWidget = this.data.widgetList[this.selectedWidgetIndex];
    this.initialise();
  }

  previousSpec() {
    if (this.selectedWidgetIndex === 0) {
      return;
    }
    this.selectedWidgetIndex = this.selectedWidgetIndex - 1;
    this.selectedWidget = this.data.widgetList[this.selectedWidgetIndex];
    this.initialise();
  }

  nextThemeMode() {
    const currentIndex = this.svgService.availableThemeModes.findIndex(
      (mode) => mode === this.currentThemeMode
    );
    if (currentIndex === this.svgService.availableThemeModes.length - 1) {
      return;
    }
    this.onColorModeChange(
      this.svgService.availableThemeModes[currentIndex + 1]
    );
  }

  previousThemeMode() {
    const currentIndex = this.svgService.availableThemeModes.findIndex(
      (mode) => mode === this.currentThemeMode
    );
    if (currentIndex === 0 || currentIndex === -1) {
      return;
    }
    this.onColorModeChange(
      this.svgService.availableThemeModes[currentIndex - 1]
    );
  }

  openWidget(e: any) {
    this._dialog.openDialog(WidgetDetailEditComponent, {
      panelClass: 'widget-detail-edit-bottom-sheet',
      data: {
        widgetId: this.selectedWidget.id,
      },
    });
    e.stopPropagation();
  }
}

/**
 * Class to represent image viewer dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class SvgViewerModel {
  constructor(
    public widget: JsWidget,
    public isMinimal = false,
    public widgetList: JsWidget[] = []
  ) {}
}
