import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatOption, MatSelect, MatSelectTrigger } from '@angular/material/select';
import * as _ from 'lodash';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatSelectTrigger
  ],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss'
})

export class SelectComponent {

  @Input() title: string = '';
  @Input() defaultValue!: (string | number)[] | string | number | null;
  @Input() control: FormControl = new FormControl(this.defaultValue);
  @Input() options: any[] = [];
  @Input() multiple: boolean = false;
  @Input() clear: boolean = false;
  @Input() search: boolean = false;
  @Input() disabled: boolean = false;
  @Input() noneOption: boolean = false;
  @Input() allOption: boolean = false;
  @Input() label: string = '';
  @Input() value: string = '';
  @Input() prefix: string = '';
  @Input() prefixValue: string = '';
  @Input() disabledOptions: any[] = [];
  @Input() hiddenOptions: any[] = [];
  searchControl: FormControl = new FormControl(null);
  filteredOptions: WritableSignal<string[]> = signal([]);

  @Output() selectEvent = new EventEmitter<{ label: string; value: any }>();

  get hasChanged() {
    if(Array.isArray(this.defaultValue)) {
      return !_.isEqual(
        this.control.value?.sort(),
        this.defaultValue?.sort()
      );
    } else {
      return this.control.value !== this.defaultValue
    }
  }

  getFirstIndexValue(): string | number | [] {
    return this.multiple ? this.control.value && this.control.value.length > 0 ? this.label && this.value ? this.options.find(x => x[this.value] === this.control.value[0])[this.label] : this.control.value[0] : [] : this.control.value;
  }
}
