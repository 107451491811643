<main class="todo-form p-3 ri-w-600" [ngClass]="{'pe-none': todoInitial.deletedAt}">
    <div class="d-flex justify-content-between mb-3">
        <h2 class="fw-semibold mb-0">Todo {{ mode === 'new' ? 'Create' : 'Update' }} <span class="text-primary fs-5" *ngIf="mode === 'edit'"> (T-{{todoInitial.tid}})</span></h2>
        <div class="d-flex" *ngIf="mode === 'edit'">
            <button class="btn btn-primary btn-sm float-end me-2" (click)="openDailyPlanModal(cc.todoDailyHrsMap[todoInitial.tid] >= 0)"> {{ cc.todoDailyHrsMap[todoInitial.tid] ? cc.todoDailyHrsMap[todoInitial.tid] + ' Hr' : 'Log Time'}}</button>
            <button class="btn btn-comment btn-sm btn-success d-flex align-items-center h-100 me-2"
                (click)="sharedFunc.getComments('todo', todoInitial)" matTooltip="Comment">
                <mat-icon class="pe-0">question_answer</mat-icon>
                <span class="ps-2" *ngIf="cc.idToTodoMap[todoInitial.id]?.latestComment">{{
                    cc.idToTodoMap[todoInitial.id].latestComment?.updatedAt | dfnsFormatDistanceToNowStrict }}
                    ago</span>
            </button>
            <button matTooltip="Get Activity" class="btn btn-info btn-sm btn-activity d-flex align-items-center"
                (click)="getActivity()"><mat-icon class="text-light">restore</mat-icon></button>
        </div>
    </div>

    <div class="ri-dialog-content">
        <form [formGroup]="todoForm" #tdForm="ngForm" *ngIf="todoForm.value.id">
            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : todoForm.value.title !== todoInitial.title}">
                <mat-label>Title</mat-label>
                <input matInput type="text" autocomplete="off" autocorrect="off" autocapitalize="none"
                    spellcheck="false" formControlName="title" name="riTodoTitle" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : todoForm.value.description !== todoInitial.description}">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="description" name="riTodoDescription" cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>

            <div class="row g-1 mb-1">
                <div class="col-6">
                    <app-select [options]="todoType" [title]="'Type'" class="w-100 mb-1" [control]="getFc('type')"
                        [defaultValue]="todoInitial.type"></app-select>
                </div>
                <div class="col-6">
                    <app-select [options]="todoStatuses" [title]="'Status'" class="w-100 mb-1"
                        [control]="getFc('status')" [defaultValue]="todoInitial.status"></app-select>
                </div>
            </div>

            <div class="row g-1 mb-1">
                <div class="col-6">
                    <app-select [options]="todoPriority" [title]="'Priority'" class="w-100 mb-1"
                        [control]="getFc('priority')" [defaultValue]="todoInitial.priority"></app-select>
                </div>
                <div class="col-6">
                    <app-select [options]="fbs.users" [title]="'Assigned To'" class="mb-1" [noneOption]="true"
                        [control]="getFc('assignedTo')" [defaultValue]="todoInitial.assignedTo" [label]="'label'"
                        [value]="'value'"></app-select>
                </div>
            </div>

            <mat-form-field appearance="fill" class="w-100 mb-1" [class.edited]="checkRelatedIdChanges()">
                <mat-label>Related Features</mat-label>
                <mat-select placeholder="Select" formControlName="relatedTids" multiple panelClass="custom-panel"
                    (openedChange)="clearSearch($event)">
                    <mat-select-trigger>
                        <span *ngIf="todoForm.value.relatedTids.length">
                            <span class="text-primary pe-1">F-{{ getFirstIndexTaskValue()?.tid || '' }}</span>
                            {{ getFirstIndexTaskValue()?.title || '' }}
                        </span>
                        <span *ngIf="(todoForm.value.relatedTids?.length || 0) > 1">
                            + {{ (todoForm.value.relatedTids?.length || 0) - 1 }}
                        </span>
                    </mat-select-trigger>
                    <div class="select-container">
                        <mat-optgroup class="width: 100%">
                            <mat-form-field style="width:100%;">
                                <input class="px-2" #searchTasks autocomplete="off" placeholder="Search"
                                    aria-label="Search" matInput [formControl]="searchTextboxControl"
                                    (keyup)="filterTasks()">
                                <button [disableRipple]="true" *ngIf="searchTasks.value" matSuffix mat-icon-button
                                    aria-label="Clear" (click)="clearSearch($event)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </mat-optgroup>
                        <mat-option *ngFor="let option of filteredTasks" [value]="option.id">
                            <span class="text-primary pe-1">F-{{ option.tid }}</span> {{ option.title}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>


            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : todoForm.value.figmaSectionLink !== todoInitial.figmaSectionLink}">
                <mat-label>Figma Section Link</mat-label>
                <input matInput type="text" autocomplete="off" autocorrect="off" autocapitalize="none"
                    spellcheck="false" formControlName="figmaSectionLink" name="riFigmaSectionLink" appUrlInput />
                <button *ngIf="todoForm.value.figmaSectionLink && !getFc('figmaSectionLink').errors"
                    (click)="openLink(todoForm.value.figmaSectionLink)" matTooltip="Show in Figma" matSuffix
                    mat-icon-button aria-label="Link">
                    <mat-icon
                        class="text-primary ri-fs-18px d-flex align-items-center justify-content-center">launch</mat-icon>
                </button>
                <button *ngIf="todoForm.value.figmaSectionLink" matSuffix mat-icon-button aria-label="Clear"
                    (click)="getFc('figmaSectionLink').setValue(null)">
                    <mat-icon class="ri-fs-18px d-flex align-items-center justify-content-center">close</mat-icon>
                </button>
            </mat-form-field>


            <!-- State Preview Figma -->
            <div class="mt-1 mb-2 text-capitalize" *ngIf="todoInitial.figmaPreviewUrl">
                <button class="btn btn-sm px-3 text-capitalize me-1" (click)="previewFigma(todoInitial.figmaPreviewUrl)" [ngClass]="{ 'btn-danger': !todoInitial.figmaPreviewValidated, 'btn-success': todoInitial.figmaPreviewValidated }"
                    *ngIf="todoInitial?.figmaPreviewUrl">
                    <fa-icon class="icon py-2 pe-2" [icon]="faFigma"></fa-icon>
                    {{ todoInitial.figmaPreviewAt | dfnsFormatDistanceToNow }} ago
                    <fa-icon *ngIf="todoForm.value.figmaPreviewValidated" class="icon text-light ps-2 fs-6"
                        [icon]="faCheck"></fa-icon>
                    <fa-icon *ngIf="!todoForm.value.figmaPreviewValidated" class="icon text-dark ps-2 fs-6"
                        [icon]="faXmark"></fa-icon>
                </button>
                <button type="button" matTooltip="Reset Figma Preview" class="btn btn-outline-secondary btn-sm me-1"
                    (click)="resetFigmaPreview()"
                    *ngIf="todoForm.value.figmaPreviewUrl && ['Todo', 'In Progress', 'Blocked', 'Need Clarity'].includes(todoInitial?.status || '')">
                    <fa-icon class="icon" [icon]="faRetweet"></fa-icon>
                </button>
                <button type="button" matTooltip="Preview History" class="btn btn-outline-secondary btn-sm"
                    (click)="openPreviewHistory(todoForm.value)" *ngIf="todoInitial?.figmaPreviewUrl">
                    <fa-icon class="icon" [icon]="faClockRotateLeft"></fa-icon>
                </button>
            </div>


            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : todoForm.value.note !== todoInitial.note}">
                <mat-label>Notes</mat-label>
                <textarea matInput formControlName="note" name="riTodNnote" cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>
        </form>
    </div>

    <div class="ri-dialog-footer">
        <div class="d-flex justify-content-between mt-5" *ngIf="!todoInitial.deletedAt">
            <div>
                <button *ngIf="mode === 'edit'" type="button"
                    class="btn btn-sm text-bg-danger ri-fs-16px lh-sm ri-btn-delete" (click)="deleteTodo()"
                    [disabled]="todoInitial.deletedAt" matTooltip="Delete">
                    <i class="bi bi-trash"></i>
                </button>
            </div>
            <div>
                <button type="button" class="btn btn-sm btn-outline-secondary" (click)="cancelChanges()"
                    [disabled]="!hasChanges()">Reset</button>
                <button type="submit" class="btn btn-sm btn-primary ms-2" (click)="saveChanges()"
                    [disabled]="!hasChanges() || todoForm.invalid">Save</button>
            </div>
        </div>
        <div class="my-3 pt-2" *ngIf="mode === 'edit';">
            <span>Feature Status Counts:&nbsp;&nbsp;</span>
            <app-task-status-count-of-tasks class="px-2 task-status-counts" [tasks]="relatedTasks()"
                (click)="showTasksList()"></app-task-status-count-of-tasks>
        </div>
    </div>

</main>