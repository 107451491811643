<main>
  <h2 mat-dialog-title>Release Feature Flag - {{data.feature}}</h2>
  <mat-radio-group class="d-flex flex-column" [(ngModel)]="selectedMainSegment" color="primary">
    <mat-radio-button class="pb-2" *ngFor="let main of mainSegments" [value]="main">{{main}}</mat-radio-button>
  </mat-radio-group>


  <!-- Add checkboxes -->
  <div class="d-flex flex-column ms-5" *ngIf="selectedMainSegment === 'CUSTOM'">
    <mat-checkbox *ngFor="let sub of customSegments" [(ngModel)]="selectedCustomSegment[sub]"
      color="primary">{{sub}}</mat-checkbox>
  </div>

  <div class="d-flex justify-content-end">
    <button class="btn btn-secondary btn-sm me-2" (click)="reset()">Reset</button>
    <button class="btn btn-primary btn-sm" (click)="save()">Update</button>
  </div>
</main>