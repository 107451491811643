import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirebaseService } from '../../../_services/firebase.service';

@Component({
  selector: 'app-watchers-select',
  templateUrl: './watchers-select.component.html',
  styleUrls: ['./watchers-select.component.scss'],
})
export class WatchersSelectComponent implements OnInit {
  initialWatchers: string[] = [];
  allUserIds: string[] = [];
  selectedUserIds: string[] = [];
  currentUserId: string = '';
  @ViewChild('watchersSelect') watchersSelect: any;
  constructor(
    public dialogRef: MatDialogRef<WatchersSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string[],
    public fbs: FirebaseService
  ) {}

  ngOnInit(): void {
    this.currentUserId = this.fbs.getCurrentUserId();
    this.allUserIds = this.fbs.users.map((user) => user.value);
    this.initialWatchers = [...this.data];
    this.selectedUserIds = [...this.data];
  }

  onSelectionChange(event: any) {
    this.selectedUserIds = event.source.selectedOptions.selected.map(
      (option: any) => option.value
    );
  }

  resetHandler() {
    this.selectedUserIds = [...this.initialWatchers];
  }

  updateHandler() {
    this.dialogRef.close([...this.selectedUserIds]);
  }

  get hasChanges() {
    return (
      this.initialWatchers.length !== this.selectedUserIds.length ||
      this.initialWatchers.some(
        (userId) => !this.selectedUserIds.includes(userId)
      )
    );
  }
}
