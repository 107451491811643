import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TaskDetailsModalComponent } from '../task-details-modal/task-details-modal.component';
import { JsTask } from '../_interfaces/Task';
import { ReleaseFormComponent } from '../releases/release-form/release-form.component';
import { JsRelease } from '../_interfaces/Release';
import { JsTodo } from '../_interfaces/Todo';
import { TodoFormComponent } from '../todos/todo-form/todo-form.component';
import { ViewFormComponent } from '../modules/project/components/views/view-form/view-form.component';
import { JsView } from '../_interfaces/View';
import { JsTranslation } from '../_interfaces/Translation';
import { TranslationDetailsModalComponent } from '../translation-details-modal/translation-details-modal.component';
import { JsEvent } from '../_interfaces/Events';
import { EventFormComponent } from '../events/event-form/event-form.component';
import { LogFormComponent } from '../logs/log-form/log-form.component';
import { ParamFormComponent } from '../params/param-form/param-form.component';
import { JsParam } from '../_interfaces/Param';
import { JsLog } from '../_interfaces/Log';
import { JsDaily } from '../_interfaces/Daily';
import { entity } from '../_interfaces/Activity';
import { JsEntity } from '../_interfaces/Entities';
import { JsComment } from '../_interfaces/Comment';
import { JsWidget } from '../_interfaces/Widget';
import { CacheService } from './cache.service';
import { DailyPlanDetailComponent } from '../daily-plan-detail/daily-plan-detail.component';
import { WidgetDetailEditComponent } from '../widget-detail-edit/widget-detail-edit.component';
import { DialogManagerService } from './dialog-manager.service';
import { CommentFormComponent } from '../modules/project/components/comments/comment-form/comment-form.component';
import { FirestoreQueryCondition } from '../_interfaces/Other';
import { FirebaseService } from './firebase.service';
import _ from 'lodash';
import { JsLeave } from '../_interfaces/Leave';
import { LeaveFormComponent } from '../modules/project/components/leave/leave-form/leave-form.component';

@Injectable({
    providedIn: 'root',
})

export class EntityService {

    constructor(private dialog: MatDialog, private cc: CacheService, private dManager: DialogManagerService, private fbs: FirebaseService) {
    }

    viewDetails(entityType: entity, entity: JsEntity | any, isViewEntityForCmts: boolean = false) {
        switch (entityType) {
            case 'task':
                this.viewTaskDetails(this.cc.idToTaskMap[entity.id]);
                break;
            case 'view':
                this.viewViewDetails(this.cc.idToViewMap[entity.id]);
                break;
            case 'widget':
                this.viewSpecDetails(entity.deletedAt !== null ? this.cc.idToDeletedSpecsMap[entity.id] : this.cc.idToSpecMap[entity.id]);
                break;
            case 'translation':
                this.viewTranslationDetails(this.cc.idToTranslationMap[entity.id]);
                break;
            case 'release':
                this.viewReleaseDetails(this.cc.idToReleaseMap[entity.id]);
                break;
            case 'event':
                this.viewEventDetails(this.cc.idToEventMap[entity.id]);
                break;
            case 'log':
                this.viewLogDetails(this.cc.idToLogMap[entity.id]);
                break;
            case 'todo':
                this.viewTodoDetails(this.cc.idToTodoMap[entity.id]);
                break;
            case 'param':
                this.viewParamDetails(this.cc.idToParamMap[entity.id]);
                break;
            case 'daily':
                this.viewDailyDetails(this.cc.idToDailyMap[entity.id]);
                break;
            case 'comment':
                if(isViewEntityForCmts) {
                    switch (entity.entity) {
                        case 'release':
                            this.viewReleaseDetails(this.cc.idToReleaseMap[entity.entityId]);
                            break;
                        case 'task':
                            this.viewTaskDetails(this.cc.idToTaskMap[entity.entityId]);
                            break;
                        case 'widget':
                            this.viewSpecDetails(this.cc.idToSpecMap[entity.entityId]);
                            break;
                        case 'todo':
                            this.viewTodoDetails(this.cc.idToTodoMap[entity.entityId]);
                            break;
                        case 'view':
                            this.viewViewDetails(this.cc.idToViewMap[entity.entityId]);
                            break;
                        case 'translation':
                            this.viewTranslationDetails(this.cc.idToTranslationMap[entity.entityId]);
                            break;
                        case 'event':
                            this.viewEventDetails(this.cc.idToEventMap[entity.entityId]);
                            break;
                        case 'log':
                            this.viewLogDetails(this.cc.idToLogMap[entity.entityId]);
                            break;
                        case 'param':
                            this.viewParamDetails(this.cc.idToParamMap[entity.entityId]);
                            break;
                        case 'daily':
                            this.viewDailyDetails(this.cc.idToDailyMap[entity.entityId]);
                            break;
                        default:
                            break;
                    }
                } else {
                    if (entity.deletedAt) {
                        this.viewCommentDetails(entity);
                    } else {
                        const filters: FirestoreQueryCondition[] = [];
                        filters.push({
                            field: 'entity',
                            operator: '==',
                            value: entity.entity,
                        });
                        filters.push({
                            field: 'entityId',
                            operator: '==',
                            value: entity.entityId,
                        });
                        const cmtSubscription = this.fbs.getComments(50, filters).subscribe(({ data: comments, lastDocument }) => {
                            const allComments = comments;
                            const selectedComment = allComments.find(cmd => cmd.id === entity.id);
                            if (selectedComment) {
                                this.viewCommentDetails(selectedComment);
                            }
                            cmtSubscription.unsubscribe();
                        });
                    }
                }

                break;

            case 'leave':
                this.viewLeaveDetails(this.cc.idToLeaveMap[entity.id]);
                break;

            default:
                break
        }
    }

    viewReleaseDetails(release: JsRelease) {
        const releaseDialog = this.dialog.open(ReleaseFormComponent, {
            width: '1000px',
            height: '90vw',
            maxHeight: '90vh',
            data: {
                mode: 'edit',
                releaseValue: release,
            },
        });
        return releaseDialog.afterClosed();
    }

    viewTaskDetails(task: JsTask) {
        const taskDialog = this.dialog.open(TaskDetailsModalComponent, {
            width: '800px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: task,
            autoFocus: false
        });
        return taskDialog.afterClosed();
    }

    viewSpecDetails(spec: JsWidget) {
        this.dManager.openDialog(WidgetDetailEditComponent, {
            data: {
                widgetId: spec.id,
            },
            panelClass: 'widget-detail-edit-bottom-sheet',
        });
    }

    viewTodoDetails(todo: JsTodo) {
        const todoDialog = this.dialog.open(TodoFormComponent, {
            width: '800px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: {
                mode: 'edit',
                todoValue: todo,
            },
            autoFocus: false
        });
        return todoDialog.afterClosed();
    }

    viewViewDetails(view: JsView) {
        const viewDialog = this.dialog.open(ViewFormComponent, {
            width: '500px !important',
            height: '800px',
            maxWidth: '500px !important',
            maxHeight: '80vh',
            data: view || null,
        });
        return viewDialog.afterClosed();
    }

    viewTranslationDetails(translation: JsTranslation) {
        const translationDialog = this.dialog.open(TranslationDetailsModalComponent, {
            width: '800px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: {
                translationToEdit: translation
            },
            autoFocus: false
        });
        return translationDialog.afterClosed();
    }

    viewEventDetails(event: JsEvent) {
        const eventDialog = this.dialog.open(EventFormComponent, {
            width: '800px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: {
                mode: 'edit',
                eventValue: event,
            },
            autoFocus: false
        });
        return eventDialog.afterClosed();
    }

    viewLogDetails(log: JsLog) {
        const logDialog = this.dialog.open(LogFormComponent, {
            width: '800px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: {
                mode: 'edit',
                logValue: log,
            },
            autoFocus: false
        });
        return logDialog.afterClosed();
    }

    viewParamDetails(param: JsParam) {
        const paramDialog = this.dialog.open(ParamFormComponent, {
            width: '800px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: {
                mode: 'edit',
                paramValue: param,
                type: param.type
            },
            autoFocus: false
        });
        return paramDialog.afterClosed();
    }

    viewDailyDetails(daily: JsDaily) {
        const dailyDialog = this.dialog.open(DailyPlanDetailComponent, {
            width: '800px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: {
                from: 'model',
                selectedDaily: daily,
                mode: 'edit'
            },
            autoFocus: false
        });
        return dailyDialog.afterClosed();
    }

    viewCommentDetails(comment: JsComment) {
        const commentDialog = this.dialog.open(CommentFormComponent, {
            width: '500px !important',
            height: '800px',
            maxWidth: '500px !important',
            maxHeight: '80vh',
            data: {
                mode: 'edit',
                commentValue: comment,
                entityType: comment.entity,
                entity: this.getEntityData(comment.entity, comment.entityId)
            },
        });
        return commentDialog.afterClosed();
    }

    viewLeaveDetails(leave: JsLeave) {
        const leaveDialog = this.dialog.open(LeaveFormComponent, {
            width: '500px !important',
            height: '800px',
            maxWidth: '500px !important',
            maxHeight: '80vh',
            data: {
                mode: 'edit',
                leaveValue: leave
            },
        });
        return leaveDialog.afterClosed();
    }

    getEntityData(entityType: entity, entityId: string): JsEntity | null {
        switch (entityType) {
            case 'release':
                return this.cc.idToReleaseMap[entityId];
            case 'task':
                return this.cc.idToTaskMap[entityId];
            case 'widget':
                return this.cc.idToSpecMap[entityId];
            case 'todo':
                return this.cc.idToTodoMap[entityId];
            case 'view':
                return this.cc.idToViewMap[entityId];
            case 'translation':
                return this.cc.idToTranslationMap[entityId];
            case 'event':
                return this.cc.idToEventMap[entityId];
            case 'log':
                return this.cc.idToLogMap[entityId];
            case 'param':
                return this.cc.idToParamMap[entityId];
            case 'daily':
                return this.cc.idToDailyMap[entityId];
            default:
                return null
        }
    }

}
