import { Component, AfterViewInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Canvg } from 'canvg';
import pixelmatch from 'pixelmatch';

@Component({
  selector: 'app-svg-diff',
  templateUrl: './svg-diff.component.html',
  styleUrls: ['./svg-diff.component.scss']
})
export class SvgDiffComponent implements AfterViewInit {
  @ViewChild('canvas') canvasRef!: ElementRef<HTMLCanvasElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      oldSvgUrl: string;
      newSvgUrl: string;
    }
  ) {}

  ngAfterViewInit(): void {
    this.compareSvgs();
  }

  async compareSvgs(): Promise<void> {
    const oldSvgCanvas = document.createElement('canvas');
    const newSvgCanvas = document.createElement('canvas');

    await Promise.all([this.renderSvgToCanvas(this.data.oldSvgUrl, oldSvgCanvas), this.renderSvgToCanvas(this.data.newSvgUrl, newSvgCanvas)]);

    const oldCtx = oldSvgCanvas.getContext('2d');
    const newCtx = newSvgCanvas.getContext('2d');

    const width = Math.max(oldSvgCanvas.width, newSvgCanvas.width);
    const height = Math.max(oldSvgCanvas.height, newSvgCanvas.height);

    console.log(oldSvgCanvas.width, oldSvgCanvas.height);
    console.log(newSvgCanvas.width, newSvgCanvas.height);

    const diffCanvas = this.canvasRef.nativeElement;
    diffCanvas.width = width;
    diffCanvas.height = height;
    const diffCtx = diffCanvas.getContext('2d');

    const oldImageData = oldCtx!.getImageData(0, 0, width, height);
    const newImageData = newCtx!.getImageData(0, 0, width, height);
    const diffImageData = diffCtx!.createImageData(width, height);

    pixelmatch(oldImageData.data, newImageData.data, diffImageData.data, width, height, {
      threshold: 0.1,
      diffColor: [255, 0, 0],
      diffColorAlt: [0, 0, 255]
    });

    diffCtx!.putImageData(diffImageData, 0, 0);
  }

  async renderSvgToCanvas(url: string, canvas: HTMLCanvasElement): Promise<void> {
    const ctx = canvas.getContext('2d')!;
    const v = await Canvg.from(ctx, url);
    await v.render();
  }
}
