import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-json-view-modal',
  standalone: true,
  imports: [
    JsonPipe
  ],
  templateUrl: './json-view-modal.component.html',
  styleUrl: './json-view-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class JsonViewModalComponent {

  jsonData : Object;
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, json: Object },
    public dialogRef: MatDialogRef<JsonViewModalComponent>
  ) {
    this.title = data.title;
    this.jsonData = data.json;
    
  }

}
