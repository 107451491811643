<main class="param-group p-3 ri-w-1000">
    <h5 class="fw-semibold">Select Analytics Param Groups <span class="badge rounded-pill text-bg-dark">{{updatedParamGroupIds.length}}</span></h5>
    <div class="ri-filter-row px-0 overflow-hidden pb-2">
      <mat-form-field [class]="{ edited: paramGroupSearch.value }" floatLabel="always">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="onFilterChange()" placeholder="Param group name, param name, description etc...'" [formControl]="paramGroupSearch" name="riParamGroupSearch" />
        <button *ngIf="paramGroupSearch.value" matSuffix mat-icon-button aria-label="Clear" (click)="paramGroupSearch.setValue(null);onFilterChange()">
            <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button class="btn btn-sm btn-primary me-0" (click)="openParamGroup($event, 'new')">Add</button>
    </div>
  
    <div class="ri-dialog-content">
      <table class="table pg-list">
        <thead class="sticky-top">
          <tr>
            <td class="fw-bold ps-3" width="5%">#</td>
            <td class="fw-bold" width="35%">Param Group Name</td>
            <td class="fw-bold" width="30%">Params</td>
            <td class="fw-bold" width="15%">Uid</td>
            <td class="fw-bold" width="15%">Last Updated At</td>
          </tr>
        </thead>
        <tbody class="ri-min-h-300">
          <tr class="ri-pointer param-block" *ngFor="let group of paramGroups()" [ngClass]="{'selected': updatedParamGroupIds.includes(group.id), 'ri-disabled' : checkPgDisabled(group)}">
            <td valign="middle">
              <mat-checkbox class="me-2" (change)="toggleParamGroup($event, group)" color="primary" [checked]="updatedParamGroupIds.includes(group.id)" [disabled]="checkPgDisabled(group)"></mat-checkbox>
            </td>
            <td valign="middle" (click)="openParamGroup($event, 'edit', group)">{{ group.name }}</td>
            <td valign="middle" (click)="openParamGroup($event, 'edit', group)">
              <div class="d-flex flex-wrap">
                <ng-container *ngFor="let param of group.params">
                  <div class="param d-block mb-1 mx-1">
                    <span class="key py-0 px-2 d-inline-block text-bg-secondary ri-fs-10px ri-lh-24px">{{param.key}}</span>
                    <span class="type py-0 px-2 d-inline-block ri-fs-10px ri-lh-24px">{{param.type}}</span>
                  </div>
                </ng-container>
              </div>
            </td>
            <td valign="middle" (click)="openParamGroup($event, 'edit', group)">{{ fbs.getDisplayName(group.uid ||'') }}</td>
            <td valign="middle" (click)="openParamGroup($event, 'edit', group)">{{ group.updatedAt | dfnsFormatDistanceToNowStrict }} ago</td>
          </tr>
        </tbody>
      </table>
    </div>
  
    <div class="ri-dialog-actions d-flex justify-content-end pt-3 text-end" *ngIf="paramGroups().length">
      <button class="btn btn-sm btn-outline-secondary" (click)="reset()" [disabled]="!hasChanges()">Reset</button>
      <button class="btn btn-sm btn-primary ms-2" (click)="updateSelectedParam()" [disabled]="!hasChanges()">Update</button>
    </div>
  
  </main>