import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsWidget, status } from '../_interfaces/Widget';
import { CacheService } from '../_services/cache.service';
import * as _ from 'lodash';
import { SvgViewerModalService } from '../_services/svg-viewer-modal.service';
import { faFigma } from '@fortawesome/free-brands-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { UserSessionStorageService } from '../_services/user-session-storage.service';
import { Router } from '@angular/router';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { WidgetDetailEditComponent } from '../widget-detail-edit/widget-detail-edit.component';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { taskStatusList } from '../shared/status';

@Component({
  selector: 'app-list-specs-of-view',
  templateUrl: './list-specs-of-view.component.html',
  styleUrls: ['./list-specs-of-view.component.scss'],
})
export class ListSpecsOfViewComponent implements OnInit {
  widgets: JsWidget[] = [];
  allWidgets: JsWidget[] = [];
  faFigma = faFigma;
  faImage = faImage;
  linkIcon = faEdit;
  typeClassMap = {
    state: 'text-bg-warning',
    action: 'text-bg-review',
    rule: 'text-bg-info',
    bug: 'text-bg-danger',
    unknown: 'text-bg-secondary',
    other: 'text-bg-secondary',
  };
  statusClassMap = {
    Backlog: 'text-bg-info',
    Design: 'text-bg-secondary',
    Review: 'text-bg-review',
    Correction: 'text-bg-secondary',
    Develop: 'text-bg-primary',
    Test: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Approve: 'text-bg-success',
  };
  statusFilter = {
    options: ['All', ...taskStatusList],
    selected: 'All'
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { viewId: string },
    private cc: CacheService,
    private SvgViewerModalService: SvgViewerModalService,
    private uss: UserSessionStorageService,
    private router: Router,
    private _dialog: DialogManagerService
  ) {}

  ngOnInit(): void {
    // Get the active widgets for the viewId
    let widgets = this.cc.allActiveWidgets.filter(
      (widget) => widget.viewId === this.data.viewId
    );
    // Sort by type in order. Use lodash.
    widgets = _.sortBy(widgets, ['type']);
    // MOve all the 'state' widgets to the top of the list
    this.widgets = _.sortBy(widgets, (widget) =>
      widget.type === 'state' ? 0 : 1
    );
    this.allWidgets = _.cloneDeep(this.widgets);
  }

  onStatusChange() {
    const allWidgets = _.cloneDeep(this.allWidgets);
    if(this.statusFilter.selected == 'All') {
      this.widgets = allWidgets;
    } else {
      this.widgets = allWidgets.filter(widget => widget.status === this.statusFilter.selected);
    }
  }

  previewFigma(widget: JsWidget) {
    if (!widget?.stateFigmaPreviewUrl) return;
    const isMinimal = widget.path.startsWith('taskDiagram');
    this.SvgViewerModalService.show(
      widget!,
      isMinimal,
      this.widgets.filter((w) => w.type === 'state' && w.stateFigmaPreviewUrl)
    ).subscribe(async (res: any) => {
      if (res && res.action === 'delete') {
        alert('Delete Preview not supported from this view!');
      }
    });
  }

  openWidget(widget: JsWidget, e: any) {
    this._dialog.openDialog(WidgetDetailEditComponent, {
      panelClass: 'widget-detail-edit-bottom-sheet',
      disableClose: true,
      data: {
        widgetId: widget.id,
      },
    });
    e.stopPropagation();
  }

  openFigma(url: string) {
    if (!url) return;
    window.open(url, '_blank');
  }
}
