import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CacheService } from './cache.service';
import { FirebaseService } from './firebase.service';
import { AppThemeConfig, Config } from '../_interfaces/Config';

type Variable = {
  name: string;
  type: string;
  isAlias: boolean;
  value: string;
};

type Mode = {
  name: string;
  variables: Variable[];
};

type Collection = {
  name: string;
  modes: Mode[];
};

type Data = {
  version: string;
  metadata: object;
  collections: Collection[];
};

@Injectable({
  providedIn: 'root',
})
export class SvgService {
  themeInfo: AppThemeConfig = {} as any; // from Firestore
  availableThemes: string[] = []; // Green, Blue etc... from Firestore
  availableModes = ['Light', 'Dark'];
  availableThemeModes: string[] = []; // Green Light, Green Dark etc... from Firestore
  defaultThemeMode: string = ''; // Green Light... from Firestore
  defaultThemeColors: { [key: string]: string } = {}; // { 'Color 1': '#F2F2F2', 'Color 2': '#A1A1A1', ... } from Firestore
  // availableColorModeNames: string[] = []; // Blue Light, Green Dark etc... from Firestore
  // defaultColorModeName: string = ''; // Green Dark... from Firestore
  // availableColorModes: Data | null = null; // { version: '1.0.0', metadata: {}, collections: [ { name: 'Color Modes', modes: [ { name: 'Blue Light', variables: [ { name: 'Primary', type: 'color', isAlias: false, value: '#F2F2F2' }, { name: 'Secondary', type: 'color', isAlias: false, value: '#A1A1A1' }, ... ] }, { name: 'Green Dark', variables: [ { name: 'Primary', type: 'color', isAlias: false, value: '#F2F2F2' }, { name: 'Secondary', type: 'color', isAlias: false, value: '#A1A1A1' }, ... ] }, ... ] } ] }
  originalColorMap: { [key: string]: string } = {}; // { '#F2F2F2': '#F2F2F2', '#A1A1A1': '#A1A1A1', ... }

  constructor(private fb: FirebaseService) {
    this.fb.getConfig().subscribe((config: Config[]) => {
      // this.defaultColorModeName = config[0].defaultColorMode;
      // this.availableColorModes = config[0].colorModes;
      // this.firestoreColorModes = config[0].colorModes;
      this.themeInfo = config[0].themeInfo;
      this.availableThemes = Object.keys(this.themeInfo.themes);
      this.defaultThemeMode = `${this.themeInfo.defaultFigmaTheme} ${this.themeInfo.defaultFigmaMode}`;
      const availableThemeModes = _.flatMap(this.availableThemes, (theme) => {
        return this.availableModes.map((mode) => `${theme} ${mode}`);
      });
      // Add Green Dark, Green Light, Blue Dark, Blue Light, etc... in order
      this.availableThemeModes = _.sortBy(availableThemeModes, (themeMode) => {
        switch (themeMode) {
          case 'Green Dark':
            return 'AA';
            break;
          case 'Green Light':
            return 'AB';
            break;
          case 'Blue Dark':
            return 'BA';
            break;
          case 'Blue Light':
            return 'BB';
            break;
          default:
            return themeMode;
            break;
        }
      });
      this.defaultThemeColors = this.getThemeColorsAsMap(
        this.themeInfo.defaultFigmaTheme,
        this.themeInfo.defaultFigmaMode
      );
      this.originalColorMap = this.getColorMapByThemeName(
        this.themeInfo.defaultFigmaTheme,
        this.themeInfo.defaultFigmaMode
      );
    });
  }

  getThemeColorsAsMapFromThemeMode(themeMode: string): {
    [key: string]: string;
  } {
    const [themeName, modeName] = themeMode.split(' ');
    return this.getThemeColorsAsMap(themeName, modeName as 'Light' | 'Dark');
  }

  getThemeColorsAsMap(
    themeName: string,
    modeName: 'Light' | 'Dark'
  ): { [key: string]: string } {
    const colors = this.themeInfo.themes[themeName][modeName];
    if (colors) {
      return colors;
    }
    return {};
  }

  getThemeColorsAsArrayFromThemeMode(
    themeMode: string
  ): { name: string; value: string }[] {
    const [themeName, modeName] = themeMode.split(' ');
    return this.getThemeColorsAsArray(themeName, modeName as 'Light' | 'Dark');
  }

  getThemeColorsAsArray(
    themeName: string,
    modeName: 'Light' | 'Dark'
  ): { name: string; value: string }[] {
    const colors = this.themeInfo.themes[themeName][modeName];
    if (colors) {
      return _.map(colors, (value, name) => {
        return {
          name,
          value,
        };
      });
    }
    return [];
  }

  getColorMapByThemeMode(themeMode: string): { [key: string]: string } {
    const [themeName, modeName] = themeMode.split(' ');
    return this.getColorMapByThemeName(themeName, modeName as 'Light' | 'Dark');
  }

  getColorMapByThemeName(
    themeName: string,
    modeName: 'Light' | 'Dark'
  ): { [key: string]: string } {
    const defaultThemeColors =
      this.themeInfo.themes[this.themeInfo.defaultFigmaTheme][
        this.themeInfo.defaultFigmaMode
      ];
    const currentThemeColors = this.themeInfo.themes[themeName][modeName];
    const result: Record<string, string> = {};
    _.forEach(defaultThemeColors, (value, key) => {
      // @ts-ignore
      result[value] = currentThemeColors[key];
    });

    // If the default color is #FFFFFF, then use 'white' as an a backup key
    if (result['#FFFFFF']) {
      result['white'] = result['#FFFFFF'];
    } else if (result['#000000']) {
      result['black'] = result['#000000'];
    }
    return result;
  }

  getExtraColors(colors: string[]): { name: string; value: string }[] {
    const defaultThemeColorsAsArray = this.getThemeColorsAsArrayFromThemeMode(
      this.defaultThemeMode
    );
    // Return colors that are not in the default theme colors
    const extraColors = [] as { name: string; value: string }[];
    colors.forEach((color) => {
      const colorName = defaultThemeColorsAsArray.filter(
        (c) => c.value === color
      )[0]?.name;
      if (!colorName) {
        extraColors.push({
          name: color,
          value: color,
        });
      }
    });
    return extraColors;
  }

  async getSvgXmlFromUrl(url: string): Promise<string> {
    return fetch(url)
      .then((response) => response.text())
      .then((svgXml) => {
        return svgXml;
      })
      .catch((error) => {
        console.error('Error fetching SVG:', error);
        return '';
      });
  }
}
