import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SpecStatusCounts, TaskStatusCounts } from '../../../_interfaces/Other';

@Component({
  selector: 'app-status-counts',
  templateUrl: './status-counts.component.html',
  styleUrls: ['./status-counts.component.scss']
})
export class StatusCountsComponent {
  @Input() specStatusCounts: SpecStatusCounts | null = null;
  @Input() taskStatusCounts: TaskStatusCounts | null = null;

  constructor() {}
}
