<div class="text-bg-danger d-flex title-wrapper justify-content-between sticky-top">
  <div>
    <span *ngIf="formattedNotifications.length" class="badge rounded-pill text-bg-light me-2 clear-all"
      matTooltip="Clear All" (click)="clearAllNotifications()">Clear
      All</span>
  </div>
  <div class="title">Notifications
    <span class="badge badge-pill text-bg-light">{{notifications.length}}</span>
  </div>
  <!-- close icon -->
  <div class="close-icon">
    <fa-icon class="icon" [icon]="faTimes" (click)="close()"></fa-icon>
  </div>
</div>
<div class="w-100 h-100 d-flex justify-content-center align-items-center text-muted p-5"
  *ngIf="formattedNotifications.length === 0">
  <div class="text-center fs-6">
    <p>Phew!! All caught up... 😇</p>
  </div>
</div>
<div *ngIf="formattedNotifications.length" class="content-wrapper">
  <div class="m-2 block-wrapper" *ngFor="let item of formattedNotifications; trackBy:trackBy" (click)="onClick(item)"
    [ngClass]="{'selected': selectedNotificationId === item.id}">
    <div class="d-flex justify-content-between p-2 block-title">
      <div class="">{{item.type}}</div>
      <div class="">{{item.title}}</div>
      <div>{{item.who}}
        <fa-icon class="icon mx-2" [icon]="faClock"></fa-icon>
        <span>{{item.when | dfnsFormatDistanceToNowStrict}}</span>
        <span class="badge rounded-pill text-bg-light ms-2" matTooltip="Clear"
          (click)="clearNotification($event, item.id)">Clear</span>
      </div>
    </div>
    <div>
      <div *ngFor="let action of item.actions" class="d-flex justify-content-between p-2">
        <div class="content-title text-start fw-bold">{{action.title}}</div>
        <div class="content-left text-start" [innerText]="action.before"></div>
        <div *ngIf="action.after" class="content-right text-start" [innerText]="action.after"></div>
        <div *ngIf="!action.after" class="content-right text-start text-muted">
          <fa-icon class="icon" [icon]="faTrash" matTooltip="Deleted"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>