import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { JsTranslation } from '../_interfaces/Translation';
import { FirebaseService } from '../_services/firebase.service';
import { getNewId } from '../shared/utils';
import { serverTimestamp } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CacheService } from '../_services/cache.service';
import { filter } from 'rxjs';
import * as _ from 'lodash';
import { FirebaseOptimisticService } from '../_services/firebase-optimistic.service';
import { isValid } from 'date-fns';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { SnackbarService } from '../_services/snackbar.service';
import { ConfirmService } from '../_services/confirm.service';
import { translationStatusList } from '../shared/status';
import { ActivityService } from '../_services/activity.service';
import { SharedFunctionService } from '../_services/shared-function.service';

@Component({
  selector: 'app-translation-details-modal',
  templateUrl: './translation-details-modal.component.html',
  styleUrls: ['./translation-details-modal.component.scss']
})
export class TranslationDetailsModalComponent implements OnInit {
  public initialTranslation!: JsTranslation;
  public translationToEdit!: JsTranslation;
  public mode: 'add' | 'edit' = 'add';
  public statusOptions = [...translationStatusList];
  public viewIds: string[] = [];
  public translationNames: string[] = [];
  public filteredViewIds: string[] = [];
  public filteredTranslationNames: string[] = [];
  public updationInProgress = false;
  public translationInProgess = false;
  public deletionInProgress = false;
  public errorMessage = '';
  public supportedLanguageCodes: string[] = [];
  public supportedLanguageCodesExceptEn: string[] = [];
  public editTextView = false;
  public variableView: 'add' | 'edit' | 'hidden' = 'hidden';
  public currentVariable: string = '';
  public curretVariableBackup: string = '';
  public isCurrentVariableValid = false;
  public currentVariableReplacements: { [key: string]: string } = {};
  public currentVariableReplacementsBackup: { [key: string]: string } = {};
  public initialVariableToEdit = '';
  isTranslationInvalid: boolean = false;

  constructor(
    private fbs: FirebaseService,
    public cc: CacheService,
    private fbo: FirebaseOptimisticService,
    private fns: AngularFireFunctions,
    private snackbar: SnackbarService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      translationToEdit: JsTranslation;
      viewId: string;
    },
    public dialogRef: MatDialogRef<TranslationDetailsModalComponent>,
    private confirmService: ConfirmService,
    private as: ActivityService,
    public sharedFunc: SharedFunctionService
  ) {
    let supportedLanguages = _.cloneDeep(this.cc.config.supportedLanguageCodes);
    if(!supportedLanguages.includes('test')) {
      supportedLanguages.push('test');
    }
    this.supportedLanguageCodes = supportedLanguages;
    this.supportedLanguageCodesExceptEn = this.supportedLanguageCodes.filter(code => code !== 'en');
    if (this.data?.translationToEdit) {
      this.initialTranslation = _.cloneDeep(this.data.translationToEdit);
      this.isTranslationInvalid = !!this.initialTranslation.viewId && !this.cc.isValidViewId(this.initialTranslation.viewId) && !this.initialTranslation.deletedAt;
      if (!this.data.translationToEdit.text['test']) {
        this.data.translationToEdit.text['test'] = this.convertEnToGibberish(this.data.translationToEdit.text['en']);
      }
      this.translationToEdit = _.cloneDeep(this.data.translationToEdit);
      this.mode = 'edit';
    } else {
      this.initNewTranslation(this.data?.viewId);
    }

    if (!this.isTranslationIdEditable()) {
      this.statusOptions = this.statusOptions.filter(status => !['Draft', 'Paused', 'Reviewed', 'Translated'].includes(status));
    }
  }

  ngOnInit(): void {
    this.translationNames = this.cc.translationPropertyUniqueValues['translationName'];
    this.viewIds = this.cc.views.map(v => v.viewId);
    this.filteredViewIds = [...this.viewIds];
    this.filteredTranslationNames = [...this.translationNames];
  }

  getActivity() {
    this.as.getActivity('translation', this.translationToEdit);
  }

  shuffleGibberish() {
    this.translationToEdit.text['test'] = this.convertEnToGibberish(this.translationToEdit.text['en']);
  }

  convertEnToGibberish(text: string | null) {
    if (text) {
      // Separate the text into an array of characters
      const characters = text.split('');

      // Filter and shuffle only the letters
      const lettersAndNumbers = characters.filter(char => /[a-zA-Z0-9]/.test(char));
      const shuffledLettersAndNumbers = this.shuffleArray(lettersAndNumbers);

      // Replace letters and numbers in the original text with shuffled ones
      let index = 0;
      const result = characters.map(char => /[a-zA-Z0-9]/.test(char) ? shuffledLettersAndNumbers[index++] : char);
      const gibberish = result.join('');

      return gibberish;
    }
    return (text)
  }

  getGibberishVariable(variable: { [key: string]: string }) {
    const startIndex = this.translationToEdit.text['en']?.indexOf(variable['en']);
    if ((startIndex || startIndex === 0) && startIndex !== -1) {
      const endIndex = startIndex + variable['en'].length;
      variable['test'] = this.translationToEdit.text['test']?.substring(startIndex, endIndex) || '';
    }
  }

  private shuffleArray(array: string[]): string[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  isTranslationIdEditable(): boolean {
    return ['Draft', 'Paused', 'Reviewed', 'Translated'].includes(this.initialTranslation.status);
  }

  isEditable(): boolean {
    return this.isTranslationIdEditable() || this.initialTranslation.status === 'Ready';
  }

  isDeletable(): boolean {
    return this.isTranslationIdEditable() || this.initialTranslation.status === 'Removed';
  }

  setFilteredValues(src: string, value: string, result: string): void {
    const filterValue = value.toLowerCase();
    // @ts-ignore
    this[result] = this[src].filter(option => option.toLowerCase().includes(filterValue));
  }

  setTranslationId(): void {
    const { viewId, translationName } = this.translationToEdit;
    // Translation Id is a combination of non-empty screen, tab, bottomSheet, overlay and translationName connected in order using an underscore
    const translationId = [viewId, translationName].filter(v => v).join('_');
    this.translationToEdit.translationId = translationId;
    this.shuffleGibberish();
    this.isValid();
  }

  hasChanges(): boolean {
    return !_.isEqual(this.translationToEdit, this.initialTranslation);
  }

  hasChangesExceptStatus(): boolean {
    const { status, ...rest } = this.translationToEdit;
    const { status: initialStatus, ...initialRest } = this.initialTranslation;
    return !_.isEqual(rest, initialRest);
  }

  hasTextChanges(): boolean {
    const { text } = this.translationToEdit;
    const { text: initialText } = this.initialTranslation;
    return !_.isEqual(text, initialText);
  }

  isValid(): boolean {
    this.errorMessage = '';
    let result = true;
    const { viewId, translationName, status, text } = this.translationToEdit;
    const { en } = text || {};
    if (!viewId || !translationName || !en) {
      result = false;
      this.errorMessage = 'Please fill in all the required fields';
    }
    if (['Translated', 'Ready', 'Implemented', 'Failed', 'Passed', 'Deprecated', 'Removed'].includes(status) && this.hasMissingTranslations()) {
      result = false;
      this.errorMessage = 'Please fill in all the translations before changing the status to Translated or above';
    }
    if (!this.viewIds.includes(viewId)) {
      // Check if its a valid viewId
      result = false;
      this.errorMessage = 'Please select a valid viewId';
    }
    // Check if its a unique translationId using array some
    const translationId = [viewId, translationName].filter(v => v).join('_');
    if (this.mode === 'add') {
      if (this.cc.allTranslations.some(t => t.translationId === translationId)) {
        result = false;
        this.errorMessage = 'This combination already exists';
      }
    } else {
      if (this.cc.allTranslations.some(t => t.translationId === translationId && t.id !== this.translationToEdit.id)) {
        result = false;
        this.errorMessage = 'This combination already exists';
      }
    }

    return result;
  }

  hasMissingTranslations(): boolean {
    let result = false;
    this.supportedLanguageCodes.forEach((code: string) => {
      if (!this.translationToEdit.text?.[code]) {
        result = true;
      }
    });
    return result;
  }

  shouldReTranslate(): boolean {
    let result = false;
    this.supportedLanguageCodes.forEach((code: string) => {
      if (this.translationToEdit.text?.[code] && this.translationToEdit.text?.[code] !== this.initialTranslation.text?.[code]) {
        result = true;
      }
    });
    // Check if any of the translations are empty
    if (this.hasMissingTranslations()) {
      result = true;
    }
    // If 'en' is empty, then no need to re-translate
    if (!this.translationToEdit.text?.['en']) {
      result = false;
    }

    return result;
  }

  onStatusChange(status: string): void { }

  getAndSetTranslations() {
    const translationCallableFn = this.fns.httpsCallable('translate');
    this.translationInProgess = true;
    const { text } = this.translationToEdit;
    const { en } = text || {};
    const translateTo = this.supportedLanguageCodes.filter(code => code !== 'en' && code !== 'test');
    if (en) {
      const data$ = translationCallableFn({
        en,
        translateTo
      });
      data$.subscribe(data => {
        let translatedData = data;
        translatedData['test'] = this.translationToEdit.text['test'];
        this.translationToEdit.text = translatedData;
        this.translationInProgess = false;
      });
    }
  }

  resetHandler(): void {
    this.translationToEdit = _.cloneDeep(this.initialTranslation);
  }

  async updateHandler() {
    this.updationInProgress = true;
    const updatedTranslation = _.cloneDeep(this.translationToEdit);
    updatedTranslation.updatedAt = new Date();
    updatedTranslation.cloudUpdatedAt = serverTimestamp();
    updatedTranslation.uid = this.fbs.getCurrentUserId();
    if (this.mode === 'add') {
      updatedTranslation.createdAt = new Date();
      await this.fbo.createItemsOptimistic<JsTranslation>([updatedTranslation], 'translations');
      this.snackbar.show('Translation added successfully');
    } else {
      await this.fbo.updateItemsOptimistic<JsTranslation>([updatedTranslation], 'translations');
      this.snackbar.show('Translation updated successfully');
    }
    this.updationInProgress = false;
    if (this.mode === 'add') {
      this.initNewTranslation();
    } else {
      this.dialogRef.close();
    }
  }

  async deleteHandler() {
    const confirmed = await this.confirmService.confirm(
      'Delete Translation',
      'Are you sure you want to delete this translation? This action cannot be undone.'
    );
    if (!confirmed) {
      return;
    }
    this.deletionInProgress = true;
    const deletedTranslation = _.cloneDeep(this.translationToEdit);
    deletedTranslation.deletedAt = new Date();
    deletedTranslation.cloudUpdatedAt = serverTimestamp();
    deletedTranslation.uid = this.fbs.getCurrentUserId();
    await this.fbo.updateItemsOptimistic<JsTranslation>([deletedTranslation], 'translations');
    this.deletionInProgress = false;
    this.dialogRef.close();
  }

  initNewTranslation(viewId = '') {
    this.translationToEdit = {
      viewId: viewId || '',
      translationName: '',
      translationId: '',
      text: {
        en: '',
        test: ''
      },
      variables: {},
      status: 'Draft',
      createdAt: new Date(),
      createdBy: this.fbs.getCurrentUserId(),
      id: getNewId(),
      uid: this.fbs.getCurrentUserId(),
      updatedAt: new Date(),
      cloudUpdatedAt: serverTimestamp(),
      deletedAt: null,
      releaseAdded: 1,
      releaseRemoved: null,
      note: '',
      designText: ''
    };
    this.initialTranslation = _.cloneDeep(this.translationToEdit);
    this.mode = 'add';
    this.editTextView = true;
  }

  onCopy() {
    this.snackbar.show();
  }

  addVariable() {
    this.editVariable();
  }

  async deleteVariable(variableName: string) {
    const confirmed = await this.confirmService.confirm('Delete Variable', 'Are you sure you want to delete this variable? This action cannot be undone.');
    if (!confirmed) {
      return;
    }
    const variables = _.cloneDeep(this.translationToEdit.variables);
    delete variables[variableName];
    this.translationToEdit.variables = variables;
    this.currentVariable = '';
    this.curretVariableBackup = '';
    this.currentVariableReplacements = {};
    this.currentVariableReplacementsBackup = {};
    this.variableView = 'hidden';
  }

  editVariable(variableName?: string) {
    if (variableName) {
      // Check if the variable exists
      const variable = this.translationToEdit.variables?.[variableName];
      if (variable) {
        this.currentVariable = variableName;
        this.curretVariableBackup = variableName;
        this.currentVariableReplacements = _.cloneDeep(variable) as {
          [key: string]: string;
        };
        this.currentVariableReplacementsBackup = _.cloneDeep(variable) as {
          [key: string]: string;
        };
        this.variableView = 'edit';
      } else {
        this.currentVariable = '';
        this.curretVariableBackup = '';
        this.currentVariableReplacements = {};
        this.currentVariableReplacementsBackup = {};
        this.variableView = 'add';
      }
    } else {
      this.currentVariable = '';
      this.curretVariableBackup = '';
      this.currentVariableReplacements = {};
      this.currentVariableReplacementsBackup = {};
      this.variableView = 'add';
    }
    this.validateVariableName(this.currentVariable);
  }

  validateAndUpdateVariable() {
    console.log(this.hasAllVariablesAdded());
    if (this.isCurrentVariableValid && this.hasAllVariablesAdded()) {
      // Update the variable
      this.translationToEdit.variables = {
        ...this.translationToEdit.variables,
        [this.currentVariable]: this.currentVariableReplacements
      };
      this.variableView = 'hidden';
    }
  }

  validateVariableName(variableName: string) {
    // It should not start with a number
    // It should not contain spaces
    // It should not be empty
    // It should only allow alphanumeric characters in camelCase
    const regex = /^[a-zA-Z][a-zA-Z0-9]*$/;
    this.isCurrentVariableValid = regex.test(variableName);
  }

  hasAllVariablesAdded(): boolean {
    const hasAllVarsAdded = this.supportedLanguageCodes.every(code => {
      return this.currentVariableReplacements[code];
    });
    return hasAllVarsAdded;
  }

  getHighlightedText(langCode: string): string {
    const text = this.translationToEdit.text?.[langCode];
    if (!text) {
      return '';
    }
    // Highlight the variables in the text with a yellow background
    const variables =
      this.variableView === 'edit' || this.variableView === 'add'
        ? {
          [this.currentVariable]: this.currentVariableReplacements
        }
        : this.translationToEdit.variables;
    const variableNames = Object.keys(variables);
    const textsToReplace = variableNames.map(name => {
      return variables[name][langCode] || '';
    });
    return this.wrapSubstringsWithSpan(text, textsToReplace);
  }

  wrapSubstringsWithSpan(sentence: string, substrings: string[]): string {
    const validSubstrings = substrings.filter(sub => sub);
    validSubstrings.forEach(sub => {
      // Escape the special characters in the substring
      sub = sub.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      const regex = new RegExp(sub, 'g');
      sentence = sentence.replace(regex, `<span class="ri-highlight">${sub}</span>`);
      // Remove extra backslashes
      sentence = sentence.replace(/\\/g, '');
    });
    return sentence;
  }

  hasVariableChanges(): boolean {
    return !_.isEqual(this.currentVariableReplacements, this.currentVariableReplacementsBackup);
  }
}
