import { Component, Inject, OnInit } from '@angular/core';
import { CacheService } from '../_services/cache.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JsTask } from '../_interfaces/Task';
import { translate } from '../../../functions/src/functions/translate';
import { DialogManagerService } from '../_services/dialog-manager.service';

@Component({
  selector: 'app-view-blocks-of-task',
  templateUrl: './view-blocks-of-task.component.html',
  styleUrls: ['./view-blocks-of-task.component.scss'],
})
export class ViewBlocksOfTaskComponent implements OnInit {
  viewIds: string[] = [];
  hideTranslate: boolean = false;
  hideSpecType: boolean = false;
  hideSpecStatus: boolean = false;

  constructor(
    private cc: CacheService,
    @Inject(MAT_DIALOG_DATA)
    public data: { task: JsTask },
    private _dialog: DialogManagerService
  ) {}

  ngOnInit(): void {
    this.cc.fallbackTaskForBottomSheet = this.data.task;
    this.update();
  }

  update() {
    this.viewIds = [];
    // Get unique viewIds
    this.viewIds = this.cc.getUniqueViewIdsOfTask(this.data.task);
  }
}
