<main class="log-form p-3 ri-w-600" [ngClass]="{'pe-none': logInitial.deletedAt}">
    <div class="d-flex justify-content-between mb-3">
        <h2 class="fw-semibold mb-0" mat-dialog-title>Log {{ mode === 'new' ? 'Create' : 'Update' }}</h2>
        <div class="d-flex">
            <h3 class="pe-2 fw-semibold text-primary mb-0" *ngIf="mode !== 'new'">{{ logInitial.lid }}</h3>
            <button class="btn btn-comment btn-sm btn-success d-flex align-items-center h-100 me-2" matTooltip="Comment" *ngIf="this.mode === 'edit'" (click)="sharedFunc.getComments('log', logInitial)">
                <mat-icon class="pe-0">question_answer</mat-icon>
                <span class="ps-2" *ngIf="cc.idToLogMap[logInitial.id]?.latestComment">{{ cc.idToLogMap[logInitial.id].latestComment?.updatedAt | dfnsFormatDistanceToNowStrict }} ago</span>
            </button>
            <button *ngIf="mode === 'edit'" matTooltip="Get Activity" class="btn btn-info btn-sm btn-activity d-flex align-items-center" (click)="getActivity()"><mat-icon class="text-light">restore</mat-icon></button>
        </div>
    </div>
    
    <div class="ri-dialog-content">
        <form [formGroup]="logsForm" #lgForm="ngForm" *ngIf="logsForm.value.id">
            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : logsForm.value.logName !== logInitial.logName, 'ri-disabled' : isDevMode}">
                <mat-label>Log Name</mat-label>
                <input matInput type="text" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" formControlName="logName" name="riLogName" [readonly]="isDevMode" maxlength="38" appAlphabetsOnly />
            </mat-form-field>
    
            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : logsForm.value.note !== logInitial.note}">
                <mat-label>Purpose</mat-label>
                <textarea matInput formControlName="note" name="riNote" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
    
            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : logsForm.value.devNote !== logInitial.devNote}">
                <mat-label>Dev Notes</mat-label>
                <textarea matInput formControlName="devNote" name="riDevNote" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
    
            <div class="row g-1 mb-1">
                <div class="col-6">
                    <app-select [options]="logTypes" [title]="'Type'" [control]="getFc('type')" [defaultValue]="logInitial.type" [disabled]="isDevMode" (selectEvent)="changeTypeEvent()"></app-select>
                </div>
                <div class="col-6">
                    <app-select [options]="isDevMode ? devLogStatuses : logStatuses" [title]="'Status'" [control]="getFc('status')" [defaultValue]="logInitial.status"></app-select>
                </div>
            </div>
    
            <div class="d-flex justify-content-between">
                <h4 class="fw-bold pt-2 m-0">Param Groups</h4>
                <button type="button" class="btn btn-sm btn-primary m-1 me-0" (click)="showParamsList($event)" [disabled]="isDevMode">Select</button>
            </div>
            <div class="" [ngClass]="{'edited': hasParamGroupChanges()}">
                <span *ngFor="let paramGroupId of logsForm.value.paramsGroupIds" (click)="!isDevMode && showParamsList($event)" class="badge text-bg-secondary me-2 p-2 my-1 ri-pointer" [ngClass]="{'ri-disabled' : isDevMode}">
                    {{getParamGroupName(paramGroupId)}}
                </span>
            </div>
    
            <div class="d-flex justify-content-between" *ngIf="params.value.length !== 0">
                <h4 class="fw-bold py-2 m-0">Params</h4>
            </div>
            
            <div formArrayName="params" *ngIf="params.controls">
                <ng-container *ngFor="let row of params.controls; let i = index">
                    <mat-form-field class="{{getNestedParamClass(row.value.key)}}" *ngIf="row.value.paramGroupId !== defaultPgId" appearance="fill" class="w-100 mb-1" [ngClass]="{'edited' : mode === 'new' ? row.value.value : isParamEdited(row.value.paramGroupId, row.value.key, row.value.type, row.value.value), 'text-danger': !isParamValid(row.value.type, row.value.value), 'ri-disabled' : isDevMode}"
                        [formGroupName]="i">
                        <mat-label>{{row.value.key}} ({{row.value.type}})</mat-label>
                        <input matInput formControlName="value" name="riParamsValue" [readonly]="isDevMode" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" />
                        <mat-error *ngIf="true">
                            Error: The value is invalid.
                        </mat-error>
                    </mat-form-field>
                </ng-container>
                <div class="row g-1 mb-1">
                    <ng-container *ngFor="let row of params.controls; let i = index">
                        <div class="col-6" *ngIf="row.value.key === 'logId' && mode !== 'new'">
                            <mat-form-field appearance="fill" class="w-100 ri-disabled" [formGroupName]="i">
                                <mat-label>{{row.value.key}} ({{row.value.type}})</mat-label>
                                <input matInput formControlName="value" name="riParamsValue" readonly autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" />
                            </mat-form-field>
                        </div>
                        <div class="" *ngIf="row.value.key === 'logType'" [ngClass]="mode === 'new' ? 'col-12' : 'col-6'">
                            <mat-form-field appearance="fill" class="w-100 ri-disabled" [formGroupName]="i">
                                <mat-label>{{row.value.key}} ({{row.value.type}})</mat-label>
                                <input matInput formControlName="value" name="riParamsValue" readonly autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" />
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
            </div>
        </form>
    </div>
    
    <div class="ri-dialog-footer d-flex justify-content-between mt-5" *ngIf="!logInitial.deletedAt">
        <div class="d-flex">
            <button type="button" class="btn btn-sm text-bg-danger ri-fs-16px lh-sm me-2 ri-btn-delete" *ngIf="mode === 'edit'"  (click)="deleteLog()" [disabled]="isDevMode || logInitial.deletedAt" matTooltip="Delete">
                <i class="bi bi-trash"></i>
            </button>
            <button type="button" class="btn btn-sm text-bg-review" (click)="sharedFunc.viewJson('Log Params', sharedFunc.convertToNestedObject(sharedFunc.getObjectStructure(logsForm.value.params)))" [disabled]="!validateAllParams()">Log JSON</button>
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-outline-secondary"
                (click)="cancelChanges()" [disabled]="!hasChanges()">Reset</button>
            <button type="submit" class="btn btn-sm btn-primary ms-2" (click)="saveChanges()"
                [disabled]="!hasChanges() || logsForm.invalid">Save</button>
        </div>
    </div>
</main>