<main class="activity-tab" [ngClass]="{'ri-min-w-1000 p-3' : isDialog}">
    <h5 class="fw-semibold sticky-top" *ngIf="isDialog">Activity for <span
            [ngClass]="{'text-primary' : entityType === 'task' || entityType === 'daily'}">{{dialogTitle}}</span></h5>
    <div class="ri-filter-row ri-filter-row-background justify-content-between sticky-top">
        <div class="d-flex">
            <app-select *ngIf="!isDialog" [options]="filterOptions.entity.options" [title]="filterOptions.entity.title"
                class="me-1" [control]="filterOptions.entity.formControl"
                [defaultValue]="filterOptions.entity.defaultValue" [label]="'label'" [value]="'value'" [allOption]="true" [hiddenOptions]="fbs.getCurrentUser()?.roles.includes('AD') ? [] : ['leave']"></app-select>

            <app-select [options]="fbs.users" [title]="filterOptions.uid.title" class="me-1"
                [control]="filterOptions.uid.formControl" [defaultValue]="filterOptions.uid.defaultValue"
                [label]="'label'" [value]="'value'" [allOption]="true"></app-select>
        </div>
        <div class="d-flex">
            <app-select [options]="filterOptions.columns.options" [title]="filterOptions.columns.title" class="me-1"
                [control]="filterOptions.columns.formControl" [defaultValue]="filterOptions.columns.defaultValue"
                [multiple]="true" (selectEvent)="colomChange()"></app-select>
            <button class="btn btn-warning float-end btn-sm mx-1" (click)="resetFilter()">Reset</button>
            <button class="btn btn-primary float-end btn-sm" (click)="getActivities()">Apply</button>
        </div>
    </div>

    <div class="position-relative table-block" [ngClass]="{ 'table-dialog' : isDialog }">
        <table class="table w-100 position-relative mb-0" *ngIf="activityList.length !== 0">
            <thead class="header-row">
                <tr>
                    <th class="header-cell sticky-top ri-pointer {{column}} text-nowrap" #theadCol scope="col"
                        [ngClass]="{'sorted' : sortKey === column, 'text-end' : column === 'Changes' }"
                        *ngFor="let column of displayedColumns" sortOrder="none" (click)="sortData(theadCol, column)">{{
                        column === 'name' && entityType ? mapName[entityType] || 'Name' : column }}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let row of activityList; let i = index">
                    <tr class="ri-pointer activity-row" (click)="viewJsonDiff(row, 'JSONDIFFLI', row.entity)">
                        <ng-container *ngFor="let col of displayedColumns">
                            <ng-container [ngSwitch]="col">
                                <ng-container *ngSwitchCase="'Comments Entity'">
                                    <td class="fw-bold" (click)="viewDetails($event,row['entity'],row.after)">{{row?.after?.entity || '-'}}</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'name'">
                                    <td class="{{col}} td" [innerHtml]="getEntityName(row['entity'],row['entityId'],row)" (click)="viewDetails($event,row['entity'],row.after)"></td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'action'">
                                    <td class="{{col}} td" valign="middle" [ngClass]="{
                                        'text-bg-success': row[col] === 'create',
                                        'text-bg-warning': row[col] === 'update',
                                        'text-bg-danger': row[col] === 'delete'}">
                                        {{row[col] ?? '-'}}
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'JSON diff'">
                                    <td class="{{col}} td" valign="middle">
                                        <button class="btn text-bg-review btn-sm ri-fs-10px"
                                            (click)="viewJsonDiff(row,'JSONDIFF', row.entity, $event)">JSON</button>
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'uid'">
                                    <td class="{{col}} td text-nowrap" valign="middle">{{ fbs.getDisplayName(row[col])
                                        }}</td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'createdAt'">
                                    <td class="{{col}} td text-nowrap" valign="middle">{{ row[col] |
                                        dfnsFormatDistanceToNowStrict }}
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Changes'">
                                    <td class="count td text-end" valign="middle">
                                        <span class="badge rounded-pill text-bg-dark ri-fs-10px">{{ row.diff?.length
                                            }}</span>
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'hasStatusChanged'">
                                    <td>
                                        <mat-icon class="text-success" *ngIf="row[col] === true">check</mat-icon>
                                        <span *ngIf="row[col] === false">-</span>
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <td class="{{col}}" valign="middle"> <span>{{ row[col] ?? '-' }}</span></td>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <div class="py-5 text-center" *ngIf="activityList.length >= 50 && !allDataLoaded">
            <a class="text-muted text-center text-decoration-none ri-fs-14px ri-pointer" (click)="getActivities(true)">More</a>
        </div>
        <div class="my-5 py-5" *ngIf="activityList.length === 0">
            <p class="text-center">Click apply to get Activity for the selected entity</p>
        </div>
    </div>

    <div class="ri-dialog-actions pt-3 text-end" *ngIf="isDialog">
        <button class="btn btn-primary btn-sm px-5" (click)="dialogRef.close()">Close</button>
    </div>
</main>