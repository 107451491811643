import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ImageViewerComponent,
  ImageViewerModel,
} from '../shared/components/image-viewer/image-viewer.component';
import { AttachmentType } from '../_interfaces/Other';

@Injectable({
  providedIn: 'root',
})
export class ImageViewerService {
  private confirmDialog: any;
  constructor(public dialog: MatDialog) {}

  show(
    selectedUrl: string,
    type: AttachmentType,
    hideConfirm = false,
    isDeleted = false,
    isView = false
  ) {
    if (selectedUrl) {
      this.confirmDialog = this.dialog.open(ImageViewerComponent, {
        maxWidth: '90vw',
        maxHeight: '90vh',
        data: new ImageViewerModel(selectedUrl, type, hideConfirm, isDeleted, isView),
      });
    }
    return this.confirmDialog.afterClosed();
  }
}
