<main class="image-viewer" [class.fit]="!showFullScale">
  <button mat-icon-button class="delete-button" (click)="delete()" matTooltip="Delete" *ngIf="!isView && !isDeleted">
    <mat-icon>delete</mat-icon>
  </button>
  <button mat-icon-button class="restore-button" (click)="restore()" matTooltip="Restore" *ngIf="!isView && isDeleted">
    <mat-icon>restore</mat-icon>
  </button>
  <button mat-icon-button class="zoom-button" (click)="showFullScale = !showFullScale" matTooltip="Zoom">
    <mat-icon>{{showFullScale ? 'zoom_out' : 'zoom_in'}}</mat-icon>
  </button>
  <mat-dialog-content>
    <img [src]="selectedImageUrl" *ngIf="['png', 'jpeg', 'jpg'].includes(type)">
    <video controls autoplay *ngIf="type === 'mp4'">
      <source [src]="selectedImageUrl" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </mat-dialog-content>
  <!-- <div mat-dialog-content>
    <img [src]="selectedImageUrl" *ngIf="['png', 'jpeg', 'jpg'].includes(type)">
    <video controls autoplay *ngIf="type === 'mp4'">
      <source [src]="selectedImageUrl" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div> -->
</main>