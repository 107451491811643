import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { Subject, takeUntil } from 'rxjs';
import { AttachmentType, DeleteAttachment } from 'src/app/_interfaces/Other';
import { ImageViewerService } from 'src/app/_services/image-viewer.service';

@Component({
  selector: 'app-attachments',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIcon,
    MatTooltip,
    NgxMatFileInputModule
  ],
  templateUrl: './attachments.component.html',
  styleUrl: './attachments.component.scss'
})

export class AttachmentsComponent {

  @ViewChild('attachmentInput') attachmentInput!: ElementRef;
  @Input() multiple: boolean = true;
  @Input() attachmentsControl: FormControl = new FormControl([]);
  @Input() entityId: string = '';
  @Output() selectEvent = new EventEmitter<File[]>();
  @Input() newAttachments: WritableSignal<File[]> = signal([]);
  @Input() deletedAttachments: WritableSignal<DeleteAttachment[]> = signal([]);
  unSubscribe = new Subject<void>();
  @Input() disabled: boolean = false;

  constructor(private imageViewer: ImageViewerService, private storage: AngularFireStorage) {

  }

  fileChange(event: any) {
    const fileList: FileList | null = (event.target as HTMLInputElement).files;
    if (fileList) {
      const nextFileNumber = this.getNextFileNumber();
      const newFiles = Array.from(fileList).filter(file => file instanceof File).map((file: any, index: number) => {
        const extension = file.name.split('.').pop();
        return new File([file], `${nextFileNumber + index}.${extension}`, {
          type: file.type,
          lastModified: file.lastModified,
        });
      });
      this.newAttachments.set([...this.newAttachments(), ...newFiles]);
      // this.selectEvent.emit(this.newAttachments);
    }
  }

  showImageFromCommentInput(
    fileName: string,
    isDeleted = false
  ) {
    this.storage.ref(`comments/${this.entityId}/${fileName}`)
      .getDownloadURL().pipe(takeUntil(this.unSubscribe))
      .subscribe((url) => {
        this.imageViewer
          .show(
            url,
            fileName.split('.').pop() as AttachmentType,
            true,
            isDeleted
          )
          .subscribe((res: any) => {
            if (res && res.action === 'delete') {
              this.addDeletedAttachment({
                name: fileName,
                url: url,
              });
            }

            if (res && res.action === 'restore') {
              this.restoreDeletedAttachment({
                name: fileName,
                url: url,
              } as DeleteAttachment);
            }
          });
      });
  }

  addDeletedAttachment(attachment: DeleteAttachment) {
    const hasItem = this.deletedAttachments().some(
      (deletedAttachment) => deletedAttachment.name === attachment.name
    );
    if (hasItem) return;
    this.deletedAttachments().push(attachment);
    const updatedAttachments = this.attachmentsControl.value.filter(
      (attachmentName: string) => attachmentName !== attachment.name
    );
    this.attachmentsControl.setValue(updatedAttachments);
  }

  restoreDeletedAttachment(attachment: DeleteAttachment) {
    this.deletedAttachments.set(this.deletedAttachments().filter(
      (deletedAttachment) => deletedAttachment.name !== attachment.name
    ))
    let attachments = this.attachmentsControl.value || [];
    attachments.push(attachment.name).sort();
    this.attachmentsControl.setValue(attachments);
  }

  showLocalImage(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imageViewer.show(
        reader.result as string,
        file.name.split('.').pop() as AttachmentType,
        true
      )
        .subscribe((res: any) => {
          if (res && res.action === 'delete') {
            this.deleteLocalAttachment(file);
          }
        });
    };
  }

  deleteLocalAttachment(file: File) {
    const newAttachments = this.newAttachments().filter(
      (attachment) => attachment.name !== file.name
    );
    this.newAttachments.set(newAttachments);
  }

  getNextFileNumber() {
    if (this.newAttachments().length) {
      const currentAttachments = this.newAttachments();
      const fileNumbers = currentAttachments.map((file) => {
        return this.getFileNumberFromFileName(file.name);
      });
      const max = Math.max(...fileNumbers);
      return max + 1;
    }
    if (!this.attachmentsControl.value.length) {
      return 1;
    }
    const currentAttachments = this.attachmentsControl.value;
    const fileNumbers = currentAttachments.map((name: string) => {
      return this.getFileNumberFromFileName(name);
    });
    const max = Math.max(...fileNumbers);
    return max + 1;
  }

  getFileNumberFromFileName(fileName: string) {
    const name = fileName?.split('.').shift();
    return parseInt(name || '0', 10) || 0;
  }

  clearFiles() {
    if (this.attachmentInput) {
      this.attachmentInput!.nativeElement.value = null;
    }
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
