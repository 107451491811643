<main class="p-3 ri-w-800">
  <div class="d-flex justify-content-between p-2">
    <h3 class="fw-semibold">Edit Theme Colors</h3>
  </div>

  <!-- Big text area input -->
  <div class="mt-1">
    <mat-form-field appearance="fill" class="w-100"
      [class]="{'edited' : !isSame() && !invalidJson, 'invalid': invalidJson}">
      <mat-label>Theme JSON</mat-label>
      <textarea matInput [(ngModel)]="themeInfoJsonToEdit" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="10" cdkAutosizeMaxRows="1000"></textarea>
    </mat-form-field>
  </div>

  <div class="d-flex justify-content-between pt-2">
    <button class="btn btn-warning btn-sm" (click)="reset()">
      Reset Theme
    </button>
    <div>
      <button class="btn btn-secondary btn-sm" (click)="cancel()">
        Cancel
      </button>
      <button [disabled]="isSame()" class="btn btn-primary btn-sm ms-1" (click)="save()">
        Update Theme
      </button>
    </div>
  </div>
</main>