import { Injectable } from '@angular/core';
import { UserSessionStorage, ViewType } from '../_interfaces/Other';
import { BehaviorSubject, Observable } from 'rxjs';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class UserSessionStorageService {
  currentUserSessionStorage: UserSessionStorage;
  currentUserSessionStorageSubject$ = new BehaviorSubject<UserSessionStorage>(this.getDefaultUserSessionStorage());
  currentUserSessionStorage$: Observable<UserSessionStorage> = this.currentUserSessionStorageSubject$.asObservable();
  constructor() {
    const userSessionStorage = sessionStorage.getItem('userSessionStorage');
    if (userSessionStorage) {
      this.currentUserSessionStorage = JSON.parse(userSessionStorage);
      this.updateSubject();
    } else {
      this.currentUserSessionStorage = this.getDefaultUserSessionStorage();
      this.updateSubject();
    }
  }

  updateSubject() {
    this.currentUserSessionStorageSubject$.next(this.currentUserSessionStorage);
  }

  getDefaultUserSessionStorage() {
    return {
      // Widgets
      widgetPath: '',
      widgetSearch: '',
      widgetDetailView: 'info' as ViewType,
      widgetStatus: [],
      widgetForReleaseFilter: null,
      widgetNotForReleaseFilter: null,
      widgetInDeliverable: null,
      widgetNotInDeliverable: null,
      widgetSubStatus: [],
      widgetPreviewColors: [],
      widgetAnalyticsStatus: [],
      widgetType: [],
      widgetUid: [],
      widgetMid: '',
      widgetTask: [],
      widgetViewId: '',
      widgetAssignedTo: [],
      widgetTags: [],
      widgetViewFilter: [
        'Assigned Tasks',
        'Assigned Comments',
        'Timelog',
        'Detail View',
        'Unassigned',
        'Total',
        'Backlog',
        'Design',
        'Review',
        'Correction',
        'Develop',
        'Test',
        'Failed',
        'Approve',
        'Assigned To'
      ],
      widgetEmptyFilter: [],

      // Viewtable
      viewTableColumns: [],
      viewTableGroupByFilter: 'viewType',

      // Tasktable
      taskTableShowPlan: false,
      taskTableIsFocusMode: false,
      taskTableColumnFilters: [],
      taskTablePageSize: 200,
      taskTableForReleaseFilter: null,
      taskTableNotForReleaseFilter: null,
      taskTableAssignedToFilter: '',
      taskTableStatusFilter: 'All',
      taskTableGroupByFilter: 'assignedTo',
      taskTableProductOwnerFilter: '',
      taskTableDesignOwnerFilter: '',
      taskTableDevOwnerFilter: '',
      taskTableTestOwnerFilter: '',
      taskTableSubStatusFilter: 'All',
      taskTableTypeFilter: 'All',
      taskTablePriorityFilter: 'All',
      taskTableShowFilter: ['Active'],
      taskTableSearch: '',
      taskTableSort: 'order',
      taskTableSortDirection: 'asc',

      // Translationtable
      translationTableForReleaseFilter: null,
      translationTableNotForReleaseFilter: null,
      translationTableStatusFilter: 'All',
      translationTableGroupByFilter: 'status',
      translationTableSearch: '',
      translationTableTaskFilter: 'All',
      translationTableShowFilter: ['Valid', 'Invalid', 'Complete', 'Incomplete'], // No deleted
      translationTableColumnFilters: [],
      translationTableSort: 'translationId', // updatedAt, translationId
      translationTableSortDirection: 'asc',

      // Events
      eventTableColumns: [],
      eventTableGroupByFilter: 'status',

      // Logs
      logTableColumns: [],
      logTableGroupByFilter: 'status',

      //Diagrams
      diagramTableGroupByFilter: 'Category',

      // Activity
      activityTableColumns: [],
      widgetActivityTableColumns: [],
      commentActivityTableColumns: [],
      translationActivityTableColumns: [],
      taskActivityTableColumns: [],
      releaseActivityTableColumns: [],
      viewActivityTableColumns: [],
      dailyActivityTableColumns: [],
      eventActivityTableColumns: [],
      logActivityTableColumns: [],
      paramActivityTableColumns: [],
      todoActivityTableColumns: [],

      // Todo
      todoTableColumns: [],
      todoTableGroupByFilter: 'priority',

      //Release
      releaseTableColumns: [],

      //Trash 
      trashTableColumns: [],

      // Staff
      staffTableColumns: []
    };
  }

  getUserSessionStorageItem<K extends keyof UserSessionStorage>(key: K): UserSessionStorage[K] {
    return this.currentUserSessionStorage[key];
  }

  setUserSessionStorageItem<K extends keyof UserSessionStorage>(key: K, value: UserSessionStorage[K], notifySubject = true) {
    this.currentUserSessionStorage[key] = value;
    this.saveToSessionStorage();
    if (notifySubject) {
      this.updateSubject();
    }
  }

  getUserSessionStorage() {
    return this.currentUserSessionStorage;
  }

  setUserSessionStorage(item: UserSessionStorage, notifySubject = true) {
    this.currentUserSessionStorage = item;
    this.saveToSessionStorage();
    if (notifySubject) {
      this.updateSubject();
    }
  }

  saveToSessionStorage() {
    sessionStorage.setItem('userSessionStorage', JSON.stringify(this.currentUserSessionStorage));
  }

  resetUserSessionStorage(prefix = '', notifySubject = true) {
    // If a vlaid prefix is provided, only reset the keys that start with that prefix (e.g. 'widget')
    const defaultSessionStorage = this.getDefaultUserSessionStorage();
    const keys = Object.keys(defaultSessionStorage) as Array<keyof UserSessionStorage>;
    if (prefix) {
      keys.forEach(key => {
        if (key.startsWith(prefix)) {
          // @ts-ignore
          this.currentUserSessionStorage[key] = defaultSessionStorage[key];
        }
      });
    } else {
      this.currentUserSessionStorage = defaultSessionStorage;
    }
    this.saveToSessionStorage();
    if (notifySubject) {
      this.updateSubject();
    }
  }

  hasCustomFiltersApplied() {
    const {
      widgetSearch: search,
      widgetStatus: status,
      widgetSubStatus: subStatus,
      widgetPreviewColors: previewColors,
      widgetAnalyticsStatus: analyticsStatus,
      widgetType: type,
      widgetUid: uid,
      widgetMid: mid,
      widgetAssignedTo: assignedTo,
      widgetTags: tags
    } = this.currentUserSessionStorage;
    return (
      search.length ||
      status.length ||
      subStatus.length ||
      previewColors.length ||
      analyticsStatus.length ||
      type.length ||
      uid.length ||
      mid.length ||
      assignedTo.length ||
      tags.length ||
      false
    );
  }
}
