import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { JsWidget } from '../_interfaces/Widget';
import {
  faTimes,
  faUser,
  faClock,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { UserSessionStorageService } from '../_services/user-session-storage.service';
import { FirebaseService } from '../_services/firebase.service';
import { ChangeDetectionStrategy } from '@angular/core';
import { SvgViewerModalService } from '../_services/svg-viewer-modal.service';

@Component({
  selector: 'app-previews',
  templateUrl: './previews.component.html',
  styleUrls: ['./previews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewsComponent implements OnInit, OnChanges {
  @Input() filteredWidgets: JsWidget[] = [];
  @Input() title: string = 'State Previews';
  @Output() onClose = new EventEmitter<void>();
  @Output() onSelect = new EventEmitter<string>();
  faTimes = faTimes;
  faUser = faUser;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faClock = faClock;
  filteredStateWidgets: JsWidget[] = [];
  filteredValidStateWidgets: JsWidget[] = [];
  selectedWidgetPath: string | '' = '';
  public mapClass = {
    Backlog: 'bg-info',
    Design: 'bg-secondary',
    Review: 'bg-review',
    Develop: 'bg-primary',
    Test: 'bg-warning',
    Failed: 'bg-danger',
    Approve: 'bg-success',
  };

  public mapSubStatus = {
    'To Do': 'bg-review',
    'In Progress': 'bg-primary',
    Blocked: 'bg-danger',
    Done: 'bg-success',
  };

  constructor(
    private uss: UserSessionStorageService,
    public fbs: FirebaseService,
    private SvgViewerModalService: SvgViewerModalService
  ) {}

  ngOnInit(): void {
    this.selectedWidgetPath = this.uss.getUserSessionStorageItem('widgetPath');
  }

  ngOnChanges(): void {
    this.filteredStateWidgets = this.filteredWidgets
      .filter((widget) => widget.type === 'state')
      .sort((a, b) => {
        const splitA = a.path.split('_').map((item) => item.toLowerCase());
        const splitB = b.path.split('_').map((item) => item.toLowerCase());

        // Compare based on the number of split items
        if (splitA.length < splitB.length) return -1;
        if (splitA.length > splitB.length) return 1;

        // If split lengths are the same, compare each segment alphabetically
        for (let i = 0; i < splitA.length; i++) {
          if (splitA[i] < splitB[i]) return -1;
          if (splitA[i] > splitB[i]) return 1;
        }

        return 0;
      });

    this.filteredValidStateWidgets = this.filteredStateWidgets.filter(
      (widget) => widget.stateFigmaPreviewUrl
    );
  }

  close(): void {
    this.onClose.emit();
  }

  onClick(widget: JsWidget): void {
    this.selectedWidgetPath = widget.path || '';
    setTimeout(() => {
      this.uss.setUserSessionStorageItem(
        'widgetPath',
        widget.path || '',
        false
      );
      this.onSelect.emit(widget.path || '');
    });
  }

  trackBy(index: number, item: JsWidget): string {
    return item.id;
  }

  getLastSegment(path: string): string {
    const segments = path.split('_');
    return segments[segments.length - 1].slice(5);
  }

  previewFigma(widget: JsWidget | null) {
    if (!widget?.stateFigmaPreviewUrl) return;
    const isMinimal = widget.path.startsWith('taskDiagram');
    this.SvgViewerModalService.show(
      widget!,
      isMinimal,
      this.filteredValidStateWidgets
    ).subscribe(async (res: any) => {
      if (res && res.action === 'delete') {
        alert('Please refresh it from the details panel on the right.');
      }
    });
  }
}
