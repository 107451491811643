import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewChild, effect, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { JsTask, priority } from '../_interfaces/Task';
import { CacheService } from '../_services/cache.service';
import { FirebaseService } from '../_services/firebase.service';
import { IdbServiceService } from '../_services/idb-service.service';
import { UserSessionStorageService } from '../_services/user-session-storage.service';
import { SnackbarService } from '../_services/snackbar.service';
import { TaskDetailsModalComponent } from '../task-details-modal/task-details-modal.component';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { ViewBlocksOfTaskComponent } from '../view-blocks-of-task/view-blocks-of-task.component';
import { SelectFilterComponent } from '../shared/components/select-filter/select-filter.component';
import { JsWidget } from '../_interfaces/Widget';
import { WidgetDetailEditComponent } from '../widget-detail-edit/widget-detail-edit.component';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { taskStatusList } from '../shared/status';
import { JsTodo } from '../_interfaces/Todo';
import { TodoListComponent } from '../todos/todo-list/todo-list.component';

@Component({
  selector: 'app-tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: ['./tasks-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksTableComponent implements OnInit, OnDestroy {
  public faUser = faUser;
  public showPlan = false;
  public isFocusMode = false;
  private widgetSubscription: any;
  planWidgets: JsWidget[] = [];
  groupedTasks: { [key: string]: JsTask[] } = {};
  orderedDeliverables: string[] = [];
  filteredTasks: JsTask[] = [];
  sortBy: keyof JsTask = 'order';
  sortDirection: 'asc' | 'desc' = 'asc';
  public filterOptions = {
    forRelease: {
      options: [0],
      selected: 0 as number | null
    },
    notForRelease: {
      options: [0],
      selected: null as number | null
    },
    status: {
      options: ['All', ...taskStatusList],
      selected: 'All'
    },
    show: {
      title: 'Show Tasks',
      options: [
        {
          label: 'Active',
          value: 'Active'
        },
        {
          label: 'Approved',
          value: 'Approved'
        },
        {
          label: 'Deleted',
          value: 'Deleted'
        }
      ],
      selected: ['Active'],
      onSelectedValueChange: (value: any) => {
        this.filterOptions.show.selected = value;
        this.uss.setUserSessionStorageItem('taskTableShowFilter', this.filterOptions.show.selected);
        this.onFilterChange();
      },
      resetSelection: ['Active'],
      resetSignal: signal(false)
    },
    productOwner: {
      selected: ''
    },
    designOwner: {
      selected: ''
    },
    devOwner: {
      selected: ''
    },
    testOwner: {
      selected: ''
    },
    subStatus: {
      options: ['All', 'To Do', 'In Progress', 'Blocked', 'Done'],
      selected: 'All'
    },
    priority: {
      options: ['All', 'Low', 'Default', 'High', 'Critical'],
      selected: 'All'
    },
    type: {
      options: ['All', 'Feature', 'Optimisation', 'Routine', 'Bug', 'Other'],
      selected: 'All'
    },
    groupBy: {
      options: [
        'None',
        'deliverable',
        'assignedTo',
        'status',
        'subStatus',
        'type',
        'priority',
        'fromRelease',
        'toRelease',
        'uid',
        'productOwner',
        'designOwner',
        'devOwner',
        'testOwner'
      ],
      selected: 'assignedTo'
    },
    assignedTo: {
      selected: ''
    },
    columns: {
      title: 'Columns',
      options: [
        { label: 'Order', value: 'order' },
        { label: 'Assigned To', value: 'assignedTo' },
        { label: 'Priority', value: 'priority' },
        { label: 'From Release', value: 'fromRelease' },
        { label: 'To Release', value: 'toRelease' },
        { label: 'Translation Status', value: 'Translation Status' },
        { label: 'View Status', value: 'View Status' },
        { label: 'Deliverable', value: 'deliverable' },
        { label: 'Spec Include Patterns', value: 'includePatterns' },
        { label: 'Spec Exclude Patterns', value: 'excludePatterns' },
        { label: 'Product Owner', value: 'productOwner' },
        { label: 'Design Owner', value: 'designOwner' },
        { label: 'Dev Owner', value: 'devOwner' },
        { label: 'Test Owner', value: 'testOwner' },
        { label: 'Sub status', value: 'subStatus' },
        { label: 'Type', value: 'type' },
        { label: 'Updated By', value: 'uid' },
        { label: 'Updated At', value: 'cloudUpdatedAt' },
        { label: 'Deleted At', value: 'deletedAt' }
      ],
      selected: [] as string[],
      selectedEmitter: new EventEmitter<string[]>()
    }
  };
  initialColumns: string[] = [
    'fid',
    'title',
    'hours',
    // 'percentageCompleted',
    'status',
    'Specs Status',
    'Todo Status',
  ];
  displayedColumns: string[] = [...this.initialColumns];
  allTasks: JsTask[] = [];
  taskSubscription: any;
  sessionSubscription: any;
  columnSubscription: any;
  filterValue: string = '';
  hasLoaded: boolean = false;
  pageSize = 200;
  groupValues: string[] = [];
  showGroupValues: any[] = [];
  statusStyleMap: { [key: string]: string } = {
    Backlog: 'text-bg-info',
    Design: 'text-bg-secondary',
    Review: 'text-bg-review',
    Develop: 'text-bg-primary',
    Blocked: 'text-bg-danger',
    Test: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Mergeable: 'text-bg-primary',
    Passed: 'text-bg-success',
    Approved: 'text-bg-success'
  };
  @ViewChild('columnFilter') columnFilter: SelectFilterComponent | null = null;
  public relatedTodoByTaskId: { [key: string]: JsTodo[] } = {};

  constructor(
    private idb: IdbServiceService,
    private changeRef: ChangeDetectorRef,
    private snackbar: SnackbarService,
    public dialog: MatDialog,
    public cc: CacheService,
    private uss: UserSessionStorageService,
    public fbs: FirebaseService,
    private _dialog: DialogManagerService
  ) {
    effect(() => {
      this.filterOptions.forRelease.options = _.cloneDeep(this.cc.availableMainReleaseNumbers());
      this.filterOptions.notForRelease.options = _.cloneDeep(this.cc.availableMainReleaseNumbers());
      this.orderedDeliverables = _.cloneDeep(this.cc.currentDeliverables());
      this.onFilterChange();
    });
  }

  detectChanges() {
    this.changeRef.detectChanges();
  }

  ngOnInit(): void {
    this.widgetSubscription = this.cc.widgets$.subscribe(widgets => {
      const planWidgets = widgets.filter(widget => widget.path.startsWith('taskWeeklyPlan'));
      if (_.isEqual(planWidgets, this.planWidgets)) return;
      this.planWidgets = planWidgets;
      this.changeRef.detectChanges();
    });
    this.columnSubscription = this.filterOptions.columns.selectedEmitter.subscribe(selected => {
      this.uss.setUserSessionStorageItem('taskTableColumnFilters', selected);
    });
    this.sessionSubscription = this.uss.currentUserSessionStorage$.subscribe(snapshot => {
      this.showPlan = snapshot.taskTableShowPlan;
      this.isFocusMode = snapshot.taskTableIsFocusMode;
      this.filterOptions.columns.selected = snapshot.taskTableColumnFilters;
      this.displayedColumns = [...this.initialColumns, ...snapshot.taskTableColumnFilters];
      this.pageSize = snapshot.taskTablePageSize;
      this.filterOptions.forRelease.selected = snapshot.taskTableForReleaseFilter;
      this.filterOptions.notForRelease.selected = snapshot.taskTableNotForReleaseFilter;
      this.filterOptions.assignedTo.selected = snapshot.taskTableAssignedToFilter;
      this.filterOptions.status.selected = snapshot.taskTableStatusFilter;
      if (this.filterOptions.groupBy.selected !== snapshot.taskTableGroupByFilter) {
        this.filterOptions.groupBy.selected = snapshot.taskTableGroupByFilter;
        this.resetShowGroupValues();
      }
      this.filterOptions.productOwner.selected = snapshot.taskTableProductOwnerFilter;
      this.filterOptions.designOwner.selected = snapshot.taskTableDesignOwnerFilter;
      this.filterOptions.devOwner.selected = snapshot.taskTableDevOwnerFilter;
      this.filterOptions.testOwner.selected = snapshot.taskTableTestOwnerFilter;
      this.filterOptions.subStatus.selected = snapshot.taskTableSubStatusFilter;
      this.filterOptions.type.selected = snapshot.taskTableTypeFilter;
      this.filterOptions.priority.selected = snapshot.taskTablePriorityFilter;
      this.filterOptions.show.selected = snapshot.taskTableShowFilter;
      this.filterValue = snapshot.taskTableSearch;
      this.onFilterChange();
    });
    setTimeout(() => {
      this.taskSubscription = this.cc.tasks$.subscribe((tasks: JsTask[]) => {
        this.allTasks = _.cloneDeep(tasks);
        this.hasLoaded = true;
        this.onFilterChange();
      });
    });

    this.getRelatedTodos();
  }

  getRelatedTodos() {
    this.idb.todos$.subscribe((todos: JsTodo[]) => {
      const allTodos = todos;
      this.relatedTodoByTaskId = {};
      allTodos.forEach(t => {
        t.relatedTids.forEach(id => {
          if (this.relatedTodoByTaskId[id]) {
            if (this.relatedTodoByTaskId[id].length !== 0) {
              this.relatedTodoByTaskId[id].push(t);
            } else {
              this.relatedTodoByTaskId[id] = [t];
            }
          } else {
            this.relatedTodoByTaskId[id] = [t];
          }
        });
      });
      this.detectChanges();
    });
  }

  viewRelatedTodosList(event: any ,task: JsTask) {
    if(!task.id) {
      return
    }
    event.stopPropagation();
    this.dialog.open(TodoListComponent, {
      data: {
        task: task
      }
    });
  }

  ngOnDestroy(): void {
    this.taskSubscription?.unsubscribe();
    this.sessionSubscription?.unsubscribe();
    this.columnSubscription?.unsubscribe();
    this.widgetSubscription?.unsubscribe();
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.uss.setUserSessionStorageItem('taskTableSearch', this.filterValue);
  }

  resetFilter() {
    this.filterValue = '';
    this.uss.setUserSessionStorageItem('taskTableSearch', this.filterValue);
  }

  onKeyCopy(value: string) {
    this.snackbar.show(`${value}`);
  }

  isShowPlanApplicable(): boolean {
    return ['assignedTo', 'uid', 'productOwner', 'designOwner', 'devOwner', 'testOwner', 'createdBy'].includes(
      this.filterOptions.groupBy.selected
    );
  }

  addTask() {
    // Open the translation details modal
    const confirmDialog = this.dialog.open(TaskDetailsModalComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: true,
    });
    return confirmDialog.afterClosed();
  }

  editTask(task: JsTask) {
    // Open the translation details modal
    const confirmDialog = this.dialog.open(TaskDetailsModalComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: task,
      autoFocus: false,
      disableClose: true,
    });
    return confirmDialog.afterClosed();
  }

  toggleShowPlan() {
    this.showPlan = !this.showPlan;
    this.uss.setUserSessionStorageItem('taskTableShowPlan', this.showPlan);
  }

  toggleFocusMode() {
    this.isFocusMode = !this.isFocusMode;
    this.uss.setUserSessionStorageItem('taskTableIsFocusMode', this.isFocusMode);
    if (this.isFocusMode) {
      this.filterOptions.groupBy.selected = 'assignedTo';
      this.uss.setUserSessionStorageItem('taskTableGroupByFilter', 'assignedTo');
      this.filterOptions.assignedTo.selected = this.fbs.getCurrentUserId();
      this.uss.setUserSessionStorageItem('taskTableAssignedToFilter', this.fbs.getCurrentUserId());
      this.showPlan = true;
      this.uss.setUserSessionStorageItem('taskTableShowPlan', true);
    } else {
      this.filterOptions.groupBy.selected = 'assignedTo';
      this.uss.setUserSessionStorageItem('taskTableGroupByFilter', 'assignedTo');
      this.filterOptions.assignedTo.selected = '';
      this.uss.setUserSessionStorageItem('taskTableAssignedToFilter', '');
      this.showPlan = false;
      this.uss.setUserSessionStorageItem('taskTableShowPlan', false);
    }
    this.onFilterChange();
  }

  onFilterChange() {
    this.groupValues = [];
    let filteredTasks = _.cloneDeep(this.allTasks);
    // Search filter
    if (this.filterValue) {
      filteredTasks = filteredTasks.filter((task: JsTask) => {
        return (
          task.title?.toLowerCase().includes(this.filterValue) ||
          task.description?.toLowerCase().includes(this.filterValue) ||
          task.currentScope?.toLowerCase().includes(this.filterValue) ||
          task.includePatterns?.toLowerCase().includes(this.filterValue) ||
          task.excludePatterns?.toLowerCase().includes(this.filterValue) ||
          task.subStatus?.toLowerCase().includes(this.filterValue) ||
          task.type?.toLowerCase().includes(this.filterValue) ||
          task.status?.toLowerCase().includes(this.filterValue)
        );
      });
    } else {
      // Do nothing
    }

    // Filter by status
    if (this.filterOptions.status.selected === 'All') {
      // Do nothing
    } else {
      filteredTasks = filteredTasks.filter((task: JsTask) => task.status === this.filterOptions.status.selected);
    }
    this.uss.setUserSessionStorageItem('taskTableStatusFilter', this.filterOptions.status.selected, false);

    // Filter by product owner
    if (this.filterOptions.productOwner.selected === '') {
      // Do nothing
    } else if (this.filterOptions.productOwner.selected === 'UNASSIGNED') {
      filteredTasks = filteredTasks.filter((task: JsTask) => !task.productOwner);
    } else {
      filteredTasks = filteredTasks.filter((task: JsTask) => task.productOwner === this.filterOptions.productOwner.selected);
    }

    // Filter by design owner
    if (this.filterOptions.designOwner.selected === '') {
      // Do nothing
    } else if (this.filterOptions.designOwner.selected === 'UNASSIGNED') {
      filteredTasks = filteredTasks.filter((task: JsTask) => !task.designOwner);
    } else {
      filteredTasks = filteredTasks.filter((task: JsTask) => task.designOwner === this.filterOptions.designOwner.selected);
    }

    // Filter by dev owner
    if (this.filterOptions.devOwner.selected === '') {
      // Do nothing
    } else if (this.filterOptions.devOwner.selected === 'UNASSIGNED') {
      filteredTasks = filteredTasks.filter((task: JsTask) => !task.devOwner);
    } else {
      filteredTasks = filteredTasks.filter((task: JsTask) => task.devOwner === this.filterOptions.devOwner.selected);
    }

    // Filter by test owner
    if (this.filterOptions.testOwner.selected === '') {
      // Do nothing
    } else if (this.filterOptions.testOwner.selected === 'UNASSIGNED') {
      filteredTasks = filteredTasks.filter((task: JsTask) => !task.testOwner);
    } else {
      filteredTasks = filteredTasks.filter((task: JsTask) => task.testOwner === this.filterOptions.testOwner.selected);
    }

    // Filter by sub status
    if (this.filterOptions.subStatus.selected === 'All') {
      // Do nothing
    } else {
      filteredTasks = filteredTasks.filter((task: JsTask) => task.subStatus === this.filterOptions.subStatus.selected);
    }
    this.uss.setUserSessionStorageItem('taskTableSubStatusFilter', this.filterOptions.subStatus.selected, false);

    // Filter by forRelease
    if (this.filterOptions.forRelease.selected === null) {
      // Do nothing
    } else {
      filteredTasks = this.cc.getFilteredTasksForRelease(filteredTasks, this.filterOptions.forRelease.selected);
    }
    this.uss.setUserSessionStorageItem('taskTableForReleaseFilter', this.filterOptions.forRelease.selected, false);

    // Filter by notForRelease
    if (this.filterOptions.notForRelease.selected === null) {
      // Do nothing
    } else {
      filteredTasks = this.cc.getFilteredTasksNotForRelease(filteredTasks, this.filterOptions.notForRelease.selected);
    }
    this.uss.setUserSessionStorageItem('taskTableNotForReleaseFilter', this.filterOptions.notForRelease.selected, false);

    // Filter by priority
    if (this.filterOptions.priority.selected === 'All') {
      // Do nothing
    } else {
      filteredTasks = filteredTasks.filter((task: JsTask) => task.priority === this.filterOptions.priority.selected);
    }
    this.uss.setUserSessionStorageItem('taskTablePriorityFilter', this.filterOptions.priority.selected, false);

    // Filter by type
    if (this.filterOptions.type.selected === 'All') {
      // Do nothing
    } else {
      filteredTasks = filteredTasks.filter((task: JsTask) => task.type === this.filterOptions.type.selected);
    }
    this.uss.setUserSessionStorageItem('taskTableTypeFilter', this.filterOptions.type.selected, false);

    // Filter by assignedTo
    if (this.filterOptions.assignedTo.selected === '') {
      // Do nothing
    } else if (this.filterOptions.assignedTo.selected === 'UNASSIGNED') {
      filteredTasks = filteredTasks.filter((task: JsTask) => !task.assignedTo);
    } else {
      filteredTasks = filteredTasks.filter((task: JsTask) => task.assignedTo === this.filterOptions.assignedTo.selected);
    }
    this.uss.setUserSessionStorageItem('taskTableAssignedToFilter', this.filterOptions.assignedTo.selected, false);

    const showFilteredTaskIds: Set<string> = new Set();
    if (this.filterOptions.show.selected.includes('Active')) {
      filteredTasks.forEach((task: JsTask) => {
        if (!task.deletedAt && task.status !== 'Approved') {
          showFilteredTaskIds.add(task.id);
        }
      });
    }
    if (this.filterOptions.show.selected.includes('Approved')) {
      filteredTasks.forEach((task: JsTask) => {
        if (!task.deletedAt && task.status === 'Approved') {
          showFilteredTaskIds.add(task.id);
        }
      });
    }
    if (this.filterOptions.show.selected.includes('Deleted')) {
      filteredTasks.forEach((task: JsTask) => {
        if (task.deletedAt) {
          showFilteredTaskIds.add(task.id);
        }
      });
    }
    filteredTasks = filteredTasks.filter((task: JsTask) => showFilteredTaskIds.has(task.id));
    this.uss.setUserSessionStorageItem('taskTableShowFilter', this.filterOptions.show.selected, false);

    // Order by order
    // @ts-ignore
    if (this.sortBy === 'hours') {
      filteredTasks = _.orderBy(filteredTasks, [this.getTotalHours], [this.sortDirection]);
    } else {
      filteredTasks = _.orderBy(filteredTasks, [this.sortBy], [this.sortDirection]);
    }
    
    // Group by GroupBy
    if (this.filterOptions.groupBy.selected === 'None') {
      // Do nothing
    } else {
      this.groupBy(filteredTasks, this.filterOptions.groupBy.selected as keyof JsTask);
    }
    this.filteredTasks = _.cloneDeep(filteredTasks);
    this.uss.setUserSessionStorageItem('taskTableGroupByFilter', this.filterOptions.groupBy.selected, false);
    this.changeRef.detectChanges();
  }

  groupBy(tasks: JsTask[], groupKey: keyof JsTask) {
    this.groupedTasks = {};
    // { '3': ['one', 'two'], '5': ['three'] } => [{groupValue: '3', groupKey: key, isGroupHeader: true, count: 12}, { groupValue: '3', value: ['one', 'two'] }, {groupValue: '5', groupKey: key}{ groupValue: '5', value: ['three'] }]
    const groupedTasks = _.groupBy(tasks, task => task[groupKey] || '');
    // this.groupValues = this.enrichGroupedTasksAndReorder(this.groupedTasks, groupKey);
    this.enrichGroupedTasksAndReorder(groupedTasks, groupKey);
  }

  resetShowGroupValues() {
    this.showGroupValues = [];
  }

  enrichGroupedTasksWithEmptyUsers(grouped: { [key: string]: JsTask[] }, groupKey: keyof JsTask) {
    const validUids = this.fbs.orderedUserIds;
    const enrichedGrouped = _.cloneDeep(grouped);
    const updatedGrouped: { [key: string]: JsTask[] } = {};
    validUids.forEach(uid => {
      if (enrichedGrouped[uid] !== undefined) {
        updatedGrouped[uid] = enrichedGrouped[uid];
      } else {
        updatedGrouped[uid] = [];
      }
    });
    if (enrichedGrouped[''] !== undefined) {
      updatedGrouped[''] = enrichedGrouped[''];
    }
    return updatedGrouped;
  }

  enrichGroupedTasksAndReorder(grouped: { [key: string]: JsTask[] }, groupKey: keyof JsTask) {
    const order = {
      status: [...this.filterOptions.status.options, ''],
      type: [...this.filterOptions.type.options, ''],
      priority: [...this.filterOptions.priority.options, ''],
      forRelease: [...this.filterOptions.forRelease.options, ''],
      notForRelease: [...this.filterOptions.notForRelease.options, ''],
      subStatus: [...this.filterOptions.subStatus.options, ''],
      deliverable: [...this.orderedDeliverables]
    };

    let enrichedGrouped = _.cloneDeep(grouped);

    if (['assignedTo', 'uid', 'productOwner', 'designOwner', 'devOwner', 'testOwner', 'createdBy'].includes(groupKey)) {
      enrichedGrouped = this.enrichGroupedTasksWithEmptyUsers(enrichedGrouped, groupKey);
    }

    const ordered = Object.keys(enrichedGrouped)
      // @ts-ignore
      .sort((a: string, b: string) => {
        // @ts-ignore
        if (order[groupKey]) {
          // @ts-ignore
          return order[groupKey].indexOf(a) - order[groupKey].indexOf(b);
        } else {
          // @ts-ignore
          return a - b;
        }
      });

    // Empty string should be last in the list or group as per the order
    if (ordered.includes('')) {
      ordered.splice(ordered.indexOf(''), 1);
      ordered.push('');
    } else {
      // ordered.push('');
    }

    this.groupValues = _.cloneDeep(ordered);
    this.groupedTasks = _.cloneDeep(enrichedGrouped);
  }

  toggleHiddenGroup(groupValue: string) {
    if (this.showGroupValues.includes(groupValue)) {
      this.showGroupValues = this.showGroupValues.filter(value => value !== groupValue);
    } else {
      this.showGroupValues.push(groupValue);
    }
    this.changeRef.detectChanges();
  }

  resetFilters() {
    this.sortBy = 'order';
    this.sortDirection = 'asc';
    this.uss.resetUserSessionStorage('taskTable');
    this.filterOptions.show.resetSignal.set(true);
    this.columnFilter?.reset();
  }

  getTotalHours = (task: JsTask): number => {
    return task.timeLogs?.reduce((acc, curr) => acc + curr.hours, 0) || 0;
  };

  showViewBlocksOfTask(task: JsTask, e: any) {
    this._dialog.openDialog(ViewBlocksOfTaskComponent, {
      data: {
        task
      }
    });

    e.stopPropagation();
  }

  getPlanWidget(displayName: string): JsWidget | null {
    let planWidget: JsWidget | null = null;
    if (displayName === '') {
      planWidget = this.planWidgets.find(widget => widget.path.endsWith('Backlog')) || null;
    } else if (displayName === 'UNASSIGNED') {
      // For non valid users not active in project app
      planWidget = null;
    } else {
      planWidget = this.planWidgets.find(widget => widget.path.endsWith(displayName)) || null;
    }
    return planWidget || null;
  }

  openPlanWidget(planWidget: JsWidget | null, e: any) {
    if (!planWidget) return;
    this._dialog.openDialog(WidgetDetailEditComponent, {
      panelClass: 'widget-detail-edit-bottom-sheet',
      disableClose: true,
      data: {
        widgetId: planWidget.id,
        hiddenFields: ['path', 'status', 'subStatus', 'assignedTo', 'tags', 'regressions', 'viewId', 'viewBlock', 'taskBlock', 'fingerPrints']
      }
    });
    e.stopPropagation();
  }

  trackByForTask(index: number, task: JsTask) {
    return task.id;
  }

  trackByForGroup(index: number, group: string) {
    return group;
  }

  showDeliverables() {
    if (this.filterOptions.groupBy.selected === 'deliverable') {
      // Do nothing
    } else {
      this.uss.setUserSessionStorageItem('taskTableGroupByFilter', 'deliverable');
    }
  }

  sort(sortBy: string) {
    // Get valid keys from the first 10 tasks
    const validKeys = new Set(Object.keys(this.allTasks[0]));
    let i = 0;
    while (i < this.allTasks.length && i < 10) {
      Object.keys(this.allTasks[i]).forEach(key => validKeys.add(key));
      i++;
    }

    if (!validKeys.has(sortBy) && !['hours', 'fromRelease', 'toRelease'].includes(sortBy)) {
      alert('Invalid sort key');
      return;
    }
    if (this.sortBy === sortBy) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortDirection = 'asc';
    }
    // @ts-ignore
    this.sortBy = sortBy;

    this.onFilterChange();
  }
}
