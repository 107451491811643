import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Effort } from '../_interfaces/Other';
import { format } from 'date-fns';
import { JsActivity } from '../_interfaces/Activity';
import { FirebaseService } from '../_services/firebase.service';
import { FirebaseOptimisticService } from '../_services/firebase-optimistic.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CacheService } from '../_services/cache.service';
import { JsWidget, status, type } from '../_interfaces/Widget';
import { IdbServiceService } from '../_services/idb-service.service';

@Component({
  selector: 'app-status-updates',
  templateUrl: './status-updates.component.html',
  styleUrls: ['./status-updates.component.scss'],
})
export class StatusUpdatesComponent implements OnDestroy, OnInit {
  @Input() who: string | null = null; // Name string
  @Input() when: string | null = null; // Date string
  @Output() onClose = new EventEmitter<boolean>();
  mainSubscription: any;
  activities: JsActivity[] = [];
  statusWidgetMap: { [key: string]: JsWidget[] } = {
    Backlog: [],
    Design: [],
    Review: [],
    Develop: [],
    Test: [],
    Failed: [],
    Approve: [],
  };
  faTimes = faTimes;
  groupByOptions = [
    { label: 'From Status', value: 'before' },
    { label: 'To Status', value: 'after' },
    { label: 'Current Status', value: 'current' },
  ];
  selectedGroupBy: 'before' | 'after' | 'current' = 'after';
  statusList = [
    'Backlog',
    'Design',
    'Review',
    'Develop',
    'Test',
    'Failed',
    'Approve',
  ];
  selectedWidgetId: string | null = null;
  selectedWidget: JsWidget | null = null;
  totalCount = 0;

  public mapClass = {
    Backlog: 'bg-info',
    Design: 'bg-secondary',
    Review: 'bg-review',
    Develop: 'bg-primary',
    Test: 'bg-warning',
    Failed: 'bg-danger',
    Approve: 'bg-success',
    null: 'bg-dark',
  };

  public typeClass = {
    bug: 'text-warning',
    state: 'text-danger',
    action: 'text-primary',
    rule: 'text-info',
    translate: 'text-success',
    track: 'text-secondary',
    other: 'text-dark',
  };

  constructor(
    public fbs: FirebaseService,
    public fbo: FirebaseOptimisticService,
    public cc: CacheService,
    private idb: IdbServiceService
  ) {}

  ngOnInit(): void {
    this.mainSubscription?.unsubscribe();
    this.mainSubscription = this.idb.statusUpdates$.subscribe(
      (activities: JsActivity[]) => {
        this.activities = activities.filter((activity) => {
          const { createdBy, createdAt } = activity;
          const date = format(new Date(createdAt), 'yyyy-MM-dd');
          return createdBy === this.who && date === this.when;
        });
        this.setStatusWidgetMap();
      }
    );
  }

  ngOnDestroy(): void {
    this.mainSubscription?.unsubscribe();
  }

  setSelectedGroupBy($e: any): void {
    this.selectedGroupBy = $e.value;
    this.setStatusWidgetMap();
  }

  getHumanReadableDate(date: string): string {
    // using datefns to check whether ist valid date or not
    if (
      !date ||
      date.split('-').length !== 3 ||
      isNaN(new Date(date).getTime())
    ) {
      return '';
    }
    // using datefns format function
    return format(new Date(date), 'eee, MMM d, yyyy');
  }

  trackByMethod(index: number, item: any): number {
    return item.id;
  }

  trackByStatus(index: number, item: any): string {
    return item;
  }

  trackByIdAndTime(index: number, item: any): string {
    return item.id + item.updatedAt;
  }

  onSelect(widget: JsWidget) {
    this.selectedWidget = widget;
    this.selectedWidgetId = widget.id;
  }

  getTypeClass(widget: JsWidget): string {
    const type = widget.type || 'other';
    //@ts-ignore
    return this.typeClass[type];
  }

  getStatusClass(widget: JsWidget): string {
    let status = widget.status;
    if (!status) {
      return 'bg-black';
    } else {
      //@ts-ignore
      return this.mapClass[status] || 'bg-black';
    }
  }

  close() {
    this.selectedWidgetId = null;
    this.onClose.emit(true);
  }

  resetWidgetStatusMap() {
    this.statusWidgetMap = {
      Backlog: [],
      Design: [],
      Review: [],
      Develop: [],
      Test: [],
      Failed: [],
      Approve: [],
    };
  }

  setStatusWidgetMap() {
    this.resetWidgetStatusMap();
    const groupBy = this.selectedGroupBy;
    // Loop through activities to create statusWidgetMap
    this.activities.forEach((activity) => {
      const { before, after } = activity;
      let widget = null;
      if (groupBy === 'before') {
        widget = before || after;
      } else if (groupBy === 'after') {
        widget = after || before;
      } else if (groupBy === 'current') {
        widget = this.cc.getWidgetById(activity.entityId);
      }
      if (widget) {
        //@ts-ignore
        this.statusWidgetMap[widget.status].push(widget);
      }
    });

    this.totalCount = this.getCount(this.statusWidgetMap);
  }

  getCount(statusWidgetMap: { [key: string]: JsWidget[] }): number {
    let count = 0;
    for (const key in statusWidgetMap) {
      if (Object.prototype.hasOwnProperty.call(statusWidgetMap, key)) {
        const element = statusWidgetMap[key];
        count += element.length;
      }
    }
    return count;
  }
}
