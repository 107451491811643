import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, Output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { Collection } from 'src/app/_interfaces/Collection';
import { JsTodo } from 'src/app/_interfaces/Todo';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { FirebaseOptimisticService } from 'src/app/_services/firebase-optimistic.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { SvgService } from 'src/app/_services/svg.service';
import { SvgViewerComponent } from 'src/app/svg-viewer/svg-viewer.component';
import * as _ from 'lodash';

type Data = { 
  figmaPrevUrl: string,
  figmaPreviewValidated: boolean,
  figmaUsedColors: string[],
  status: string,
  collection: Collection,
  todoData: JsTodo, 
}

@Component({
  selector: 'app-svg-view',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    SvgViewerComponent,
    MatIconButton,
    MatTooltip,
    MatIcon,
    MatChipListbox,
    MatChipOption,
    CdkCopyToClipboard,
  ],
  templateUrl: './svg-view.component.html',
  styleUrl: './svg-view.component.scss'
})

export class SvgViewComponent {

  showFullScale = false;
  currentColorMap?: { [key: string]: string };
  currentNameToColorMap: { name: string; value: string }[] = [];
  currentNameToColorMapExtra: { name: string; value: string }[] = [];
  currentThemeMode: string = '';
  hoverColor: string = '';
  @Output() previewValidationChanged = new EventEmitter<any>();

  constructor( 
    @Inject(MAT_DIALOG_DATA) public data: Data,
    public dialogRef: MatDialogRef<SvgViewComponent>,
    public dialog: MatDialog,
    private confirmService: ConfirmService,
    public svgService: SvgService,
    private changeRef: ChangeDetectorRef,
    private snackbar: SnackbarService,
    private fbo: FirebaseOptimisticService) {
  }

  ngOnInit(): void {
    this.currentThemeMode = this.svgService.defaultThemeMode;
    this.currentColorMap = this.svgService.getColorMapByThemeMode(
      this.currentThemeMode
    );
    if (!this.currentThemeMode) {
      this.onColorModeChange(this.svgService.defaultThemeMode);
    }
  }

   // Listen for keydown events
   @HostListener('window:keydown', ['$event'])
   handleKeyboardEvent(event: KeyboardEvent) {
     event.stopPropagation();
     if (event.key === 'Escape') {
       this.dialogRef.close();
     }
 
     if (event.key === 'ArrowUp') {
       this.previousThemeMode();
     }
 
     if (event.key === 'ArrowDown') {
       this.nextThemeMode();
     }
   }

  onColorModeChange(mode: string) {
    this.currentThemeMode = mode;
    this.currentColorMap = this.svgService.getColorMapByThemeMode(mode);
    this.changeRef.detectChanges();
  }

  onFocusColor(color: string) {
    if (color === this.hoverColor || !color) return;
    this.hoverColor = color;
    this.changeRef.detectChanges();
  }

  onBlurColor() {
    this.hoverColor = '';
    this.changeRef.detectChanges();
  }

  previousThemeMode() {
    const currentIndex = this.svgService.availableThemeModes.findIndex(
      (mode) => mode === this.currentThemeMode
    );
    if (currentIndex === 0 || currentIndex === -1) {
      return;
    }
    this.onColorModeChange(
      this.svgService.availableThemeModes[currentIndex - 1]
    );
  }

  nextThemeMode() {
    const currentIndex = this.svgService.availableThemeModes.findIndex(
      (mode) => mode === this.currentThemeMode
    );
    if (currentIndex === this.svgService.availableThemeModes.length - 1) {
      return;
    }
    this.onColorModeChange(
      this.svgService.availableThemeModes[currentIndex + 1]
    );
  }

  onHexCopy() {
    this.snackbar.show('Hex code copied to clipboard');
  }

  onCurrentUsedColorsChange(event: Set<string>) {
    // Current colors from Color map in Firestore
    const currentNameToColorMap: typeof this.currentNameToColorMap = [];
    this.currentNameToColorMapExtra = this.svgService.getExtraColors(
      this.data.figmaUsedColors || []
    );
    const currentModeColors =
      this.svgService.getThemeColorsAsArrayFromThemeMode(this.currentThemeMode);
    [...event].forEach((color) => {
      const colorName = currentModeColors.filter((c) => c.value === color)[0]
        .name;
      currentNameToColorMap.push({
        name: colorName,
        value: color,
      });
    });

    this.currentNameToColorMap = currentNameToColorMap.sort((a, b) => {
      return (
        parseInt(a.name.split(' ')[1], 10) - parseInt(b.name.split(' ')[1], 10)
      );
    });

    this.currentNameToColorMap = this.currentNameToColorMap.concat(
      this.currentNameToColorMapExtra
    );
  }

  async toggleStatePreviewValidity($event: MouseEvent) {
    $event.stopPropagation();
    const title = this.data.figmaPreviewValidated
      ? 'Unapprove Previews'
      : 'Approve Previews';
    const confirmation = await this.confirmService.confirm(
      title,
      `Are you sure? This will change the state of the preview validity!!`
    );
    if (!confirmation) return;

    const todoData = _.cloneDeep(this.data.todoData);

    todoData.figmaPreviewValidated = !this.data.figmaPreviewValidated;
    todoData.figmaUsedColors = this.data.figmaUsedColors;

    // Optimistic update
    this.fbo.updateItemsOptimistic([todoData], 'todos')
      .then(() => {
        this.data.figmaPreviewValidated = !this.data.figmaPreviewValidated;
        this.previewValidationChanged.emit(this.data.figmaPreviewValidated);
        this.changeRef.detectChanges();
      })
      .catch((error) => {
        this.snackbar.show('Failed to update');
      });
  }

  async delete() {
    const confirmation = await this.confirmService.confirm(
      'Delete',
      `Are you sure? This is irreversible!!`
    );
    if (!confirmation) return;
    this.dialogRef.close({
      action: 'delete',
    });
  }
}