<span class="ri-pointer" *ngIf="!isEmpty; else noTodos">
    <span class="badge rounded-pill text-bg-review me-1" [matTooltip]="getPercentage('todo')" [ngClass]="{ 'opacity-25': !todo }">
      {{ todo || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-primary me-1" [matTooltip]="getPercentage('inProgress')" [ngClass]="{ 'opacity-25': !inProgress }">
      {{ inProgress || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-secondary me-1" [matTooltip]="getPercentage('blocked')" [ngClass]="{ 'opacity-25': !blocked }">
      {{ blocked || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-info me-1" [matTooltip]="getPercentage('needClarity')" [ngClass]="{ 'opacity-25': !needClarity }">
      {{ needClarity || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-warning me-1" [matTooltip]="getPercentage('done')" [ngClass]="{ 'opacity-25': !done }">
      {{ done || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-success me-1" [matTooltip]="getPercentage('approved')" [ngClass]="{ 'opacity-25': !approved }">
      {{ approved || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-dark" [matTooltip]="getTotalPercentageProgress()" [ngClass]="{ 'opacity-25': !total }">
      {{ isShowPercentage() ? getPercentageProgressOnly('total') : total || '-' }}
    </span>
  </span>
  <ng-template #noTodos>
    <span class="d-inline-block">- - -</span>
  </ng-template>
  