<div *ngIf="widget" class="px-2 mb-5 mw-100 overflow-scroll">
  <div class="fingerprint-container">
    <span [ngClass]="{'current': widget.status === 'Backlog'}" matTooltip="Backlog"
      class="badge {{mapClass.backlogAssignedTo}}">{{backlogAssignedTo}}</span>
    <span [ngClass]="{'current': widget.status === 'Design'}" matTooltip="Design"
      class="badge {{mapClass.designAssignedTo}}">{{designAssignedTo}}</span>
    <span [ngClass]="{'current': widget.status === 'Review'}" matTooltip="Review"
      class="badge {{mapClass.reviewAssignedTo}}">{{reviewAssignedTo}}</span>
    <span [ngClass]="{'current': widget.status === 'Develop'}" matTooltip="Develop"
      class="badge {{mapClass.developAssignedTo}}">{{developAssignedTo}}</span>
    <span [ngClass]="{'current': widget.status === 'Test'}" matTooltip="Test"
      class="badge {{mapClass.testAssignedTo}}">{{testAssignedTo}}</span>
    <span [ngClass]="{'current': widget.status === 'Failed'}" matTooltip="Failed"
      class="badge {{mapClass.failedAssignedTo}}">{{failedAssignedTo}}</span>
    <span [ngClass]="{'current': widget.status === 'Approve'}" matTooltip="Approve"
      class="badge {{mapClass.approveAssignedTo}}">{{approveAssignedTo}}</span>
  </div>
  <span class="text-muted">Last updated by {{lastUpdatedBy}} {{ widget.updatedAt |
    dfnsFormatDistanceToNow }} ago</span>
</div>