import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { JsWidget } from '../_interfaces/Widget';
import { JsTask } from '../_interfaces/Task';
import { CacheService } from '../_services/cache.service';
import { TaskDetailsModalComponent } from '../task-details-modal/task-details-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { isPathMatchingPattern } from '../shared/utils';

@Component({
  selector: 'app-spec-task-block',
  templateUrl: './spec-task-block.component.html',
  styleUrls: ['./spec-task-block.component.scss'],
})
export class SpecTaskBlockComponent implements OnChanges, OnInit, OnDestroy {
  @Input() spec: JsWidget | null = null;

  tasks: JsTask[] = [];
  currentPath: string = '';
  relatedTasks: JsTask[] = [];
  taskSubscription: any;
  statusStyleMap: { [key: string]: string } = {
    Backlog: 'text-bg-info',
    Design: 'text-bg-secondary',
    Review: 'text-bg-review',
    Develop: 'text-bg-primary',
    Blocked: 'text-bg-danger',
    Test: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Mergeable: 'text-bg-primary',
    Passed: 'text-bg-success',
    Approved: 'text-bg-success',
  };

  constructor(private cc: CacheService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.taskSubscription = this.cc.tasks$.subscribe((tasks) => {
      this.tasks = tasks;
      if (this.spec && this.spec.path) {
        this.relatedTasks = this.getTasksOfSpec(this.spec.path);
      } else {
        this.relatedTasks = [];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['spec'] && this.spec && this.spec.path !== this.currentPath) {
      this.currentPath = this.spec.path;
      this.relatedTasks = this.getTasksOfSpec(this.spec.path);
    }
  }

  ngOnDestroy(): void {
    this.taskSubscription?.unsubscribe();
  }

  getTasksOfSpec(specPath: string): JsTask[] {
    return this.tasks.filter((task) => {
      if (task.deletedAt) return false;
      return isPathMatchingPattern(
        specPath,
        task.includePatterns,
        task.excludePatterns
      );
    });
  }

  editTask(task: JsTask) {
    // Open the translation details modal
    const confirmDialog = this.dialog.open(TaskDetailsModalComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: task,
      autoFocus: false,
      disableClose: true,
    });
    return confirmDialog.afterClosed();
  }
}
