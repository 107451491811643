import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  show(
    message = 'Copied to clipboard',
    buttonLabel = 'Close',
    duration: number = 1000,
    event: any = null
  ) {
    this.snackBar.open(message, buttonLabel, {
      duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

    if (event) {
      event.stopPropagation();
    }
  }
}
