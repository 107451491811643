import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DialogManagerService {
  private currentDialogRef: MatDialogRef<any> | null = null;
  constructor(private dialog: MatDialog) {}

  openDialog<T, D = any>(
    component: any,
    {
      data,
      minWidth,
      minHeight,
      panelClass,
    }: {
      data?: D;
      minWidth?: number | string;
      minHeight?: number | string;
      panelClass?: string | string[];
    }
  ) {
    // if (this.currentDialogRef) {
    //   this.currentDialogRef.close();
    // }
    const newPanelClass = ['ri-modal'];
    if (panelClass) {
      if (_.isArray(panelClass)) {
        newPanelClass.push(...panelClass);
      } else {
        newPanelClass.push(panelClass);
      }
    }
    this.currentDialogRef = this.dialog.open(component, {
      data,
      minWidth,
      minHeight,
      maxWidth: '90vw',
      maxHeight: '90vh',
      panelClass: newPanelClass,
    });

    return this.currentDialogRef;
  }
}
