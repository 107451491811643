<main class="select-config p-3 ri-w-600">
    <div class="d-flex justify-content-between pb-2">
        <h3 class="fw-semibold mb-0">Select {{ title }}</h3>
    </div>
    
    <div class="ri-dialog-content">
        <div class="d-flex flex-column">
            <mat-checkbox *ngFor="let opt of options" color="primary" (change)="toggleCheckBox($event, opt)" [checked]="selectedOptions.includes(opt)">{{opt}}</mat-checkbox>
          </div>
    </div>
    
    <div class="ri-dialog-actions pt-3 d-flex justify-content-end">
        <button class="btn btn-outline-secondary btn-sm me-2" (click)="reset()">Reset</button>
        <button class="btn btn-primary btn-sm" (click)="update()">Update Selection</button>
    </div>
</main>