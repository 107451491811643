<div *ngIf="view === 'Daily Plan'">
  <div class="ri-filter-row">
    <mat-form-field [class]="{ edited: search.value }" floatLabel="always">
      <mat-label>Search</mat-label>
      <input matInput #search placeholder="Daily Status, Update/Description etc..." (keyup)="searchInput.emit(search.value)" />
      <button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <app-select-filter
      #columnFilter
      [options]="filterOptions.columns.options"
      [title]="filterOptions.columns.title"
      class="me-1"
      [initialSelection]="filterOptions.columns.initialSelection"
      [resetSelection]="filterOptions.columns.initialSelection"
      [selectedEmitter]="filterOptions.columns.selectedEmitter"></app-select-filter>
    <button class="btn btn-warning float-end btn-sm mx-1" (click)="reset()">Reset</button>
    <button class="btn btn-primary float-end btn-sm" (click)="createNewDaily()">Log Time</button>
  </div>

  <div class="ri-filter-row ri-filter-row-background">
    <app-select-filter
      #statusFilter
      [options]="filterOptions.status.options"
      [title]="filterOptions.status.title"
      class="me-1"
      [selectedEmitter]="filterOptions.status.selectedEmitter"></app-select-filter>
    <app-select-filter
      #userFilter
      [options]="fbService.users"
      [title]="filterOptions.user.title"
      [initialSelection]="filterOptions.user.initialSelection"
      [resetSelection]="filterOptions.user.resetSelection"
      class="me-1"
      [selectedEmitter]="filterOptions.user.selectedEmitter"></app-select-filter>
    <mat-form-field appearance="fill" [class]="{ edited: selectedDateRange !== 'Today' }" class="ri-max-w-200 ri-min-w-200">
      <mat-label>{{ filterOptions.dateRange.title }}</mat-label>
      <mat-select [value]="selectedDateRange" (selectionChange)="filterOptions.dateRange.selectedEmitter.emit($event.value)">
        <mat-option *ngFor="let item of filterOptions.dateRange.options" [value]="item.value">{{ item.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="standup-host bg-white rounded-2 d-flex align-items-center px-1 ms-auto me-1" *ngIf="cc.config.standupHost">
      <img class="img" src="assets/images/assistant.png" style="height: 28px;">
      <div class="ps-2">
        <p class="mb-0 fw-bolder text-muted pt-1 lh-09">{{ cc.config.standupHost || '--' }}</p>
        <span class="ri-fs-10px text-muted mb-0">{{ cc.config.standupHostUpdatedAt | dfnsFormatDistanceToNowStrict }} ago</span>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column">
    <div class="col-wrapper d-flex flex-row flex-grow-1">
      <div class="flex-grow-1">
        <div class="scroll-container">
          <as-split unit="pixel" [gutterSize]="2">
            <!-- STANDUP VIEW -->
            <as-split-area class="main-list">
              <div *ngFor="let user of orderedUsersForStandup; trackBy: trackByUserLabel">
                <div *ngIf="shouldShowUser(user.value)">
                  <div class="fs-6 d-flex justify-content-between fw-bolder text-bg-secondary p-3">
                    <span>
                      <span class="text-start ri-pointer" (click)="showUserTaskHours(user.value)">
                        {{ user.label }}
                      </span>
                      <span class="badge rounded-pill text-bg-light ri-pointer" (click)="showAssignedTasks(user.value)">
                        {{ $any(this.assignedTasks[user.value])?.length || 0 }}
                      </span>
                    </span>
                    <span class="text-start"></span>
                    <span class="text-end">{{ getUserDailysAll(user.value).totalHours }} {{ getUserDailysAll(user.value).totalHours <= 1 ? 'hr' : 'hrs' }}</span>
                  </div>
                  <div class="p-2 text-start" *ngIf="getPlanWidget(fbService.getDisplayName(user.value))">
                    <span
                      (click)="openPlanWidget(getPlanWidget(fbService.getDisplayName(user.value)), $event)"
                      matTooltip="Weekly Plan"
                      class="text-muted plan-text ri-pointer">
                      <pre class="plan">{{ getPlanWidget(fbService.getDisplayName(user.value))?.description ? getPlanWidget(fbService.getDisplayName(user.value))?.description : 'Click here to add weekly plan' }}</pre>
                    </span>
                  </div>
                  <div
                    *ngIf="!getDatesFromUserDateDailysMap(user.value).length"
                    class="card m-2 d-flex justify-content-between flex-column cursor text-center p-2 text-muted">
                    - - -
                  </div>
                  <div *ngIf="getDatesFromUserDateDailysMap(user.value).length">
                    <div *ngFor="let date of getDatesFromUserDateDailysMap(user.value); trackBy: trackByDate">
                      <div class="my-3 mx-2 text-primary d-flex justify-content-between fw-bolder">
                        <span class="text-start">{{ sharedFunc.getFormattedDate(date) }}</span>
                        <span class="text-start"></span>
                        <span class="text-end">{{ getUserDailysByDate(user.value, date).totalHours }} {{ getUserDailysByDate(user.value, date).totalHours <= 1 ? 'hr' : 'hrs' }}</span>
                      </div>

                      <div class="d-flex justify-content-between">
                        <!-- Task Status Counts -->
                        <div class="text-start d-flex flex-column cursor px-3" (click)="showUpdatedTasks(user.value, date)">
                          <app-status-counts
                            class="p-0"
                            matTooltip="From Status"
                            matTooltipPosition="above"
                            *ngIf="userDateTaskFromStatusCountsMap[user.value] && userDateTaskFromStatusCountsMap[user.value][date]"
                            [taskStatusCounts]="userDateTaskFromStatusCountsMap[user.value][date]"></app-status-counts>
                          <app-status-counts
                            class="p-0"
                            matTooltip="To Status"
                            matTooltipPosition="above"
                            *ngIf="userDateTaskToStatusCountsMap[user.value] && userDateTaskToStatusCountsMap[user.value][date]"
                            [taskStatusCounts]="userDateTaskToStatusCountsMap[user.value][date]"></app-status-counts>
                          <app-status-counts
                            class="p-0"
                            matTooltip="Current Status"
                            matTooltipPosition="above"
                            *ngIf="userDateTaskCurrentStatusCountsMap[user.value] && userDateTaskCurrentStatusCountsMap[user.value][date]"
                            [taskStatusCounts]="userDateTaskCurrentStatusCountsMap[user.value][date]"></app-status-counts>
                        </div>

                        <!-- Spec Status Counts -->
                        <div class="text-end d-flex flex-column cursor px-3" (click)="showUpdatedWidgets(user.value, date)">
                          <app-status-counts
                            class="p-0"
                            matTooltip="From Status"
                            matTooltipPosition="above"
                            *ngIf="userDateFromStatusCountsMap[user.value] && userDateFromStatusCountsMap[user.value][date]"
                            [specStatusCounts]="userDateFromStatusCountsMap[user.value][date]"></app-status-counts>
                          <app-status-counts
                            class="p-0"
                            matTooltip="To Status"
                            matTooltipPosition="above"
                            *ngIf="userDateToStatusCountsMap[user.value] && userDateToStatusCountsMap[user.value][date]"
                            [specStatusCounts]="userDateToStatusCountsMap[user.value][date]"></app-status-counts>
                          <app-status-counts
                            class="p-0"
                            matTooltip="Current Status"
                            matTooltipPosition="above"
                            *ngIf="userDateCurrentStatusCountsMap[user.value] && userDateCurrentStatusCountsMap[user.value][date]"
                            [specStatusCounts]="userDateCurrentStatusCountsMap[user.value][date]"></app-status-counts>
                        </div>
                      </div>

                      <!-- Daily plans -->
                       <app-daily-plan-card [dailys]="getUserDailysByDate(user.value, date).dailys" [filterOptions]="filterOptions" (selectEvent)="onSelect($event)"></app-daily-plan-card>
                      
                    </div>
                  </div>
                </div>
              </div>
            </as-split-area>

            <!-- // DETAIL FORM AREA -->
            <as-split-area [size]="475" [maxSize]="650" [minSize]="475" class="position-relative">
              <app-daily-plan-detail [dailyInput]="selectedDaily"></app-daily-plan-detail>
            </as-split-area>
          </as-split>
        </div>
      </div>
    </div>
  </div>
</div>
<app-status-updates *ngIf="view === 'Status Updates'" [who]="selectedWho" [when]="selectedWhen" (onClose)="onCloseStatusUpdates()"></app-status-updates>
