import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-view-translated-text',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './view-translated-text.component.html',
  styleUrl: './view-translated-text.component.scss'
})

export class ViewTranslatedTextComponent {

  title: string;
  texts: { [key: string] : string};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, texts: { [key: string] : string } },
    public dialogRef: MatDialogRef<ViewTranslatedTextComponent>,
  ) {
    this.title = data.title;
    this.texts = data.texts;
  }

  objectKeys(obj: { [key: string] : string}): string[] {
    return Object.keys(obj);
  }
}
