import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'sortNodes',
})
export class SortNodesPipe implements PipeTransform {
  transform(arr: any[], ...args: unknown[]): any[] {
    if (!arr) return [];
    if (Array.isArray(arr) && arr.length === 0) {
      return [];
    }
    return _.sortBy(arr, [
      (o) => {
        if (!o?.value?.type) return 'z' + o.key.toLowerCase();
        switch (o.value.type) {
          case 'bug':
            return 'a' + o.key.toLowerCase();
            break;
          case 'state':
            return 'b' + o.key.toLowerCase();
            break;
          case 'action':
            return 'c' + o.key.toLowerCase();
            break;
          case 'rule':
            return 'd' + o.key.toLowerCase();
            break;
          case 'translate':
            return 'e' + o.key.toLowerCase();
            break;
          case 'track':
            return 'f' + o.key.toLowerCase();
            break;
          default:
            return 'g' + o.key.toLowerCase();
            break;
        }
      },
    ]);
  }
}
