<main class="px-3 pb-3 ri-min-w-1000">
  <!-- list all widgets using flex bootstrap 5 -->
  <div class="sticky-top bg-body p-2 d-flex align-items-center justify-content-between">
    <span class="fs-6">Specs for viewId - <span class="text-primary">{{data.viewId}}</span></span>
    <mat-form-field class="select-control ri-max-w-200 ri-min-w-200" appearance="fill"
      [class]="{ edited: statusFilter.selected !== 'All' }">
      <mat-label>Status</mat-label>
      <mat-select [(value)]="statusFilter.selected" placeholder="Status" (selectionChange)="onStatusChange()">
        <mat-option *ngFor="let item of statusFilter.options" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="ri-min-h-400 mt-2 px-2" *ngIf="widgets?.length;else noWidgets">
    <table class="table specs-list">
      <tbody>
        <tr *ngFor="let widget of widgets">
          <td scope="row" valign="middle">
            <span class="badge round-pill text-capitalize" [class]="typeClassMap[widget.type]">{{widget.type}}</span>
          </td>
          <td class="text-break" width="90%" valign="middle">{{widget.path}}</td>
          <td valign="middle">
            <span class="badge round-pill" [class]="statusClassMap[widget.status]">{{widget.status}}</span>
          </td>
          <td valign="middle">
            <button class="btn btn-sm figma" [class.text-bg-secondary]="widget.type === 'state' && widget.stateFigmaPreviewUrl" (click)="previewFigma(widget)">
              <fa-icon matTooltip="Preview" matTooltipPosition="above" *ngIf="widget.stateFigmaPreviewUrl" class="icon" [icon]="faImage"></fa-icon>
            </button>
          </td>
          <td valign="middle">
            <button class="btn btn-sm figma" [class.text-bg-secondary]="widget.type === 'state' && widget.stateFigmaFrameUrl" (click)="openFigma(widget.stateFigmaFrameUrl)">
              <fa-icon *ngIf="widget.stateFigmaFrameUrl" class="icon" [icon]="faFigma" matTooltip="Open in Figma" matTooltipPosition="above"></fa-icon>
            </button>
          </td>
          <td valign="middle">
            <button class="btn btn-sm open text-bg-info" (click)="openWidget(widget, $event)">
              <fa-icon class="icon" matTooltip="Edit Spec" matTooltipPosition="above" [icon]="linkIcon"></fa-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</main>
<ng-template #noWidgets>
  <div class="text-center text-muted py-5 my-5">No Specs</div>
</ng-template>