import { CommonModule } from '@angular/common';
import { Component, Inject, signal, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { JsParam } from 'src/app/_interfaces/Param';
import { ParamFormComponent } from '../param-form/param-form.component';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CacheService } from 'src/app/_services/cache.service';
import { JsWidget } from 'src/app/_interfaces/Widget';
import * as _ from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { DateFnsModule } from 'ngx-date-fns';
import { ConfirmService } from 'src/app/_services/confirm.service';

@Component({
  selector: 'app-param-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateFnsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatSuffix,
    MatIcon,
    MatCheckbox,
    MatIconButton
  ],
  templateUrl: './param-list.component.html',
  styleUrl: './param-list.component.scss',
})

export class ParamListComponent {

  unSubscribe = new Subject<void>();
  allParamGroups: WritableSignal<JsParam[]> = signal([]);
  paramGroups: WritableSignal<JsParam[]> = signal([]);
  initialWidget: JsWidget | null = null;
  initialParamGroupIds: string[] = [];
  updatedParamGroupIds: string[] = [];
  updatedParamGroups: JsParam[] = [];
  paramGroupSearch: FormControl = new FormControl(null);
  maxParams: number = 25;

  constructor(
    private dialogRef: MatDialogRef<ParamListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { paramGroupIds: string[], maxParams: number, type: 'Event' | 'Log', entityId: string },
    public dialog: MatDialog,
    public cc: CacheService,
    public fbs: FirebaseService,
    public confirmService: ConfirmService
  ) {
    this.initialParamGroupIds = [...this.data.paramGroupIds];
    this.updatedParamGroupIds = [...this.data.paramGroupIds];
    this.maxParams = this.data.maxParams;
    this.cc.params$.pipe(takeUntil(this.unSubscribe)).subscribe((params) => {
      const paramGroups = params.filter(param => !param.deletedAt && param.type === this.data.type);

      this.allParamGroups.set(this.sortParamGroup(paramGroups));
      this.updatedParamGroups = paramGroups.filter(param => this.updatedParamGroupIds.includes(param.id));
      this.onFilterChange();
    });

    dialogRef.backdropClick().pipe(takeUntil(this.unSubscribe)).subscribe(async () => {
      if (this.hasChanges()) {
        const confirmed = await this.confirmService.confirm('Alert', 'You have unsaved changes. Do you really want to discard them?', 'Discard', 'Cancel');
        if (!confirmed) {
          return;
        }
        this.dialogRef.close();
      } else {
        this.dialogRef.close(); // Close immediately if no unsaved changes
      }
    });
  }

  ngOnInit(): void {
    if (this.data.type === 'Event') {
      this.cc.getUpdatedEvent$.pipe(takeUntil(this.unSubscribe)).subscribe(event => {
        if (event.id === this.data.entityId) {
          this.initialParamGroupIds = [...event.paramsGroupIds];
          this.updatedParamGroupIds = [...event.paramsGroupIds];
          this.updatedParamGroups = this.allParamGroups().filter(param => this.updatedParamGroupIds.includes(param.id));
        }
      });
    } else {
      this.cc.getUpdatedLog$.pipe(takeUntil(this.unSubscribe)).subscribe(log => {
        if (log.id === this.data.entityId) {
          this.initialParamGroupIds = [...log.paramsGroupIds];
          this.updatedParamGroupIds = [...log.paramsGroupIds];
          this.updatedParamGroups = this.allParamGroups().filter(param => this.updatedParamGroupIds.includes(param.id));
        }
      });
    }
  }

  openParamGroup(e: any, mode: 'new' | 'edit', group?: JsParam) {
    const confirmDialog = this.dialog.open(ParamFormComponent, {
      panelClass: 'ri-modal',
      width: '1000px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      disableClose: true,
      data: {
        mode: mode,
        param: group,
        type: this.data.type
      },
    });

    return confirmDialog.afterClosed().toPromise();
  }

  sortParamGroup(paramGroup: JsParam[]): JsParam[] {
    // Sort by Alphabetic order
    return paramGroup.sort((a: JsParam, b: JsParam) => {

      let valueA = a['name'];
      let valueB = b['name'];

      // Handle string comparison
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    });
  }

  onFilterChange() {
    let filteredParamGroups = _.cloneDeep(this.allParamGroups());

    if (this.paramGroupSearch.value) {
      filteredParamGroups = filteredParamGroups.filter((pg: JsParam) => {
        return (
          pg.name?.toLowerCase().includes(this.paramGroupSearch.value) ||
          pg.note?.toLowerCase().includes(this.paramGroupSearch.value) ||
          pg.params.some(param => param.key.toLowerCase().includes(this.paramGroupSearch.value)) ||
          pg.params.some(param => param.type.toLowerCase().includes(this.paramGroupSearch.value))
        );
      });
    }

    this.paramGroups.set(filteredParamGroups);
  }

  checkPgDisabled(group: JsParam): boolean {
    const disable = group.params.some(gp =>
      this.updatedParamGroups.some(pg =>
        pg.params.some(params => params.key === gp.key && group.id !== pg.id)
      )
    );
    const totalParams = this.updatedParamGroups.reduce((sum, obj) => sum + obj.params.length, 0);
    return disable || (!this.updatedParamGroupIds.includes(group.id) && (totalParams === this.maxParams || (totalParams + group.params.length > this.maxParams)));
  }

  toggleParamGroup(e: any, paramGroup: JsParam) {
    const isChecked = e.checked;
    if (isChecked && !this.updatedParamGroupIds.includes(paramGroup.id)) {
      this.updatedParamGroupIds.push(paramGroup.id);
      this.updatedParamGroups.push(paramGroup);
    } else if (!isChecked && this.updatedParamGroupIds.includes(paramGroup.id)) {
      const index = this.updatedParamGroupIds.indexOf(paramGroup.id);
      this.updatedParamGroupIds.splice(index, 1);
      const pgIndex = this.updatedParamGroups.findIndex(param => param.id === paramGroup.id);
      this.updatedParamGroups.splice(pgIndex, 1);
    } else {
      return;
    }
  }

  async updateSelectedParam() {
    // this.updatedParamGroups = this.sortParamGroup(this.updatedParamGroups);
    this.dialogRef.close(this.updatedParamGroups);
  }

  reset() {
    this.updatedParamGroupIds = _.cloneDeep(this.initialParamGroupIds);
  }

  hasChanges() {
    return !_.isEqual(
      _.sortBy(this.initialParamGroupIds),
      _.sortBy(this.updatedParamGroupIds)
    );
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
