<main class="translation-form p-3 ri-w-800" [ngClass]="{ 'pe-none': translationToEdit.deletedAt }">
  <div class="d-flex justify-content-between mb-3">
    <h2 class="px-2 fw-semibold mb-0">Translation {{ mode === 'add' ? 'Create' : 'Update' }}</h2>
    <div class="d-flex" *ngIf="mode === 'edit'">
      <button *ngIf="this.mode === 'edit'"
        class="btn btn-comment btn-sm btn-success d-flex align-items-center text-nowrap me-2"
        (click)="sharedFunc.getComments('translation', initialTranslation)" matTooltip="Comment">
        <mat-icon class="pe-0">question_answer</mat-icon>
        <span class="ps-2" *ngIf="cc.idToTranslationMap[initialTranslation.id]?.latestComment">{{
          cc.idToTranslationMap[initialTranslation.id].latestComment?.updatedAt | dfnsFormatDistanceToNowStrict }}
          ago</span>
      </button>
      <button matTooltip="Get Activity" *ngIf="this.mode === 'edit'"
        class="btn btn-info btn-sm btn-activity d-flex align-items-center me-2" (click)="getActivity()"><mat-icon
          class="text-light">restore</mat-icon></button>
    </div>
  </div>

  <div class="ri-dialog-content">
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="fill" class="w-100"
          [class]="{ edited: initialTranslation.viewId !== translationToEdit.viewId }">
          <mat-label>viewId (Required)</mat-label>
          <input #viewId type="text" placeholder="viewId" matInput [(ngModel)]="translationToEdit.viewId"
            (ngModelChange)="setTranslationId()" (keyup)="setFilteredValues('viewIds', viewId.value, 'filteredViewIds')"
            [disabled]="!isTranslationIdEditable() && !isTranslationInvalid" [matAutocomplete]="autoscreen" />
          <mat-autocomplete #autoscreen="matAutocomplete">
            <mat-option *ngFor="let option of filteredViewIds" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100" appearance="fill"
          [class]="{ edited: initialTranslation.status != translationToEdit.status }">
          <mat-label>Status</mat-label>
          <mat-select [(value)]="translationToEdit.status" (selectionChange)="onStatusChange($event.value)">
            <mat-option *ngFor="let item of statusOptions" [value]="item">{{ item }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <mat-form-field appearance="fill" class="w-100 pt-1"
      [class]="{ edited: initialTranslation.translationName !== translationToEdit.translationName }">
      <mat-label>Translation Name (Required)</mat-label>
      <input #translationName type="text" placeholder="Label" matInput [(ngModel)]="translationToEdit.translationName"
        (ngModelChange)="setTranslationId()"
        (keyup)="setFilteredValues('translationNames', translationName.value, 'filteredTranslationNames')"
        [disabled]="!isTranslationIdEditable() && !isTranslationInvalid" [matAutocomplete]="autolabel" />
      <mat-autocomplete #autolabel="matAutocomplete">
        <mat-option *ngFor="let option of filteredTranslationNames" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!-- TRANSLATION KEY -->
    <div>
      <span *ngIf="!errorMessage" class="d-inline-block my-3 text-primary ri-pointer"
        [cdkCopyToClipboard]="translationToEdit.translationId || 'NO KEY'" (click)="onCopy()">
        {{ translationToEdit.translationId || 'NO KEY' }}
      </span>
      <button class="btn btn-dark btn-sm float-end my-2" (click)="editTextView = true"
        *ngIf="isEditable() && !editTextView && variableView === 'hidden' && !translationToEdit.deletedAt">
        Edit Text
      </button>
      <button class="btn btn-dark btn-sm float-end my-2" (click)="editTextView = false"
        *ngIf="editTextView && !hasTextChanges()">Text View</button>
    </div>

    <!-- Note -->
    <mat-form-field appearance="fill" class="w-100 mt-1"
      [class]="{ edited: initialTranslation.note !== translationToEdit.note }">
      <mat-label>Notes (Bullet Points)</mat-label>
      <textarea matInput [(ngModel)]="translationToEdit.note" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2" cdkAutosizeMaxRows="9"></textarea>
    </mat-form-field>

    <!-- Design Text -->
    <mat-form-field appearance="fill" class="w-100 mt-1"
      [class]="{ edited: initialTranslation.designText !== translationToEdit.designText }">
      <mat-label>Design/Figma Text (eg: 'Save', 'Save|Send|Done')</mat-label>
      <input type="text" placeholder="Text shown in Figma (eg: 'Save', 'Save|Send|Done')" matInput
        [(ngModel)]="translationToEdit.designText" />
    </mat-form-field>

    <!-- ERROR MESSAGE -->
    <div *ngIf="errorMessage" class="text-center my-3 text-danger">{{ errorMessage }}</div>

    <!-- SHOW TRANSLATION TEXTS TO EDIT -->
    <div *ngIf="editTextView">
      <mat-form-field *ngIf="initialTranslation.text" appearance="fill" class="w-100 pt-1"
        [class]="{ edited: initialTranslation.text['en'] !== translationToEdit.text['en'] }">
        <mat-label>en (Required)</mat-label>
        <textarea matInput [(ngModel)]="translationToEdit.text['en']" cdkTextareaAutosize
          #autosize="cdkTextareaAutosize" [disabled]="!isEditable()" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="30"
          (ngModelChange)="shuffleGibberish()"></textarea>
      </mat-form-field>

      <mat-form-field *ngFor="let langCode of supportedLanguageCodesExceptEn" appearance="fill" class="w-100 pt-1"
        [class]="{ edited: initialTranslation.text[langCode] !== translationToEdit.text[langCode] }">
        <mat-label>{{ langCode }}</mat-label>
        <textarea matInput [(ngModel)]="translationToEdit.text[langCode]" cdkTextareaAutosize
          #autosize="cdkTextareaAutosize" [disabled]="!isEditable() || langCode === 'test'" cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="30"></textarea>
      </mat-form-field>

      <button class="btn btn-dark btn-sm me-2 mt-3" (click)="getAndSetTranslations()"
        *ngIf="translationToEdit.text['en']"
        [disabled]="!isEditable() || !translationToEdit.text['en'] || translationInProgess || !shouldReTranslate()">
        <span *ngIf="!translationInProgess">Translate Now</span>
        <span *ngIf="translationInProgess">Translating now...</span>
      </button>
    </div>

    <!-- VIEW TRANLATION TEXTS WITH HIGHLIGHTING -->
    <div *ngIf="!editTextView">
      <div *ngFor="let langCode of supportedLanguageCodes" class="py-2">
        <span class="lang-code-label">{{ langCode }}</span>
        <pre class="ms-2" [innerHTML]="getHighlightedText(langCode)"></pre>
      </div>
    </div>

    <!-- SHOW VARIABLES TO EDIT -->
    <div *ngIf="variableView !== 'hidden'" class="my-3">
      <h3>Variable Details</h3>
      <mat-form-field appearance="fill" class="w-100" [class]="{ edited: currentVariable !== curretVariableBackup }">
        <mat-label>Variable Name (Required)</mat-label>
        <input #variableName type="text" placeholder="eg: amount" matInput [(ngModel)]="currentVariable"
          [disabled]="variableView !== 'add'" (keyup)="validateVariableName(variableName.value)" />
      </mat-form-field>
      <div *ngIf="isCurrentVariableValid">
        <mat-form-field *ngFor="let langCode of supportedLanguageCodes" appearance="fill" class="w-100 pt-1"
          [class]="{ edited: currentVariableReplacements[langCode] !== currentVariableReplacementsBackup[langCode] }">
          <mat-label>{{ langCode }}</mat-label>
          <input type="text" placeholder="Text to be replaced eg: $4.99" matInput
            [disabled]="!isEditable() || langCode === 'test'" [(ngModel)]="currentVariableReplacements[langCode]"
            (ngModelChange)="langCode === 'en' && getGibberishVariable(currentVariableReplacements)" />
        </mat-form-field>
      </div>
      <div class="my-3 text-end">
        <!-- Delete Variable button -->
        <button class="btn btn-danger btn-sm me-2 float-start" (click)="deleteVariable(currentVariable)"
          *ngIf="isEditable()">
          <span *ngIf="!deletionInProgress">Delete Variable</span>
        </button>
        <button class="btn btn-secondary btn-sm me-2" (click)="variableView = 'hidden'">Cancel</button>
        <button class="btn btn-primary btn-sm" (click)="validateAndUpdateVariable()" *ngIf="isEditable()"
          [disabled]="!isCurrentVariableValid || !hasAllVariablesAdded() || !hasVariableChanges()">
          {{ this.mode === 'add' ? 'Add Variable' : 'Update Variable' }}
        </button>
      </div>
    </div>

    <!-- SHOW CLICKABLE VARIABLE BADGES -->
    <div class="mt-3"
      *ngIf="variableView === 'hidden' && (translationToEdit?.variables | keys).length && !editTextView">
      Variables:
      <span *ngFor="let variable of translationToEdit.variables | keys"
        class="badge rounded-pill bg-secondary me-2 py-2 px-4 ri-pointer" (click)="editVariable(variable)"
        [matTooltip]="translationToEdit.variables[variable]['en'] || ''" matTooltipPosition="above">
        {{ variable }}
      </span>
    </div>

    <div *ngIf="translationToEdit.deletedAt" class="text-center my-3 text-danger">This translation has been deleted
    </div>
  </div>


  <!-- MAIN BOTTOM ACTON -->
  <div class="ri-dialog-footer">

    <div class="d-flex justify-content-between w-100 pt-3"
      *ngIf="!translationToEdit.deletedAt && variableView === 'hidden'">
      <div class="d-flex">
        <button class="btn btn-danger btn-sm ri-btn-delete" (click)="deleteHandler()" *ngIf="this.mode === 'edit'"
          [disabled]="!isDeletable() || deletionInProgress" matTooltip="Delete"><i class="bi bi-trash"></i></button>

        <button class="btn btn-primary btn-sm ms-2" (click)="addVariable()"
          *ngIf="!editTextView && isEditable() && variableView === 'hidden'">
          Add Variable
        </button>
      </div>
      <div>
        <button class="btn btn-secondary btn-sm me-2" (click)="resetHandler()" [disabled]="!hasChanges()">Reset</button>
        <button class="btn btn-primary btn-sm" (click)="updateHandler()"
          [disabled]="updationInProgress || !hasChanges() || translationInProgess || !isValid()">Save</button>
      </div>
    </div>
    <div class="mt-4 pt-2 mb-3">
      <span>Specs:&nbsp;<app-spec-status-counts-of-view
          [viewId]="translationToEdit.viewId"></app-spec-status-counts-of-view></span>
    </div>
  </div>

</main>