import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { JsTask } from '../_interfaces/Task';
import { CacheService } from '../_services/cache.service';
import { ListTranslationsOfTaskComponent } from '../list-translations-of-task/list-translations-of-task.component';
import { DialogManagerService } from '../_services/dialog-manager.service';

@Component({
  selector: 'app-translation-status-counts-of-task',
  templateUrl: './translation-status-counts-of-task.component.html',
  styleUrls: ['./translation-status-counts-of-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationStatusCountsOfTaskComponent implements OnChanges {
  @Input() task: JsTask | undefined = undefined;
  isEmpty: boolean = true;
  Draft: number = 0;
  Paused: number = 0;
  Reviewed: number = 0;
  Translated: number = 0;
  Ready = 0;
  Implemented: number = 0;
  Failed: number = 0;
  Passed: number = 0;
  Deprecated: number = 0;
  Removed: number = 0;
  totalTreanslations: number = 0;
  incompleteTranslations: number = 0;
  constructor(private cc: CacheService, private _dialog: DialogManagerService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['task']) {
      this.update();
    }
  }

  update() {
    this.isEmpty = true;
    this.totalTreanslations = 0;
    this.incompleteTranslations = 0;
    this.Draft = 0;
    this.Paused = 0;
    this.Reviewed = 0;
    this.Translated = 0;
    this.Ready = 0;
    this.Implemented = 0;
    this.Failed = 0;
    this.Passed = 0;
    this.Deprecated = 0;
    this.Removed = 0;

    const uniqueViewIds = this.cc.getUniqueViewIdsOfTask(this.task);

    // Loop through the viewIds and count the number of translations based on their status
    uniqueViewIds.forEach(viewId => {
      if (viewId) {
        if (this.cc.viewIdsToTranslationIdsGroupedByStatus[viewId]) {
          Object.keys(this.cc.viewIdsToTranslationIdsGroupedByStatus[viewId]).forEach((status: string) => {
            // @ts-ignore
            this[status] =
              // @ts-ignore
              this[status] + this.cc.viewIdsToTranslationIdsGroupedByStatus[viewId][status].length;
          });
          // Calculate the total number of translations
          this.totalTreanslations =
            this.Draft +
            this.Paused +
            this.Reviewed +
            this.Ready +
            this.Translated +
            this.Implemented +
            this.Failed +
            this.Passed +
            this.Deprecated +
            this.Removed;
          if (this.totalTreanslations) {
            this.isEmpty = false;
          }
        }
      }
    });
  }

  showTranslationsListOfTask(task: JsTask | undefined, e: any) {
    if (!task) return;
    this._dialog.openDialog(ListTranslationsOfTaskComponent, {
      data: {
        task
      }
    });

    e.stopPropagation();
  }
}
