import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FirebaseService } from '../../../_services/firebase.service';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { WatchersSelectComponent } from '../watchers-select/watchers-select.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-watchers-preview',
  templateUrl: './watchers-preview.component.html',
  styleUrls: ['./watchers-preview.component.scss'],
  imports: [
    CommonModule,
    MatTooltip,
    FaIconComponent
  ],
  standalone: true,
})
export class WatchersPreviewComponent implements OnInit, OnChanges {
  @Input() initialWatchers: string[] = [];
  @Input() size: 'small' | 'normal' = 'normal';
  @Output() onUpdateWatchers = new EventEmitter<string[]>();
  private watchersDialog: any;
  faBell = faBell;
  watchers: string[] = [];
  watchersForDisplay: string = '';
  currentUserId: string = '';
  isCurrentUserWatching = false;

  constructor(private fbs: FirebaseService, public dialog: MatDialog) {
    this.currentUserId = this.fbs.getCurrentUserId();
  }

  ngOnInit(): void {
    this.watchers = [...(this.initialWatchers || [])];
    this.isCurrentUserWatching = this.watchers.includes(this.currentUserId);
  }

  ngOnChanges() {
    this.watchers = [...(this.initialWatchers || [])];
    this.isCurrentUserWatching = this.watchers.includes(this.currentUserId);
  }

  showWatchersDialog() {
    this.watchersDialog = this.dialog.open(WatchersSelectComponent, {
      width: '410px',
      data: this.watchers,
    });

    this.watchersDialog.afterClosed().subscribe((result: string[]) => {
      if (result) {
        this.watchers = result;
        this.isCurrentUserWatching = this.watchers.includes(this.currentUserId);
        this.onUpdateWatchers.emit(result);
      }
    });
  }
}
