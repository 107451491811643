import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CacheService } from '../_services/cache.service';
import { FirebaseService } from '../_services/firebase.service';
import { JsTask } from '../_interfaces/Task';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { TaskDetailsModalComponent } from '../task-details-modal/task-details-modal.component';
import { taskStatusList } from '../shared/status';

@Component({
  selector: 'app-task-list-modal',
  templateUrl: './task-list-modal.component.html',
  styleUrl: './task-list-modal.component.scss'
})
export class TaskListModalComponent {
  statusStyleMap: { [key: string]: string } = {
    Backlog: 'text-bg-info',
    Design: 'text-bg-secondary',
    Review: 'text-bg-review',
    Develop: 'text-bg-primary',
    Blocked: 'text-bg-danger',
    Test: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Mergeable: 'text-bg-primary',
    Passed: 'text-bg-success',
    Approved: 'text-bg-success'
  };
  statusFilter = {
    options: ['All', ...taskStatusList],
    selected: 'All'
  }
  taskList: JsTask[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      tasks: JsTask[];
    },
    public cc: CacheService,
    public fbs: FirebaseService,
    private _dialog: DialogManagerService
  ) {
    this.taskList = data.tasks;
  }

  openTask(task: JsTask): void {
    this._dialog.openDialog(TaskDetailsModalComponent, {
      data: task,
      disableClose: true,
    });
  }

  onStatusChange() {
    const allTasks = this.data.tasks;
    if(this.statusFilter.selected == 'All') {
      this.taskList = allTasks;
    } else {
      this.taskList = allTasks.filter(task => task.status === this.statusFilter.selected);
    }
  }
}
