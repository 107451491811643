import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { JsWidget, assignedToKey } from '../_interfaces/Widget';
import { JsUser } from '../_interfaces/User';
import {
  faMessage,
  faUserPen,
  faClock,
  faChartSimple,
  faCircleInfo,
  faRetweet,
  faRotateLeft,
  faLanguage,
  faArrowUpRightFromSquare,
  faCopy,
  faClockRotateLeft
} from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FirebaseService } from '../_services/firebase.service';
import { FirebaseOptimisticService } from '../_services/firebase-optimistic.service';
import { ImageViewerService } from '../_services/image-viewer.service';
import { UserSessionStorageService } from '../_services/user-session-storage.service';
import { Router } from '@angular/router';
import { CacheService } from '../_services/cache.service';
import { faFigma } from '@fortawesome/free-brands-svg-icons';
import { SvgViewerModalService } from '../_services/svg-viewer-modal.service';
import { SnackbarService } from '../_services/snackbar.service';
import { SelectAutocompleteComponent } from '../shared/components/select-autocomplete/select-autocomplete.component';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { PreviewHistoryComponent } from '../preview-history/preview-history.component';
import { ActivityService } from '../_services/activity.service';
import { SharedFunctionService } from '../_services/shared-function.service';
import { WidgetDetailEditComponent } from '../widget-detail-edit/widget-detail-edit.component';
import { ConfirmService } from '../_services/confirm.service';
import { Subject, takeUntil } from 'rxjs';
import { serverTimestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-widget-detail',
  templateUrl: './widget-detail.component.html',
  styleUrls: ['./widget-detail.component.scss']
})
export class WidgetDetailComponent implements OnInit, OnDestroy {
  @Input() widget: JsWidget | null = null;
  @Input() mode: 'edit' | 'restore' | 'activity' | 'production' = 'edit';
  @Input() hiddenFields: string[] = ['path', 'navigation'];
  @Output() onRestore = new EventEmitter<JsWidget>();
  @Output() showWidget = new EventEmitter<JsWidget>();
  @ViewChild('actionResultStateId')
  actionResultStateId: SelectAutocompleteComponent | null = null;
  actionResultStateWidget: JsWidget | null = null;
  faClockRotateLeft = faClockRotateLeft;
  faMessage = faMessage;
  faUserPen = faUserPen;
  faClock = faClock;
  faChartSimple = faChartSimple;
  faCircleInfo = faCircleInfo;
  faRetweet = faRetweet;
  faFigma = faFigma;
  faRotateLeft = faRotateLeft;
  faLanguage = faLanguage;
  faLink = faArrowUpRightFromSquare;
  faCopy = faCopy;
  faCheck = faCheck;
  faXmark = faXmark;
  private ussSubscription: any;
  private currentRegressionSubscription: any;
  private widgetSubscription: any;
  public initialIndex = 0;
  public figmaFileId = 'XCnK9gXiu9ZHjlXuJ7WANU';
  public localAssignedToKey: assignedToKey = 'backlogAssignedTo';
  public savedAssignedToKey: assignedToKey = 'backlogAssignedTo';
  public errorMessage = '';
  public currentRegression = '';
  public regressionResult = ''; //PASS, FAIL, '';
  public widgetToEdit: JsWidget | null = null;
  public filteredViewIds: string[] = [];
  public activeStateWidgets: JsWidget[] = [];
  public statusOptions = [
    { value: 'Backlog', label: 'Backlog' },
    { value: 'Design', label: 'Design' },
    { value: 'Review', label: 'Review' },
    { value: 'Correction', label: 'Correction' },
    { value: 'Develop', label: 'Develop' },
    { value: 'Test', label: 'Test' },
    { value: 'Failed', label: 'Failed' },
    { value: 'Approve', label: 'Approve' }
  ];
  public subStatusOptions = [
    { value: 'To Do', label: 'To Do' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Blocked', label: 'Blocked' },
    { value: 'Done', label: 'Done' }
  ];

  public pathTypes = [
    { value: 'bug', label: 'Bug' },
    { value: 'state', label: 'State' },
    { value: 'action', label: 'Action' },
    { value: 'rule', label: 'Rule' },
    { value: 'translate', label: 'Translate' },
    { value: 'track', label: 'Track' },
    { value: 'other', label: 'Other' }
  ];

  public actionTypes = [
    { value: 'Tap', label: 'Tap' },
    { value: 'Tap Device Back Button', label: 'Tap Device Back Button' },
    { value: 'Swipe Up', label: 'Swipe Up' },
    { value: 'Swipe Down', label: 'Swipe Down' },
    { value: 'Swipe Left', label: 'Swipe Left' },
    { value: 'Swipe Right', label: 'Swipe Right' },
    { value: 'Scroll Up', label: 'Scroll Up' },
    { value: 'Scroll Down', label: 'Scroll Down' },
    { value: 'Scroll Up or Down', label: 'Scroll Up or Down' },
    { value: 'Keyboard Input', label: 'Keyboard Input' },
    { value: 'Long Press', label: 'Long Press' },
    { value: 'Double Tap', label: 'Double Tap' },
    { value: 'Toggle On', label: 'Toggle On' },
    { value: 'Toggle Off', label: 'Toggle Off' },
    { value: 'Other', label: 'Other' }
  ];
  unSubscribe = new Subject<void>();
  
  constructor(
    public dialog: MatDialog,
    public fbService: FirebaseService,
    private fbo: FirebaseOptimisticService,
    private SvgViewerModalService: SvgViewerModalService,
    public uss: UserSessionStorageService,
    private router: Router,
    public cc: CacheService,
    public snackbar: SnackbarService,
    private as: ActivityService,
    public sharedFunc: SharedFunctionService,
    @Optional() public dialogRef: MatDialogRef<WidgetDetailEditComponent>,
    @Optional() private confirmService: ConfirmService,
  ) {
    dialogRef?.backdropClick().pipe(takeUntil(this.unSubscribe)).subscribe(async () => {
      if (this.hasChanges()) {
        const confirmed = await this.confirmService.confirm('Alert', 'You have unsaved changes. Do you really want to discard them?', 'Discard', 'Cancel');
        if (!confirmed) {
          return;
        }
        this.dialogRef.close();
      } else {
        this.dialogRef.close(); // Close immediately if no unsaved changes
      }
    });
  }

  ngOnInit(): void {
    this.ussSubscription = this.uss.currentUserSessionStorage$.subscribe(uss => {
      if (uss.widgetDetailView === 'info') {
        this.initialIndex = 0;
      } else if (uss.widgetDetailView === 'comment') {
        this.initialIndex = 1;
      } else if (uss.widgetDetailView === 'activity') {
        this.initialIndex = 2;
      } else if (uss.widgetDetailView === 'timelog') {
        this.initialIndex = 3;
      } else {
        this.initialIndex = 0;
      }
    });
    this.currentRegressionSubscription = this.cc.currentRegression$.subscribe(regression => {
      this.currentRegression = regression;
      this.initRegressionResult();
    });
    this.filteredViewIds = this.cc.validViewIds;

    this.widgetSubscription = this.cc.widgets$.subscribe(res => {
      // Get the active widgets for the viewId
      this.activeStateWidgets = res.filter(widget => !widget.deletedAt && widget.type === 'state');
    });
  }

  getActivity() {
    this.as.getActivity('widget',this.widgetToEdit);
  }

  ngOnDestroy() {
    if (this.ussSubscription) {
      this.ussSubscription.unsubscribe();
    }
    if (this.currentRegressionSubscription) {
      this.currentRegressionSubscription.unsubscribe();
    }
    if (this.widgetSubscription) {
      this.widgetSubscription.unsubscribe();
    }
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }

  onIndexChange(index: number) {
    if (index === 0) {
      this.initialIndex = 0;
      this.uss.setUserSessionStorageItem('widgetDetailView', 'info', false);
    } else if (index === 1) {
      this.initialIndex = 1;
      this.uss.setUserSessionStorageItem('widgetDetailView', 'comment', false);
    } else if (index === 2) {
      this.initialIndex = 2;
      this.uss.setUserSessionStorageItem('widgetDetailView', 'activity', false);
    } else if (index === 3) {
      this.initialIndex = 3;
      this.uss.setUserSessionStorageItem('widgetDetailView', 'timelog', false);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentWidgetStateBackup = _.cloneDeep(this.widgetToEdit);
    if (_.isEqual(changes['widget']?.currentValue, changes['widget']?.previousValue)) {
      return;
    }
    this.widgetToEdit = _.cloneDeep(this.widget);
    // To avoid unnecessary field reverts when firestore updates get fetched
    if (
      _.isEqual(changes['widget']?.currentValue?.id, changes['widget']?.previousValue?.id) &&
      _.isEqual(changes['widget']?.currentValue?.stateFigmaPreviewValidated, changes['widget']?.previousValue?.stateFigmaPreviewValidated)
    ) {
      this.widgetToEdit = currentWidgetStateBackup;
      return;
    }
    this.filteredViewIds = this.cc.validViewIds;
    this.localAssignedToKey = (this.widgetToEdit?.status.toLowerCase() + 'AssignedTo') as assignedToKey;
    this.savedAssignedToKey = this.localAssignedToKey;
    this.initRegressionResult();
    // Prefetch figma preview
    setTimeout(() => {
      if (this.widgetToEdit?.stateFigmaPreviewUrl) {
        const img = new Image();
        img.src = this.widgetToEdit.stateFigmaPreviewUrl as string;
        img.onload = () => {
          console.log('Figma prefetch done!');
        };
      }
    });

    // Set actionResultStateWidget if actionResultStateId is set
    if (this.widgetToEdit?.actionResultStateId) {
      this.setActionResultStateWidget(this.widgetToEdit.actionResultStateId);
    } else {
      this.actionResultStateWidget = null;
    }
  }

  // previewFigma() {
  //   const dialogRef = this.dialog.open(ModalComponent, {
  //     data:
  //       'https://www.figma.com/embed?embed_host=astra&url=' +
  //       'https://www.figma.com/file/XCnK9gXiu9ZHjlXuJ7WANU/Mevolve-app---Master?node-id=' +
  //       this.widgetToEdit?.stateFigmaFrameUrl,
  //   });

  //   dialogRef.afterClosed().subscribe(async (result) => {
  //     if (!result?.payload) return;
  //   });
  // }

  hasChanges() {
    // Comapre except updatedAt and cloudUpdatedAt
    const widget = _.cloneDeep(this.widgetToEdit);
    const initialWidget = _.cloneDeep(this.widget);
    if (widget && initialWidget) {
      widget.updatedAt = initialWidget.updatedAt;
      widget.cloudUpdatedAt = initialWidget.cloudUpdatedAt;
    }
    return !_.isEqual(widget, initialWidget);
  }

  openFigma() {
    if (!this.widgetToEdit?.stateFigmaFrameUrl) return;
    window.open(this.widgetToEdit?.stateFigmaFrameUrl, '_blank');
  }

  async resetFigmaPreview() {
    if (!this.widget) return;
    this.widget.stateFigmaPreviewUrl = '';
    this.widget.stateFigmaPreviewAt = null;
    await this.fbo.updateItemsOptimistic<JsWidget>([this.widget], 'widgets');
  }

  previewFigma(widget: JsWidget | null) {
    if (!widget?.stateFigmaPreviewUrl) return;
    const isMinimal = widget.path.startsWith('taskDiagram');
    this.SvgViewerModalService.show(widget!, isMinimal).subscribe(async (res: any) => {
      if (res && res.action === 'delete' && this.widgetToEdit) {
        await this.resetFigmaPreview();
      }
    });
  }

  async updateWidget() {
    if (!this.widgetToEdit) return;
    this.widgetToEdit.updatedAt = new Date();
    this.widgetToEdit.uid = this.fbService.getCurrentUserId();
    if (this.widgetToEdit.stateFigmaFrameUrl !== this.widget?.stateFigmaFrameUrl) {
      (this.widgetToEdit.stateFigmaPreviewUrl = ''), (this.widgetToEdit.stateFigmaPreviewAt = null);
    }
    const widget = this.widgetToEdit;
    this.widget = this.widgetToEdit;
    await this.fbo.updateItemsOptimistic<JsWidget>([widget], 'widgets');
  }

  resetWidget() {
    if (this.actionResultStateId) {
      this.actionResultStateId.reset();
    }
    this.widgetToEdit = _.cloneDeep(this.widget);
    this.localAssignedToKey = this.savedAssignedToKey;
    this.initRegressionResult();
  }

  async updateWidgetWatchers(watchers: string[]) {
    if (!this.widget) return;
    const widget = _.cloneDeep(this.widget);
    widget.watchers = watchers;
    this.widget.watchers = watchers;
    if(!this.widgetToEdit) return;
    this.widgetToEdit.watchers = watchers;
    await this.fbo.updateItemsOptimistic<JsWidget>([widget], 'widgets');
  }

  async restoreWidget() {
    if (!this.widgetToEdit) return;
    const count = await this.fbService.getWidgetsCount(this.widgetToEdit.path).then(res => res?.size);
    if (count === 0) {
      this.widgetToEdit.deletedAt = null;
      this.widgetToEdit.updatedAt = new Date();
      this.fbo.updateItemsOptimistic<JsWidget>([this.widgetToEdit], 'widgets');
      this.onRestore.emit(this.widgetToEdit);
    } else {
      alert('A path with the same name already exists!!');
    }
  }

  onStatusChange($event: any) {
    // this.widgetToEdit!.subStatus = 'To Do';
    // this.localAssignedToKey = ($event.value.toLowerCase() + 'AssignedTo') as assignedToKey;
    // if (this.localAssignedToKey === 'failedAssignedTo' && !this.widget?.failedAssignedTo && this.widget?.developAssignedTo) {
    //   this.widgetToEdit!.failedAssignedTo = this.widget.developAssignedTo;
    // } else {
    //   this.widgetToEdit!.failedAssignedTo = this.widget?.failedAssignedTo || null;
    // }
  }

  openState(path: string) {
    this.uss.setUserSessionStorageItem('widgetPath', path);
    this.router.navigate(['path']);
  }

  canChangeStatus() {
    const isAssignedUser = this.widget?.[this.savedAssignedToKey] === this.fbService.currentUser.value;
    const isPO = this.fbService.currentUser.roles?.includes('PO');
    return isAssignedUser || isPO;
  }

  getLastSegment(path: string) {
    return path.split('_').pop();
  }

  // Is array off strings same as array of strings irrespective of index
  isSameArray(a: string[], b: string[]) {
    if (!a || !b) return false;
    return a.length === b.length && a.every(val => b.includes(val)) && b.every(val => a.includes(val));
  }

  // Initilase regressionResult from widget
  initRegressionResult() {
    if (!this.widget) return;
    const { regressions } = this.widget;
    const currentRegression = this.currentRegression;
    const PASS = currentRegression + '_PASS';
    const FAIL = currentRegression + '_FAIL';
    if (regressions.includes(PASS)) {
      this.regressionResult = 'PASS';
    } else if (regressions.includes(FAIL)) {
      this.regressionResult = 'FAIL';
    } else {
      this.regressionResult = '';
    }
  }

  // Update regressionResult in widget
  onRegressionChange($event: any) {
    this.widgetToEdit!.regressions = this.widget!.regressions.filter(
      r => !r.startsWith(this.currentRegression) // Remove all regressions of current regression
    );
    if ($event.value === 'PASS' || $event.value === 'FAIL') {
      this.widgetToEdit!.regressions.push(this.currentRegression + '_' + $event.value);
    }
  }

  setFilteredViewIds(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredViewIds = this.cc.validViewIds.filter(option => option.toLowerCase().includes(filterValue));
  }

  isViewIdValid() {
    // Check if its included in the list of validViewIds
    const isValid = this.cc.validViewIds.includes(this.widgetToEdit?.viewId as string) || !this.widgetToEdit?.viewId;
    if (!isValid) {
      this.errorMessage = 'viewId is not valid';
    }
  }

  checkValidity() {
    this.errorMessage = '';
    this.isViewIdValid();
  }

  onActionResultStateIdChange($event: any) {
    this.widgetToEdit!.actionResultStateId = $event;
    this.setActionResultStateWidget($event);
  }

  setActionResultStateWidget(widgetId: string) {
    this.actionResultStateWidget = this.cc.allActiveWidgets.find(widget => widget.id === widgetId && widget.type === 'state') || null;
  }

  showWidgetDetails(widget: JsWidget | null) {
    if (!widget) return;
    this.showWidget.emit(widget);
  }

  isNotHidden(field: string) {
    return !this.hiddenFields.includes(field);
  }

  navigateToWidget(path: string) {
    if (!path) return;
    this.uss.setUserSessionStorageItem('widgetPath', path);
    this.router.navigate(['path']);
  }

  openPreviewHistory(widget: JsWidget) {
    this.dialog.open(PreviewHistoryComponent, {
      closeOnNavigation: false,
      data: {
        entityId: widget.id,
        previewKey: 'stateFigmaPreviewUrl',
        previewAtKey: 'stateFigmaPreviewAt'
      }
    });
  }

  async deleteHandler(isDelete: boolean) {
    const confirmation = await this.confirmService.confirm('Restore spec', `Are you sure you want to restore this spec?`);
    if (!confirmation) return;
    if (!this.widget) return;
    const deletedwidget = _.cloneDeep(this.widget);
    deletedwidget.cloudUpdatedAt = serverTimestamp();
    deletedwidget.deletedAt = isDelete ? new Date() : null;
    await this.fbo.updateItemsOptimistic<JsWidget>([deletedwidget], 'widgets');
    if (!isDelete) {
      this.cc.restoreEntitySubject.next('widget');
    }
    this.dialogRef?.close();
  }

  copySpecUrl(): string {
    const domain = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    return `${domain}/path/${this.widgetToEdit?.path}`
  }
}
