<div class="ri-filter-row">
  <mat-form-field [class]="{ edited: filterValue }" floatLabel="always">
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Translation id, name, viewId, status, en etc..." [(ngModel)]="filterValue" />
    <button mat-button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="resetFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <span class="ri-filter-count">{{ filteredTranslations.length }}</span>
  <app-select-filter
    #columnDisplay
    [options]="filterOptions.columns.options"
    [title]="filterOptions.columns.title"
    class="me-1"
    [initialSelection]="filterOptions.columns.selected"
    [selectedEmitter]="filterOptions.columns.selectedEmitter"></app-select-filter>
  <button class="btn btn-warning btn-sm me-1" (click)="resetFilters()">Reset Filters</button>
  <button class="btn btn-primary btn-sm" (click)="addTranslation()">Add</button>
</div>

<div class="ri-filter-row ri-filter-row-background">
  <mat-form-field appearance="fill" class="ri-max-w-200 ri-min-w-200" [class]="{ edited: filterOptions.task.initialSelection !== filterOptions.task.selected }">
    <mat-label>{{ filterOptions.task.title }}</mat-label>
    <mat-select [(value)]="filterOptions.task.selected" (selectionChange)="onFilterChange()">
      <mat-option [value]="null">All</mat-option>
      <mat-option *ngFor="let item of cc.allTasks" [value]="item">
        <span class="text-primary">F-{{ item.tid }}</span>
        {{ item.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="ri-max-w-200 ri-min-w-200" [class]="{ edited: filterOptions.status.selected !== 'All' }" appearance="fill">
    <mat-label>Status</mat-label>
    <mat-select [(value)]="filterOptions.status.selected" placeholder="Status" (selectionChange)="onFilterChange()">
      <mat-option *ngFor="let item of filterOptions.status.options" [value]="item">{{ item }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="ri-max-w-200 ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.show.selected !== 'Active' }">
    <mat-label>Show</mat-label>
    <mat-select [(value)]="filterOptions.show.selected" placeholder="Show" (selectionChange)="onFilterChange()">
      <mat-option *ngFor="let item of filterOptions.show.options" [value]="item">{{ item }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="ri-min-w-200 ri-max-w-200" appearance="fill" [class]="{ edited: filterOptions.forRelease.selected != null }">
    <mat-label>{{ filterOptions.forRelease.title }}</mat-label>
    <mat-select [(value)]="filterOptions.forRelease.selected" (selectionChange)="onFilterChange()">
      <mat-option [value]="null">All</mat-option>
      <mat-option *ngFor="let r of cc.availableMainReleaseNumbers()" [value]="r">{{ r }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="ri-min-w-200 ri-max-w-200" appearance="fill" [class]="{ edited: filterOptions.notForRelease.selected != null }">
    <mat-label>{{ filterOptions.notForRelease.title }}</mat-label>
    <mat-select [(value)]="filterOptions.notForRelease.selected" (selectionChange)="onFilterChange()">
      <mat-option [value]="null">All</mat-option>
      <mat-option *ngFor="let r of cc.availableMainReleaseNumbers()" [value]="r">{{ r }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="ri-min-w-200 ri-max-w-200" appearance="fill" [class]="{ edited: filterOptions.inDeliverable.selected != null }">
    <mat-label>{{ filterOptions.inDeliverable.title }}</mat-label>
    <mat-select [(value)]="filterOptions.inDeliverable.selected" (selectionChange)="onFilterChange()">
      <mat-option [value]="null">All</mat-option>
      <mat-option *ngFor="let r of cc.currentDeliverables()" [value]="r">{{ r }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="ri-min-w-200 ri-max-w-200" appearance="fill" [class]="{ edited: filterOptions.notInDeliverable.selected != null }">
    <mat-label>{{ filterOptions.notInDeliverable.title }}</mat-label>
    <mat-select [(value)]="filterOptions.notInDeliverable.selected" (selectionChange)="onFilterChange()">
      <mat-option [value]="null">All</mat-option>
      <mat-option *ngFor="let r of cc.currentDeliverables()" [value]="r">{{ r }}</mat-option>
    </mat-select>
  </mat-form-field>

  <button class="btn btn-secondary btn-sm ms-1" (click)="publish()">Publish Translations</button>
  <button class="btn btn-primary btn-sm ms-1" (click)="showHistory($event)">
    Version History
    <span class="badge rounded-pill text-bg-light" *ngIf="this.cc.latestPublications['translations']">
      {{ this.cc.latestPublications['translations'].pid }}
    </span>
  </button>
</div>

<!-- Table content -->
<div class="table-block text-nowrap w-100 overflow-x-scroll">
  <div class="table-wrapper">
    <div class="sticky-top header-row">
      <span
        class="header-cell ri-pointer"
        [class]="column"
        [ngClass]="{
          'fw-bolder text-primary': (sortBy === column || sortBy == 'text.' + column) && sortDirection === 'asc',
          'fw-bolder text-danger': (sortBy === column || sortBy == 'text.' + column) && sortDirection === 'desc'
        }"
        *ngFor="let column of displayedColumns">
        <span class="header" (click)="sort(column)">
          <span>{{ column }}</span>
        </span>
      </span>
    </div>
    <div class="translation-block" *ngIf="filterOptions.groupBy.selected === 'None'">
      <div *ngFor="let translation of filteredTranslations; trackBy: trackByForTranslation">
        <ng-container *ngTemplateOutlet="translationRow; context: { $implicit: translation }"></ng-container>
      </div>
      <div class="group-total-row">
        <ng-container *ngTemplateOutlet="groupTotal"></ng-container>
      </div>
      <div class="ri-bottom-spacer"></div>
    </div>
    <div class="group-block" *ngIf="filterOptions.groupBy.selected !== 'None'">
      <div *ngFor="let group of groupValues; trackBy: trackByForGroup">
        <div class="group-header-row">
          <ng-container *ngTemplateOutlet="groupHeader; context: { $implicit: group }"></ng-container>
        </div>
      </div>
      <div class="group-total-row">
        <ng-container *ngTemplateOutlet="groupTotal"></ng-container>
      </div>
      <div class="ri-bottom-spacer"></div>
    </div>
  </div>
</div>

<!-- translation row -->
<ng-template #translationRow let-element>
  <div class="translation-row ri-pointer" [class]="element.status" (click)="editTranslation(element)">
    <span class="translation-row-inner {{ col }}" *ngFor="let col of displayedColumns">
      <ng-container [ngSwitch]="col">
        <ng-container *ngSwitchCase="'translationId'">
          <span>
            {{ element[col] }}
            <!-- Show number of varoables in a badge -->
            <span class="badge rounded-pill text-bg-primary" *ngIf="(element.variables | keys).length">{{ (element.variables | keys).length }} vars</span>
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'cloudUpdatedAt'">
          <span>{{ element[col] | dfnsFormatDistanceToNowStrict }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'createdAt'">
          <span>{{ element[col] | dfnsFormatDistanceToNowStrict }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'deletedAt'">
          <span>{{ element[col] | dfnsFormatDistanceToNowStrict }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'uid'">
          <span>{{ fbs.getDisplayName(element[col]) }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'createdBy'">
          <span>{{ fbs.getDisplayName(element[col]) }}</span>
        </ng-container>
        @for (item of supportedLanguageCodes; track item) {
        <ng-container *ngSwitchCase="item">
          <span>{{ element.text[col] ?? '-' }}</span>
        </ng-container>
        }
        <ng-container *ngSwitchCase="'status'">
          <span class="d-inline-block" [ngClass]="statusStyleMap[element?.[col]]">{{ element[col] }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'Specs'">
          <span class="text-nowrap">
            <app-spec-type-counts-of-view [viewId]="element.viewId"></app-spec-type-counts-of-view>
          </span>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span>{{ element[col] ?? '-' }}</span>
        </ng-container>
      </ng-container>
    </span>
  </div>
</ng-template>

<!--  Group header -->
<ng-template #groupHeader let-groupValue>
  <div (click)="toggleHiddenGroup(groupValue)" class="ri-pointer group-wrapper py-1">
    <div class="position-relative">
      <strong class="group-key">
        {{ filterOptions.groupBy.selected }} -
        {{ ['createdBy', 'uid'].includes(filterOptions.groupBy.selected) ? fbs.getDisplayName(groupValue) : groupValue }}
        ({{ groupedTranslations[groupValue].length }})
      </strong>
      <!-- <app-translation-status-count-of-translations
        class="px-2 translation-status-counts"
        [translations]="this.groupedTranslations[groupValue]"
        (onUpdate)="detectChanges()"></app-translation-status-count-of-translations>

      <app-spec-status-count-of-translations
        class="px-2 spec-status-counts"
        [translations]="this.groupedTranslations[groupValue]"
        (onUpdate)="detectChanges()"></app-spec-status-count-of-translations> -->

      <!-- <app-owner-badges
        *ngIf="filterOptions.groupBy.selected === 'deliverable'"
        class="px-2 owner-badges"
        [deliverable]="groupValue"
        (onUpdate)="detectChanges()"></app-owner-badges> -->
    </div>
  </div>
  <div *ngIf="showGroupValues.includes(groupValue)">
    <div *ngFor="let translation of groupedTranslations[groupValue]; trackBy: trackByForTranslation">
      <ng-container *ngTemplateOutlet="translationRow; context: { $implicit: translation }"></ng-container>
    </div>
  </div>
</ng-template>

<!--  Group Total -->
<ng-template #groupTotal>
  <!-- <div class="group-wrapper total py-1">
    <div class="position-relative" (click)="cc.showTranslationProgressAsPercentage = !cc.showTranslationProgressAsPercentage">
      <strong class="group-key">TOTAL - ({{ filteredTranslations.length }})</strong>
      <app-translation-status-count-of-translations
        class="px-2 translation-status-counts"
        [translations]="this.filteredTranslations"
        (onUpdate)="detectChanges()"></app-translation-status-count-of-translations>

      <app-spec-status-count-of-translations
        class="px-2 spec-status-counts"
        [translations]="this.filteredTranslations"
        (onUpdate)="detectChanges()"></app-spec-status-count-of-translations>
    </div>
  </div> -->
</ng-template>
