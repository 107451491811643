<div class="bg-review text-light d-flex title-wrapper justify-content-between sticky-top">
  <div>
  </div>
  <div class="title">{{title}}
    <span class="badge badge-pill text-bg-light">{{filteredValidStateWidgets.length + ' / ' +
      filteredStateWidgets.length}}</span>
  </div>
  <!-- close icon -->
  <div class="close-icon">
    <fa-icon class="icon" [icon]="faTimes" (click)="close()"></fa-icon>
  </div>
</div>
<div class="viewport">
  <cdk-virtual-scroll-viewport itemSize="10" class="h-100" minBufferPx="1000" maxBufferPx="2000">
    <div *cdkVirtualFor="let item of filteredStateWidgets | orderBy:'path'; let index = index; trackBy: trackBy"
      class="preview-item-container">
      <!-- Preview item showing 50x50 thumbnail n left and four metadata on the right -->
      <div class="preview-item p-1" [class.selected]="item.path === selectedWidgetPath" (click)="onClick(item)">
        <div class="preview-item-thumbnail" (click)="previewFigma(item)">
          <img [attr.loading]="index > 5 ? 'lazy' : 'eager'" *ngIf="item.stateFigmaPreviewUrl"
            class="preview-item-image" [src]="item.stateFigmaPreviewUrl" alt="thumbnail">
          <!-- Placeholder -->
          <img *ngIf="!item.stateFigmaPreviewUrl" class="preview-item-image opacity-25"
            src="https://imagedelivery.net/VXgxWxWTt2Sm11NYIkJ4Hg/c285dc27-0ab5-46f7-0310-7f43182b3800/public"
            alt="thumbnail">
        </div>
        <div class="preview-item-metadata ps-3">
          <div class="preview-item-metadata-title fs-6 fw-bold">
            <div class="main-title fs-6 fw-bold"
              [ngClass]="{'text-primary': item.stateFigmaPreviewValidated, 'text-danger': !item.stateFigmaPreviewValidated }">
              {{ getLastSegment(item.path) }}</div>
            <div class="small">{{(item.stateFigmaPreviewAt | dfnsFormatDistanceToNowStrict) ||
              '- - -'}}
            </div>
            <div class="small">{{ item.path }}</div>
          </div>
          <div class="preview-item-metadata-subtitle d-flex align-items-center">
            <span class="badge rounded-pill py-2 px-3 me-2 {{$any(mapClass)[item.status]}}">
              {{ item.status }}
            </span>
            <span class="badge rounded-pill py-2 px-3 me-2 {{$any(mapSubStatus)[item.subStatus]}}">
              {{ item.subStatus }}
            </span>
          </div>
          <div class="view-id-container">
            <span *ngIf="item.viewId" class="badge badge-pill mt-3 text-bg-dark"
              matTooltip="viewId">{{item.viewId}}</span>
            <span *ngIf="!item.viewId" class="badge badge-pill mt-3 text-bg-danger">Missing viewId</span>
          </div>
          <div class="preview-item-metadata-subtitle text-muted d-flex justify-content-between align-items-center my-1">
            <span matTooltip="Current Assignee">
              <span *ngIf="fbs.getCurrentAssigneeNameFromWidget(item)">
                <fa-icon class="icon me-2" [icon]="faUser"></fa-icon>
                <span class="small">{{ fbs.getCurrentAssigneeNameFromWidget(item) }}</span>
              </span>
              <span *ngIf="!fbs.getCurrentAssigneeNameFromWidget(item)">
                <fa-icon class="icon me-2" [icon]="faUser"></fa-icon>
                <span>--- ---</span>
              </span>
            </span>
            <span class="{{$any(mapClass)[item.type]}} text-capitalize" matTooltip="Last Updated">
              <fa-icon class="icon me-2" [icon]="faClock"></fa-icon>
              <span class="small">
                {{ item.updatedAt | dfnsFormatDistanceToNow }} ago
              </span>
            </span>
          </div>
          <app-tags-preview [widget]="item"></app-tags-preview>
        </div>


      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</div>