<mat-form-field appearance="fill" class="w-100" [class]="{'edited': hasChanged}">
  <mat-label>{{title}}</mat-label>
  <mat-select [formControl]="formCtrl" multiple>
    <mat-select-trigger>
      {{formCtrl.value?.[0]?.label || ''}}
      <span *ngIf="(formCtrl.value?.length || 0) > 1">
        + {{(formCtrl.value?.length || 0) - 1}}
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let item of options" [value]="item">{{item.label}}</mat-option>
  </mat-select>
</mat-form-field>