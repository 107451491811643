import { Component, Inject, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JsTodo } from 'src/app/_interfaces/Todo';
import { todoStatusList } from 'src/app/shared/status';
import _ from 'lodash';
import { JsTask } from 'src/app/_interfaces/Task';
import { TodoFormComponent } from '../todo-form/todo-form.component';
import { CommonModule } from '@angular/common';
import { SelectComponent } from 'src/app/shared/components/select/select.component';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { IdbServiceService } from 'src/app/_services/idb-service.service';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-todo-list',
  standalone: true,
  imports: [
    CommonModule,
    SelectComponent,
    MatTooltip
  ],
  templateUrl: './todo-list.component.html',
  styleUrl: './todo-list.component.scss'
})

export class TodoListComponent {

  unSubscribe = new Subject<void>();
  todos: WritableSignal<JsTodo[]> = signal([]);
  allTodos: WritableSignal<JsTodo[]> = signal([]);
  priorityMap: { [key: string]: string } = {
    Low: 'text-bg-info',
    Normal: 'text-bg-warning',
    High: 'text-bg-danger'
  };
  statusClassMap: { [key: string]: string } = {
    'Todo': 'text-bg-review',
    'In Progress': 'text-bg-primary',
    'Blocked': 'text-bg-secondary',
    'Need Clarity': 'text-bg-info',
    'Done': 'text-bg-warning',
    'Approved': 'text-bg-success'
  };
  statusFilter = {
    title: 'Status',
    options: ['All', ...todoStatusList],
    formControl: new FormControl('All'),
    defaultValue: 'All'
  }

  constructor(
    private dialigRef: MatDialogRef<TodoListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { task: JsTask },
    private dialog: MatDialog,
    private idb: IdbServiceService
  ) {
  
  }

  ngOnInit() {
    this.idb.todos$.pipe(takeUntil(this.unSubscribe)).subscribe(res => {
      const filteredTodos = res.filter(todo => todo.relatedTids.includes(this.data.task.id) && !todo.deletedAt);
      this.allTodos.set(_.sortBy(filteredTodos, 'cloudUpdatedAt').reverse());
      this.todos.set(this.allTodos());
    });
  }

  onStatusChange() {
    const allTodos = _.cloneDeep(this.allTodos());
    if (this.statusFilter.formControl.value == 'All') {
      this.todos.set(allTodos);
    } else {
      this.todos.set(allTodos.filter(todo => todo.status === this.statusFilter.formControl.value));
    }
  }

  openTodo(todo: JsTodo) {
    const eventForm = this.dialog.open(TodoFormComponent, {
      width: '500px !important',
      height: '800px',
      maxWidth: '500px !important',
      maxHeight: '80vh',
      data: {
        mode: 'edit',
        todoValue: todo,
      },
    });
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
