import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MentionModule } from 'angular-mentions';
import { Subject } from 'rxjs';
import _ from 'lodash';
import { FirebaseService } from 'src/app/_services/firebase.service';

@Component({
  selector: 'app-tags-textarea',
  standalone: true,
  imports: [
    CommonModule,
    MentionModule
  ],
  templateUrl: './tags-textarea.component.html',
  styleUrl: './tags-textarea.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsTextareaComponent),
      multi: true
    },
    {
      provide: MatFormFieldControl,
      useExisting: TagsTextareaComponent
    }
  ]
})

export class TagsTextareaComponent {

  @ViewChild('editableDiv', { static: true }) editableDiv!: ElementRef;
  @Input() mentions: string[] = [];
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();

  // @Input() value: string = '';
  stateChanges = new Subject<void>();
  focused: boolean = false;
  touched: boolean = false;
  @Input() disabled: boolean = false;
  private lastHtml: string = '';
  isSelectingMention: boolean = false;

  onChange = (_: any) => { };
  onTouched = () => { };

  @HostListener('window:mention-select-started')
  onMentionSelectStarted() {
    this.isSelectingMention = true;
  }

  @HostListener('window:mention-select-ended')
  onMentionSelectEnded() {
    this.isSelectingMention = false;
  }

  constructor(private renderer: Renderer2, public fbs: FirebaseService) { }

  ngOnInit() {
    this.editableDiv.nativeElement.innerHTML = _.cloneDeep(this.value);
  }

  mentionConfig = {
    // mentionSelect: (item: any) => this.onMentionSelect(item),
    allowSpace: true,
    // labelKey: 'label'
  };

  onMentionSelect(item: any) {
    return `@${item.value}`;
  }

  ngAfterViewInit() {
    // Focus the div if the initial value is null
    if (this.value === null || this.value === '') {
      this.focus();
    }
  }

  focus() {
    this.editableDiv.nativeElement.focus();
    this.focused = true;
    this.stateChanges.next();
  }

  writeValue(value: string): void {
    // this.value = value;
    // if (this.editableDiv) {
    //   this.editableDiv.nativeElement.innerHTML = value;
    // }
  }

  get empty() {
    return !this.editableDiv?.nativeElement?.innerHTML;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  onBlur() {
    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.editableDiv.nativeElement.contentEditable = !isDisabled;
  }

  // Implement other required MatFormFieldControl methods...
  setDescribedByIds(ids: string[]): void {
    // Implementation
  }

  onContainerClick(event: MouseEvent): void {
    if (!this.focused) {
      this.focus();
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.isSelectingMention) {
        // Prevent default behavior when selecting a mention
        event.preventDefault();
      } else {
        // Allow default behavior (new line) when not selecting a mention
        this.onInput();
      }
    }
  }

  insertLineBreak() {
    document.execCommand('insertLineBreak');
    this.onInput();
  }

  onInput(event?: Event) {
    const html = this.editableDiv.nativeElement.innerHTML;

    if (html !== this.lastHtml) {
      const cursorPosition = this.getCursorPosition();

      // Process mentions
      const processedHtml = this.processMentions(html);

      if (processedHtml !== html) {
        this.renderer.setProperty(this.editableDiv.nativeElement, 'innerHTML', processedHtml);
        this.setCursorPosition(cursorPosition);
      }

      this.lastHtml = processedHtml;
      this.valueChange.emit(this.editableDiv.nativeElement.innerHTML);
    }
  }

  processMentions(html: string): string {
    // Remove existing mention spans
    html = html.replace(/<span>(.*?)<\/span>/g, '$1');
    // Add spans to new mentions
    return html.replace(/@([\w-]+\s[\w-]+)/g, (match, name) => {
      if (this.mentions.includes(name)) {
        return `<span>@${name}</span>`;
      }
      return match;
    });
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const text = event.clipboardData?.getData('text/plain') || '';
    document.execCommand('insertText', false, text);
  }

  getCursorPosition(): number {
    const selection = window.getSelection();
    if (!selection?.rangeCount) return 0;

    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(this.editableDiv.nativeElement);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
  }

  setCursorPosition(position: number) {
    const selection = window.getSelection();
    const range = document.createRange();

    let currentNode = this.editableDiv.nativeElement;
    let currentPos = 0;

    const findNodeAtPosition = (node: Node): { node: Node, offset: number } | null => {
      if (node.nodeType === Node.TEXT_NODE) {
        const nodeLength = node.textContent?.length || 0;
        if (currentPos + nodeLength >= position) {
          return { node, offset: position - currentPos };
        }
        currentPos += nodeLength;
      } else {
        for (let i = 0; i < node.childNodes.length; i++) {
          const result = findNodeAtPosition(node.childNodes[i]);
          if (result) return result;
        }
      }
      return null;
    };

    const result = findNodeAtPosition(currentNode);
    if (result) {
      range.setStart(result.node, result.offset);
      range.collapse(true);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  }
}
