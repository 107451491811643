import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CacheService } from '../_services/cache.service';

@Component({
  selector: 'app-release-feature-flag-modal',
  templateUrl: './release-feature-flag-modal.component.html',
  styleUrls: ['./release-feature-flag-modal.component.scss'],
})
export class ReleaseFeatureFlagModalComponent implements OnInit {
  segmentsToEdit: string[] = [];
  mainSegments = ['ALL', 'NONE', 'CUSTOM'];
  customSegments: string[] = [];
  selectedMainSegment = 'NONE';
  selectedCustomSegment: { [key: string]: string } = {};
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      feature: string;
      segments: string[];
    },
    public cc: CacheService,
    public dialogRef: MatDialogRef<ReleaseFeatureFlagModalComponent>
  ) {}

  ngOnInit(): void {
    this.initialise();
  }

  initialise() {
    this.segmentsToEdit = [...this.data.segments];
    this.customSegments = this.cc.config?.supportedSegments || [];
    this.selectedMainSegment = 'NONE';
    if (this.data.segments.includes('NONE')) {
      this.selectedMainSegment = 'NONE';
    } else if (this.data.segments.includes('ALL')) {
      this.selectedMainSegment = 'ALL';
    } else if (this.data.segments.length > 0) {
      this.selectedMainSegment = 'CUSTOM';
      this.selectedCustomSegment = {};
      this.data.segments.forEach((segment) => {
        this.selectedCustomSegment[segment] = segment;
      });
    }
  }

  save() {
    if (this.selectedMainSegment === 'ALL') {
      this.dialogRef.close(['ALL']);
    } else if (this.selectedMainSegment === 'NONE') {
      this.dialogRef.close(['NONE']);
    } else if (this.selectedMainSegment === 'CUSTOM') {
      let result = Object.keys(this.selectedCustomSegment).filter(
        (key) => this.selectedCustomSegment[key]
      );
      if (result.length === 0) {
        this.dialogRef.close(['NONE']);
        return;
      }
      this.dialogRef.close(result);
    }
  }

  reset() {
    this.initialise();
  }
}
