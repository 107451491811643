<main>
  <div class="sticky-top bg-body p-3">
    <span class="fs-6">
      Version History -
      <span class="text-primary">{{ data.type | titlecase }}</span>
    </span>
  </div>
  <div class="p-3">
    <div class="p-2 block mb-2 ri-pointer" *ngFor="let item of filteredPublications" (click)="showDiff(item.pid)">
      <div class="d-flex justify-content-between mt-1 mb-2">
        <span>
          <span class="fw-bold">v{{ item.pid }}</span>
          <span *ngIf="cc.latestPublications[data.type]?.pid === item.pid" class="badge rounded-pill text-bg-primary ms-2">Current</span>
        </span>
        <span class="text-end">
          <span class="me-2">{{ this.fbs.getUsername(item.uid) }}</span>
          <span class="text-muted">{{ item.createdAt | dfnsFormatDistanceToNowStrict }} ago</span>
          <fa-icon
            class="icon ps-2 text-primary"
            matTooltip="Open URL in a new tab"
            matTooltipPosition="above"
            (click)="openUrl(item.pid, $event)"
            [icon]="faArrowUpRightFromSquare"></fa-icon>
          <fa-icon
            class="icon ps-2 text-primary"
            matTooltip="Download JSON"
            matTooltipPosition="above"
            (click)="download(data.type, item.pid, $event)"
            [icon]="faDownload"></fa-icon>
        </span>
      </div>
      <div class="mb-1">
        {{ item.note }}
      </div>
      <mat-progress-bar *ngIf="loadingPid === item.pid" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</main>
