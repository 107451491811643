import { CommonModule, TitleCasePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as Hogan from 'hogan.js';
import * as Diff2html from 'diff2html';
import { createTwoFilesPatch } from 'diff';
import { type } from 'src/app/_interfaces/Publication';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { MatInput } from '@angular/material/input';

interface IDIFF {
  title: string,
  before: string | number,
  after: string | number,
}
interface Data { 
  entity:  | 'release' | 'trash' | 'activity';
  mode: 'add' | 'view';
  title: string, 
  type: 'JSON' | 'JSONDIFF' | 'JSONDIFFLI', 
  oldJson: Object, 
  newJson: Object, 
  diffList?: IDIFF[],
  errors?: string[];
  strict: boolean;
}

@Component({
  selector: 'app-json-view',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    CdkTextareaAutosize,
    TitleCasePipe
  ],
  templateUrl: './json-view.component.html',
  styleUrl: './json-view.component.scss'
})

export class JsonViewComponent {
  title: string;
  type: 'JSON' | 'JSONDIFF' | 'JSONDIFFLI';
  oldJson: string;
  newJson: string;
  diffList?: IDIFF[] = [];
  noOldJson?: boolean = false;
  outputHtml: string = '';
  publishNote: FormControl = new FormControl(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data,
    public dialogRef: MatDialogRef<JsonViewComponent>,
    private confirmService: ConfirmService
  ) {
    this.title = data.title;
    this.type = data.type;
    this.diffList = data.diffList;
    this.oldJson = JSON.stringify(this.sortObjectKeys(data.oldJson), null, 2);
    this.newJson = JSON.stringify(this.sortObjectKeys(data.newJson), null, 2);
    this.noOldJson =  Object.keys(data.oldJson).length === 0;
    this.showDiff(this.oldJson, this.newJson);
  }

  sortObjectKeys(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(this.sortObjectKeys.bind(this));
    }

    const sortedKeys = Object.keys(obj).sort();
    const result: any = {};

    sortedKeys.forEach((key) => {
      result[key] = this.sortObjectKeys(obj[key]);
    });

    return result;
  }

  showDiff(oldText: string, newText: string) {
    const targetElement = document.getElementById('test');
    const configuration = {
      drawFileList: true,
      matching: 'lines',
    };

    const diffText = createTwoFilesPatch(
      'JSON',
      'JSON',
      oldText,
      newText
    );

    let outputHtml = Diff2html.html(diffText, {
      drawFileList: false,
      matching: 'words',
      outputFormat: 'side-by-side',
    });

    this.outputHtml = outputHtml;
  }

  cancel() {
    this.dialogRef.close({
      action: 'cancel',
      note: this.publishNote.value,
    });
  }

  async download() {
    const confirmation = await this.confirmService.confirm(
      `Download latest ${this.data.entity} version?`,
      `This will download the ${this.data.entity} as a json file. This WILL NOT PUBLISH a new version of ${this.data.entity} and WILL NOT make it available to all users accessing it via url. This downloaded file is only meant to be used for DEVELOPMENT and DEBUGGING purpose. Ideally you should be using the published version of ${this.data.entity} available at https://assets.mevolve.app/${this.data.type}/$VERSION`
    );
    if (!confirmation) return;
    this.dialogRef.close({
      action: 'download',
      note: this.publishNote.value,
    });
  }

  async publish() {
    const confirmation = await this.confirmService.confirm(
      `Publish new ${this.data.entity} version?`,
      `This will create a new version of ${this.data.entity} overwriting the older version and make it available to all users accessing it via url - https://assets.mevolve.app/${this.data.entity}/$VERSION`
    );
    if (!confirmation) return;
    this.dialogRef.close({
      action: 'publish',
      note: this.publishNote.value,
    });
  }
}
