import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { CommentFormComponent } from '../comment-form/comment-form.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentComponent } from '../comment/comment.component';
import { MatTooltip } from '@angular/material/tooltip';
import { DateFnsModule } from 'ngx-date-fns';
import { FbComment, JsComment } from 'src/app/_interfaces/Comment';
import { entity } from 'src/app/_interfaces/Activity';
import { FirestoreQueryCondition } from 'src/app/_interfaces/Other';
import { Subject, takeUntil } from 'rxjs';
import _ from 'lodash';
import { JsEntity } from 'src/app/_interfaces/Entities';
import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/firestore';

@Component({
  selector: 'app-comment-list',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatMenuModule,
    CommentComponent,
    MatTooltip,
    DateFnsModule
  ],
  templateUrl: './comment-list.component.html',
  styleUrl: './comment-list.component.scss'
})

export class CommentListComponent implements OnInit {

  commentsList: WritableSignal<JsComment[]> = signal([]);
  unSubscribe = new Subject<void>();
  lastDocument: QueryDocumentSnapshot<FbComment, DocumentData> | null = null;
  allDataLoaded: boolean = false;

  constructor(
    public fbs: FirebaseService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { entityType: entity, entity: JsEntity },
    public dialogRef: MatDialogRef<CommentListComponent>,
    private changeRef: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.getCommentByEntity();
  }

  getCommentByEntity(isLoadMore: boolean = false) {
    if (this.allDataLoaded) {
      return;
    }
    if (!isLoadMore) {
      this.lastDocument = null;
      this.allDataLoaded = false;
    }
    const filters: FirestoreQueryCondition[] = [];
    filters.push({
      field: 'entity',
      operator: '==',
      value: this.data.entityType,
    });
    filters.push({
      field: 'entityId',
      operator: '==',
      value: this.data.entity.id,
    });

    this.fbs.getComments(5, filters, this.lastDocument).pipe(takeUntil(this.unSubscribe)).subscribe(({ data: comments, lastDocument }) => {
      if (comments.length > 0) {
        if (lastDocument) {
          this.lastDocument = lastDocument as unknown as QueryDocumentSnapshot<FbComment, DocumentData>;
        }
        const sortedActivities = _.sortBy(comments, 'createdAt').reverse();
        if (isLoadMore) {
          this.commentsList.set([...this.commentsList(), ...sortedActivities]);
        } else {
          this.commentsList.set(sortedActivities);
        }
      } else {
        if(isLoadMore) {
          this.allDataLoaded = true;
        } else {
          this.commentsList.set([]);
        }
      }
      this.updateChanges();
    });
  }

  updateChanges() {
    this.changeRef.detectChanges();
  }

  openCommentForm(event: any, mode: 'new' | 'edit', comment?: JsComment) {
    event.stopPropagation();
    if (mode === 'edit' && comment?.createdBy == this.fbs.getCurrentUserId() || mode === 'new') {
      const commentForm = this.dialog.open(CommentFormComponent, {
        width: '500px !important',
        height: '800px',
        maxWidth: '500px !important',
        maxHeight: '80vh',
        data: {
          mode: mode,
          commentValue: comment,
          entityType: this.data.entityType,
          entity: this.data.entity
        },
      });
  
      commentForm.afterClosed().pipe(takeUntil(this.unSubscribe)).subscribe((comment: JsComment) => {
        // if (comment && comment.id) {
        //   comment
        // }
      });
    } else {
      return
    }
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
