<main class="px-3 pb-3 ri-min-w-1000">
  <!-- list all widgets using flex bootstrap 5 -->
  <div class="sticky-top bg-body p-2 d-flex align-items-center justify-content-between">
    <span class="fs-6">{{ data.title || 'Features' }}</span>
    <mat-form-field class="select-control ri-max-w-200 ri-min-w-200" appearance="fill"
      [class]="{ edited: statusFilter.selected !== 'All' }">
      <mat-label>Status</mat-label>
      <mat-select [(value)]="statusFilter.selected" placeholder="Status" (selectionChange)="onStatusChange()">
        <mat-option *ngFor="let item of statusFilter.options" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="ri-min-h-400 mt-2 px-2" *ngIf="taskList?.length;else noTasks">
    <table class="table task-list">
      <thead>
        <tr>
          <td class="w-5 py-3 fw-bolder">FID</td>
          <td class="w-40 py-3 fw-bolder">Name</td>
          <td class="w-10 py-3 fw-bolder">Status</td>
          <td class="w-15 py-3 fw-bolder">Assigned</td>
          <td class="w-30 py-3 fw-bolder">Specs</td>
        </tr>
      </thead>
      <tbody>
        <tr class="ri-pointer" *ngFor="let task of taskList" (click)="openTask(task)">
          <td scope="row" valign="middle" width="5%">
            <span class="text-primary text-nowrap">F-{{ task.tid }}</span>
          </td>
          <td class="text-break" width="40%" valign="middle">{{ task.title }}</td>
          <td valign="middle" width="10%">
            <span class="badge rounded-pill" [ngClass]="statusStyleMap[task.status]">{{ task.status }}</span>
          </td>
          <td valign="middle" width="15%">
            {{ fbs.getDisplayName(task.assignedTo) }}
          </td>
          <td valign="middle" width="30%">
            <app-spec-status-counts-of-task [task]="task"></app-spec-status-counts-of-task>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</main>

<ng-template #noTasks>
  <div class="text-center text-muted py-5 my-5">No Features</div>
</ng-template>