import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChange } from '@angular/core';
import { CacheService } from '../_services/cache.service';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { ListTranslationsOfViewComponent } from '../list-translations-of-view/list-translations-of-view.component';
import { ViewBlocksOfTaskComponent } from '../view-blocks-of-task/view-blocks-of-task.component';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-translation-status-counts-of-view',
  templateUrl: './translation-status-counts-of-view.component.html',
  styleUrls: ['./translation-status-counts-of-view.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltip
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationStatusCountsOfViewComponent implements OnInit {
  @Input() viewId: string = '';
  total: number = 0;
  isEmpty: boolean = true;
  Draft: number = 0;
  Paused: number = 0;
  Reviewed: number = 0;
  Translated: number = 0;
  Ready = 0;
  Implemented: number = 0;
  Failed: number = 0;
  Passed: number = 0;
  Deprecated: number = 0;
  Removed: number = 0;
  totalTreanslations: number = 0;
  incompleteTranslations: number = 0;
  constructor(private cc: CacheService, private _dialog: DialogManagerService) {}

  ngOnInit(): void {}

  ngOnChanges(change: SimpleChange) {
    // @ts-ignore
    if (change['viewId']) {
      this.update();
    }
  }

  update() {
    this.isEmpty = true;
    this.total;
    this.totalTreanslations = 0;
    this.incompleteTranslations = 0;
    this.Draft = 0;
    this.Paused = 0;
    this.Reviewed = 0;
    this.Translated = 0;
    this.Ready = 0;
    this.Implemented = 0;
    this.Failed = 0;
    this.Passed = 0;
    this.Deprecated = 0;
    this.Removed = 0;

    if (this.cc.viewIdsToTranslationIdsGroupedByStatus[this.viewId]) {
      Object.keys(this.cc.viewIdsToTranslationIdsGroupedByStatus[this.viewId]).forEach((status: string) => {
        // @ts-ignore
        this[status] = this.cc.viewIdsToTranslationIdsGroupedByStatus[this.viewId][status].length;
        // @ts-ignore
        this.totalTreanslations += this[status];
      });
      if (this.totalTreanslations) {
        this.isEmpty = false;
      }
    }

    this.total =
      this.Draft + this.Paused + this.Reviewed + this.Ready + this.Translated + this.Implemented + this.Failed + this.Passed + this.Deprecated + this.Removed;
  }

  showSpecList(viewId: string, e: any) {
    this._dialog.openDialog(ListTranslationsOfViewComponent, {
      data: {
        viewId
      }
    });
    e.stopPropagation();
  }
}
