<main class="view-translated-text p-3 ri-w-800">
    <div class="d-flex justify-content-between pb-3">
        <h3 class="fw-semibold mb-0">Translated {{ title }}</h3>
    </div>
    
    <div class="ri-dialog-content">
        <div class="" *ngFor="let langCode of objectKeys(texts)">
            <h5 class="fw-semibold mb-1">{{langCode}}</h5>
            <div class="card mb-2 p-2">
                <pre class="mb-0">{{texts[langCode]}}</pre>
            </div>
        </div>
    </div>
    
    <div class="ri-dialog-actions pt-3 text-end">
        <button class="btn btn-primary btn-sm px-5" (click)="dialogRef.close()">Close</button>
    </div>
</main>