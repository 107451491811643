<main class="svg-viewer" [class.fit]="!showFullScale">
    <mat-dialog-content>
        <app-svg-viewer [srcUrl]="data.figmaPrevUrl" [currentColorMap]="currentColorMap" [hoverColor]="hoverColor"
            (onCurrentUsedColorsChange)="onCurrentUsedColorsChange($event)"></app-svg-viewer>
    </mat-dialog-content>
</main>

<!-- Corner buttons -->

<button mat-icon-button class="delete-button small-button" (click)="delete()" matTooltip="Delete"
    [disabled]="!['Todo', 'In Progress', 'Blocked', 'Need Clarity'].includes(data.status || '')"
    *ngIf="['Todo', 'In Progress', 'Blocked', 'Need Clarity'].includes(data.status || '')">
    <mat-icon>delete</mat-icon>
</button>
<button mat-icon-button class="zoom-button small-button" (click)="showFullScale = !showFullScale" matTooltip="Zoom">
    <mat-icon>{{showFullScale ? 'zoom_out' : 'zoom_in'}}</mat-icon>
</button>
<button mat-icon-button class="validity-button small-button"
    [ngClass]="{'bg-success': data.figmaPreviewValidated, 'invalid': !data.figmaPreviewValidated}"
    (click)="toggleStatePreviewValidity($event)"
    [matTooltip]="(data.figmaPreviewValidated ? 'Valid Preview' : 'Invalid Preview') + '. Click to toggle.'">
    <mat-icon>{{data.figmaPreviewValidated ? 'check' : 'close'}}</mat-icon>
</button>
<button *ngIf="currentNameToColorMapExtra.length" mat-icon-button class="extra-button small-button"
    matTooltip="Extra Colors">
    {{currentNameToColorMapExtra.length}}
</button>



<div class="d-flex color-container ri-hide-scrollbar">
    <div class="flex-grow">
        <div class="color-modes">
            <mat-chip-listbox [value]="currentThemeMode">
                <mat-chip-option *ngFor="let mode of svgService.availableThemeModes" [value]="mode" [selectable]="true"
                    [selected]="mode === currentThemeMode" [color]="mode === currentThemeMode ? 'primary' : ''"
                    (click)="onColorModeChange(mode)">{{mode}}</mat-chip-option>
            </mat-chip-listbox>
        </div>
    </div>
    <div class="flex-grow ms-2">
        <div class="current-colors">
            <mat-chip-listbox>
                <mat-chip-option [cdkCopyToClipboard]="item.value" (click)="onHexCopy()"
                    *ngFor="let item of currentNameToColorMap" class="color"
                    [ngStyle]="{'background-color': item.value}" (mouseenter)="onFocusColor(item.value)"
                    (mouseleave)="onBlurColor()" [matTooltip]="(item.name.split(' ')[1] || 'Extra') + ' ' + item.value">
                    {{item.name.split(' ')[1] || item.name}} <span>&nbsp;&nbsp; {{item.value}}</span>
                </mat-chip-option>
            </mat-chip-listbox>
        </div>
    </div>
</div>