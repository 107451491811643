<div class="d-flex sticky-top justify-content-between bg-light px-2">
  <h4 class="p-2 text-center title m-0">{{who ? who : ''}} {{when ? ' - ' + getHumanReadableDate(when) : ''}}
    ({{this.totalCount}})</h4>
  <mat-form-field appearance="fill" class="p-2">
    <mat-label>Group By</mat-label>
    <mat-select [(value)]="selectedGroupBy" (selectionChange)="setSelectedGroupBy($event)">
      <mat-option *ngFor="let group of groupByOptions" [value]="group.value">
        {{group.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- close icon -->
  <div class="close-icon p-2" (click)="close()">
    <fa-icon class="icon fs-4" [icon]="faTimes"></fa-icon>
  </div>
</div>
<div>
  <as-split unit="pixel" [gutterSize]="2">
    <as-split-area>
      <div *ngFor="let status of statusChange?.[selectedGroupBy] | keyvalue">
        <h6 class="p-2 text-center {{'text-'+ $any(mapClass)[status.key]}}">
          {{selectedGroupBy === 'after' ? 'To ' + status.key : (status.key === 'null' ? 'Newly Created' : 'From ' +
          status.key)}} ({{getCount(status.value)}})
        </h6>
        <div *ngFor="let path of status.value | keyvalue">
          <div *ngFor="let activity of path.value; trackBy: trackByMethod"
            [class]="{'selected': selectedWidgetId === activity.entityId}"
            class="card m-2 d-flex justify-content-between flex-row cursor-ri-pointer" (click)="onSelect(activity)">
            <div class="text-start w-75 p-2">
              <div class="path" [class]="getTypeClass(activity)">{{$any(activity?.[selectedGroupBy])?.path}}
                <!-- <span class="badge rounded-pill {{getTypeClass(activity)}}">{{activity?.[selectedGroupBy]?.type}}</span> -->
              </div>
              <div *ngIf="status.key === 'null'">{{$any(activity?.after)?.path}}</div>
              <div class="text-capitalize d-flex mt-2">
                <span matTooltip="From Status"
                  class="badge me-2 {{getStatusClass(activity, 'before')}}">{{$any(activity?.before)?.status || '
                  '}}</span>
                <span matTooltip="To Status"
                  class="badge me-2 {{getStatusClass(activity, 'after')}}">{{$any(activity?.after)?.status || '
                  '}}</span>
                <span matTooltip="Current Status"
                  class="badge me-2 {{getStatusClass(activity, 'current')}}">{{this.cc.getWidgetStatus(activity.entityId) ||
                  ' '}}</span>
              </div>
            </div>
            <div class="text-end w-25 p-2 d-flex flex-column">
              <div>{{ fbs.getUserNameFromId(activity.createdBy) }}</div>
              <div class="mt-2">
                <span class="text-capitalize badge rounded-pill bg-secondary">{{activity.createdAt |
                  dfnsFormatDistanceToNow}} ago</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </as-split-area>

    <!-- // DETAIL FORM AREA -->
    <as-split-area [size]="475" [maxSize]="650" [minSize]="475" class="position-relative">
      <app-widget-detail mode="activity" [widget]="selectedWidget"></app-widget-detail>
    </as-split-area>
  </as-split>
</div>