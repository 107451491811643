import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule, } from '@angular/common';
import { Component, Inject, signal, ViewChild, WritableSignal } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DialogManagerService } from 'src/app/_services/dialog-manager.service';
import { JsEvent } from 'src/app/_interfaces/Events';
import { JsParam, Param } from 'src/app/_interfaces/Param';
import { CacheService } from 'src/app/_services/cache.service';
import { getNewId } from 'src/app/shared/utils';
import _ from 'lodash';
import { FirebaseOptimisticService } from 'src/app/_services/firebase-optimistic.service';
import { SharedFunctionService } from 'src/app/_services/shared-function.service';
import { ConfirmService } from '../../_services/confirm.service';
import { ActivityService } from '../../_services/activity.service';
import { MatIcon } from '@angular/material/icon';
import { SelectComponent } from 'src/app/shared/components/select/select.component';
import { OnlyLowercaseDirective } from 'src/app/_directives/only-lowercase.directive';
import { ParamListComponent } from 'src/app/params/param-list/param-list.component';
import { devEventStatusList, eventStatusList } from 'src/app/shared/status';
import { Subject, takeUntil } from 'rxjs';
import { IdbServiceService } from 'src/app/_services/idb-service.service';
import { DateFnsModule } from 'ngx-date-fns';

@Component({
  selector: 'app-event-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CdkTextareaAutosize,
    MatLabel,
    MatFormField,
    MatInput,
    MatError,
    MatIcon,
    OnlyLowercaseDirective,
    SelectComponent,
    DateFnsModule
  ],
  templateUrl: './event-form.component.html',
  styleUrl: './event-form.component.scss'
})

export class EventFormComponent {

  defaultPgId: string = 'DEFAULT-PARAM-GROUP-FOR-EVENTS';
  mode: 'new' | 'edit';
  allEvents: WritableSignal<JsEvent[]> = signal([]);
  devEventStatuses: string[] = devEventStatusList;
  eventStatuses: string[] = eventStatusList;
  eventTypes: string[] = ['user_action', 'client_action', 'server_action', 'other'];
  eventsForm: FormGroup;
  @ViewChild('evForm') evForm!: NgForm;
  eventInitial: JsEvent;
  paramInitial:  Param[];
  unSubscribe = new Subject<void>();

  constructor(
    public cc: CacheService,
    private _dialog: DialogManagerService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'new' | 'edit', eventValue: JsEvent },
    public dialogRef: MatDialogRef<EventFormComponent>,
    public dialog: MatDialog,
    private fbo: FirebaseOptimisticService,
    public sharedFunc: SharedFunctionService,
    private confirm: ConfirmService,
    public as: ActivityService,
    private idb: IdbServiceService
  ) {

    this.mode = data.mode;
    this.eventInitial = data.mode == 'new' ? this.initiateEventsForm() : this.initiateEventsForm(data.eventValue);
    this.eventsForm = this.fb.group({
      eventName: new FormControl(this.eventInitial.eventName, [Validators.required, Validators.max(38), Validators.maxLength(38)]),
      type: new FormControl(this.eventInitial.type, Validators.required),
      paramsGroupIds: new FormControl(this.eventInitial.paramsGroupIds, Validators.required),
      params: this.fb.array([], Validators.required),
      note: new FormControl(this.eventInitial.note, Validators.required),
      devNote: new FormControl(this.eventInitial.devNote),
      status: new FormControl(this.eventInitial.status, Validators.required),
      eid: new FormControl(this.eventInitial.eid, Validators.required),
      id: new FormControl(this.eventInitial.id, Validators.required),
      deletedAt: new FormControl(this.eventInitial.deletedAt)
    });
    if (data.eventValue && data.eventValue.params && data.eventValue.params.length !== 0) {
      this.mapParams();
    } else {
      this.pushDefaultParam();
    }
    this.paramInitial = this.params.value;
    dialogRef.backdropClick().pipe(takeUntil(this.unSubscribe)).subscribe(async () => {
      if (this.hasChanges()) {
        const confirmed = await this.confirm.confirm('Alert', 'You have unsaved changes. Do you really want to discard them?', 'Discard', 'Cancel');
        if (!confirmed) {
          return;
        }
        this.dialogRef.close();
      } else {
        this.dialogRef.close(); // Close immediately if no unsaved changes
      }
    });
  }

  ngOnInit() {
    if (this.mode === 'edit') {
      this.cc.getUpdatedEvent$.pipe(takeUntil(this.unSubscribe)).subscribe(event => {
        if (event.id === this.eventInitial.id) {
          this.eventInitial = event;
          this.cancelChanges();
        }
      });
    } else {
      this.idb.events$.pipe(takeUntil(this.unSubscribe)).subscribe((events: JsEvent[]) => {
        if (events.length !== 0) {
          this.allEvents.set(events);
          if (!this.eventsForm.value.eid || this.eventsForm.value.eid === 0) {
            const eventId = this.getNextEventId();
            this.eventsForm.get('eid')?.setValue(eventId);
            this.eventInitial.eid = eventId;
            const eventParam = this.eventInitial.params.find(p => p.key === 'e_id' && p.paramGroupId === this.defaultPgId);
            if(eventParam){
              eventParam.value = eventId;
            } 
            const index = this.params.controls.findIndex((control: AbstractControl) => {
              const group = control as FormGroup;
              return group.get('key')?.value === 'e_id';
            });

            if (index !== -1) {
              const formGroupToUpdate: FormGroup = this.params.at(index) as FormGroup;
              formGroupToUpdate.get('value')?.setValue(eventId);
            }
            this.paramInitial = this.params.value;
          }
        }
      });
    }
    this.cc.getUpdatedParam$.pipe(takeUntil(this.unSubscribe)).subscribe(pg => {
      if (this.eventsForm.value.paramsGroupIds && this.eventsForm.value.paramsGroupIds.includes(pg.id)) {
        const paramsBackup: Param[] = _.cloneDeep(this.params.value);
        for (let i = this.params.length - 1; i >= 0; i--) {
          const param = this.params.at(i).value;
          if (param.paramGroupId === pg.id) {
            this.params.removeAt(i);
          }
        }
        if (pg.deletedAt) {
          const index = this.eventsForm.value.paramsGroupIds.indexOf(pg.id);
          if (index !== -1) {
            const pgIds = this.eventsForm.value.paramsGroupIds;
            pgIds.splice(index, 1);
            this.eventsForm.get('paramsGroupIds')?.setValue(pgIds);
          }
        } else {
          pg.params.forEach(pgParam => {
            const oldParams = paramsBackup.find(p => p.paramGroupId === pg.id && p.key === pgParam.key && p.type === pgParam.type);
            this.params.push(this.createParam(oldParams ? oldParams : pgParam, pg.id));
          });
        }
        this.paramInitial = this.params.value;
      }
    });
  }

  mapParams() {
    let formInit = true;
    this.idb.params$.pipe(takeUntil(this.unSubscribe)).subscribe(paramGroups => {
      if (formInit) {
        const clonedParams = _.cloneDeep(this.eventInitial.params);
        const validPgs = paramGroups.filter(pg => this.eventsForm.value.paramsGroupIds.includes(pg.id) && !pg.deletedAt);
        const validPgIds = this.eventsForm.value.paramsGroupIds.filter((pgId: string) => validPgs.some(vpgs => vpgs.id === pgId));
        this.eventsForm.get('paramsGroupIds')?.setValue(validPgIds);
        this.updateParamsWithPg(validPgs, clonedParams);
        formInit = false;
      }
    });
  }

  pushDefaultParam() {
    const defaultParam = this.getDefaultParam(this.eventsForm.value.eid, this.eventsForm.value.type)
    defaultParam.forEach(param => {
      this.params.push(this.createParam(param, this.defaultPgId));
    });
  }

  getDefaultParam(eid: number, type: string): Param[] {
    return [
      {
        paramGroupId: this.defaultPgId,
        key: 'e_id',
        type: 'number',
        value: eid.toString(),
      },
      {
        paramGroupId: this.defaultPgId,
        key: 'e_type',
        type: 'string',
        value: type
      }
    ]
  }

  changeTypeEvent() {
    const index = this.params.controls.findIndex((control: AbstractControl) => {
      const group = control as FormGroup;
      return group.get('key')?.value === 'e_type';
    });

    if (index !== -1) {
      const formGroupToUpdate: FormGroup = this.params.at(index) as FormGroup;
      formGroupToUpdate.get('value')?.setValue(this.eventsForm.value.type);
    }
  }

  getActivity() {
    this.as.getActivity('event', this.eventInitial)
  }

  getFc(fc: string) {
    return this.eventsForm.get(fc) as FormControl
  }

  get params(): FormArray {
    return this.eventsForm.get('params') as FormArray;
  }

  get isDevMode(): boolean {
    return this.devEventStatuses.includes(this.eventInitial.status) && this.mode == 'edit' && this.eventInitial.status !== 'Removed';
  }

  initiateEventsForm(event?: JsEvent): JsEvent {
    return {
      eventName: event ? event.eventName : '',
      paramsGroupIds: event ? event.paramsGroupIds : [],
      params: event ? event.params : this.getDefaultParam(0, 'user_action'),
      note: event ? event.note : '',
      devNote: event ? event.devNote : '',
      status: event ? event.status : 'Draft',
      type: event ? event.type : 'user_action',
      id: event ? event.id : getNewId(),
      eid: event ? event.eid : 0,
      deletedAt: event ? event.deletedAt : null,
    }
  }

  getNextEventId(): number {
    const maxEventId = _.max(this.allEvents().map(event => event.eid)) || 0;
    return maxEventId + 1;
  }

  async saveChanges() {
    const eventBackup = _.cloneDeep(this.eventsForm.value);
    let updateStatus = null;

    const updateId = this.eventsForm.value.id;
    if (!this.eventsForm.value.id) return;
    if (this.mode === 'new') {
      updateStatus = await this.fbo.createItemsOptimistic<JsEvent>(
        [this.eventsForm.value],
        'events'
      );
      if (updateStatus && updateId === this.eventInitial.id) {
        this.mode = 'edit';
        this.eventInitial = _.cloneDeep(eventBackup);
        this.cancelChanges();
      }
    } else if (this.mode === 'edit') {
      updateStatus = await this.fbo.updateItemsOptimistic<JsEvent>(
        [this.eventsForm.value],
        'events'
      );
      this.eventInitial = _.cloneDeep(this.eventsForm.value);
      this.cc.setUpdatedEvent(this.eventInitial);
    }

    if (!updateStatus) {
      this.eventInitial = _.cloneDeep(eventBackup);
    }
    this.dialogRef.close();
  }

  hasChanges() {
    const initial = _.cloneDeep(this.eventInitial);
    const paramsInitial = _.cloneDeep(this.paramInitial);
    initial.params = paramsInitial;
    const current = _.cloneDeep(this.eventsForm.value);
    // sort params and paramsGroupIds
    if (initial.params?.length !== current.params?.length) return true;
    if (initial.paramsGroupIds?.length !== current.paramsGroupIds?.length)
      return true;
    initial.params.sort((a: { key: string; }, b: { key: string; }) => (a.key > b.key ? 1 : -1));
    current.params.sort((a: { key: string; }, b: { key: string; }) => (a.key > b.key ? 1 : -1));
    initial.paramsGroupIds.sort((a: string, b: string) => (a > b ? 1 : -1));
    current.paramsGroupIds.sort((a: string, b: string) => (a > b ? 1 : -1));
    return !_.isEqual(initial, current);
  }

  showParamsList(e: any) {
    const clonedParams = _.cloneDeep(this.eventsForm.value.params);
    this._dialog
      .openDialog(ParamListComponent, {
        panelClass: 'ri-bottom-sheet',
        disableClose: true,
        data: {
          paramGroupIds: this.eventsForm.value?.paramsGroupIds || [],
          maxParams: 25,
          type: 'Event',
          entityId: this.eventsForm.value.id
        },
      })
      .afterClosed()
      .subscribe((result: JsParam[]) => {
        if (result) {
          const updatedParamGroups = result;
          const selectedParamGroupId = updatedParamGroups.map(pg => pg.id);
          this.eventsForm.get('paramsGroupIds')?.setValue(selectedParamGroupId);
          this.updateParamsWithPg(updatedParamGroups, clonedParams);
        }
      });
    e.stopPropagation();
  }

  updateParamsWithPg(paramGroups: JsParam[], clonedParams: Param[]) {
    this.clearParams();
    paramGroups.forEach((paramGroup: JsParam) => {
      paramGroup.params.forEach((param: Param) => {
        const oldParams = clonedParams.find(p => p.paramGroupId === paramGroup.id && p.key === param.key && p.type === param.type);
        this.params.push(this.createParam(oldParams ? oldParams : param, paramGroup.id));
      });
    });
    this.pushDefaultParam();
  }

  validateAllParams(): boolean {
    return this.eventsForm.value.params.every((param: Param) => param.value && param.value !== '' && param.value !== null);
  }

  createParam(param: Param, paramGroupId?: string) {
    return this.fb.group({
      paramGroupId: new FormControl(paramGroupId),
      key: new FormControl(param.key, Validators.required),
      type: new FormControl(param.type, Validators.required),
      value: new FormControl(param.value ? param.value : null, Validators.required),
    });
  }

  clearParams() {
    while (this.params.length !== 0) {
      this.params.removeAt(0);
    }
  }

  isParamEdited(
    paramGroupId: string,
    key: string,
    type: string,
    value: string
  ) {
    const initialParamValue = this.getValidInitialParamValue(
      paramGroupId,
      key,
      type
    );
    return initialParamValue !== value;
  }

  getValidInitialParamValue(paramGroupId: string, key: string, type: string) {
    const initialParams = this.eventInitial.params;
    const initialParam = initialParams.find(
      (param: any) =>
        param.paramGroupId === paramGroupId &&
        param.key === key &&
        param.type === type
    );
    if (initialParam) {
      return initialParam.value;
    } else {
      return '';
    }
  }

  isParamValid(paramType: string, paramValue: string) {
    if (paramValue && paramValue.startsWith('{{')) {
      return true;
    } else if (paramType === 'boolean') {
      return ['true', 'false'].includes(paramValue);
    } else if (
      ['number', 'optionNumber', 'unixTimestamp'].includes(paramType)
    ) {
      return isNaN(Number(paramValue)) === false;
    } else if (_.isString(paramValue)) {
      return true;
    }
    return false;
  }

  hasParamGroupChanges() {
    const initial = _.cloneDeep(this.eventInitial);
    const current = _.cloneDeep(this.eventsForm.value);
    initial.paramsGroupIds.sort((a: string, b: string) => (a > b ? 1 : -1));
    current.paramsGroupIds.sort((a: string, b: string) => (a > b ? 1 : -1));
    return !_.isEqual(initial.paramsGroupIds, current.paramsGroupIds);
  }

  getParamGroupName(id: string) {
    const paramGroup = this.cc.params.find((param: JsParam) => param.id === id);
    return paramGroup ? paramGroup.name : '';
  }

  async deleteHandler(isDelete: boolean) {
    const confirmed = await this.confirm.confirm(`${isDelete ? 'Delete' : 'Restore'} Event`, `Are you sure you want to ${isDelete ? 'delete' : 'restore'} this event?`);
    if (confirmed) {
      if (!this.eventsForm.value.id || this.mode === 'new') return;
      this.eventInitial.deletedAt = isDelete ? new Date() : null;
      const eventBackup = _.cloneDeep(this.eventInitial);
      await this.fbo.updateItemsOptimistic<JsEvent>([this.eventInitial], 'events');
      if (!isDelete) {
        this.cc.restoreEntitySubject.next('event');
      }
      this.eventInitial = this.initiateEventsForm();
      this.cc.setUpdatedEvent(eventBackup);
      this.cancelChanges();
      this.dialogRef.close();
    }
  }

  cancelChanges() {
    this.eventsForm.reset();
    this.evForm.resetForm();
    this.clearParams();
    this.eventsForm.patchValue(this.eventInitial);
    if (this.eventInitial.params.length !== 0) {
      this.eventInitial.params.forEach((param: Param) => {
        this.params.push(this.createParam(param, param.paramGroupId));
      });
    } else {
      this.pushDefaultParam();
    };
    this.paramInitial = this.params.value;
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
