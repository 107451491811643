import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatLabel, MatFormField, MatError, MatSuffix, MatHint } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { JsLeave } from 'src/app/_interfaces/Leave';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { SelectComponent } from 'src/app/shared/components/select/select.component';
import { getDateString, getNewId } from 'src/app/shared/utils';
import _ from 'lodash';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { NumbersOnlyDirective } from 'src/app/_directives/numbers-only.directive';

@Component({
  selector: 'app-leave-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CdkTextareaAutosize,
    MatLabel,
    MatFormField,
    MatInput,
    MatError,
    MatIcon,
    SelectComponent,
    MatSuffix,
    MatIconButton,
    MatTooltip,
    MatDatepickerModule,
    MatDatepickerToggle,
    MatHint,
    NumbersOnlyDirective,
    DatePipe
  ],
  templateUrl: './leave-form.component.html',
  styleUrl: './leave-form.component.scss'
})

export class LeaveFormComponent {

  mode: 'new' | 'edit';
  leaveForm: FormGroup;
  @ViewChild('lvForm') lvForm!: NgForm;
  leaveInitial: JsLeave;
  today: Date = new Date();

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'new' | 'edit', leaveValue: JsLeave },
    public fbs: FirebaseService,
    public dialogRef: MatDialogRef<LeaveFormComponent>,
    public dialog: MatDialog,
    private confirm: ConfirmService,
  ) {

    this.mode = data.mode;
    this.leaveInitial = data.mode == 'new' ? this.initiateLeaveForm() : this.initiateLeaveForm(data.leaveValue);
    this.leaveForm = this.fb.group({
      uid: new FormControl(this.leaveInitial.uid, Validators.required),
      workDays: new FormControl(this.leaveInitial.workDays, [Validators.required, Validators.min(0.1)]),
      startDate: new FormControl(this.leaveInitial.startDate, Validators.required),
      endDate: new FormControl(this.leaveInitial.endDate, Validators.required),
      reason: new FormControl(this.leaveInitial.reason, Validators.required),
      id: new FormControl(this.leaveInitial.id, Validators.required),
      deletedAt: new FormControl(this.leaveInitial.deletedAt)
    });

    this.leaveForm.get('startDate')?.valueChanges.subscribe(value => {
      if (value && value instanceof Date) {
        const formattedDate = getDateString(value);
        this.leaveForm.get('startDate')?.setValue(formattedDate, { emitEvent: false });
        this.getEndDate();
      }
    });
  }

  initiateLeaveForm(leave?: JsLeave): JsLeave {
    return {
      uid: leave ? leave.uid : this.fbs.getCurrentUserId(),
      workDays: leave ? leave.workDays : null,
      startDate: leave ? leave.startDate : '',
      endDate: leave ? leave.endDate : '',
      reason: leave ? leave.reason : '',
      id: leave ? leave.id : getNewId(),
      deletedAt: leave ? leave.deletedAt : null
    }
  }

  getFc(fcName: string): FormControl {
    return this.leaveForm.get(fcName) as FormControl;
  }

  hasChanges() {
    const initial = _.cloneDeep(this.leaveInitial);
    const current = _.cloneDeep(this.leaveForm.value);
    return !_.isEqual(initial, current);
  }

  async saveChanges() {
    const leaveBackup = _.cloneDeep(this.leaveForm.value);
    let updateStatus = null;

    const updateId = this.leaveForm.value.id;
    if (!this.leaveForm.value.id) return;
    if (this.mode === 'new') {
      await this.fbs.createItems<JsLeave>([this.leaveForm.value], 'leaves');
      if (updateStatus && updateId === this.leaveInitial.id) {
        this.mode = 'edit';
        this.leaveInitial = _.cloneDeep(leaveBackup);
        this.cancelChanges();
      }
    } else if (this.mode === 'edit') {
      await this.fbs.updateItems<JsLeave>([this.leaveForm.value], 'leaves');
      this.leaveInitial = _.cloneDeep(this.leaveForm.value);
    }

    if (!updateStatus) {
      this.leaveInitial = _.cloneDeep(leaveBackup);
    }
    this.dialogRef.close();
  }

  getEndDate() {
    if (this.leaveForm.value.startDate && this.leaveForm.value.workDays) {
      const endDate = this.calculateEndDate(this.leaveForm.value.startDate, this.leaveForm.value.workDays);
      this.leaveForm.get('endDate')?.setValue(endDate);
    }
  }

  calculateEndDate(startDate: Date, workDays: number): String {
    let currentDate = new Date(startDate);
    let remainingWorkDays = workDays;
    while (remainingWorkDays > 0) {
      // Check if the current day is a weekday (not Saturday [6] or Sunday [0])
      if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0) {
        remainingWorkDays -= 1; // Decrement workdays if it's a valid workday
      }
      if (remainingWorkDays > 0) {
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }
    }
    const formattedDate = getDateString(currentDate);
    return formattedDate;
  }

  async deleteLeave() {
    const result = await this.confirm.confirm(
      'Delete Leave',
      'Are you sure you want to delete this leave?'
    );
    if (result) {
      if (!this.leaveForm.value.id || this.mode === 'new') return;
      this.leaveInitial.deletedAt = new Date();
      await this.fbs.updateItems<JsLeave>([this.leaveInitial], 'leaves');
      this.leaveInitial = this.initiateLeaveForm();
      this.cancelChanges();
      this.dialogRef.close();
    }
  }

  cancelChanges() {
    this.leaveForm.reset();
    this.lvForm.resetForm()
    this.leaveForm.patchValue(this.leaveInitial);
  }
}
