import { Injectable } from '@angular/core';
import { getcurrentLocalDateAsString, getcurrentLocalTimeAsString, getNewId } from '../shared/utils';
import { FirebaseService } from './firebase.service';
import { JsDaily } from '../_interfaces/Daily';
import { eventParameter } from '../_interfaces/Widget';
import { Param } from '../_interfaces/Param';
import { MatDialog } from '@angular/material/dialog';
import { JsonViewModalComponent } from 'src/app/json-view-modal/json-view-modal.component';
import { CommentListComponent } from '../modules/project/components/comments/comment-list/comment-list.component';
import { entity } from '../_interfaces/Activity';

@Injectable({
  providedIn: 'root'
})
export class SharedFunctionService {

  constructor(private fbService: FirebaseService, public dialog: MatDialog) { }

  createNewDailyTask(tid?: number, type?: 'Feature' | 'Todo'): JsDaily {
    const newId = getNewId();
    return {
      id: newId,
      uid: this.fbService.getCurrentUserId(),
      createdBy: this.fbService.getCurrentUserId(),
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null,
      status: 'Not Started',
      planFor: type ? type : 'Feature',
      eodNote: '',
      hours: 0,
      hoursLeft: 0,
      assignedTo: this.fbService.getCurrentUserId(),
      tid: tid ? tid : 0,
      date: getcurrentLocalDateAsString(),
      time: getcurrentLocalTimeAsString(),
      updateTime: getcurrentLocalTimeAsString(),
      percentage: 0
    }
  }

  getFormattedDate(date: string) {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
  }

  viewJson(title: string, jsonData: Object, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    const confirmDialog = this.dialog.open(JsonViewModalComponent, {
      autoFocus: false,
      maxWidth: '500px',
      data: {
        title: title,
        json: jsonData
      },
    });
  }

  getObjectStructure(params: Param[]): Object {
    let obj = {};
    params.forEach((param: { key: string | number; }) => {
      // @ts-ignore
      obj[param.key] = this.getParsedParamValue(param);
    });
    return obj;
  }

  getParsedParamValue(param: eventParameter): any {
    const value = param.value;
    if (value && value.startsWith('{{')) {
      return String(value) || null;
    } else if (param.type === 'boolean') {
      return Boolean(value) || null;
    } else if (
      ['number', 'optionNumber', 'unixTimestamp'].includes(param.type)
    ) {
      return Number(value) || Number(value) === 0 ? Number(value) : null;
    } else {
      return String(value) || null;
    }
  }

  convertToNestedObject(obj: { [key: string]: any }): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const keys = key.split('.');
        keys.reduce((acc, currentKey, index) => {
          if (index === keys.length - 1) {
            acc[currentKey] = obj[key];
          } else {
            acc[currentKey] = acc[currentKey] || {};
          }
          return acc[currentKey];
        }, result);
      }
    }

    return result;
  }

  getComments(entityType: entity, entity: any) {
    const confirmDialog = this.dialog.open(CommentListComponent, {
      autoFocus: false,
      data: { entityType: entityType, entity: entity },
    });
  }
}
