import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { JsWidget } from '../_interfaces/Widget';
import { CacheService } from '../_services/cache.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-widget-detail-edit',
  templateUrl: './widget-detail-edit.component.html',
  styleUrls: ['./widget-detail-edit.component.scss'],
})
export class WidgetDetailEditComponent implements OnInit, OnDestroy {
  widgetSubscription: any;
  widgetToEdit: JsWidget | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { widgetId: string; hiddenFields?: string[] },
    private cc: CacheService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    if (!this.data.hiddenFields) {
      this.data.hiddenFields = [];
    }
    this.widgetSubscription = this.cc.widgets$.subscribe((res) => {
      // @ts-ignore
      this.widgetToEdit =
        res.find((widget) => widget.id === this.data.widgetId) || null;
    });
    if(!this.widgetToEdit) {
      this.widgetSubscription = this.cc.deletedWidgets$.subscribe((res) => {
        // @ts-ignore
        this.widgetToEdit =
          res.find((widget) => widget.id === this.data.widgetId) || null;
      });
    }
    console.log(this.widgetToEdit);
  }

  ngOnDestroy(): void {
    this.widgetSubscription.unsubscribe();
  }
}
