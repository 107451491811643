import { Injectable, inject } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Performance, trace, FirebasePerformance, PerformanceTrace, initializePerformance } from '@angular/fire/performance';
import isOnline from 'is-online';
import { JsEntity, FbEntity } from '../_interfaces/Entities';
import { environment } from '../../environments/environment.prod';
import { FirebaseService } from './firebase.service';
import { Collection } from '../_interfaces/Collection';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  catchError,
  debounceTime,
  filter,
  firstValueFrom,
  fromEvent,
  interval,
  lastValueFrom,
  merge,
  mergeMap,
  of,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError
} from 'rxjs';
import { JsWidget, FbWidget } from '../_interfaces/Widget';
import * as _ from 'lodash';
import { UserSessionStorageService } from './user-session-storage.service';
import { JsActivity } from '../_interfaces/Activity';
import { Timestamp } from '@angular/fire/firestore';
import { OnlineStatusService } from 'ngx-online-status';
import { JsTask } from '../_interfaces/Task';
import { JsDaily } from '../_interfaces/Daily';
import { JsRelease } from '../_interfaces/Release';
import { Analytics, logEvent, setUserId, setDefaultEventParameters } from '@angular/fire/analytics';
import { FbTranslation, JsTranslation } from '../_interfaces/Translation';
import { FbView, JsView } from '../_interfaces/View';
import { FbParam, JsParam } from '../_interfaces/Param';
import { FbPublication, JsPublication } from '../_interfaces/Publication';
import { JsEvent } from '../_interfaces/Events';
import { JsTodo } from '../_interfaces/Todo';
import { JsLog } from '../_interfaces/Log';
import { LocalStorageService } from './local-storage.service';
import { dbConfig } from '../_configs/db-config';
import { Computed } from '../_interfaces/Computed';

@Injectable({
  providedIn: 'root'
})
export class IdbServiceService {
  // satus updates from 31 days ago
  STATUS_UPDATES_FROM = new Date(new Date().setDate(new Date().getDate() - 8));
  // notifications from 31 days ago
  NOTIFICATIONS_FROM = new Date(new Date().setDate(new Date().getDate() - 31));
  stores: Collection[] = ['widgets', 'translations', 'todos', 'views', 'releases', 'statusUpdates', 'tasks', 'dailys', 'events', 'logs', 'notifications', 'params', 'publications', ];
  // widgetsSubject$ = new BehaviorSubject<JsWidget[]>([]);
  specSubject$ = new BehaviorSubject<JsWidget[]>([]);
  translationsSubject$ = new BehaviorSubject<JsTranslation[]>([]);
  viewsSubject$ = new BehaviorSubject<JsView[]>([]);
  paramsSubject$ = new BehaviorSubject<JsParam[]>([]);
  publicationsSubject$ = new BehaviorSubject<JsPublication[]>([]);
  notificationsSubject$ = new BehaviorSubject<JsActivity[]>([]);
  dailysSubject$ = new BehaviorSubject<JsDaily[]>([]);
  releasesSubject$ = new BehaviorSubject<JsRelease[]>([]);
  tasksSubject$ = new BehaviorSubject<JsTask[]>([]);
  statusUpdatesSubject$ = new BehaviorSubject<JsActivity[]>([]);
  eventsSubject$ = new BehaviorSubject<JsEvent[]>([]);
  logsSubject$ = new BehaviorSubject<JsLog[]>([]);
  todosSubject$ = new BehaviorSubject<JsTodo[]>([]);
  computedSubject$ = new BehaviorSubject<Computed[]>([]);
  // widgets$: Observable<JsWidget[]> = this.widgetsSubject$.asObservable();
  specs$: Observable<JsWidget[]> = this.specSubject$.asObservable();
  translations$: Observable<JsTranslation[]> = this.translationsSubject$.asObservable();
  views$: Observable<JsView[]> = this.viewsSubject$.asObservable();
  params$: Observable<JsParam[]> = this.paramsSubject$.asObservable();
  publications$: Observable<JsPublication[]> = this.publicationsSubject$.asObservable();
  notifications$: Observable<JsActivity[]> = this.notificationsSubject$.asObservable();
  dailys$: Observable<JsDaily[]> = this.dailysSubject$.asObservable();
  releases$: Observable<JsRelease[]> = this.releasesSubject$.asObservable();
  tasks$: Observable<JsTask[]> = this.tasksSubject$.asObservable();
  statusUpdates$: Observable<JsActivity[]> = this.statusUpdatesSubject$.asObservable();
  events$: Observable<JsEvent[]> = this.eventsSubject$.asObservable();
  logs$: Observable<JsLog[]> = this.logsSubject$.asObservable();
  todos$: Observable<JsTodo[]> = this.todosSubject$.asObservable();
  computed$: Observable<Computed[]> = this.computedSubject$.asObservable();
  // deletedWidgetsSubject$ = new BehaviorSubject<JsWidget[]>([]);
  // deletedWidgets$: Observable<JsWidget[]> = this.deletedWidgetsSubject$.asObservable();
  
  private fetchTriggers: { [key: string]: Subject<void> } = {
    widgets: new Subject<void>(),
    translations: new Subject<void>(),
    views: new Subject<void>(),
    params: new Subject<void>(),
    publications: new Subject<void>(),
    notifications: new Subject<void>(),
    dailys: new Subject<void>(),
    releases: new Subject<void>(),
    tasks: new Subject<void>(),
    statusUpdates: new Subject<void>(),
    events: new Subject<void>(),
    logs: new Subject<void>(),
    todos: new Subject<void>(),
    computed: new Subject<void>()
  };
  private latestUpdatedAts: { [key: string]: Date } = {
    widgets: new Date(2000, 0, 1),
    translations: new Date(2000, 0, 1),
    views: new Date(2000, 0, 1),
    params: new Date(2000, 0, 1),
    publications: new Date(2000, 0, 1),
    // 10 days ago
    notifications: new Date(new Date().setDate(new Date().getDate() - 31)),
    // 10 days ago
    dailys: new Date(new Date().setDate(new Date().getDate() - 10)),
    releases: new Date(2000, 0, 1),
    tasks: new Date(2000, 0, 1),
    // 10 days ago
    statusUpdates: new Date(new Date().setDate(new Date().getDate() - 31)),
    events: new Date(2000, 0, 1),
    logs: new Date(2000, 0, 1),
    todos: new Date(2000, 0, 1),
    computed: new Date(2000, 0, 1),
  };
  private isInitialsed = false;
  private idbPerformanceTrace: PerformanceTrace;
  unSubscribe = new Subject<void>();

  // RESYNC -> INITIALISE -> INITIALISE COLLECTION(reinit new fetch after every fetchtrigger.next()[on visibility changes and docs received]) -> FETCH LATEST DATA -> UPDATE IDB -> UPDATE OBSERVABLES FROM IDB
  constructor(
    private dbService: NgxIndexedDBService,
    private fbService: FirebaseService,
    private uss: UserSessionStorageService,
    private onlineStatusService: OnlineStatusService,
    private performance: Performance = inject(Performance),
    private analytics: Analytics = inject(Analytics),
    private localService: LocalStorageService
  ) {
    setUserId(this.analytics, this.fbService.getCurrentUserId());
    setDefaultEventParameters({
      user: this.fbService.getCurrentUserId(),
      version: environment.version
    });
    logEvent(this.analytics, 'idb_service', {
      user: this.fbService.getCurrentUserId(),
      version: environment.version
    });
    this.idbPerformanceTrace = trace(this.performance, 'idbServiceService');
    this.idbPerformanceTrace.putAttribute('user', this.fbService.getCurrentUserId() || 'UNKNOWN');
    this.idbPerformanceTrace.putAttribute('version', environment.version);
  }

  // Only truggers when the app needs a clean start (e.g. when user gets newer version of the app))
  async resync() {
    console.log('RESYNCING');
    this.uss.resetUserSessionStorage();
    await this.resyncWidgetsStore();
    await this.resyncTranslationsStore();
    await this.resyncViewsStore();
    await this.resyncParamsStore();
    await this.resyncPublicationsStore();
    await this.resyncNotificationsStore();
    await this.resyncDailysStore();
    await this.resyncTasksStore();
    await this.resyncStatusUpdatesStore();
    await this.resyncReleasesStore();
    await this.resyncEventsStore();
    await this.resyncLogsStore();
    await this.resyncTodosStore();
    await this.updateObservablesFromIdb();
  }

  // Always gets triggered on app start to get NEW ITEMS after resync from Firestore - basically a subscription for newer ones
  initialise() {
    const localUser = this.localService.getUser();
    const localUserId = localUser && localUser.user?.uid;
    if (this.isInitialsed || !localUserId) return;
    this.isInitialsed = true;
    console.log('INITIALISING');
    const collections = [
      'widgets',
      'translations',
      'views',
      'params',
      'publications', // 'publications' is a collection of 'colors', 'views', 'specs', 'translations
      'notifications',
      'dailys',
      'releases',
      'tasks',
      'statusUpdates',
      'events',
      'logs',
      'todos',
      'computed'
    ];

    const initCollections = collections.map(collection => {
      return this.getLatestCloudUpdatedAt(collection as Collection).then(timestamp => {
        console.log('latestUpdatedAts', collection, timestamp);
        this.latestUpdatedAts[collection] = timestamp;
        this.initialiseCollection(collection as Collection);
      });
    });

    Promise.all(initCollections).then(async () => {
      console.log('INITIALISED');
      // To take care of offline situations
      let lastCheckTime = new Date();
      let initialised = false;
      interval(1000 * 60)
        .pipe(startWith(0),takeUntil(this.unSubscribe)) // This makes it start immediately
        .subscribe(() => {
          const currentTime = new Date();
          const timeDiff = currentTime.getTime() - lastCheckTime.getTime();
          // if diff greater than 2 mins then check for new items
          if (timeDiff > 1000 * 60 * 2 || !initialised) {
            console.log('CHECKING FOR NEW ITEMS');
            initialised = true;
            lastCheckTime = currentTime;
            collections.forEach(collection => {
              this.fetchTriggers[collection]?.next();
            });
          } else {
            lastCheckTime = currentTime;
          }
        });
      await this.updateObservablesFromIdb();
    });
  }

  initialiseCollection(collection: Collection) {
    this.fetchTriggers[collection]?.pipe(switchMap(() => this.fetchLatestData(collection)),takeUntil(this.unSubscribe)).subscribe();
  }

  fetchLatestData(collection: Collection) {
    console.log('FETCHING LATEST DATA - ' + collection);
    let baseObservable: Observable<any>;

    switch (collection) {
      case 'notifications':
        // If you have a different method for fetching notifications, replace with that.
        // For the sake of this example, I'm using the same method with a different argument.
        baseObservable = this.fbService.getActivities(10000, [
          {
            field: 'watchers',
            operator: 'array-contains',
            value: this.fbService.getCurrentUserId()
          },
          {
            field: 'notify',
            operator: '==',
            value: true
          },
          {
            field: 'cloudUpdatedAt',
            operator: '>',
            value: Timestamp.fromDate(this.latestUpdatedAts[collection] || new Date())
          }
        ]);
        break;
      case 'statusUpdates':
        baseObservable = this.fbService.getActivities(10000, [
          {
            field: 'hasStatusChanged',
            operator: '==',
            value: true
          },
          {
            field: 'cloudUpdatedAt',
            operator: '>',
            value: Timestamp.fromDate(this.latestUpdatedAts[collection] || new Date(0))
          }
        ]);
        break;
      default:
        // If collection doesn't match any of the above, default to fetching items for the given collection name.
        baseObservable = this.fbService.getAllItems<FbEntity, JsEntity>(collection, this.latestUpdatedAts[collection]);
    }

    return baseObservable.pipe(
      debounceTime(3000),
      mergeMap(async (items: JsEntity[]) => {
        console.log('FIRESTORE READ - ' + collection, items.length);
        this.idbPerformanceTrace.incrementMetric(`INIT - ${collection}`, items.length);
        logEvent(this.analytics, 'init_firestore_read_' + collection, {
          count: items.length
        });
        if (items.length > 0) {
          await this.updateBulk(collection, items);
          await this.updateObservablesFromIdb(collection);
        }
        const latestUpdatedItem = _.maxBy(items, 'cloudUpdatedAt');
        if (latestUpdatedItem && latestUpdatedItem.cloudUpdatedAt && latestUpdatedItem.cloudUpdatedAt > this.latestUpdatedAts[collection]) {
          this.latestUpdatedAts[collection] = latestUpdatedItem.cloudUpdatedAt as Date;
          this.fetchTriggers[collection].next();
        }
        return items;
      }),
      catchError(e => {
        console.error('ERROR in ' + collection + ' fetchLatestData');
        console.log('error', e);
        return throwError(() => new Error('ups something happened'));
      })
    );
  }

  async updateObservablesFromIdb<T extends JsEntity>(entity: Collection | 'computed' | null = null) {

    if (entity === null || entity === 'releases') {
      const releases = await this.getAll<JsRelease>('releases');
      console.log('releases shown from IDB', releases.length);
      this.releasesSubject$.next(releases || []);
    }

    if (entity === null || entity === 'tasks') {
      const tasks = await this.getAll<JsTask>('tasks');
      console.log('tasks shown from IDB', tasks.length);
      this.tasksSubject$.next(tasks || []);
    }

    if (entity === null || entity === 'widgets') {
      // const widgets = await this.getAll<JsWidget>('widgets');
      // const activeWidgets = widgets?.filter(item => item.deletedAt === null);
      // const deletedWidgets = widgets?.filter(item => item.deletedAt !== null);
      // console.log('activeWidgets shown from IDB', activeWidgets.length);
      // console.log('deletedWidgets shown from IDB', deletedWidgets.length);
      // console.log('widgets shown from IDB', widgets.length);
      // const deletedWidgetsSorted = _.sortBy(deletedWidgets, ['deletedAt']).reverse();
      // this.widgetsSubject$.next(activeWidgets || []);
      // this.deletedWidgetsSubject$.next(deletedWidgetsSorted || []);
      const specs = await this.getAll<JsWidget>('widgets');
      console.log('releases shown from IDB', specs.length);
      this.specSubject$.next(specs || []);
    }

    if (entity === null || entity === 'todos') {
      const todos = await this.getAll<JsTodo>('todos');
      console.log('todos shown from IDB', todos.length);
      this.todosSubject$.next(todos || []);
    }

    if (entity === null || entity === 'views') {
      const views = await this.getAll<JsView>('views');
      console.log('views shown from IDB', views.length);
      this.viewsSubject$.next(views || []);
    }

    if (entity === null || entity === 'translations') {
      const translations = await this.getAll<JsTranslation>('translations');
      console.log('translations shown from IDB', translations.length);
      this.translationsSubject$.next(translations || []);
    }

    if (entity === null || entity === 'events') {
      const events = await this.getAll<JsEvent>('events');
      console.log('events shown from IDB', events.length);
      this.eventsSubject$.next(events || []);
    }

    if (entity === null || entity === 'logs') {
      const logs = await this.getAll<JsLog>('logs');
      console.log('logs shown from IDB', logs.length);
      this.logsSubject$.next(logs || []);
    }

    if (entity === null || entity === 'params') {
      const params = await this.getAll<JsParam>('params');
      console.log('params shown from IDB', params.length);
      this.paramsSubject$.next(params || []);
    }

    if (entity === null || entity === 'publications') {
      const publications = await this.getAll<JsPublication>('publications');
      console.log('publications shown from IDB', publications.length);
      this.publicationsSubject$.next(publications || []);
    }

    if (entity === null || entity === 'dailys') {
      const dailys = await this.getAll<JsDaily>('dailys');
      console.log('dailys shown from IDB', dailys.length);
      this.dailysSubject$.next(dailys || []);
    }

    if (entity === null || entity === 'statusUpdates') {
      const statusUpdates = await this.getAll<JsActivity>('statusUpdates');
      console.log('statusUpdates shown from IDB', statusUpdates.length);
      this.statusUpdatesSubject$.next(statusUpdates || []);
    }

    if (entity === null || entity === 'notifications') {
      const notifications = await this.getAll<JsActivity>('notifications');
      console.log('notifications shown from IDB', notifications.length);
      this.notificationsSubject$.next(notifications ? notifications.filter(item => item.watchers?.includes(this.fbService.getCurrentUserId())) : []);
    }

    if (entity === null || entity === 'computed') {
      const computed = await this.getAll<any>('computed');
      console.log('computed shown from IDB',computed);
      this.computedSubject$.next(computed || {});
    }

    return true;
  }

  async resyncWidgetsStore() {
    await this.clearStoreObjects('widgets');
    const items = await lastValueFrom(this.fbService.getAllItems<FbEntity, JsEntity>('widgets').pipe(take(1)));
    await this.addBulk('widgets', items || []);
    console.log('FIRESTORE RESYNC widgets - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - widgets`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_widgets', {
      count: items.length
    });
  }

  async resyncTranslationsStore() {
    await this.clearStoreObjects('translations');
    const items = await lastValueFrom(this.fbService.getAllItems<FbTranslation, JsTranslation>('translations').pipe(take(1)));
    await this.addBulk('translations', items || []);
    console.log('FIRESTORE RESYNC translations - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - translations`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_translations', {
      count: items.length
    });
  }

  async resyncViewsStore() {
    await this.clearStoreObjects('views');
    const items = await lastValueFrom(this.fbService.getAllItems<FbView, JsView>('views').pipe(take(1)));
    await this.addBulk('views', items || []);
    console.log('FIRESTORE RESYNC views - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - views`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_views', {
      count: items.length
    });
  }

  async resyncParamsStore() {
    await this.clearStoreObjects('params');
    const items = await lastValueFrom(this.fbService.getAllItems<FbParam, JsParam>('params').pipe(take(1)));
    await this.addBulk('params', items || []);
    console.log('FIRESTORE RESYNC params - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - params`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_params', {
      count: items.length
    });
  }

  async resyncPublicationsStore() {
    await this.clearStoreObjects('publications');
    const items = await lastValueFrom(this.fbService.getAllItems<FbPublication, JsPublication>('publications').pipe(take(1)));
    await this.addBulk('publications', items || []);
    console.log('FIRESTORE RESYNC publications - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - publications`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_publications', {
      count: items.length
    });
  }

  async resyncNotificationsStore() {
    await this.clearStoreObjects('notifications');
    const items = await lastValueFrom(
      this.fbService
        .getActivities(1000, [
          {
            field: 'watchers',
            operator: 'array-contains',
            value: this.fbService.getCurrentUserId()
          },
          {
            field: 'notify',
            operator: '==',
            value: true
          },
          {
            field: 'cloudUpdatedAt',
            operator: '>',
            value: Timestamp.fromDate(this.NOTIFICATIONS_FROM)
          }
        ])
        .pipe(take(1))
    );
    await this.addBulk('notifications', items || []);
    console.log('FIRESTORE RESYNC notifications - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - notifications`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_notifications', {
      count: items.length
    });
  }

  async resyncDailysStore() {
    await this.clearStoreObjects('dailys');
    const items = await lastValueFrom(this.fbService.getAllItems<FbEntity, JsEntity>('dailys', this.latestUpdatedAts['dailys']).pipe(take(1)));
    await this.addBulk('dailys', items || []);
    console.log('FIRESTORE RESYNC dailys - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - dailys`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_dailys', {
      count: items.length
    });
  }

  async resyncReleasesStore() {
    await this.clearStoreObjects('releases');
    const items = await lastValueFrom(this.fbService.getAllItems<FbEntity, JsEntity>('releases').pipe(take(1)));
    await this.addBulk('releases', items || []);
    console.log('FIRESTORE RESYNC releases - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - releases`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_releases', {
      count: items.length
    });
  }

  async resyncTasksStore() {
    await this.clearStoreObjects('tasks');
    const items = await lastValueFrom(this.fbService.getAllItems<FbEntity, JsEntity>('tasks').pipe(take(1)));
    await this.addBulk('tasks', items || []);
    console.log('FIRESTORE RESYNC tasks - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - tasks`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_tasks', {
      count: items.length
    });
  }

  async resyncEventsStore() {
    await this.clearStoreObjects('events');
    const items = await lastValueFrom(this.fbService.getAllItems<FbEntity, JsEntity>('events').pipe(take(1)));
    await this.addBulk('events', items || []);
    console.log('FIRESTORE RESYNC events - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - events`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_events', {
      count: items.length
    });
  }

  async resyncLogsStore() {
    await this.clearStoreObjects('logs');
    const items = await lastValueFrom(this.fbService.getAllItems<FbEntity, JsEntity>('logs').pipe(take(1)));
    await this.addBulk('logs', items || []);
    console.log('FIRESTORE RESYNC logs - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - logs`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_logs', {
      count: items.length
    });
  }

  async resyncTodosStore() {
    await this.clearStoreObjects('todos');
    const items = await lastValueFrom(this.fbService.getAllItems<FbEntity, JsEntity>('todos').pipe(take(1)));
    await this.addBulk('todos', items || []);
    console.log('FIRESTORE RESYNC todos - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - todos`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_todos', {
      count: items.length
    });
  }

  async resyncStatusUpdatesStore() {
    await this.clearStoreObjects('statusUpdates');
    const items = await lastValueFrom(
      this.fbService
        .getActivities(10000, [
          {
            field: 'hasStatusChanged',
            operator: '==',
            value: true
          },
          {
            field: 'cloudUpdatedAt',
            operator: '>',
            value: Timestamp.fromDate(this.STATUS_UPDATES_FROM)
          }
        ])
        .pipe(take(1))
    );
    await this.addBulk('statusUpdates', items || []);
    console.log('FIRESTORE RESYNC statusUpdates - ', items?.length);
    this.idbPerformanceTrace.incrementMetric(`RESYNC - statusUpdates`, items.length);
    logEvent(this.analytics, 'resync_firestore_read_statusUpdates', {
      count: items.length
    });
  }

  async getLatestCloudUpdatedAt(store: Collection): Promise<Date> {
    let latestDate = new Date(2000, 0, 1);
    await firstValueFrom(this.dbService.getAll(store)).then(items => {
      items.forEach(element => {
        // console.log(
        //   // @ts-ignore
        //   element.cloudUpdatedAt,
        //   latestDate,
        //   // @ts-ignore
        //   element.cloudUpdatedAt > latestDate
        // );
        // @ts-ignore
        if (element.cloudUpdatedAt > latestDate) {
          // @ts-ignore
          latestDate = element.cloudUpdatedAt;
        }
      });
    });
    // Reduce 1 minute to avoid missing items
    // return new Date(latestDate.getTime() - 1 * 60000);
    return latestDate;
  }

  async updateRecord(storeName: string, id: string, updatedData: Partial<Computed>): Promise<void> {
    try {
      // Step 1: Try to retrieve the existing record by ID
      const existingRecord = await lastValueFrom(this.dbService.getByKey<Computed>(storeName, id));
  
      let recordToSave: Computed;
  
      if (existingRecord) {
        recordToSave = { ...existingRecord, ...updatedData };
      } else {
        recordToSave = { id, ...updatedData } as Computed;
      }
      await lastValueFrom(this.dbService.update(storeName, recordToSave));

    } catch (error) {
      console.error(`Error saving record with id ${id}:`, error);
    }
  }

  async clearAll() {
    this.stores.forEach(async store => {
      await this.clearStoreObjects(store);
    });
    return true;
  }

  // async createDatabase() {
  //   return await this.dbService.createDatabase(1, (db) => {
  //     this.stores.forEach((store) => {
  //       db.createObjectStore(store, { keyPath: 'id', autoIncrement: false });
  //     });
  //   });
  // }

  initializeDatabase() {
    dbConfig.objectStoresMeta.forEach((storeConfig) => {
      this.dbService.createObjectStore(storeConfig);
    });
  }

  async deleteDatabase() {
    return await lastValueFrom(this.dbService.deleteDatabase());
  }

  async clearStoreObjects(storeName: Collection) {
    return await lastValueFrom(this.dbService.clear(storeName));
  }

  async deleteStore(storeName: Collection) {
    return await lastValueFrom(this.dbService.deleteObjectStore(storeName));
  }

  async createObjectStore(storeName: Collection) {
    return await this.dbService.createObjectStore({
      store: storeName,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
    });
  }

  async getAll<T extends JsEntity>(storeName: Collection | 'computed'): Promise<T[]> {
    return await lastValueFrom(this.dbService.getAll<T>(storeName));
  }

  async getByKey(storeName: Collection, key: any) {
    return await lastValueFrom(this.dbService.getByKey(storeName, key));
  }

  async getByIndex(storeName: Collection, index: string, key: any) {
    return await lastValueFrom(this.dbService.getByIndex(storeName, index, key));
  }

  async addBulk(storeName: Collection | 'computed', values: JsEntity[]) {
    return await lastValueFrom(this.dbService.bulkAdd(storeName, values));
  }

  async add(storeName: Collection, value: JsEntity) {
    return await lastValueFrom(this.dbService.add(storeName, value));
  }

  async update(storeName: Collection, value: JsEntity) {
    return await lastValueFrom(this.dbService.update(storeName, value));
  }

  async updateBulk(storeName: Collection | 'computed', values: JsEntity[]) {
    return await lastValueFrom(this.dbService.bulkPut(storeName, values));
  }

  async getBulk(storeName: Collection, keys: any[]) {
    return await lastValueFrom(this.dbService.bulkGet(storeName, keys));
  }

  async delete(storeName: Collection, key: any) {
    return await lastValueFrom(this.dbService.delete(storeName, key));
  }

  async bulkDelete(storeName: Collection, keys: any[]) {
    return await lastValueFrom(this.dbService.bulkDelete(storeName, keys));
  }

  async count(storeName: Collection) {
    return await lastValueFrom(this.dbService.count(storeName));
  }

  async openCursor(storeName: Collection) {
    return await lastValueFrom(this.dbService.openCursor(storeName));
  }

  deleteIndexDb() {
    this.dbService.deleteDatabase().pipe(takeUntil(this.unSubscribe))?.subscribe({
      next: (result) => {
        console.log('Database deleted successfully', result);
      },
      error: (error) => {
        console.error('Error deleting database:', error);
      }
    });
  }

  stopSubscription() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
