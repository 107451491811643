<div class="warning-dialog p-3 ri-w-600">
    <h5 class="px-2 fw-semibold" mat-dialog-title>Alert!</h5>
    
    <div mat-dialog-content style="min-width: 350px;">
        <div class="" *ngIf="filteredEvents().length !== 0 || filteredLogs().length !== 0; else editableWarningText">
            <p class="px-2">The selected parameter group "<strong *ngFor="let pg of selectedGroups; let i = index">{{ pg.name }}{{ i === selectedGroups.length - 1 ? '' : ', ' }}</strong>" {{ selectedGroups.length > 1 ? 'are' : 'is' }} used in the following {{ data.type === 'Event' ? 'events' : 'Logs'}}, We suggest to avoid add / edit / delete the params or deleting the paramgroup. If you still want to edit or delete {{ selectedGroups.length > 1 ? 'them' : 'it' }}, kindly remove {{ selectedGroups.length > 1 ? 'these' : 'this' }} parameter group from all the following {{ data.type === 'Event' ? 'events' : 'Logs'}} first<span *ngIf="!isDevMode"> (or) click <strong class="text-info">continue anyway</strong> to proceed with your changes</span>.</p>

            <table class="table event-list">
                <tbody *ngIf="data.type === 'Event'">
                    <tr class="ri-pointer" *ngFor="let data of filteredEvents()" (click)="openEventModal('edit', data)">
                        <td valign="middle"><span class="text-primary pe-2">{{data.eid}}</span> {{ data.eventName }}</td>
                        <td valign="middle" [ngClass]="{
                            'text-bg-light': data.status === 'Draft',
                            'text-bg-dark': (data.status === 'Paused') || (data.status === 'Removed'),
                            'text-bg-review': data.status === 'Reviewed',
                            'text-bg-primary': data.status === 'Ready',
                            'text-bg-warning': data.status === 'Implemented',
                            'text-bg-danger': data.status === 'Failed',
                            'text-bg-success': data.status === 'Passed',
                            'text-bg-secondary': data.status === 'Deprecated'}">
                            {{ data.status }}
                        </td>
                        <td valign="middle" class="text-end"><button class="btn btn-sm text-bg-review ri-fs-10px" (click)="sharedFunc.viewJson('Event Params',sharedFunc.getObjectStructure(data.params),$event)">JSON</button></td>
                    </tr>
                </tbody>
                <tbody *ngIf="data.type === 'Log'">
                    <tr class="ri-pointer" *ngFor="let data of filteredLogs()" (click)="openLogModal('edit', data)">
                        <td valign="middle"><span class="text-primary pe-2">{{data.lid}}</span> {{ data.logName }}</td>
                        <td valign="middle" [ngClass]="{
                            'text-bg-light': data.status === 'Draft',
                            'text-bg-dark': (data.status === 'Paused') || (data.status === 'Removed'),
                            'text-bg-review': data.status === 'Reviewed',
                            'text-bg-primary': data.status === 'Ready',
                            'text-bg-warning': data.status === 'Implemented',
                            'text-bg-danger': data.status === 'Failed',
                            'text-bg-success': data.status === 'Passed',
                            'text-bg-secondary': data.status === 'Deprecated'}">
                            {{ data.status }}
                        </td>
                        <td valign="middle" class="text-end"><button class="btn btn-sm text-bg-review ri-fs-10px" (click)="sharedFunc.viewJson('Log Params',sharedFunc.convertToNestedObject(sharedFunc.getObjectStructure(data.params)),$event)">JSON</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #editableWarningText>
            <p class="px-2">You can edit or delete this selected param group</p>
        </ng-template>
    </div>
    
    <div mat-dialog-actions class="text-end px-2 pt-4">
        <button *ngIf="!isDevMode" type="submit" class="btn btn-sm btn-secondary px-4 me-2" (click)="dialogRef.close()">Close</button>
        <button type="submit" class="btn btn-sm btn-primary px-5" (click)="dialogRef.close(!isDevMode)">{{ isDevMode ? 'Ok' : 'Continue Anyway' }}</button>
    </div>
</div>