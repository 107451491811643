import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttachmentType } from '../../../_interfaces/Other';
import { ConfirmService } from '../../../_services/confirm.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  selectedImageUrl: string;
  type: AttachmentType;
  hideConfirm: boolean;
  isDeleted: boolean;
  showFullScale = false;
  isView: boolean;
  constructor(
    public dialogRef: MatDialogRef<ImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageViewerModel,
    private confirmService: ConfirmService
  ) {
    // Update view with given values
    this.selectedImageUrl = data.selectedImageUrl;
    this.type = data.type;
    this.hideConfirm = data.hideConfirm;
    this.isDeleted = data.isDeleted;
    this.isView = data.isView
  }

  ngOnInit(): void {}

  async delete() {
    if (!this.hideConfirm) {
      const confirmation = await this.confirmService.confirm(
        'Delete',
        `Are you sure? This is irreversible!!`
      );
      if (!confirmation) return;
    }
    this.dialogRef.close({
      action: 'delete',
    });
  }

  restore() {
    this.dialogRef.close({
      action: 'restore',
    });
  }
}

/**
 * Class to represent image viewer dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ImageViewerModel {
  constructor(
    public selectedImageUrl: string,
    public type: AttachmentType,
    public hideConfirm: boolean,
    public isDeleted: boolean,
    public isView: boolean
  ) {}
}
