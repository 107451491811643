<div class="m-2 view-details" *ngIf="viewId">
  <div class="view-row p-2 text-center text-bg-secondary">
    View ID Linked</div>
  <div>
    <div class="translations-row text-center ri-pointer fs-6 p-3" [cdkCopyToClipboard]="viewId || ''"
      (click)="snackbar.show('viewId copied to clipboard')">
      {{viewId}}
    </div>
    <div *ngIf="!hideTranslate" class="translations-row d-flex justify-content-between p-2">
      <span>Translations</span>
      <app-translation-status-counts-of-view [viewId]="viewId!"></app-translation-status-counts-of-view>
    </div>
    <div *ngIf="!hideSpecStatus" class="spec-status-row d-flex justify-content-between p-2">
      <span>Spec Status</span>
      <app-spec-status-counts-of-view [viewId]="viewId!"></app-spec-status-counts-of-view>
    </div>
    <div *ngIf="!hideSpecType" class="spec-type-row d-flex justify-content-between p-2">
      <span>Spec Types</span>
      <app-spec-type-counts-of-view [viewId]="viewId!"></app-spec-type-counts-of-view>
    </div>
  </div>
</div>