import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from '../_services/firebase.service';
import * as _ from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
import { SnackbarService } from '../_services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslationDetailsModalComponent } from '../translation-details-modal/translation-details-modal.component';
import { JsTranslation } from '../_interfaces/Translation';
import { CacheService } from '../_services/cache.service';
import { ListSpecsOfViewComponent } from '../list-specs-of-view/list-specs-of-view.component';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UserSessionStorageService } from '../_services/user-session-storage.service';
import { JsonService } from '../_services/json.service';
import { ListPublicationsComponent } from '../list-publications/list-publications.component';
import { translate } from '../../../functions/src/functions/translate';
import { translationStatusList } from '../shared/status';

@Component({
  selector: 'app-translation-table',
  templateUrl: './translation-table.component.html',
  styleUrls: ['./translation-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationTableComponent implements OnInit, OnDestroy {
  public filterOptions = {
    forRelease: {
      title: 'For Release',
      selected: null
    },
    notForRelease: {
      title: 'Not For Release',
      selected: null
    },
    inDeliverable: {
      title: 'In Deliverable',
      selected: null
    },
    notInDeliverable: {
      title: 'Not In Deliverable',
      selected: null
    },
    status: {
      options: ['All', ...translationStatusList],
      selected: 'All'
    },
    task: {
      title: 'Feature',
      initialSelection: null,
      selected: null
    },
    show: {
      options: ['Active', 'Valid', 'Invalid', 'Incomplete', 'Deleted'],
      selected: 'Active'
    },
    columns: {
      title: 'Columns',
      options: [] as { label: string; value: string }[],
      selected: [] as string[],
      selectedEmitter: new EventEmitter<string[]>()
    },
    groupBy: {
      options: ['None', 'status', 'uid', 'viewId', 'designText'],
      selected: 'status'
    }
  };
  initialColumns: string[] = ['translationId', 'status', 'en', 'Specs', 'uid', 'cloudUpdatedAt'];
  optionalColumnsStatic = [
    { label: 'viewId', value: 'viewId' },
    { label: 'translationName', value: 'translationName' },
    { label: 'note', value: 'note' },
    { label: 'designText', value: 'designText' },
    { label: 'Deleted At', value: 'deletedAt' },
    { label: 'Created By', value: 'createdBy' },
    { label: 'Created At', value: 'createdAt' }
  ];
  displayedColumns: string[] = [...this.initialColumns];
  allTranslations: JsTranslation[] = [];
  filteredTranslations: JsTranslation[] = [];
  translationSubscription: any;
  filterValue: string = '';
  hasLoaded: boolean = false;
  supportedLanguageCodes: string[] = [];
  sessionSubscription: any;
  columnSubscription: any;
  sortBy: keyof JsTranslation | string = 'translationId';
  sortDirection: 'asc' | 'desc' = 'asc';
  groupValues: string[] = [];
  showGroupValues: any[] = [];
  groupedTranslations: { [key: string]: JsTranslation[] } = {};
  statusStyleMap: { [key: string]: string } = {
    Draft: 'text-bg-light',
    Paused: 'text-bg-dark',
    Reviewed: 'text-bg-review',
    Translated: 'text-bg-primary',
    Ready: 'text-bg-info',
    Implemented: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Passed: 'text-bg-success',
    Deprecated: 'text-bg-secondary',
    Removed: 'text-bg-dark'
  };

  constructor(
    private changeRef: ChangeDetectorRef,
    private snackbar: SnackbarService,
    public dialog: MatDialog,
    public cc: CacheService,
    public fbs: FirebaseService,
    private uss: UserSessionStorageService,
    private json: JsonService,
    private _dialog: DialogManagerService
  ) {
    // this.filterOptions.columns.options = [
    //   ...this.optionalColumnsStatic,
    //   ...this.supportedLanguageCodes.map((code: string) => ({
    //     label: code,
    //     value: code,
    //   })),
    // ];
    this.columnSubscription = this.filterOptions.columns.selectedEmitter.subscribe(selected => {
      this.uss.setUserSessionStorageItem('translationTableColumnFilters', selected);
    });
    this.sessionSubscription = this.uss.currentUserSessionStorage$.subscribe(snapshot => {
      this.filterOptions.columns.selected = snapshot.translationTableColumnFilters;
      this.displayedColumns = [...this.initialColumns, ...snapshot.translationTableColumnFilters];
    });
    this.fbs.getConfig().subscribe(config => {
      let supportedLanguages = _.cloneDeep(config[0].supportedLanguageCodes);
      supportedLanguages.push('test');

      if (_.isEqual(this.supportedLanguageCodes, supportedLanguages)) return;
      // All excluding 'en
      this.supportedLanguageCodes = supportedLanguages;
      this.filterOptions.columns.options = [
        ...this.optionalColumnsStatic,
        ...this.supportedLanguageCodes
          .filter(code => code !== 'en')
          .map((code: string) => ({
            label: code,
            value: code
          }))
      ];
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.translationSubscription = this.cc.translations$.subscribe((translations: JsTranslation[]) => {
        this.allTranslations = _.cloneDeep(translations);
        this.hasLoaded = true;
        this.onFilterChange();
      });
    });
  }

  ngOnDestroy(): void {
    this.translationSubscription?.unsubscribe();
  }

  sort(sort: string) {
    // Get the keys of the first translation
    const keys = Object.keys(this.allTranslations[0]);
    // Check if the key is valid
    if (!(keys.includes(sort) || this.supportedLanguageCodes.includes(sort))) {
      return;
    }

    if (this.sortBy === sort || this.sortBy === `text.${sort}`) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortDirection = 'asc';
    }

    if (this.supportedLanguageCodes.includes(sort)) {
      // Sort by language code
      this.sortBy = `text.${sort}`;
    } else {
      // Sort by translationId
      this.sortBy = sort;
    }
    this.onFilterChange();
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.onFilterChange();
  }

  resetFilter() {
    this.filterValue = '';
    this.onFilterChange();
  }

  onKeyCopy(value: string) {
    this.snackbar.show(`${value}`);
  }

  addTranslation() {
    // Open the translation details modal
    const confirmDialog = this.dialog.open(TranslationDetailsModalComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      autoFocus: false
    });
    return confirmDialog.afterClosed();
  }

  editTranslation(translation: JsTranslation) {
    // Open the translation details modal
    const confirmDialog = this.dialog.open(TranslationDetailsModalComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        translationToEdit: translation
      },
      autoFocus: false
    });
    return confirmDialog.afterClosed();
  }

  onFilterChange() {
    let filteredTranslations: JsTranslation[] = _.cloneDeep(this.allTranslations);

    // Filter by task
    if (this.filterOptions.task.selected) {
      filteredTranslations = this.cc.getTranslationsOfTask(this.filterOptions.task.selected);
    }

    // Filter by status
    if (this.filterOptions.status.selected !== 'All') {
      filteredTranslations = filteredTranslations.filter((translation: JsTranslation) => translation.status === this.filterOptions.status.selected);
    }

    // Filter by deleted
    if (this.filterOptions.show.selected === 'Active') {
      filteredTranslations = filteredTranslations.filter((translation: JsTranslation) => !translation.deletedAt);
    } else if (this.filterOptions.show.selected === 'Valid') {
      // Show only undeleted translations which has valid viewId
      filteredTranslations = filteredTranslations.filter(
        (translation: JsTranslation) => !translation.deletedAt && !this.hasInvalidTranslation(translation) && !this.hasIncompleteTranslation(translation)
      );
    } else if (this.filterOptions.show.selected === 'Incomplete') {
      // Show only undeleted translations which has missing translations
      filteredTranslations = filteredTranslations.filter((translation: JsTranslation) => !translation.deletedAt && this.hasIncompleteTranslation(translation));
    } else if (this.filterOptions.show.selected === 'Invalid') {
      // Show only undeleted translations which has invalid viewId
      filteredTranslations = filteredTranslations.filter(
        (translation: JsTranslation) => (translation.viewId && !this.cc.isValidViewId(translation.viewId) && !translation.deletedAt));
    } else {
      filteredTranslations = filteredTranslations.filter((translation: JsTranslation) => !!translation.deletedAt);
    }

    // Filter by forRelease
    if (this.filterOptions.forRelease.selected !== null) {
      const uniqueViewIds = this.cc.getUniqueViewIdsForRelease(this.filterOptions.forRelease.selected);
      filteredTranslations = filteredTranslations.filter((translation: JsTranslation) => uniqueViewIds.includes(translation.viewId));
    }

    // Filter by notForRelease
    if (this.filterOptions.notForRelease.selected !== null) {
      const uniqueViewIds = this.cc.getUniqueViewIdsForRelease(this.filterOptions.notForRelease.selected);
      filteredTranslations = filteredTranslations.filter((translation: JsTranslation) => !uniqueViewIds.includes(translation.viewId));
    }

    // Filter by inDeliverable
    if (this.filterOptions.inDeliverable.selected !== null) {
      const uniqueViewIds = this.cc.getUniqueViewIdsInDeliverable(this.filterOptions.inDeliverable.selected);
      filteredTranslations = filteredTranslations.filter((translation: JsTranslation) => uniqueViewIds.includes(translation.viewId));
    }

    // Filter by notInDeliverable
    if (this.filterOptions.notInDeliverable.selected !== null) {
      const uniqueViewIds = this.cc.getUniqueViewIdsInDeliverable(this.filterOptions.notInDeliverable.selected);
      filteredTranslations = filteredTranslations.filter((translation: JsTranslation) => !uniqueViewIds.includes(translation.viewId));
    }

    // Filter by search. Just turn them as lower case json string and search in it.
    if (this.filterValue) {
      filteredTranslations = filteredTranslations.filter((task: JsTranslation) => JSON.stringify(task).toLowerCase().includes(this.filterValue));
    }

    // Sort
    filteredTranslations = _.orderBy(filteredTranslations, [this.sortBy], [this.sortDirection]);

    // Group by GroupBy
    if (this.filterOptions.groupBy.selected === 'None') {
      // Do nothing
    } else {
      this.groupBy(filteredTranslations, this.filterOptions.groupBy.selected as keyof JsTranslation);
    }

    this.filteredTranslations = _.cloneDeep(filteredTranslations);

    this.changeRef.detectChanges();
  }

  groupBy(translations: JsTranslation[], groupKey: keyof JsTranslation) {
    this.groupedTranslations = {};
    // { '3': ['one', 'two'], '5': ['three'] } => [{groupValue: '3', groupKey: key, isGroupHeader: true, count: 12}, { groupValue: '3', value: ['one', 'two'] }, {groupValue: '5', groupKey: key}{ groupValue: '5', value: ['three'] }]
    const groupedTasks = _.groupBy(translations, translation => translation[groupKey] || '');
    // this.groupValues = this.enrichGroupedTasksAndReorder(this.groupedTasks, groupKey);
    this.enrichGroupedTasksAndReorder(groupedTasks, groupKey);
  }

  enrichGroupedTasksAndReorder(grouped: { [key: string]: JsTranslation[] }, groupKey: keyof JsTranslation) {
    const order = {
      status: [...this.filterOptions.status.options, ''],
      viewId: Object.keys(this.cc.viewsByViewId).sort(),
      uid: this.fbs.orderedUserIds
    };

    let enrichedGrouped = _.cloneDeep(grouped);

    // if (['uid', 'createdBy'].includes(groupKey)) {
    //   enrichedGrouped = this.enrichGroupedTasksWithEmptyUsers(enrichedGrouped, groupKey);
    // }

    const ordered = Object.keys(enrichedGrouped)
      // @ts-ignore
      .sort((a: string, b: string) => {
        // @ts-ignore
        if (order[groupKey]) {
          // @ts-ignore
          return order[groupKey].indexOf(a) - order[groupKey].indexOf(b);
        } else {
          // @ts-ignore
          return a - b;
        }
      });

    // Empty string should be last in the list or group as per the order
    if (ordered.includes('')) {
      ordered.splice(ordered.indexOf(''), 1);
      ordered.push('');
    } else {
      // ordered.push('');
    }

    this.groupValues = _.cloneDeep(ordered);
    this.groupedTranslations = _.cloneDeep(enrichedGrouped);
  }

  enrichGroupedTasksWithEmptyUsers(grouped: { [key: string]: JsTranslation[] }, groupKey: keyof JsTranslation) {
    const validUids = this.fbs.orderedUserIds;
    const enrichedGrouped = _.cloneDeep(grouped);
    const updatedGrouped: { [key: string]: JsTranslation[] } = {};
    validUids.forEach(uid => {
      if (enrichedGrouped[uid] !== undefined) {
        updatedGrouped[uid] = enrichedGrouped[uid];
      } else {
        updatedGrouped[uid] = [];
      }
    });
    if (enrichedGrouped[''] !== undefined) {
      updatedGrouped[''] = enrichedGrouped[''];
    }
    return updatedGrouped;
  }

  toggleHiddenGroup(groupValue: string) {
    if (this.showGroupValues.includes(groupValue)) {
      this.showGroupValues = this.showGroupValues.filter(value => value !== groupValue);
    } else {
      this.showGroupValues.push(groupValue);
    }
    this.changeRef.detectChanges();
  }

  hasIncompleteTranslation(translation: JsTranslation) {
    let translationText = _.cloneDeep(translation.text);
    if (translationText.hasOwnProperty('test')) {
      delete translationText['test'];
    }
    const expectedLanguageCodes = [...this.cc.config.supportedLanguageCodes].sort();
    const actualLanguageCodes = Object.keys(translationText)
      .filter(code => translationText[code])
      .sort();
    return !_.isEqual(expectedLanguageCodes, actualLanguageCodes);
  }

  hasInvalidTranslation(translation: JsTranslation) {
    return !this.cc.viewsByViewId[translation.viewId];
  }

  resetFilters() {
    this.filterValue = '';
    this.filterOptions.status.selected = 'All';
    this.filterOptions.show.selected = 'Active';
    this.filterOptions.task.selected = null;
    this.sortBy = 'translationId';
    this.sortDirection = 'asc';
    this.filterOptions.forRelease.selected = null;
    this.filterOptions.notForRelease.selected = null;
    this.filterOptions.inDeliverable.selected = null;
    this.filterOptions.notInDeliverable.selected = null;
    this.onFilterChange();
  }

  publish() {
    const translations: any = {};
    const errors: string[] = [];
    const publishLanguageCodes = [...this.cc.config.publishLanguageCodes, 'test'];
    publishLanguageCodes.forEach((code: string) => {
      translations[code] = {};
    });
    const translationsToDownload = this.allTranslations
      .filter(
        translation =>
          !translation.deletedAt &&
          ['Ready', 'Implemented', 'Failed', 'Passed', 'Deprecated'].includes(translation.status) &&
          !this.hasInvalidTranslation(translation) &&
          !this.hasIncompleteTranslation(translation)
      )
      .sort((a, b) => a.translationId.localeCompare(b.translationId));
    translationsToDownload.forEach(translation => {
      publishLanguageCodes.forEach((code: string) => {
        // @ts-ignore
        translations[code][translation.translationId] = translation.text[code] || '';

        const variables: { var: string; eg: string }[] = [];
        // Add variables
        if (translation.variables) {
          Object.keys(translation.variables)
            .sort()
            .forEach(variableKey => {
              // @ts-ignore
              variables.push({
                var: variableKey,
                eg: translation.variables[variableKey][code] || ''
              });

              // Replace example with {{var}} in translation
              // @ts-ignore
              translations[code][translation.translationId] =
                // @ts-ignore
                translations[code][translation.translationId].replace(
                  // @ts-ignore
                  translation.variables[variableKey][code],
                  `{{${variableKey}}}`
                );
            });
        }

        if (variables.length) {
          // @ts-ignore
          translations[code][`@${translation.translationId}`] = variables;
        }

        // Check for missing translations
        if (!translation.text[code]) {
          errors.push(`Translation ${translation.translationId} is missing ${code} translation`);
        }

        // Check for variables with empty values/examples
        variables.forEach(variable => {
          if (!variable.var) {
            errors.push(`Translation ${translation.translationId} has empty variable value for ${variable.eg}`);
          }
          if (!variable.eg) {
            errors.push(`Translation ${translation.translationId} has empty variable example for ${variable.var}`);
          }
        });

        // Check for invalid viewId
        if (!this.cc.viewsByViewId[translation.viewId]) {
          errors.push(`Translation ${translation.translationId} has invalid viewId ${translation.viewId}`);
        }
      });
    });
    this.json.publishJson('translations', translations, errors, true);
  }

  showHistory(e: any) {
    this._dialog.openDialog(ListPublicationsComponent, {
      data: {
        type: 'translations'
      }
    });
    e.stopPropagation();
  }

  trackByForTranslation(index: number, translation: JsTranslation) {
    return translation.id;
  }

  trackByForGroup(index: number, group: string) {
    return group;
  }
}
