<div class="preview-history p-3" #previewContainer>
  <div class="preview-history__container d-flex overflow-auto">
    <div class="preview-history__item text-center me-2" *ngFor="let preview of sortedPreviews; let i = index">
      <div class="preview-history__date d-flex justify-content-between" matTooltip="{{ preview.updatedAt | date : 'medium' }}" matTooltipPosition="above">
        <span>{{ preview.updatedAt | dfnsFormatDistanceToNowStrict }} ago</span>
        <span class="badge text-bg-secondary ri-pointer" (click)="showDiff(i)">Diff</span>
      </div>
      <img [src]="preview.url" alt="preview" class="img-fluid" />
    </div>
  </div>
</div>
