export const environment = {
  firebase: {
    projectId: 'mevolve-project',
    appId: '1:1017739510521:web:01d977a4c4be60b69d9828',
    storageBucket: 'mevolve-project-assets',
    apiKey: 'AIzaSyBaqED-GX2dtD76Yx8qdl7tURE8n1o-1wQ',
    authDomain: 'mevolve-project.firebaseapp.com',
    messagingSenderId: '1017739510521',
    measurementId: 'G-X84REGK57R',
  },
  production: true,
  version: '##VERSION##',
  buildDate: '##DATE##',
  useEmulators: false,
};
