import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { CommentFormComponent } from '../comment-form/comment-form.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentComponent } from '../comment/comment.component';
import { MatTooltip } from '@angular/material/tooltip';
import { DateFnsModule } from 'ngx-date-fns';
import { FbComment, JsComment } from 'src/app/_interfaces/Comment';
import { entity } from 'src/app/_interfaces/Activity';
import { FirestoreQueryCondition } from 'src/app/_interfaces/Other';
import { Subject, takeUntil } from 'rxjs';
import _ from 'lodash';
import { JsEntity } from 'src/app/_interfaces/Entities';
import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/firestore';

@Component({
  selector: 'app-comment-list',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatMenuModule,
    CommentComponent,
    MatTooltip,
    DateFnsModule
  ],
  templateUrl: './comment-list.component.html',
  styleUrl: './comment-list.component.scss'
})

export class CommentListComponent implements OnInit {

  commentsList: WritableSignal<JsComment[]> = signal([]);
  unSubscribe = new Subject<void>();
  lastDocument: QueryDocumentSnapshot<FbComment, DocumentData> | null = null;
  allDataLoaded: boolean = false;
  @ViewChild('commentList') private commentList!: ElementRef;
  maxComments: number = 5;
  isLoading: boolean = false;

  constructor(
    public fbs: FirebaseService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { entityType: entity, entity: JsEntity },
    public dialogRef: MatDialogRef<CommentListComponent>,
    private changeRef: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.getCommentByEntity(false);
  }

  getCommentByEntity(isLoadMore: boolean) {
    this.isLoading = true;
    if (this.allDataLoaded) {
      return;
    }
    if (!isLoadMore) {
      this.lastDocument = null;
      this.allDataLoaded = false;
    }
    const filters: FirestoreQueryCondition[] = [];
    filters.push({
      field: 'entity',
      operator: '==',
      value: this.data.entityType,
    });
    filters.push({
      field: 'entityId',
      operator: '==',
      value: this.data.entity.id,
    });

    // const previousScrollHeight = this.commentList?.nativeElement.scrollHeight;
    // const previousScrollTop = this.commentList?.nativeElement.scrollTop;

    this.fbs.getComments(this.maxComments, filters, this.lastDocument).pipe(takeUntil(this.unSubscribe)).subscribe(({ data: comments, lastDocument }) => {
      if (comments.length > 0) {
        const scrollPos = this.commentList.nativeElement.scrollTop;
        if (lastDocument) {
          this.lastDocument = lastDocument as unknown as QueryDocumentSnapshot<FbComment, DocumentData>;
        }
        const sortedActivities = _.sortBy(comments, 'createdAt').reverse();
        if (isLoadMore) {
          this.commentsList.set([...this.commentsList(), ...sortedActivities]);
          setTimeout(() => {
            // const newScrollHeight = this.commentList.nativeElement.scrollHeight;
            // const heightDifference = newScrollHeight - previousScrollHeight;
            this.commentList.nativeElement.scrollTop = scrollPos;
          });
        } else {
          this.commentsList.set(sortedActivities);
          // setTimeout(() => {
          //   this.scrollToBottom(); 
          // });
        }
      } else {
        if (isLoadMore) {
          this.allDataLoaded = true;
        } else {
          this.commentsList.set([]);
        }
      }
      this.isLoading = false;
      this.updateChanges();
    });
  }

  scrollToTop(): void {
    this.commentList.nativeElement.scrollTo({
      // top: this.commentList.nativeElement.scrollHeight,
      top: 0,
      behavior: 'smooth' // Adds smooth scrolling effect
    });
  }

  onCommentsScroll(): void {
    // const scrollTop = this.commentList.nativeElement.scrollTop;
    // if (scrollTop === 0 && this.commentsList().length >= this.maxComments && !this.allDataLoaded) {
    //   this.getCommentByEntity(true);
    // }
    const element = this.commentList.nativeElement;
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight;
    const clientHeight = element.clientHeight;
  
    // Check if scrolled to bottom
    if (scrollTop + clientHeight >= scrollHeight && this.commentsList().length >= this.maxComments && !this.allDataLoaded) {
      this.getCommentByEntity(true);
    }
  }

  updateChanges() {
    this.changeRef.detectChanges();
  }

  openCommentForm(event: any, mode: 'new' | 'edit', comment?: JsComment) {
    event.stopPropagation();
    const commentForm = this.dialog.open(CommentFormComponent, {
      width: '500px !important',
      height: '800px',
      maxWidth: '500px !important',
      maxHeight: '80vh',
      disableClose: true,
      data: {
        mode: mode,
        commentValue: comment,
        entityType: this.data.entityType,
        entity: _.cloneDeep(this.data.entity)
      },
    });

    commentForm.afterClosed().pipe(takeUntil(this.unSubscribe)).subscribe((scrollToTop: boolean) => {
      if (scrollToTop) {
        this.scrollToTop();
      }
    });
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
