<span class="ri-pointer" *ngIf="!isEmpty; else noSpecs" (click)="showSpecList(viewId, $event)">
  <span class="badge rounded-pill text-bg-info me-1" *ngIf="Backlog" matTooltip="Backlog">
    {{Backlog || ''}}
  </span>
  <span class="badge rounded-pill text-bg-secondary me-1" *ngIf="Design" matTooltip="Design">
    {{Design || ''}}
  </span>
  <span class="badge rounded-pill text-bg-review me-1" *ngIf="Review" matTooltip="Review">
    {{Review || ''}}
  </span>
  <span class="badge rounded-pill text-bg-dark-danger me-1" *ngIf="Correction" matTooltip="Correction">
    {{Correction || ''}}
  </span>
  <span class="badge rounded-pill text-bg-primary me-1" *ngIf="Develop" matTooltip="Develop">
    {{Develop || ''}}
  </span>
  <span class="badge rounded-pill text-bg-warning me-1" *ngIf="Test" matTooltip="Test">
    {{Test || ''}}
  </span>
  <span class="badge rounded-pill text-bg-danger me-1" *ngIf="Failed" matTooltip="Failed">
    {{Failed || ''}}
  </span>
  <span class="badge rounded-pill text-bg-success me-1" *ngIf="Approve" matTooltip="Approve">
    {{Approve || ''}}
  </span>
  <span class="badge rounded-pill text-bg-dark me-1" matTooltip="Total">
    {{ Total || ''}}
  </span>
</span>
<ng-template #noSpecs>-</ng-template>