<div>
  <div class="ri-filter-row">
    <mat-form-field [class]="{ edited: filterValue }" floatLabel="always">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Feature titles, status, subStatus, priority, type, etc..." [(ngModel)]="filterValue" />
      <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="resetFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <span class="ri-filter-count">{{ filteredTasks.length }}</span>
    <app-select-filter
      #columnFilter
      [options]="filterOptions.columns.options"
      [title]="filterOptions.columns.title"
      class="me-1"
      [initialSelection]="filterOptions.columns.selected"
      [resetSelection]="[]"
      [selectedEmitter]="filterOptions.columns.selectedEmitter"></app-select-filter>
    <button class="btn btn-warning btn-sm" (click)="resetFilters()">Reset Filters</button>
    <button class="btn btn-primary btn-sm ms-1" (click)="toggleFocusMode()" matTooltip="Focus Mode">
      <fa-icon class="icon mx-1" [icon]="faUser"></fa-icon>
    </button>
    <button class="btn btn-review btn-sm ms-1" (click)="toggleShowPlan()" *ngIf="isShowPlanApplicable()">
      {{ showPlan ? 'Hide Plan' : 'Show Plan' }}
    </button>
    <button class="btn btn-secondary btn-sm ms-1" (click)="showDeliverables()" [disabled]="filterOptions.groupBy.selected === 'deliverable'">
      Deliverables
    </button>
    <button class="btn btn-primary btn-sm ms-1" (click)="addTask()">Add</button>
  </div>
  <div class="ri-filter-row ri-filter-row-background">
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.groupBy.selected !== 'assignedTo' }">
      <mat-label>Group By</mat-label>
      <mat-select [(value)]="filterOptions.groupBy.selected" placeholder="Group By" (selectionChange)="this.showGroupValues = []; onFilterChange()">
        <mat-option *ngFor="let item of filterOptions.groupBy.options" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.forRelease.selected !== null }">
      <mat-label>For release</mat-label>
      <mat-select [(value)]="filterOptions.forRelease.selected" placeholder="For release" (selectionChange)="onFilterChange()">
        <mat-option [value]="null">All</mat-option>
        <mat-option *ngFor="let item of filterOptions.forRelease.options" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.notForRelease.selected !== null }">
      <mat-label>Not in release</mat-label>
      <mat-select [(value)]="filterOptions.notForRelease.selected" placeholder="Not in release" (selectionChange)="onFilterChange()">
        <mat-option [value]="null">All</mat-option>
        <mat-option *ngFor="let item of filterOptions.notForRelease.options" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-select-filter
      #showFilter
      [options]="filterOptions.show.options"
      class="me-1"
      [title]="filterOptions.show.title"
      [initialSelection]="filterOptions.show.selected"
      [resetSelection]="filterOptions.show.resetSelection"
      (selectedValueChange)="filterOptions.show.onSelectedValueChange($event)"
      [resetSignal]="filterOptions.show.resetSignal"></app-select-filter>
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.assignedTo.selected !== '' }">
      <mat-label>Assigned To</mat-label>
      <mat-select [(value)]="filterOptions.assignedTo.selected" placeholder="Assigned To" (selectionChange)="onFilterChange()">
        <mat-option [value]="''">All</mat-option>
        <mat-option value="UNASSIGNED">UNASSIGNED</mat-option>
        <mat-option *ngFor="let user of fbs.users" [value]="user.value">
          {{ user.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.status.selected !== 'All' }">
      <mat-label>Status</mat-label>
      <mat-select [(value)]="filterOptions.status.selected" placeholder="Status" (selectionChange)="onFilterChange()">
        <mat-option *ngFor="let item of filterOptions.status.options" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.subStatus.selected !== 'All' }">
      <mat-label>Sub status</mat-label>
      <mat-select [(value)]="filterOptions.subStatus.selected" placeholder="Sub status" (selectionChange)="onFilterChange()">
        <mat-option *ngFor="let item of filterOptions.subStatus.options" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.type.selected !== 'All' }">
      <mat-label>Type</mat-label>
      <mat-select [(value)]="filterOptions.type.selected" placeholder="Type" (selectionChange)="onFilterChange()">
        <mat-option *ngFor="let item of filterOptions.type.options" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.priority.selected !== 'All' }">
      <mat-label>Priority</mat-label>
      <mat-select [(value)]="filterOptions.priority.selected" placeholder="Priority" (selectionChange)="onFilterChange()">
        <mat-option *ngFor="let item of filterOptions.priority.options" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- productOwner -->
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.productOwner.selected !== '' }">
      <mat-label>Product Owner</mat-label>
      <mat-select [(value)]="filterOptions.productOwner.selected" placeholder="Product Owner" (selectionChange)="onFilterChange()">
        <mat-option [value]="''">All</mat-option>
        <mat-option value="UNASSIGNED">UNASSIGNED</mat-option>
        <mat-option *ngFor="let user of fbs.users" [value]="user.value">
          {{ user.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- designOwner -->
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.designOwner.selected !== '' }">
      <mat-label>Design Owner</mat-label>
      <mat-select [(value)]="filterOptions.designOwner.selected" placeholder="Design Owner" (selectionChange)="onFilterChange()">
        <mat-option [value]="''">All</mat-option>
        <mat-option value="UNASSIGNED">UNASSIGNED</mat-option>
        <mat-option *ngFor="let user of fbs.users" [value]="user.value">
          {{ user.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- devOwner -->
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.devOwner.selected !== '' }">
      <mat-label>Dev Owner</mat-label>
      <mat-select [(value)]="filterOptions.devOwner.selected" placeholder="Dev Owner" (selectionChange)="onFilterChange()">
        <mat-option [value]="''">All</mat-option>
        <mat-option value="UNASSIGNED">UNASSIGNED</mat-option>
        <mat-option *ngFor="let user of fbs.users" [value]="user.value">
          {{ user.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- testOwner -->
    <mat-form-field class="ri-min-w-200" appearance="fill" [class]="{ edited: filterOptions.testOwner.selected !== '' }">
      <mat-label>Test Owner</mat-label>
      <mat-select [(value)]="filterOptions.testOwner.selected" placeholder="Test Owner" (selectionChange)="onFilterChange()">
        <mat-option [value]="''">All</mat-option>
        <mat-option value="UNASSIGNED">UNASSIGNED</mat-option>
        <mat-option *ngFor="let user of fbs.users" [value]="user.value">
          {{ user.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<!-- Table content -->
<div class="table-block text-nowrap w-100 overflow-x-scroll">
  <div class="table-wrapper">
    <div class="sticky-top header-row">
      <span
        class="header-cell ri-pointer"
        [class]="column"
        [ngClass]="{
          'fw-bolder text-primary': sortBy === column && sortDirection === 'asc',
          'fw-bolder text-danger': sortBy === column && sortDirection === 'desc'
        }"
        *ngFor="let column of displayedColumns">
        <span class="header" (click)="sort(column)">
          <span>{{ column }}</span>
        </span>
      </span>
    </div>
    <div class="task-block" *ngIf="filterOptions.groupBy.selected === 'None'">
      <div *ngFor="let task of filteredTasks; trackBy: trackByForTask">
        <ng-container *ngTemplateOutlet="taskRow; context: { $implicit: task }"></ng-container>
      </div>
      <div class="group-total-row">
        <ng-container *ngTemplateOutlet="groupTotal"></ng-container>
      </div>
      <div class="ri-bottom-spacer"></div>
    </div>
    <div class="group-block" *ngIf="filterOptions.groupBy.selected !== 'None'">
      <div *ngFor="let group of groupValues; trackBy: trackByForGroup">
        <div class="group-header-row">
          <ng-container *ngTemplateOutlet="groupHeader; context: { $implicit: group }"></ng-container>
        </div>
      </div>
      <div class="group-total-row">
        <ng-container *ngTemplateOutlet="groupTotal"></ng-container>
      </div>
      <div class="ri-bottom-spacer"></div>
    </div>
  </div>
</div>

<!-- task row -->
<ng-template #taskRow let-element>
  <div class="task-row ri-pointer" [class]="element.status" (click)="editTask(element)">
    <span class="task-row-inner {{ col }}" *ngFor="let col of displayedColumns">
      <ng-container [ngSwitch]="col">
        <ng-container *ngSwitchCase="'title'">
          <span>
            <span>{{ element[col] ?? '-' }}</span>
            <span matTooltip="Related to" *ngIf="element.relatedTids?.length" class="badge text-bg-primary ms-1">
              {{ element.relatedTids?.length }}
            </span>
            <span matTooltip="Blocked by" *ngIf="element.blockedByTids.length || element.subStatus === 'Blocked'" class="badge text-bg-warning ms-1">
              {{ element.blockedByTids.length }}
            </span>
            <span matTooltip="Blocking" *ngIf="cc.tidBlockingTaskMap[element.tid]?.length" class="badge text-bg-danger ms-1">
              {{ cc.tidBlockingTaskMap[element.tid].length }}
            </span>
            <span *ngIf="cc.featureDailyHrsMap[element.tid] && cc.featureDailyHrsMap[element.tid] !== 0" matTooltip="Today log" class="badge bg-secondary ms-1">{{ cc.featureDailyHrsMap[element.tid] }} Hr</span>
            <!-- <span matTooltip="Related Todo's" class="badge bg-dark ms-1">{{ relatedTodoByTaskId[element.id] && relatedTodoByTaskId[element.id].length }}</span> -->
             <!-- <span matTooltip="Specs without viewId" class="badge bg-dark ms-1" *ngIf="cc.getSpecsWithoutViewIdCount(element) !== 0">{{ cc.getSpecsWithoutViewIdCount(element) }}</span> -->
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'hours'">
          <span>{{ getTotalHours(element) | number : '1.0-1' }}&nbsp;hr</span>
        </ng-container>
        <ng-container *ngSwitchCase="'cloudUpdatedAt'">
          <span>{{ element[col] | dfnsFormatDistanceToNowStrict }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'createdAt'">
          <span>{{ element[col] | dfnsFormatDistanceToNowStrict }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'deletedAt'">
          <span>{{ element[col] | dfnsFormatDistanceToNowStrict }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'uid'">
          <span>{{ fbs.getDisplayName(element[col]) }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'assignedTo'">
          <span>{{ fbs.getDisplayName(element[col]) }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'productOwner'">
          <span>{{ fbs.getDisplayName(element[col]) }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'designOwner'">
          <span>{{ fbs.getDisplayName(element[col]) }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'devOwner'">
          <span>{{ fbs.getDisplayName(element[col]) }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'testOwner'">
          <span>{{ fbs.getDisplayName(element[col]) }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'fid'">
          <span class="text-primary">F-{{ element.tid }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'percentageCompleted'">
          <span>{{ element[col] }}&nbsp;%</span>
        </ng-container>
        <ng-container *ngSwitchCase="'status'">
          <span class="d-inline-block" [ngClass]="statusStyleMap[element?.[col]]">{{ element[col] }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'Specs Status'">
          <span class="text-nowrap">
            <app-spec-status-counts-of-task [task]="element"></app-spec-status-counts-of-task>
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'View Status'">
          <span class="text-nowrap" (click)="showViewBlocksOfTask(element, $event)">
            <app-view-status-counts-of-task [task]="element"></app-view-status-counts-of-task>
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'Translation Status'">
          <span class="text-nowrap">
            <app-translation-status-counts-of-task [task]="element"></app-translation-status-counts-of-task>
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'Todo Status'">
          <span class="text-nowrap">
            <app-todo-status-count-of-todo [todos]="relatedTodoByTaskId[element.id] ? relatedTodoByTaskId[element.id] : []" (onUpdate)="detectChanges()" (click)="relatedTodoByTaskId[element.id] && viewRelatedTodosList($event, element)"></app-todo-status-count-of-todo>
          </span>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span>{{ element[col] ?? '-' }}</span>
        </ng-container>
      </ng-container>
    </span>
  </div>
</ng-template>

<!--  Group header -->
<ng-template #groupHeader let-groupValue>
  <div (click)="toggleHiddenGroup(groupValue)" class="ri-pointer group-wrapper py-1" *ngIf="!(isFocusMode && !groupedTasks[groupValue].length)">
    <div class="position-relative">
      <strong class="group-key">
        {{ filterOptions.groupBy.selected }} -
        {{
          ['assignedTo', 'uid', 'productOwner', 'designOwner', 'devOwner', 'testOwner'].includes(filterOptions.groupBy.selected)
            ? fbs.getDisplayName(groupValue)
            : groupValue
        }}
        ({{ groupedTasks[groupValue].length }})
      </strong>
      <app-task-status-count-of-tasks
        class="px-2 task-status-counts"
        [tasks]="this.groupedTasks[groupValue]"
        (onUpdate)="detectChanges()"></app-task-status-count-of-tasks>

      <app-spec-status-count-of-tasks
        class="px-2 spec-status-counts"
        [tasks]="this.groupedTasks[groupValue]"
        (onUpdate)="detectChanges()"></app-spec-status-count-of-tasks>

      <app-owner-badges
        *ngIf="filterOptions.groupBy.selected === 'deliverable'"
        class="px-2 owner-badges"
        [deliverable]="groupValue"
        (onUpdate)="detectChanges()"></app-owner-badges>
    </div>
    <div
      class="p-2"
      *ngIf="
        showPlan &&
        ['assignedTo', 'uid', 'productOwner', 'designOwner', 'devOwner', 'testOwner'].includes(filterOptions.groupBy.selected) &&
        getPlanWidget(fbs.getDisplayName(groupValue))
      ">
      <span (click)="openPlanWidget(getPlanWidget(fbs.getDisplayName(groupValue)), $event)" matTooltip="Weekly Plan" class="text-muted plan-text">
        <pre class="plan mb-0">{{ getPlanWidget(fbs.getDisplayName(groupValue))?.description }}</pre>
      </span>
    </div>
  </div>
  <div *ngIf="showGroupValues.includes(groupValue)">
    <div *ngFor="let task of groupedTasks[groupValue]; trackBy: trackByForTask">
      <ng-container *ngTemplateOutlet="taskRow; context: { $implicit: task }"></ng-container>
    </div>
  </div>
</ng-template>

<!--  Group Total -->
<ng-template #groupTotal>
  <div class="group-wrapper total py-1">
    <div class="position-relative" (click)="cc.showTaskProgressAsPercentage = !cc.showTaskProgressAsPercentage">
      <strong class="group-key">TOTAL - ({{ filteredTasks.length }})</strong>
      <app-task-status-count-of-tasks
        class="px-2 task-status-counts"
        [tasks]="this.filteredTasks"
        (onUpdate)="detectChanges()"></app-task-status-count-of-tasks>

      <app-spec-status-count-of-tasks
        class="px-2 spec-status-counts"
        [tasks]="this.filteredTasks"
        (onUpdate)="detectChanges()"></app-spec-status-count-of-tasks>
    </div>
  </div>
</ng-template>
