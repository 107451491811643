<div class="d-flex sticky-top justify-content-between bg-light px-2">
  <h4 class="p-2 text-center title m-0">{{who ? fbs.getUserNameFromId(who): ''}} {{when ? ' - ' +
    getHumanReadableDate(when) : ''}}
    ({{this.totalCount}})</h4>
  <mat-form-field appearance="fill" class="p-2">
    <mat-label>Group By</mat-label>
    <mat-select [(value)]="selectedGroupBy" (selectionChange)="setSelectedGroupBy($event)">
      <mat-option *ngFor="let group of groupByOptions" [value]="group.value">
        {{group.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- close icon -->
  <div class="close-icon p-2" (click)="close()">
    <fa-icon class="icon fs-4" [icon]="faTimes"></fa-icon>
  </div>
</div>
<div>
  <as-split unit="pixel" [gutterSize]="2">
    <as-split-area>
      <div *ngFor="let status of statusList; trackBy: trackByStatus">
        <h6 class="p-2 text-center {{'text-'+ $any(mapClass)[status]}}">
          {{ status }} ({{statusWidgetMap[status].length}})
        </h6>
        <div *ngFor="let widget of statusWidgetMap[status]; trackBy: trackByIdAndTime"
          [class]="{'selected': widget && selectedWidgetId === widget.id}"
          class="card m-2 d-flex justify-content-between flex-row cursor-ri-pointer" (click)="onSelect(widget)">
          <div class="text-start w-75 p-2">
            <div class="path" [class]="getTypeClass(widget)">{{widget.path}}
              <!-- <span class="badge rounded-pill {{getTypeClass(activity)}}">{{activity?.[selectedGroupBy]?.type}}</span> -->
            </div>
            <div class="text-capitalize d-flex mt-2">
              <span [matTooltip]="selectedGroupBy"
                class="badge me-2 {{getStatusClass(widget)}}">{{widget.status}}</span>
            </div>
          </div>
          <div class="text-end w-25 p-2 d-flex flex-column">
            <div *ngIf="who">{{ fbs.getUserNameFromId(who) }}</div>
            <div class="mt-2">
              <span class="text-capitalize badge rounded-pill bg-secondary">{{widget.updatedAt |
                dfnsFormatDistanceToNow}} ago</span>
            </div>
          </div>
        </div>
      </div>
    </as-split-area>

    <!-- // DETAIL FORM AREA -->
    <as-split-area [size]="475" [maxSize]="650" [minSize]="475" class="position-relative">
      <app-widget-detail mode="activity" [widget]="selectedWidget"></app-widget-detail>
    </as-split-area>
  </as-split>
</div>