<main class="feature-form pb-3 ri-min-w-1000">

  <div class="d-flex justify-content-between mb-3 p-3" [ngClass]="{ 'bg-danger': currentRegression && regressionResult === 'FAIL', 'bg-success': currentRegression && regressionResult === 'PASS' }">
    <h2 class="d-inline-block mb-0" [ngClass]="{ 'text-white': currentRegression && (regressionResult === 'FAIL' || regressionResult === 'PASS') }">Feature Details <span class="text-primary fs-5" [ngClass]="{ 'text-white': currentRegression && (regressionResult === 'FAIL' || regressionResult === 'PASS') }"> (F-{{initialTask.tid}})</span></h2>
    <div class="d-flex" *ngIf="mode === 'edit'">

      <mat-button-toggle-group class="regression-type me-2" *ngIf="mode === 'edit' && currentRegression && !taskToEdit.deletedAt" [(ngModel)]="regressionResult" aria-label="Font Style" (change)="onRegressionChange($event)">
        <mat-button-toggle [value]="''">None</mat-button-toggle>
        <mat-button-toggle value="PASS">PASS</mat-button-toggle>
        <mat-button-toggle value="FAIL">FAIL</mat-button-toggle>
      </mat-button-toggle-group>


      <button *ngIf="taskToEdit.deletedAt" matTooltip="Restore" class="btn btn-danger btn-sm btn-restore d-flex align-items-center me-2" (click)="deleteHandler(false)"><mat-icon class="text-light">replay</mat-icon></button>
      <button  *ngIf="!taskToEdit.deletedAt" class="btn btn-primary btn-sm float-end me-2" (click)="openDailyPlanModal(cc.featureDailyHrsMap[initialTask.tid] >= 0)"> {{ cc.featureDailyHrsMap[initialTask.tid] ? cc.featureDailyHrsMap[initialTask.tid] + ' Hr' : 'Log Time'}}</button>
      <button *ngIf="!taskToEdit.deletedAt" matTooltip="Comment" class="btn btn-comment btn-sm btn-success d-flex align-items-center h-100 me-2" (click)="sharedFunc.getComments('task', taskInitial)" [ngClass]="{ 'bg-white text-success': currentRegression && regressionResult === 'PASS' }">
        <mat-icon class="pe-0">question_answer</mat-icon>
        <span class="ps-2" *ngIf="cc.idToTaskMap[taskInitial.id]?.latestComment">{{ cc.idToTaskMap[taskInitial.id].latestComment?.updatedAt | dfnsFormatDistanceToNowStrict }} ago</span>
      </button>
      <button matTooltip="Get Activity" class="btn btn-info btn-sm btn-activity d-flex align-items-center" (click)="getActivity()"><mat-icon class="text-light">restore</mat-icon></button>
    </div>
  </div>

  <div class="ri-dialog-content px-3" [ngClass]="{'ri-pe-none': taskToEdit.deletedAt}">
    <mat-form-field appearance="fill" class="w-100 mt-1" [class]="{'edited' : initialTask.title !== taskToEdit.title}">
      <mat-label>Title (Required)</mat-label>
      <input #taskTitle type="text" placeholder="Add Time based habit" matInput [(ngModel)]="taskToEdit.title"
        (ngModelChange)="isValid()">
    </mat-form-field>
  
    <!-- Text area for Description -->
    <mat-form-field appearance="fill" class="w-100 mt-1"
      [class]="{'edited' : initialTask.description !== taskToEdit.description}">
      <mat-label>What to do / User stories / Optimisation details (Bullet Points)</mat-label>
      <textarea #taskDescription matInput [(ngModel)]="taskToEdit.description" cdkTextareaAutosize
        (ngModelChange)="isValid()" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="9"></textarea>
    </mat-form-field>
  
    <!-- First set -->
    <mat-form-field class="w-12-5 pe-1" appearance="fill"
      [class]="{'edited': initialTask.fromRelease != taskToEdit.fromRelease}">
      <mat-label>From Release</mat-label>
      <mat-select [(value)]="taskToEdit.fromRelease" (selectionChange)="isValid()">
        <mat-option *ngFor="let release of cc.availableMainReleaseNumbers()" [value]="release">{{release}}</mat-option>
        <mat-option [value]="null">None</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-12-5 pe-1" appearance="fill"
      [class]="{'edited': initialTask.toRelease != taskToEdit.toRelease}">
      <mat-label>To Release</mat-label>
      <mat-select [(value)]="taskToEdit.toRelease" (selectionChange)="isValid()"
        [disabled]="taskToEdit.fromRelease === null || taskToEdit.fromRelease === undefined">
        <mat-option *ngFor="let release of cc.availableMainReleaseNumbers()" [value]="release">{{release}}</mat-option>
        <mat-option [value]="null">None</mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field class="w-25 pe-1" appearance="fill" [class]="{'edited': initialTask.type != taskToEdit.type}">
      <mat-label>Feature Type</mat-label>
      <mat-select [(value)]="taskToEdit.type">
        <mat-option *ngFor="let type of taskTypes" [value]="type">{{type}}</mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field class="w-25 pe-1" appearance="fill" [class]="{'edited': initialTask.priority != taskToEdit.priority}">
      <mat-label>Feature Priority</mat-label>
      <mat-select [(value)]="taskToEdit.priority">
        <mat-option *ngFor="let priority of taskPriorities" [value]="priority">{{priority}}</mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field appearance="fill" class="w-25 mt-1 pe-1" [class]="{'edited' : initialTask.order !== taskToEdit.order}">
      <mat-label>Feature Order</mat-label>
      <input type="number" placeholder="8.2" matInput [(ngModel)]="taskToEdit.order" type="number" max="100000" min="0">
    </mat-form-field>

    <!-- <mat-form-field appearance="fill" class="w-17-5 mt-1" [class]="{ edited: isRegressionEdited() }">
      <mat-label>Regression</mat-label>
      <mat-select
        [(value)]="regressionResult"
        (selectionChange)="onRegressionChange($event)"
        [disabled]="mode !== 'edit' || !currentRegression">
        <mat-option [value]="''">- - -</mat-option>
        <mat-option value="PASS">PASS</mat-option>
        <mat-option value="FAIL">FAIL</mat-option>
      </mat-select>
    </mat-form-field> -->
  
    <div class="py-3"> </div>
  
    <!-- Spec Prefixes -->
    <mat-form-field appearance="fill" class="w-50 mt-1 pe-1"
      [class]="{'edited' : initialTask.includePatterns !== taskToEdit.includePatterns}">
      <mat-label>Spec Include Pattern (eg: appMobile*, *past*, *illustration)</mat-label>
      <textarea matInput [(ngModel)]="taskToEdit.includePatterns" cdkTextareaAutosize
        placeholder="appMobile_screens*, *past*" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="9"></textarea>
    </mat-form-field>
  
    <mat-form-field appearance="fill" class="w-50 mt-1"
      [class]="{'edited' : initialTask.excludePatterns !== taskToEdit.excludePatterns}">
      <mat-label>Spec Exclude Pattern (eg: appMobile*, *past*, *illustration)</mat-label>
      <textarea matInput [(ngModel)]="taskToEdit.excludePatterns" cdkTextareaAutosize
        placeholder="appMobile_screens*, *past*" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="9"></textarea>
    </mat-form-field>
  
    <!-- Third set -->
    <div class="pt-1">
      <mat-form-field class="w-25 pe-1" appearance="fill"
        [class]="{'edited': initialTask.productOwner != taskToEdit.productOwner}">
        <mat-label>Product Owner</mat-label>
        <mat-select [(value)]="taskToEdit.productOwner">
          <mat-option [value]="''">None</mat-option>
          <mat-option *ngFor="let user of fbs.users" [value]="user.value">{{user.label}}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field class="w-25 pe-1" appearance="fill"
        [class]="{'edited': initialTask.designOwner != taskToEdit.designOwner}">
        <mat-label>Design Owner</mat-label>
        <mat-select [(value)]="taskToEdit.designOwner">
          <mat-option [value]="''">None</mat-option>
          <mat-option *ngFor="let user of fbs.users" [value]="user.value">{{user.label}}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field class="w-25 pe-1" appearance="fill"
        [class]="{'edited': initialTask.devOwner != taskToEdit.devOwner}">
        <mat-label>Dev Owner</mat-label>
        <mat-select [(value)]="taskToEdit.devOwner">
          <mat-option [value]="''">None</mat-option>
          <mat-option *ngFor="let user of fbs.users" [value]="user.value">{{user.label}}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field class="w-25 pe-1" appearance="fill"
        [class]="{'edited': initialTask.testOwner != taskToEdit.testOwner}">
        <mat-label>Test Owner</mat-label>
        <mat-select [(value)]="taskToEdit.testOwner">
          <mat-option [value]="''">None</mat-option>
          <mat-option *ngFor="let user of fbs.users" [value]="user.value">{{user.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  
    <div class="py-3"> </div>
  
    <!-- Text area for Current Scope -->
    <mat-form-field appearance="fill" class="w-100 mt-1"
      [class]="{'edited' : initialTask.currentScope !== taskToEdit.currentScope}">
      <mat-label>Notes for the assignee / Edge cases / Blocker Details (Bullet Points)</mat-label>
      <textarea #currentScope matInput [(ngModel)]="taskToEdit.currentScope" cdkTextareaAutosize
        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="9"></textarea>
    </mat-form-field>
  
    <!-- Second set -->
    <mat-form-field class="w-20 pe-1" appearance="fill"
      [class]="{'edited': initialTask.assignedTo != taskToEdit.assignedTo}">
      <mat-label>Assigned To</mat-label>
      <mat-select [(value)]="taskToEdit.assignedTo">
        <mat-option [value]="''">None</mat-option>
        <mat-option *ngFor="let user of fbs.users" [value]="user.value">{{user.label}}</mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field class="w-20 pe-1" appearance="fill" [class]="{'edited': initialTask.status != taskToEdit.status}">
      <mat-label>Status</mat-label>
      <mat-select [(value)]="taskToEdit.status">
        <mat-option *ngFor="let status of taskStatuses" [value]="status">{{status}}</mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field class="w-20 pe-1" appearance="fill"
      [class]="{'edited': initialTask.subStatus != taskToEdit.subStatus}">
      <mat-label>Sub status</mat-label>
      <mat-select [(value)]="taskToEdit.subStatus">
        <mat-option *ngFor="let subStatus of taskSubStatuses" [value]="subStatus">{{subStatus}}</mat-option>
      </mat-select>
    </mat-form-field>
  
    <app-select-filter #blockingTasksFilter [options]="filterOptions.blockedByTids.options"
      class="w-20 mt-1 d-inline-block" [resetSelection]="filterOptions.blockedByTids.initialSelection"
      [title]="filterOptions.blockedByTids.title" [initialSelection]="filterOptions.blockedByTids.initialSelection"
      [selectedEmitter]="filterOptions.blockedByTids.selectedEmitter"></app-select-filter>
  
    <app-select-filter #relatedTasksFilter [options]="filterOptions.relatedTids.options" class="w-20 mt-1 d-inline-block"
      [resetSelection]="filterOptions.relatedTids.initialSelection" [title]="filterOptions.relatedTids.title"
      [initialSelection]="filterOptions.relatedTids.initialSelection"
      [selectedEmitter]="filterOptions.relatedTids.selectedEmitter"></app-select-filter>
    <!--
    <mat-form-field appearance="fill" class="w-25 mt-1"
      [class]="{'edited' : initialTask.percentageCompleted !== taskToEdit.percentageCompleted}">
      <mat-label>Completion % (Required)</mat-label>
      <input #taskPercentage type="text" placeholder="80" matInput [(ngModel)]="taskToEdit.percentageCompleted"
        type="number" max="100" min="0">
    </mat-form-field> -->
  </div>

  <div class="ri-dialog-footer px-3" [ngClass]="{'ri-pe-none': taskToEdit.deletedAt}">
    <div *ngIf="errorMessage" class="text-center text-danger py-3">{{errorMessage}}</div>
    <div class="mt-4 pt-2 pb-1 d-flex justify-content-between" *ngIf="!taskToEdit.deletedAt">
      <!-- Add delete icon button -->
      <div class="d-flex">
        <button class="btn btn-danger btn-sm me-2 ri-btn-delete" matTooltip="Delete" [disabled]="deletionInProgress" (click)="deleteHandler(true)" *ngIf="mode === 'edit'">
          <i class="bi bi-trash"></i>
        </button>
        <button *ngIf="taskToEdit?.timeLogs?.length" class="btn btn-secondary btn-sm"
          (click)="showTimeLogs = !showTimeLogs">Logged Time</button>
      </div>
      <div class="d-flex">
        <button class="btn btn-secondary btn-sm me-2" (click)="resetHandler()" [disabled]="!hasChanges()">
          Reset
        </button>
        <button class="btn btn-primary btn-sm" (click)="updateHandler()" [disabled]="updationInProgress || errorMessage || !isValid() || !hasChanges()">Save
        </button>
      </div>
    </div>
  
    <div class="mt-2 ri-pointer" *ngIf="showTimeLogs" (click)="showTaskDailys(taskToEdit)">
      <h2>Total Timelogs <span class="float-end">{{getTotalTimeSpent(taskToEdit) | number:'1.0-1'}} hr</span></h2>
      <mat-grid-list cols="3" *ngFor="let timeLog of taskToEdit.timeLogs" rowHeight="40px">
        <mat-grid-tile class="d-block">
          <div class="text-start">{{fbs.getDisplayName(timeLog.uid)}}</div>
        </mat-grid-tile>
        <mat-grid-tile class="d-block">
          <div class="text-end">{{getTimeDistance(timeLog.lastUpdated)}}</div>
        </mat-grid-tile>
        <mat-grid-tile class="d-block">
          <div class="text-end">{{timeLog.hours | number:'1.0-1'}} hr</div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  
    <div class="my-3 pt-2 d-flex justify-content-between">
      <span>Specs:&nbsp;<app-spec-status-counts-of-task
          [task]="taskToEdit"></app-spec-status-counts-of-task>&nbsp;&nbsp;</span>
      <!-- <span (click)="viewRelatedTodosList(taskToEdit)">Todos:&nbsp;<app-todo-status-count-of-todo [todos]="relatedTodos()"></app-todo-status-count-of-todo>&nbsp;&nbsp;</span> -->
       <span *ngIf="cc.featureToInvalidViewIdSpec[initialTask.id] && cc.featureToInvalidViewIdSpec[initialTask.id].length !== 0" class="d-flex align-items-center">Specs without viewId: <span class="badge bg-dark ms-1">{{ cc.featureToInvalidViewIdSpec[initialTask.id].length }}</span></span>
      <span>Translations:&nbsp;<app-translation-status-counts-of-task class="float-end"
          [task]="taskToEdit"></app-translation-status-counts-of-task></span>
    </div>
  
    <div *ngIf="cc.tidBlockingTaskMap[taskToEdit.tid]?.length">
      <h3 class="d-inline-block text-danger fw-bold">Blocking other Features</h3>
      <div class="pb-2 ri-pointer" *ngFor="let task of cc.tidBlockingTaskMap[taskToEdit.tid]" (click)="openTask(task)">
        <span class="text-primary me-2">F-{{task.tid}}</span> <span>{{task.title}}</span>
      </div>
    </div>
  
    <div *ngIf="taskToEdit.blockedByTids?.length">
      <h3 class="d-inline-block text-warning fw-bold">Blocked by below Features</h3>
      <div class="pb-2 ri-pointer" *ngFor="let tid of taskToEdit.blockedByTids" (click)="openTask(cc.tidTaskMap[tid])">
        <span class="text-primary me-2">F-{{tid}}</span> <span>{{cc.tidTaskMap[tid].title}}</span>
      </div>
    </div>
  
    <div *ngIf="taskToEdit.relatedTids?.length">
      <h3 class="d-inline-block text-primary fw-bold">Related Features</h3>
      <div class="pb-2 ri-pointer" *ngFor="let tid of taskToEdit.relatedTids" (click)="openTask(cc.tidTaskMap[tid])">
        <span class="text-primary me-2">F-{{tid}}</span> <span>{{cc.tidTaskMap[tid].title}}</span>
      </div>
    </div>
  </div>

</main>