<span class="text-nowrap" *ngIf="ownerInfo">
  @for (uid of ownerInfo.productOwner; track $index;) {
  <span class="badge rounded-pill text-bg-review me-1" [matTooltip]="fb.uidUserMap[uid]?.label">
    {{ getInitials(uid) }}
  </span>
  } @for (uid of ownerInfo.designOwner; track $index;) {
  <span class="badge rounded-pill text-bg-secondary me-1" [matTooltip]="fb.uidUserMap[uid]?.label">
    {{ getInitials(uid) }}
  </span>
  } @for (uid of ownerInfo.devOwner; track $index;) {
  <span class="badge rounded-pill text-bg-primary me-1" [matTooltip]="fb.uidUserMap[uid]?.label">
    {{ getInitials(uid) }}
  </span>
  } @for (uid of ownerInfo.testOwner; track $index;) {
  <span class="badge rounded-pill text-bg-warning me-1" [matTooltip]="fb.uidUserMap[uid]?.label">
    {{ getInitials(uid) }}
  </span>
  }
</span>
