import { Injectable } from '@angular/core';
import { entity, JsActivity } from '../_interfaces/Activity';
import { FirebaseService } from './firebase.service';
import { JsWidget } from '../_interfaces/Widget';
import { JsComment, JsReply } from '../_interfaces/Comment';
import * as _ from 'lodash';
import { JsTranslation } from '../_interfaces/Translation';
import { flattenObjectIncludingArrays } from '../shared/utils';
import { JsTask } from '../_interfaces/Task';
import { MatDialog } from '@angular/material/dialog';
import { ActivityComponent } from 'src/app/activity/activity.component';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  userTypes = [
    'createdBy',
    'uid',
    'resolvedBy',
    'backlogAssignedTo',
    'designAssignedTo',
    'reviewAssignedTo',
    'developAssignedTo',
    'testAssignedTo',
    'failedAssignedTo',
    'approveAssignedTo',
    'assignedTo',
    'watchers',
    'Owner'
  ];
  constructor(private fbService: FirebaseService, private dialog: MatDialog,) {}

  getActivityDiff(
    activity: JsActivity,
    ignoreKeys: string[] = [],
    renameKeys: any = {}
  ): any {
    // sort the timelogs on task type as per the before
    this.sortTimeLogs(activity);
    const b = flattenObjectIncludingArrays(activity.before || {});
    const a = flattenObjectIncludingArrays(activity.after || {});
    const beforeKeys = Object.keys(b || {});
    const afterKeys = Object.keys(a || {});
    const allKeys = _.uniq([...beforeKeys, ...afterKeys]);
    const exceptIgnoreKeys = allKeys.filter((k) => !ignoreKeys.includes(k));
    const diff = exceptIgnoreKeys
      .map((key: string) => {
        return {
          title: key,
          before:
            b &&
            // @ts-ignore
            b[key as keyof (JsWidget | JsComment | JsTranslation)],
          after:
            a &&
            // @ts-ignore
            a[key as keyof (JsWidget | JsComment | JsTranslation)],
        };
      })
      .filter((d) => {
        return (
          !_.isEqual(d.after, d.before) &&
          !d?.title?.endsWith('At') &&
          !d?.title?.endsWith('lastUpdated')
        );
      })
      .map((d) => {
        if (d.title === 'watchers') {
          return this.getWatchersDiff(
            d.before as unknown as string[],
            d.after as unknown as string[]
          );
        }
        if (d.title === 'attachmentNames') {
          return this.getAttachmentNamesDiff(
            d.before as unknown as string[],
            d.after as unknown as string[]
          );
        }

        if (d.title === 'replies') {
          return this.getRepliesDiff(
            //
            d.before as unknown as JsReply[],
            d.after as unknown as JsReply[]
          );
        }
        return {
          title: d.title,
          before: this.getPrintableValue(d.title, d.before),
          after: this.getPrintableValue(d.title, d.after),
        };
      })
      .map((d) => {
        if (renameKeys[d.title]) {
          d.title = renameKeys[d.title];
        }
        return d;
      });
    return diff;
  }

  sortTimeLogs(activity: JsActivity): JsActivity {
    if (activity.entity !== 'task') {
      return activity;
    }
    const before = activity.before as JsTask;
    const after = activity.after as JsTask;
    if (!before || !after) {
      return activity;
    }
    const beforeTimeLogs = before.timeLogs;
    const afterTimeLogs = after.timeLogs;
    if (!beforeTimeLogs || !afterTimeLogs) {
      return activity;
    }
    // Sort afterTimeLogs based on beforeTimeLogs uid
    const beforeTimelogUids = beforeTimeLogs.map((t) => t.uid);
    afterTimeLogs.sort((a, b) => {
      return (
        beforeTimelogUids.indexOf(a.uid) - beforeTimelogUids.indexOf(b.uid)
      );
    });
    activity.after = {
      ...after,
      timeLogs: afterTimeLogs,
    };
    return activity;
  }

  getAttachmentNamesDiff(before: string[], after: string[]): any {
    const added = after && after.filter((a) => !before.includes(a));
    const removed = before && before.filter((a) => !after.includes(a));
    return {
      title: 'attachments',
      before: removed.length ? removed.join('\n') : '',
      after: added.length ? added.join('\n') : '',
    };
  }

  getWatchersDiff(before: string[], after: string[]): any {
    const added =
      after &&
      after
        .filter((a) => !before.includes(a))
        .map((a) => this.fbService.getUserNameFromId(a));
    const removed =
      before &&
      before
        .filter((a) => !after.includes(a))
        .map((a) => this.fbService.getUserNameFromId(a));
    return {
      title: 'watchers',
      before: removed.length ? removed.join('\n') : '',
      after: added.length ? added.join('\n') : '',
    };
  }

  getRepliesDiff(before: JsReply[], after: JsReply[]): any {
    const added =
      after &&
      after.filter((a) => before.findIndex((b) => b.id === a.id) === -1);
    const removed =
      before &&
      before.filter((a) => after.findIndex((b) => b.id === a.id) === -1);
    return {
      title: 'replies',
      before: removed.length ? this.getFormattedReply(removed[0]) : '',
      after: added.length ? this.getFormattedReply(added[0]) : '',
    };
  }

  // getHumanReadableDateTimeLog(timeLog: string): string {
  //   let strTimeLog = timeLog;
  //   if (Array.isArray(timeLog)) {
  //     strTimeLog = timeLog[0];
  //   }
  //   if (!strTimeLog) {
  //     return '';
  //   }
  //   console.log('strTimeLog', strTimeLog);
  //   const [date, uid, duration] = strTimeLog.split('_');
  //   const userName = this.fbService.getUserNameFromId(uid);
  //   return `${userName} - ${duration} - ${date}`;
  // }

  getPrintableValue(key: string, value: any): string | undefined {
    // if key ends with any of userTypes, then value is userId. So, get userName from userId
    if (this.userTypes.some((t) => key.endsWith(t) || key.startsWith(t))) {
      return this.fbService.getUserNameFromId(value) || '';
    }

    // if (this.userTypes.includes(key)) {
    //   return this.fbService.getUserNameFromId(value) || '';
    // }
    // if (key === 'timeLogs') {
    //   return 'TIMELOGS';
    // }
    // if (key === 'watchers') {
    //   return value
    //     .map((w: string) => this.fbService.getUserNameFromId(w))
    //     .join('\n');
    // }
    return value ?? '';
  }

  getFormattedReply(reply: JsReply): string {
    const createdBy = this.fbService.getUserNameFromId(reply.createdBy);
    return `${reply.text} \n\n - By ${createdBy}`;
  }

  getActivity(entityType: entity, entity: any) {
    const confirmDialog = this.dialog.open(ActivityComponent, {
      autoFocus: false,
      data: { entityType: entityType, entity: entity },
    });
  }
}
