<main class="comment-list px-3 pb-3 ri-w-600">
    <div class="d-flex justify-content-between py-3 position-sticky">
        <h3 class="fw-semibold mb-0">Comments</h3>
        <button class="btn btn-primary btn-sm" (click)="openCommentForm($event, 'new')">Add</button>
    </div>

    <div class="ri-dialog-content">
        <div class="comment-box py-1 d-block ri-pointer" *ngFor="let comment of commentsList()" (click)="openCommentForm($event, 'edit', comment)">
            <app-comment [comment]="comment" [entity]="data.entity" [entityType]="data.entityType"></app-comment>
        </div>
        <div class="py-5 text-center" *ngIf="commentsList().length >= 5 && !allDataLoaded">
            <a class="text-muted text-center text-decoration-none ri-fs-14px ri-pointer" (click)="getCommentByEntity(true)">More</a>
        </div>
        <p *ngIf="commentsList().length === 0" class="text-center py-5 my-4">No Comments added</p>
    </div>

    <div class="ri-dialog-footer">
        <div class="d-flex justify-content-between mt-4">
            <div class="">
                
            </div>
            <div>
                <button type="button" class="btn btn-sm btn-outline-secondary" (click)="dialogRef.close()">Cancel</button>
            </div>
        </div>
    </div>

</main>