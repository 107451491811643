import { Component, Inject } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { serverTimestamp } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsView, ViewStatus, ViewType } from 'src/app/_interfaces/View';
import { ActivityService } from 'src/app/_services/activity.service';
import { CacheService } from 'src/app/_services/cache.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { FirebaseOptimisticService } from 'src/app/_services/firebase-optimistic.service';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { getNewId } from 'src/app/shared/utils';
import * as _ from 'lodash';
import { JsTranslation } from 'src/app/_interfaces/Translation';
import { JsWidget } from 'src/app/_interfaces/Widget';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { SpecStatusCountsOfViewComponent } from 'src/app/spec-status-counts-of-view/spec-status-counts-of-view.component';
import { TranslationStatusCountsOfViewComponent } from 'src/app/translation-status-counts-of-view/translation-status-counts-of-view.component';
import { DateFnsModule } from 'ngx-date-fns';
import { SharedFunctionService } from 'src/app/_services/shared-function.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-view-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    MatAutocompleteModule,
    MatInput,
    MatOption,
    CdkCopyToClipboard,
    CdkTextareaAutosize,
    MatTooltip,
    MatIcon,
    SpecStatusCountsOfViewComponent,
    TranslationStatusCountsOfViewComponent,
    DateFnsModule
  ],
  templateUrl: './view-form.component.html',
  styleUrl: './view-form.component.scss'
})

export class ViewFormComponent {
  initialView!: JsView;
  viewToEdit!: JsView;
  mode: 'add' | 'edit' = 'add';
  viewTypes = ['screen', 'tab', 'bottomSheet', 'overlay', 'dropdown', 'tooltip', 'toast', 'other'];
  viewStatuses = ['Draft', 'Reviewed', 'Approved', 'Implemented'];
  viewNames: string[] = [];
  filteredViewNames: string[] = [];
  updationInProgress = false;
  deletionInProgress = false;
  errorMessage = '';
  unSubscribe = new Subject<void>();

  constructor(
    private fbs: FirebaseService,
    public cc: CacheService,
    private fbo: FirebaseOptimisticService,
    private fns: AngularFireFunctions,
    private snackbar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: JsView | undefined,
    public dialogRef: MatDialogRef<ViewFormComponent>,
    private confirmService: ConfirmService,
    private as: ActivityService,
    public sharedFunc: SharedFunctionService
  ) {
    if (this.data) {
      this.initialView = _.cloneDeep(this.data);
      this.viewToEdit = _.cloneDeep(this.data);
      this.mode = 'edit';
    } else {
      this.viewToEdit = {
        viewType: 'screen',
        viewName: '',
        viewId: '',
        id: getNewId(),
        uid: this.fbs.getCurrentUserId(),
        createdBy: this.fbs.getCurrentUserId(),
        createdAt: new Date(),
        updatedAt: new Date(),
        cloudUpdatedAt: serverTimestamp(),
        deletedAt: null,
        status: 'Draft',
        note: ''
      };
      this.initialView = _.cloneDeep(this.viewToEdit);
      this.mode = 'add';
    }
    dialogRef.backdropClick().pipe(takeUntil(this.unSubscribe)).subscribe(async () => {
      if (this.hasChanges()) {
        const confirmed = await this.confirmService.confirm('Alert', 'You have unsaved changes. Do you really want to discard them?', 'Discard', 'Cancel');
        if (!confirmed) {
          return;
        }
        this.dialogRef.close();
      } else {
        this.dialogRef.close(); // Close immediately if no unsaved changes
      }
    });
  }

  ngOnInit(): void {
    this.viewNames = this.cc.viewNames;
    this.filteredViewNames = this.cc.viewNames;
  }

  getActivity() {
    this.as.getActivity('view', this.viewToEdit);
  }

  setFilteredValues(src: string, value: string, result: string): void {
    const filterValue = value.toLowerCase();
    // @ts-ignore
    this[result] = this[src].filter(option => option.toLowerCase().includes(filterValue));
  }

  setViewId(): void {
    this.isValid();
    const { viewType, viewName } = this.viewToEdit;
    // Generated key is a combination of lowercase first letter of type and label separated by an underscore
    const viewId = `${viewType}_${viewName}`;
    this.viewToEdit.viewId = viewId;
  }

  hasChanges(): boolean {
    return !_.isEqual(this.viewToEdit, this.initialView);
  }

  isValid(): boolean {
    this.errorMessage = '';
    let result = true;
    const { viewType, viewName } = this.viewToEdit;
    if (!viewType || !viewName) {
      result = false;
      this.errorMessage = 'Type and Label are mandatory fields';
    }
    // Check if generated key is unique
    const viewId = `${viewType}_${viewName}`;
    const existingView = this.cc.views.find(view => view.viewId === viewId);
    if (existingView && existingView.id !== this.viewToEdit.id) {
      result = false;
      this.errorMessage = 'Generated viewId is not unique';
    }
    // Check if viewName is camelCase
    if (viewName !== _.camelCase(viewName)) {
      result = false;
      this.errorMessage = 'View Name should be in camelCase';
    }
    return result;
  }

  resetHandler(): void {
    this.viewToEdit = _.cloneDeep(this.initialView);
    this.isValid();
  }

  onCopy() {
    this.snackbar.show();
  }

  async updateHandler() {
    this.updationInProgress = true;
    const updatedView = _.cloneDeep(this.viewToEdit);
    updatedView.updatedAt = new Date();
    updatedView.cloudUpdatedAt = serverTimestamp();
    updatedView.uid = this.fbs.getCurrentUserId();
    if (this.mode === 'add') {
      updatedView.createdAt = new Date();
      await this.fbo.createItemsOptimistic<JsView>([updatedView], 'views');
    } else {
      // show confirmation dialog
      const confirmed = await this.confirmService.confirm(
        'Update View',
        'Are you sure you want to update this view? This action will update everywhere this viewId is used including specs, translations and analytics.'
      );
      if (!confirmed) {
        this.updationInProgress = false;
        return;
      }

      await this.fbo.updateItemsOptimistic<JsView>([updatedView], 'views');
      // Update all translations with this viewId
      const translations = this.cc.allTranslations
        .filter(translation => translation.viewId === this.initialView.viewId && translation.deletedAt === null)
        .map(translation => {
          translation.viewId = updatedView.viewId;
          translation.translationId = translation.translationId.replace(this.initialView.viewId, updatedView.viewId);
          translation.status = 'Draft';
          return translation;
        });
      await this.fbo.updateItemsOptimistic<JsTranslation>(translations, 'translations');
      // Update all specs with this viewId
      const specs = this.cc.allActiveWidgets
        .filter(spec => spec.viewId === this.initialView.viewId && spec.deletedAt === null)
        .map(spec => {
          spec.viewId = updatedView.viewId;
          spec.status = 'Review';
          return spec;
        });
      await this.fbo.updateItemsOptimistic<JsWidget>(specs, 'widgets');
    }
    this.updationInProgress = false;
    this.dialogRef.close();
  }

  async deleteHandler(isDelete: boolean) {
    const confirmed = await this.confirmService.confirm(`${isDelete ? 'Delete' : 'Restore'} View`, `Are you sure you want to ${isDelete ? 'delete' : 'restore'} this view? ${isDelete ? 'It will impact everywhere this view is used in including translations and analytics.' : ''}`);
    if (!confirmed) {
      return;
    }
    this.deletionInProgress = true;
    const deletedView = _.cloneDeep(this.viewToEdit);
    deletedView.deletedAt = isDelete ? new Date() : null;
    deletedView.cloudUpdatedAt = serverTimestamp();
    deletedView.uid = this.fbs.getCurrentUserId();
    await this.fbo.updateItemsOptimistic<JsView>([deletedView], 'views');
    if (!isDelete) {
      this.cc.restoreEntitySubject.next('view');
    }
    this.deletionInProgress = false;
    this.dialogRef.close();
  }

  onTypeChange(type: string) {
    this.viewToEdit.viewType = type as ViewType;
    this.setViewId();
  }

  onStatusChange(status: string) {
    this.viewToEdit.status = status as ViewStatus;
  }

  isEditable(): boolean {
    // Should not be deleted and should be either draft or review
    return this.viewToEdit.deletedAt === null && (this.viewToEdit.status === 'Draft' || this.viewToEdit.status === 'Reviewed');
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
