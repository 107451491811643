<div class="container mt-3 mb-2" *ngIf="!showTasksList">
  <div class="row">
    <div class="col-12 text-center">
      <h1>Impact View</h1>
    </div>
  </div>
  <div class="container text-center">
    <div class="row g-1">
      <div class="col-4">
        <div class="p-3 border bg-light fw-bolder">Name
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light fw-bolder">Sun {{selectedWeekString[0].split('-')[2]}}
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light fw-bolder">Mon {{selectedWeekString[1].split('-')[2]}}
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light fw-bolder">Tue {{selectedWeekString[2].split('-')[2]}}
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light fw-bolder">Wed {{selectedWeekString[3].split('-')[2]}}
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light fw-bolder">Thu {{selectedWeekString[4].split('-')[2]}}
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light fw-bolder">Fri {{selectedWeekString[5].split('-')[2]}}
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light fw-bolder">Sat {{selectedWeekString[6].split('-')[2]}}
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light fw-bolder">Total</div>
      </div>
    </div>



    <div class="row g-1" *ngFor="let user of userEffort | keyvalue ; let i = index">
      <div class="col-4">
        <div class="p-3 border bg-light h-100">{{fbs.getUserNameFromId($any(user.key))}}</div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light d-flex cursor" (click)="onClick(user.key, selectedWeekString[0])"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getUserStatusCountObject(user.key, selectedWeekString[0]) }"></ng-container>
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light d-flex cursor" (click)="onClick(user.key, selectedWeekString[1])"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getUserStatusCountObject(user.key, selectedWeekString[1]) }"></ng-container>
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light d-flex cursor" (click)="onClick(user.key, selectedWeekString[2])"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getUserStatusCountObject(user.key, selectedWeekString[2]) }"></ng-container>
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light d-flex cursor" (click)="onClick(user.key, selectedWeekString[3])"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getUserStatusCountObject(user.key, selectedWeekString[3]) }"></ng-container>
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light d-flex cursor" (click)="onClick(user.key, selectedWeekString[4])"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getUserStatusCountObject(user.key, selectedWeekString[4]) }"></ng-container>
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light d-flex cursor" (click)="onClick(user.key, selectedWeekString[5])"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getUserStatusCountObject(user.key, selectedWeekString[5]) }"></ng-container>
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light d-flex cursor" (click)="onClick(user.key, selectedWeekString[6])"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getUserStatusCountObject(user.key, selectedWeekString[6]) }"></ng-container>
        </div>
      </div>
      <div class="col-1">
        <div class="p-3 border bg-light d-flex cursor" (click)="onClick(user.key, 'total')"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getUserStatusCountObject(user.key, 'total') }"></ng-container>
        </div>
      </div>
    </div>


    <div class="row g-1">
      <div class="col-4">
        <div class="p-3 border bg-light fw-bolder h-100">Total</div>
      </div>
      <div class="col-1" (click)="onDateClick(selectedWeekString[0])">
        <div class="p-3 border bg-light fw-bolder"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getDateStatusCountObject(selectedWeekString[0]) }"></ng-container>
        </div>
      </div>
      <div class="col-1" (click)="onDateClick(selectedWeekString[1])">
        <div class="p-3 border bg-light fw-bolder"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getDateStatusCountObject(selectedWeekString[1]) }"></ng-container>
        </div>
      </div>
      <div class="col-1" (click)="onDateClick(selectedWeekString[2])">
        <div class="p-3 border bg-light fw-bolder"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getDateStatusCountObject(selectedWeekString[2]) }"></ng-container>
        </div>
      </div>
      <div class="col-1" (click)="onDateClick(selectedWeekString[3])">
        <div class="p-3 border bg-light fw-bolder"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getDateStatusCountObject(selectedWeekString[3]) }"></ng-container>
        </div>
      </div>
      <div class="col-1" (click)="onDateClick(selectedWeekString[4])">
        <div class="p-3 border bg-light fw-bolder"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getDateStatusCountObject(selectedWeekString[4]) }"></ng-container>
        </div>
      </div>
      <div class="col-1" (click)="onDateClick(selectedWeekString[5])">
        <div class="p-3 border bg-light fw-bolder"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getDateStatusCountObject(selectedWeekString[5]) }"></ng-container>
        </div>
      </div>
      <div class="col-1" (click)="onDateClick(selectedWeekString[6])">
        <div class="p-3 border bg-light fw-bolder"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getDateStatusCountObject(selectedWeekString[6]) }"></ng-container>
        </div>
      </div>
      <div class="col-1" (click)="onGrandTotalClick()">
        <div class="p-3 border bg-light fw-bolder"><ng-container
            *ngTemplateOutlet="statusChange; context:{ $implicit: getGrandTotalStatusCountObject() }"></ng-container>
        </div>
      </div>
    </div>
    <mat-button-toggle-group name="selectedWeek" aria-label="Selected Week" class="mt-3">
      <mat-button-toggle value="secondLast" (change)="setSelectedWeek($event)"
        [checked]="selectedWeek === 'secondLast'">Second Last
        Week</mat-button-toggle>
      <mat-button-toggle value="previous" (change)="setSelectedWeek($event)"
        [checked]="selectedWeek === 'previous'">Last Week</mat-button-toggle>
      <mat-button-toggle value="current" (change)="setSelectedWeek($event)" [checked]="selectedWeek === 'current'">This
        Week</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<app-effort-tasks *ngIf="showTasksList" [statusChange]="selectedStatusChange" [who]="selectedWho" [when]="selectedWhen"
  (onClose)="onTaskViewClose($event)"></app-effort-tasks>


<!-- Status pills -->
<ng-template #status let-item>
  <div class="cursor d-inline">
    <span matTooltip="Total" matTooltipPosition="left" class="mx-1 float-end badge rounded-pill bg-dark"
      [class]="{ 'opacity-25' : item['Total'] === 0 }"> {{
      item['Total'] }}
    </span>
    <span matTooltip="Approve" matTooltipPosition="left" [matTooltipShowDelay]="1000"
      class="mx-1 float-end badge rounded-pill bg-success" [class]="{ 'opacity-25' : item['Approve'] === 0 }"> {{
      item['Approve'] }}
    </span>
    <span matTooltip="Failed" matTooltipPosition="left" [matTooltipShowDelay]="1000"
      class="mx-1 float-end badge rounded-pill bg-danger" [class]="{'opacity-25' : item['Failed'] === 0 }"> {{
      item['Failed']
      }}
    </span>
    <span matTooltip="Test" matTooltipPosition="left" [matTooltipShowDelay]="1000"
      class="mx-1 float-end badge rounded-pill bg-warning" [class]="{ 'opacity-25' : item['Test'] === 0 }"> {{
      item['Test'] }}
    </span>
    <span matTooltip="Correction" matTooltipPosition="left" [matTooltipShowDelay]="1000"
      class="mx-1 float-end badge rounded-pill bg-dark-danger" [class]="{ 'opacity-25' : item['Correction'] === 0 }"> {{
      item['Correction'] }}
    </span>
    <span matTooltip="Develop" matTooltipPosition="left" [matTooltipShowDelay]="1000"
      class="mx-1 float-end badge rounded-pill bg-primary" [class]="{ 'opacity-25' : item['Develop'] === 0 }"> {{
      item['Develop'] }}
    </span>
    <span matTooltip="Review" matTooltipPosition="left" [matTooltipShowDelay]="1000"
      class="mx-1 float-end badge rounded-pill bg-review" [class]="{ 'opacity-25' : item['Review'] === 0 }"> {{
      item['Review'] }}
    </span>
    <span matTooltip="Design" matTooltipPosition="left" [matTooltipShowDelay]="1000"
      class="mx-1 float-end badge rounded-pill bg-secondary" [class]="{ 'opacity-25' : item['Design'] === 0 }"> {{
      item['Design'] }}
    </span>
    <span matTooltip="Backlog" matTooltipPosition="left" [matTooltipShowDelay]="1000"
      class="mx-1 float-end badge rounded-pill bg-info" [class]="{ 'opacity-25' : item['Backlog'] === 0 }"> {{
      item['Backlog'] }}
    </span>
  </div>
</ng-template>


<!-- Status Chnage block -->
<ng-template #statusChange let-statusChange>
  <div class="d-flex flex-column">
    <div class="points-container">{{statusChange.after.Points}}</div>
    <div class="d-flex flex-row">
      <div>
        <ng-container *ngTemplateOutlet="status; context:{ $implicit: statusChange.before }"></ng-container>
      </div>
      <div>
        <ng-container *ngTemplateOutlet="status; context:{ $implicit: statusChange.after }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
