import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { JsTask } from '../_interfaces/Task';
import { JsWidget } from '../_interfaces/Widget';
import { JsView } from '../_interfaces/View';
import { CacheService } from '../_services/cache.service';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-view-status-counts-of-task',
  templateUrl: './view-status-counts-of-task.component.html',
  styleUrls: ['./view-status-counts-of-task.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltip
  ]
})
export class ViewStatusCountsOfTaskComponent implements OnChanges {
  @Input() task: JsTask | undefined = undefined;
  isEmpty = true;
  totalViews = 0;
  Draft = 0;
  Reviewed = 0;
  Approved = 0;
  Implemented = 0;
  constructor(private cc: CacheService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['task']) {
      this.update();
    }
  }

  update() {
    this.isEmpty = true;
    this.totalViews = 0;
    this.Draft = 0;
    this.Reviewed = 0;
    this.Approved = 0;
    this.Implemented = 0;
    const uniqueViewIds = this.cc.getUniqueViewIdsOfFeature(this.task);

    // Loop through the views and get the status counts
    uniqueViewIds.forEach((viewId) => {
      const view = this.cc.viewsByViewId[viewId as string];
      if (view) {
        switch (view.status) {
          case 'Draft':
            this.Draft++;
            break;
          case 'Reviewed':
            this.Reviewed++;
            break;
          case 'Approved':
            this.Approved++;
            break;
          case 'Implemented':
            this.Implemented++;
            break;
        }
      }
    });

    // Calculate the total number of views
    this.totalViews =
      this.Draft + this.Reviewed + this.Approved + this.Implemented;
    if (this.totalViews > 0) {
      this.isEmpty = false;
    }
  }
}
