import { Firestore, Timestamp } from '@angular/fire/firestore';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';
import { convertTimestamps } from 'convert-firebase-timestamp';
import { format, isValid, startOfDay } from 'date-fns';
import { AnyEntity, FbEntity, JsEntity, StringEntity } from '../_interfaces/Entities';
import { serverTimestamp } from '@angular/fire/firestore';
import { FbActivity, JsActivity } from '../_interfaces/Activity';
import { SpecStatusCounts } from '../_interfaces/Other';
import * as _ from 'lodash';
import { JsWidget } from '../_interfaces/Widget';
import { Env, EnvDatas } from '../_interfaces/Release';

export function convertTimeStampsFb_Js(obj: FbEntity | FbActivity): JsEntity | JsActivity {
  return convertTimestamps(obj) as JsEntity;
}

export function convertTimeStampsJs_String(obj: AnyEntity | undefined) {
  if (!obj) return null;
  const newObj: { [key: string]: any } = {};
  for (let [key, value] of Object.entries(obj)) {
    if (value && isValid(value)) {
      newObj[key] = value.toString();
    } else {
      newObj[key] = value;
    }
  }
  return newObj as StringEntity;
}

export function convertTimeStampsString_Js(obj: StringEntity | undefined) {
  if (!obj) return null;
  const newObj: { [key: string]: any } = {};
  for (let [key, value] of Object.entries(obj)) {
    if (value && typeof value === 'string' && Date.parse(value) && isValid(Date.parse(value))) {
      newObj[key] = Date.parse(value);
    } else {
      newObj[key] = value;
    }
  }
  return newObj as JsEntity;
}

export function convertTimeStampsJs_Fb(obj: Partial<JsEntity> | undefined): Partial<FbEntity> | null {
  if (!obj) return null;
  const newObj: { [key: string]: any } = {};
  for (let [key, value] of Object.entries(obj)) {
    if (key.endsWith('At') && value && isValid(value)) {
      newObj[key] = Timestamp.fromMillis(value);
    } else {
      newObj[key] = value;
    }
  }
  newObj['cloudUpdatedAt'] = serverTimestamp();
  return newObj as FbEntity;
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export function getRandomBoolean() {
  return Math.random() < 0.5;
}

export function getRandomBooleanArray(num: number) {
  return [...Array(num).keys()].map(i => getRandomBoolean());
}

export function getNewId() {
  return uuidv4();
}

export function getFakeSentence(min: number, max: number) {
  const num = faker.datatype.number({ min, max });
  return faker.random.words(num);
}

export function getFakeDatesArray(from: Date, to: Date, num: number) {
  return faker.date.betweens(from, to, num).map(i => startOfDay(i));
}

export function setSessionData(key: string, value: any) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getSessionData(key: string) {
  const str = sessionStorage.getItem(key);
  if (!str) return null;
  return JSON.parse(str);
}

export function getTimelogAsObject(date: Date, timeLogs: string[], userId: string | null): { hours: string; minutes: string } {
  const stringDate = format(date, 'yyyy-MM-dd');
  const timeLog = timeLogs.find(timeLog => {
    return timeLog.startsWith(stringDate + '_' + userId);
  });
  if (timeLog && userId) {
    const hoursAndMinutes = timeLog.split('_')[2];
    const hours = hoursAndMinutes.split(':')[0];
    const minutes = hoursAndMinutes.split(':')[1];
    return { hours, minutes };
  } else {
    return { hours: '0h', minutes: '0m' };
  }
}

export function getTimelogAsMinutes(date: Date, timeLogs: string[], userId: string | null): number {
  const stringDate = format(date, 'yyyy-MM-dd');
  const timeLog = timeLogs.find(timeLog => {
    return timeLog.startsWith(stringDate + '_' + userId);
  });
  if (timeLog && userId) {
    const hoursAndMinutes = timeLog.split('_')[2];
    const hours = parseInt(hoursAndMinutes.split('h')[0]);
    const minutes = parseInt(hoursAndMinutes.split(':')[1].split('m')[0]);
    return hours * 60 + minutes;
  } else {
    return 0;
  }
}

export function getTimelogAsString(date: Date, timeLogs: string[], userId: string | null): string {
  const stringDate = format(date, 'yyyy-MM-dd');
  const timeLog = timeLogs.find(timeLog => {
    return timeLog.startsWith(stringDate + '_' + userId);
  });
  if (timeLog && userId) {
    return timeLog.split('_')[2];
  } else {
    return '0h0m';
  }
}

export function getTimelogAsStringFromMinutes(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  const hoursString = hours + 'h';
  const minutesString = ' ' + minutesLeft + 'm';
  return hoursString + minutesString;
}

export function getTimelogAsMinutesFromObject(
  hours: string, // format: 1h
  minutes: string // format: 1m
): number {
  const hoursNumber = parseInt(hours.split('h')[0]);
  const minutesNumber = parseInt(minutes.split('m')[0]);
  return hoursNumber * 60 + minutesNumber;
}

export function getDateString(date: Date) {
  if (!date) return '';
  const month = String(date.getMonth() + 1).padStart(2, '0'); /* getMonth() is zero-based */
  const day = String(date.getDate()).padStart(2, '0');
  return `${date.getFullYear()}-${month}-${day}`;
}

export function getcurrentLocalDateAsString() {
  const now = new Date();
  return getDateString(now);
}

export function getcurrentLocalTimeAsString() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}

export function getLastDateStrings(num: number) {
  const dates = [];
  for (let i = 0; i < num; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(getDateString(date));
  }
  return dates;
}

export function generateNumberBetweenRange(start: number, end: number) {
  // Get a floating-point number between start and end
  const num = Math.random() * (end - start) + start;
  return num;
}

export function getProgressPercentageFromStatusCounts(statusCounts: SpecStatusCounts): number {
  let score = 0;
  let total = 0;
  const weightage = {
    Backlog: 1,
    Design: 2,
    Review: 3,
    Develop: 4,
    Test: 8,
    Failed: 6,
    Approve: 10
  }; // higher the number, higher the weightage
  Object.keys(statusCounts).forEach(key => {
    if (key === 'Total') return;
    // @ts-ignore
    score += statusCounts[key] * weightage[key];
    // @ts-ignore
    total += statusCounts[key] * 10;
  });
  return Math.round((score / total) * 100);
}

export function getReversedIndex(index: number, length: number) {
  return length - index;
}

// export function sortObjectKeys(obj: any): any {
//   // Check if the value is a plain object that should be sorted
//   function isSortable(obj: any): boolean {
//     return _.isPlainObject(obj);
//   }

//   // Sort an object by its keys
//   function sort(obj: any): any {
//     return (
//       _(obj)
//         .toPairs() // Convert object to [key, value] pairs
//         .sortBy(0) // Sort pairs by key
//         // @ts-ignore
//         .map(([key, value]) => [key, isSortable(value) ? sort(value) : value]) // Recursively sort nested objects
//         .fromPairs() // Convert pairs back to an object
//         .value()
//     );
//   }

//   return sort(obj);
// }

export function sortObjectKeys(obj: any): any {
  // Check if the value is a plain object that should be sorted
  function isPlainObject(value: any): boolean {
    return Object.prototype.toString.call(value) === '[object Object]';
  }

  // Sort an object by its keys
  function sort(obj: any): any {
    if (!isPlainObject(obj)) {
      return obj;
    }

    const sortedObj: { [key: string]: any } = {};
    Object.keys(obj)
      .sort()
      .forEach(key => {
        sortedObj[key] = isPlainObject(obj[key]) ? sort(obj[key]) : obj[key];
      });
    return sortedObj;
  }

  return sort(obj);
}

// @ts-ignore
export function sortNestedColors(obj) {
  // Function to determine if an object contains color keys
  // @ts-ignore
  const containsColorKeys = o =>
    // @ts-ignore
    _.some(_.keys(o), k => k.startsWith('Color '));

  // Recursive function to sort color keys
  // @ts-ignore
  function sortObjectByColorKeys(o) {
    if (_.isPlainObject(o)) {
      // If the object contains color keys, sort them by the numeric part of the key
      if (containsColorKeys(o)) {
        // @ts-ignore
        const sortedPairs = _.toPairs(o).sort((a, b) => {
          const numA = parseInt(a[0].split(' ')[1], 10);
          const numB = parseInt(b[0].split(' ')[1], 10);
          return numA - numB;
        });
        return _.fromPairs(sortedPairs);
      } else {
        // If the object does not contain color keys, recursively sort any nested objects
        return _.mapValues(o, sortObjectByColorKeys);
      }
    }
    return o;
  }

  return sortObjectByColorKeys(obj);
}

export function flattenObjectExceptArrays(obj: any, path = '') {
  const r = _.reduce(
    obj,
    (acc, value, key) => {
      let newPath = path ? `${path}.${key}` : key;
      if (_.isObject(value) && !_.isArray(value)) {
        _.assign(acc, flattenObjectExceptArrays(value, newPath));
      } else {
        _.set(acc, newPath, value);
      }
      return acc;
    },
    {}
  );

  return sortObjectKeys(r);
}

export function flattenObjectToDotNotation(obj: any, parentKey = '', result: any = {}) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        flattenObjectToDotNotation(obj[key], newKey, result);
      } else {
        result[newKey] = obj[key];
      }
    }
  }
  return result;
}

export function flattenObjectIncludingArrays(obj: any, baseKey = '') {
  const r = _.transform(
    obj,
    (result, value, key: string) => {
      const newKey = baseKey ? `${baseKey}.${key}` : `${key}`;
      if (_.isObject(value) && !_.isDate(value) && !_.isRegExp(value)) {
        _.merge(result, flattenObjectIncludingArrays(value, newKey));
      } else {
        // @ts-ignore
        result[newKey] = value;
      }
    },
    {}
  );

  return sortObjectKeys(r);
}

export function isValidDsl(dsl: string): boolean {
  // It should either have a comma or a colon
  return dsl.includes(',') || dsl.includes(':');
}

// Example dsl: 'start1|start2,s:start1|start2,i:include1|include2|include3,i:include2|include3,e:exclude1|exclude2'
// Comma seperated - AND, | seperated - OR
export function filterWidgetsWithDSL(objects: JsWidget[], dsl: string): JsWidget[] {
  // if no dsl, return all objects
  if (!dsl) return objects;

  // Initial filter - assume all objects pass
  let filteredObjects = [...objects];

  // Split the DSL into parts for each condition
  const dslParts = dsl.split(',');

  // Process each condition separately
  dslParts.forEach(part => {
    // Determine if the part has a specified prefix or default to 's:'
    let [type, values] = part.includes(':') ? part.split(':') : ['s', part];
    const valueList = values.split('|');

    switch (type) {
      case 's': // Starts with condition
        filteredObjects = filteredObjects.filter(obj => valueList.some(value => obj.path.startsWith(value)));
        break;
      case 'i': // Includes condition
        filteredObjects = filteredObjects.filter(obj => valueList.some(value => obj.path.includes(value)));
        break;
      case 'e': // Excludes condition
        filteredObjects = filteredObjects.filter(obj => !valueList.some(value => obj.path.includes(value)));
        break;
      default:
        // If an unrecognized prefix is encountered, you might choose to log an error or handle it appropriately.
        console.error(`Unrecognized prefix: ${type}`);
    }
  });

  return filteredObjects;
}

// console.log(filterWidgetsWithGlobsPatterns(widgets, "start*End, *Middle*", ""));
// // Includes widgets that start with "start" and end with "End" OR contain "Middle"

// console.log(filterWidgetsWithGlobsPatterns(widgets, "*start*, *End", ""));
// // Includes widgets that contain "start" OR end with "End"

// console.log(filterWidgetsWithGlobsPatterns(widgets, "*MiddleEnd, start*", "completelyDifferent"));
// // Excludes widgets that match the exclusion pattern "completelyDifferent"
export function filterWidgetsWithGlobsPatterns(widgets: JsWidget[], includePatterns: string = '', excludePatterns: string = ''): JsWidget[] {
  // Helper function to compile patterns into regexes
  const compilePatternsToRegexes = (patterns: string): RegExp[] => {
    return patterns
      .trim()
      .split(',')
      .map(pattern => pattern.trim())
      .filter(pattern => pattern) // Remove empty patterns after trimming
      .map(pattern => new RegExp('^' + pattern.replace(/([.+?^=!:${}()|\[\]\/\\])/g, '\\$1').replace(/\*/g, '.*') + '$', 'i'));
  };

  // Compile include and exclude patterns into regexes
  const includeRegexes = compilePatternsToRegexes(includePatterns);
  const excludeRegexes = compilePatternsToRegexes(excludePatterns);

  // Adjusted logic: Return an empty array if includePatterns is empty (or only whitespace)
  // This ignores excludePatterns if includePatterns is empty
  if (includePatterns.trim() === '') {
    return [];
  }

  // Filter widgets based on the regexes, allowing for case-insensitive matching
  return widgets.filter(widget => {
    const matchesInclude = includeRegexes.some(regex => regex.test(widget.path));
    const matchesExclude = excludeRegexes.some(regex => regex.test(widget.path));
    return matchesInclude && !matchesExclude;
  });
}

// // Example usage:
// const inputString = "startMiddleEnd";

// console.log(isPathMatchingPattern(inputString)); // false, no patterns provided
// console.log(isPathMatchingPattern(inputString, "start*End, *Middle*")); // true
// console.log(isPathMatchingPattern(inputString, "*start*, *End")); // false
// console.log(isPathMatchingPattern(inputString, "", "*End")); // false, excluded by "*End"
// console.log(isPathMatchingPattern(inputString, "start*End, , *MiddleEnd")); // true

export function isPathMatchingPattern(path: string, includePatterns: string = '', excludePatterns: string = ''): boolean {
  // Helper function to compile patterns into regexes
  const compilePatternsToRegexes = (patterns: string): RegExp[] => {
    return patterns
      .trim()
      .split(',')
      .map(pattern => pattern.trim())
      .filter(pattern => pattern) // Remove empty patterns after trimming
      .map(pattern => new RegExp('^' + pattern.replace(/([.+?^=!:${}()|\[\]\/\\])/g, '\\$1').replace(/\*/g, '.*') + '$', 'i'));
  };

  // Compile include and exclude patterns into regexes
  const includeRegexes = compilePatternsToRegexes(includePatterns);
  const excludeRegexes = compilePatternsToRegexes(excludePatterns);

  // Early return false if both patterns are empty, implying no matching criteria
  if (includePatterns.trim() === '' && excludePatterns.trim() === '') {
    return false;
  }

  // Check if the path matches any of the include patterns (or consider it a match if no include patterns provided)
  const matchesInclude = includeRegexes.length === 0 ? true : includeRegexes.some(regex => regex.test(path));

  // Check if the path matches any of the exclude patterns
  const matchesExclude = excludeRegexes.some(regex => regex.test(path));

  // The path is considered matching if it matches include patterns and does not match exclude patterns
  return matchesInclude && !matchesExclude;
}


// export function convertToEnvironmentArray(flatConfig: Record<string, any>, envList: Env[]): EnvDatas[] {
//   // Initialize an object to hold environment data
//   const envs: Record<string, any> = {};

//   // Initialize environment data for each environment in the envList
//   envList.forEach(env => {
//     envs[env] = {
//       env,
//       enabledPlatforms: null,
//       featureFlags: [],
//       parameters: []
//     };
//   });

//   // Collect all unique feature flag keys and parameter keys
//   const allFeatureKeys = new Set<string>();
//   const allParameterKeys = new Set<string>();

//   // Populate environment data and collect feature keys and parameter keys
//   for (const key in flatConfig) {
//     if (flatConfig.hasOwnProperty(key)) {
//       const value = flatConfig[key];
//       const [env, category, subKey] = key.split('.');

//       if (envList.includes(env as Env)) {
//         if (category === 'enabledPlatforms') {
//           envs[env].enabledPlatforms = value;
//         }

//         if (category === 'featureFlags') {
//           envs[env].featureFlags.push({ key: subKey, value: value });
//           allFeatureKeys.add(subKey);
//         }

//         if (category === 'parameters') {
//           envs[env].parameters.push({ key: subKey, value: value });
//           allParameterKeys.add(subKey);
//         }
//       }
//     }
//   }

//   // Convert the Sets to Arrays
//   const allFeatureKeysArray = Array.from(allFeatureKeys);
//   const allParameterKeysArray = Array.from(allParameterKeys);

//   // Function to ensure each environment has all feature flags and parameters
//   function ensureAllKeys(env: any, keysArray: string[], type: string) {
//     const existingKeys = env[type].map((item: any) => item.key);
//     keysArray.forEach((key) => {
//       if (!existingKeys.includes(key)) {
//         env[type].push({ key, value: null });
//       }
//     });
//   }

//   // Normalize each environment
//   Object.values(envs).forEach(env => {
//     ensureAllKeys(env, allFeatureKeysArray, 'featureFlags');
//     ensureAllKeys(env, allParameterKeysArray, 'parameters');
//   });

//   // Convert the envs object to an array and return
//   return Object.values(envs);
// }

export function convertDotNotationToStructured(flatConfig: Record<string, any>, supportedEnvs: Env[]): EnvDatas {
  const result: EnvDatas = {
    env: supportedEnvs,
    enabledPlatforms: [],
    enabledSegments: [],
    featureFlags: [],
    parameters: []
  };

  // Temporary storage for feature flags, parameters, and platforms
  const featureFlagsMap: Record<string, Record<string, any>> = {};
  const parametersMap: Record<string, Record<string, any>> = {};
  const platformsMap: Record<string, Set<string>> = {};
  const segmentsMap: Record<string, Set<string>> = {};

  // Iterate over the flatConfig to build intermediate structures
  for (const key in flatConfig) {
    if (flatConfig.hasOwnProperty(key)) {
      const value = flatConfig[key];
      const [env, category, subKey] = key.split('.');

      if (category === 'enabledPlatforms') {
        if (!platformsMap[env]) {
          platformsMap[env] = new Set();
        }
        value.forEach((platform: string) => platformsMap[env].add(platform));
      }

      if (category === 'enabledSegments') {
        if (!segmentsMap[env]) {
          segmentsMap[env] = new Set();
        }
        value.forEach((segments: string) => segmentsMap[env].add(segments));
      }

      if (category === 'featureFlags') {
        if (!featureFlagsMap[subKey]) {
          featureFlagsMap[subKey] = {};
        }
        featureFlagsMap[subKey][env] = value;
      }

      if (category === 'parameters') {
        if (!parametersMap[subKey]) {
          parametersMap[subKey] = {};
        }
        parametersMap[subKey][env] = value;
      }
    }
  }

  // Collect all unique keys and sort them alphabetically
  const allFeatureFlags = Object.keys(featureFlagsMap).sort();
  const allParameters = Object.keys(parametersMap).sort();

  // Ensure all feature flag keys are represented in the final output
  result.featureFlags = allFeatureFlags.map(key => 
    result.env.map((env: Env) => ({
      env,
      key,
      value: featureFlagsMap[key][env] || []
    }))
  );

  // Ensure all parameter keys are represented in the final output
  result.parameters = allParameters.map(key => 
    result.env.map((env: Env) => ({
      env,
      key,
      value: parametersMap[key][env] || null
    }))
  );

  // Convert enabled platforms to the desired format
  result.enabledPlatforms = result.env.map((env: Env) => ({
    env,
    value: Array.from(platformsMap[env] || [])
  }));

  // Convert enabled segments to the desired format
  result.enabledSegments = result.env.map((env: Env) => ({
    env,
    value: Array.from(segmentsMap[env] || [])
  }));

  return result;
}