<main class="comment-form p-3 ri-w-600" [ngClass]="{'pe-none': commentInitial.deletedAt || commentInitial.createdBy !== fbs.getCurrentUserId()}">
    <div class="d-flex justify-content-between mb-3">
        <h3 class="fw-semibold mb-0">Comment {{ mode === 'new' ? 'Create' : 'Update' }}</h3>
        <button *ngIf="mode === 'edit'" matTooltip="Get Activity" class="btn btn-info btn-sm btn-activity d-flex align-items-center" (click)="getActivity()"><mat-icon class="text-light">restore</mat-icon></button>
    </div>

    <div class="ri-dialog-content">
        <form [formGroup]="commentForm" #cmtForm="ngForm">
            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : commentForm.value.text !== commentInitial.text}">
                <mat-label>Text</mat-label>
                <textarea matInput formControlName="text" name="riCommentText" cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>

            <div class="row g-1 mb-1">
                <div class="col-6">
                    <app-select [options]="commentType" [title]="'Type'" class="w-100" [control]="getFc('type')"
                        [defaultValue]="commentInitial.type"></app-select>
                </div>
                <div class="col-6">
                    <app-select [options]="fbs.users" [title]="'Assigned To'" class="" [noneOption]="true"
                    [control]="getFc('assignedTo')" [defaultValue]="commentInitial.assignedTo" [label]="'label'" [value]="'value'"></app-select>
                </div>
            </div>
            <mat-checkbox [checked]="commentForm.value.resolvedBy" [disabled]="commentInitial.resolvedBy" color="primary" (change)="toggleResolved($event)">{{ commentForm.value.resolvedBy ? 'Resolved by ' + fbs.getUserNameFromId(commentForm.value.resolvedBy) : 'Mark as resolved' }}</mat-checkbox>

            <app-attachments [newAttachments]="newAttachments" [deletedAttachments]="deletedAttachments" [entityId]="commentForm.value.id" [attachmentsControl]="getFc('attachmentNames')"></app-attachments>
        </form>
    </div>

    <div class="ri-dialog-footer" *ngIf="!commentInitial.deletedAt || commentInitial.createdBy === fbs.getCurrentUserId()">
        <div class="d-flex justify-content-between mt-4">
            <div class="">
                <button *ngIf="mode === 'edit' && commentInitial.createdBy === fbs.getCurrentUserId()" type="button" class="btn btn-sm text-bg-danger ri-fs-16px lh-sm me-2 ri-btn-delete"
                    (click)="deleteComment()" [disabled]="commentInitial.deletedAt">
                    <i class="bi bi-trash"></i>
                </button>
            </div>
            <div>
                <button type="button" class="btn btn-sm btn-outline-secondary" (click)="cancelChanges()"
                    [disabled]="!hasChanges()">Reset</button>
                <button type="submit" class="btn btn-sm btn-primary ms-2" (click)="saveChanges()"
                    [disabled]="!hasChanges() || commentForm.invalid">Save Comment</button>
            </div>
        </div>
    </div>

</main>