import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DeliverableOwnerInfo } from '../_interfaces/Other';
import * as _ from 'lodash';
import { CacheService } from '../_services/cache.service';
import { FirebaseService } from '../_services/firebase.service';
import { MatTooltip } from '@angular/material/tooltip';
import { JsUser } from '../_interfaces/User';
import { O } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-owner-badges',
  templateUrl: './owner-badges.component.html',
  styleUrl: './owner-badges.component.scss'
})
export class OwnerBadgesComponent implements OnChanges {
  initialInfo: DeliverableOwnerInfo = {
    productOwner: [],
    designOwner: [],
    devOwner: [],
    testOwner: []
  };
  ownerInfo: DeliverableOwnerInfo = _.cloneDeep(this.initialInfo);
  @Input() deliverable: string | undefined = undefined;
  @Output() onUpdate = new EventEmitter();

  constructor(public cc: CacheService, public fb: FirebaseService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['deliverable']?.currentValue != null) {
      this.update();
    } else {
      this.ownerInfo = _.cloneDeep(this.initialInfo);
    }
    this.onUpdate.emit();
  }

  update() {
    // @ts-ignore
    this.ownerInfo = this.cc.deliverableOwners[this.deliverable];
  }

  getInitials(uid: string): string {
    const user = this.fb.uidUserMap[uid];
    if (!user?.label) {
      return '-';
    } else {
      return user.label
        .split(' ')
        .map((n: string) => n[0])
        .join('')
        .toUpperCase();
    }
  }
}
