import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { convertTimeStampsFb_Js, convertTimeStampsJs_Fb, getNewId } from '../shared/utils';
import { JsWidget, FbWidget } from '../_interfaces/Widget';
import { JsEntity, FbEntity } from '../_interfaces/Entities';
import { Collection } from '../_interfaces/Collection';
import { FbUser, JsUser } from '../_interfaces/User';
import { AppThemeConfig, Config } from '../_interfaces/Config';
import { DocumentData, QueryDocumentSnapshot, Timestamp, serverTimestamp } from '@angular/fire/firestore';
import { FbActivity, JsActivity } from '../_interfaces/Activity';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FbComment, JsComment } from '../_interfaces/Comment';
import { FirestoreQueryCondition } from '../_interfaces/Other';
import { FbDaily, JsDaily } from '../_interfaces/Daily';
import { FbLeave, JsLeave } from '../_interfaces/Leave';

interface keyValueType {
  [key: string]: string | number | boolean | Date | null;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  public widgets$!: Observable<JsWidget[]>;
  public deletedWidgets$!: Observable<JsWidget[]>;
  configSubject$ = new BehaviorSubject<Config[]>([]);
  public config$: Observable<Config[]> = this.configSubject$.asObservable();
  currentUserSubject$ = new BehaviorSubject<any>({});
  public currentUser$: Observable<any> = this.currentUserSubject$.asObservable();
  public users: any[] = [];
  public orderedUserIds: string[] = [];
  public currentUser!: any;
  public uidUserMap: any = {};
  public tagNameToUidMap: {[key: string] : string} = {};
  public uidToName: {[key: string] : string} = {};
  constructor(public afs: AngularFirestore, public auth: AngularFireAuth, private router: Router, private storage: AngularFireStorage) {

  }

  getFbUserData(): Observable<FbUser[]> {
    return this.afs.collection<FbUser>('users').valueChanges();
  }

  getUserNameFromId(id: string | undefined): string | undefined {
    if (!id) return;
    return this.users.find(user => user.value === id)?.label;
  }

  getDisplayName(id: string | null): string {
    if (!id) return '';
    const name = this.users.find(user => user.value === id)?.label;
    if (name && name.split(' ').length > 1) {
      return name.split(' ')[0];
    } else {
      return 'INVALID';
    }
  }

  getUserFirstAndLastLetter(id: string) {
    const nameParts = this.getUserNameFromId(id)?.trim().split(' ') || '';

    if (nameParts.length === 0) {
      return '';
    }
    const firstNameFirstLetter = nameParts[0].charAt(0);

    if (nameParts.length === 1) {
      return firstNameFirstLetter;
    }

    const lastNameFirstLetter = nameParts[nameParts.length - 1].charAt(0);
    return firstNameFirstLetter + lastNameFirstLetter;
  }

  getCurrentAssigneeNameFromWidget(widget: JsWidget): string | undefined {
    const assigneeId = widget[`${widget.status.toLowerCase()}AssignedTo` as string as keyof JsWidget];
    return assigneeId ? this.getUserNameFromId(assigneeId as string) : undefined;
  }

  getCurrentUserId() {
    return this.getLocalUser()?.user?.uid;
  }

  getCurrentUser() {
    const uid = this.getCurrentUserId();
    if (uid) {
      return this.users.find(user => user.value === uid);
    }
  }

  async getWidgetsCount(path: string) {
    return await this.afs
      .collection<FbWidget>('widgets', ref => ref.where('deletedAt', '==', null).where('path', '==', path))
      .get()
      .toPromise();
  }

  getConfig<T extends FbEntity, K extends JsEntity>(): Observable<Config[]> {
    return this.afs
      .collection<T>('config')
      .valueChanges()
      .pipe(
        map((items: T[]) => {
          const config = items.map(item => convertTimeStampsFb_Js(item) as K);
          this.configSubject$.next(config as unknown as Config[] || []);
          return config;
        })
      ) as unknown as Observable<Config[]>;
  }


  updateThemeInfo(themeInfo: AppThemeConfig) {
    const uid = this.getLocalUser()?.user?.uid;
    return this.afs.collection('config').doc('0').update({ themeInfo: themeInfo, updatedAt: new Date(), uid: uid });
  }

  getAllItems<T extends FbEntity, K extends JsEntity>(collection: Collection, from?: Date) {
    return this.afs
      .collection<T>(collection, ref => {
        let condition = ref.where('cloudUpdatedAt', '>', Timestamp.fromDate(from || new Date(0)));
        return condition;
      })
      .valueChanges()
      .pipe(
        map((items: T[]) => {
          return items.map(item => convertTimeStampsFb_Js(item) as K);
        })
      );
  }

  getActivities<T extends FbActivity, K extends JsActivity>(limit: number = 25, criterias?: FirestoreQueryCondition[], lastDocument?: QueryDocumentSnapshot<T, DocumentData> | null) {
    return this.afs.collection<T>('activities', ref => {
      let condition = ref.where('deletedAt', '==', null).orderBy('cloudUpdatedAt', 'desc');
      if (criterias?.length) {
        for (const { field, operator, value } of criterias) {
          condition = condition.where(field, operator, value);
        }
      }
      if (lastDocument) {
        condition = condition.startAfter(lastDocument);
      }
      if (limit) condition = condition.limit(limit);
      return condition;
    }).valueChanges()
      .pipe(
        map((items: T[]) => {
          return items.map(item => convertTimeStampsFb_Js(item) as K);
        })
      );
  }

  getActivitiesData<T extends FbActivity, K extends JsActivity>(limit: number = 25, criterias?: FirestoreQueryCondition[], lastDocument?: QueryDocumentSnapshot<T, DocumentData> | null) {
    return this.afs.collection<T>('activities', ref => {
      let condition = ref.where('deletedAt', '==', null).orderBy('cloudUpdatedAt', 'desc');
      if (criterias?.length) {
        for (const { field, operator, value } of criterias) {
          condition = condition.where(field, operator, value);
        }
      }
      if (lastDocument) {
        condition = condition.startAfter(lastDocument);
      }
      if (limit) condition = condition.limit(limit);
      return condition;
    }).get() // Use `get()` to retrieve document snapshots
      .pipe(
        map((querySnapshot) => {
          const items: K[] = [];
          querySnapshot.forEach((doc) => {
            const item = convertTimeStampsFb_Js(doc.data()) as K;
            items.push(item);
          });

          return { data: items, lastDocument: querySnapshot.docs[querySnapshot.docs.length - 1] };
        })
      );
  }

  getActivitiesSnapshotChanges<T extends FbActivity, K extends JsActivity>(limit: number = 25, criterias?: FirestoreQueryCondition[], lastDocument?: QueryDocumentSnapshot<T, DocumentData> | null) {
    return this.afs.collection<T>('activities', ref => {
      let condition = ref.where('deletedAt', '==', null).orderBy('cloudUpdatedAt', 'desc');
      if (criterias?.length) {
        for (const { field, operator, value } of criterias) {
          condition = condition.where(field, operator, value);
        }
      }
      if (lastDocument) {
        condition = condition.startAfter(lastDocument);
      }
      if (limit) condition = condition.limit(limit);
      return condition;
    }).snapshotChanges() // Use `get()` to retrieve document snapshots
      .pipe(
        map((snapshotChanges) => {
          const items: K[] = [];
          snapshotChanges.forEach((change) => {
            const item = convertTimeStampsFb_Js(change.payload.doc.data()) as K;
            items.push(item);
          });

          return { data: items, lastDocument: snapshotChanges[snapshotChanges.length - 1]?.payload?.doc };
        })
      );
  }

  getComments<T extends FbComment, K extends JsComment>(limit: number = 25, criterias?: FirestoreQueryCondition[], lastDocument?: QueryDocumentSnapshot<T, DocumentData> | null) {
    return this.afs.collection<T>('comments', ref => {
      let condition = ref.where('deletedAt', '==', null).orderBy('cloudUpdatedAt', 'desc');
      if (criterias?.length) {
        for (const { field, operator, value } of criterias) {
          condition = condition.where(field, operator, value);
        }
      }
      if (lastDocument) {
        condition = condition.startAfter(lastDocument);
      }
      if (limit) condition = condition.limit(limit);
      return condition;
    })
      .snapshotChanges() // Use `get()` to retrieve document snapshots
      .pipe(
        map((snapshotChanges) => {
          const items: K[] = [];
          snapshotChanges.forEach((change) => {
            const item = convertTimeStampsFb_Js(change.payload.doc.data()) as K;
            items.push(item);
          });

          return { data: items, lastDocument: snapshotChanges[snapshotChanges.length - 1]?.payload?.doc };
        })
      );
  }

  getTrashDatas<T extends FbEntity, K extends any>(limit: number = 25, collection: Collection, criterias?: FirestoreQueryCondition[], lastDocument?: QueryDocumentSnapshot<T, DocumentData> | null) {
    return this.afs.collection<T>(collection, ref => {
      let condition = ref.where('deletedAt', '!=', null).orderBy('cloudUpdatedAt', 'desc');
      if (criterias?.length) {
        for (const { field, operator, value } of criterias) {
          condition = condition.where(field, operator, value);
        }
      }
      if (lastDocument) {
        condition = condition.startAfter(lastDocument);
      }
      if (limit) condition = condition.limit(limit);
      return condition;
    })
      .get() // Use `get()` to retrieve document snapshots
      .pipe(
        map((querySnapshot) => {
          const items: K[] = [];
          querySnapshot.forEach((doc) => {
            const item = convertTimeStampsFb_Js(doc.data()) as K;
            items.push(item);
          });

          return { data: items, lastDocument: querySnapshot.docs[querySnapshot.docs.length - 1] };
        })
      );
  }

  getLeaves<T extends FbLeave, K extends JsLeave>(criterias?: FirestoreQueryCondition[], lastDocument?: QueryDocumentSnapshot<T, DocumentData> | null) {
    return this.afs.collection<T>('leaves', ref => {
      let condition = ref.orderBy('cloudUpdatedAt', 'desc');
      if (criterias?.length) {
        for (const { field, operator, value } of criterias) {
          condition = condition.where(field, operator, value);
        }
      }
      if (lastDocument) {
        condition = condition.startAfter(lastDocument);
      }
      // if (limit) condition = condition.limit(limit);
      return condition;
    }).valueChanges()
      .pipe(
        map((items: T[]) => {
          return items.map(item => convertTimeStampsFb_Js(item) as K);
        })
      );
  }

  getDailysOfTask<T extends FbDaily, K extends JsDaily>(tid: number) {
    return this.afs
      .collection<T>('dailys', ref => {
        let condition = ref.where('deletedAt', '==', null).where('tid', '==', tid).orderBy('cloudUpdatedAt', 'desc');
        return condition;
      })
      .valueChanges()
      .pipe(
        map((items: T[]) => {
          return items.map(item => convertTimeStampsFb_Js(item) as K);
        })
      );
  }

  getAssignedComments<T extends FbComment, K extends JsComment>(limit: number = 25) {
    return this.afs
      .collection<T>('comments', ref => {
        let condition = ref
          .where('deletedAt', '==', null)
          .where('assignedTo', '==', this.getCurrentUserId())
          .where('resolvedBy', '==', null)
          .orderBy('updatedAt', 'desc');
        if (limit) condition = condition.limit(limit);
        return condition;
      })
      .valueChanges()
      .pipe(
        map((items: T[]) => {
          return items.map(item => convertTimeStampsFb_Js(item) as K);
        })
      );
  }

  getItems<T extends FbEntity, K extends JsEntity>(criteria: keyValueType, collection: Collection, limit?: number) {
    return this.afs
      .collection<T>(collection, ref => {
        let condition = ref.where('deletedAt', '==', null).orderBy('createdAt', 'desc');
        for (const [key, value] of Object.entries(criteria)) {
          condition = condition.where(key, '==', value);
        }
        if (limit) condition = condition.limit(limit);
        return condition;
      })
      .valueChanges()
      .pipe(
        map((items: T[]) => {
          return items.map(item => convertTimeStampsFb_Js(item) as K);
        })
      );
  }

  getLastCheckedTime<T extends FbEntity, K extends JsEntity>(collection: Collection) {
    return this.afs.collection<T>(collection)
      .valueChanges()
      .pipe(
        map((items: T[]) => {
          return items.map(item => convertTimeStampsFb_Js(item) as K);
        })
      );
  }

  async createItems<T extends JsEntity>(items: T[], collection: Collection) {
    const uid = this.getLocalUser()?.user?.uid;
    if (!items.length || !uid) return;
    // Flesh details
    items.forEach(item => {
      item.id = item.id || getNewId();
      item.uid = collection === 'users' ? item.uid : uid;
      item.createdBy = uid;
      item.createdAt = item.createdAt || new Date();
      item.cloudUpdatedAt = serverTimestamp();
    });

    // Add in Firestore - Because of the max limit of 500 writes per batch
    if (items.length > 10 && items.length < 450) {
      const batch = this.afs.firestore.batch();
      items.forEach(async item => {
        const docRef = this.afs.firestore.collection(collection).doc(item.id);
        batch.set(docRef, convertTimeStampsJs_Fb(item)!, { merge: true });
      });
      await batch.commit();
    } else {
      items.forEach(async item => {
        await this.afs.collection(collection).doc(item.id).set(convertTimeStampsJs_Fb(item));
      });
    }
  }

  async updateItems<T extends JsEntity>(items: T[], collection: Collection) {
    const uid = this.getLocalUser()?.user?.uid;
    if (!items.length || !uid) return;
    // Flesh details
    items.forEach(item => {
      item.uid = collection === 'users' ? item.uid : uid;;
      item.updatedAt = new Date();
      item.cloudUpdatedAt = serverTimestamp();
    });
    // Add in Firestore - Because of the max limit of 500 writes per batch
    if (items.length > 10 && items.length < 450) {
      const batch = this.afs.firestore.batch();
      items.forEach(item => {
        const docRef = this.afs.firestore.collection(collection).doc(item.id);
        batch.update(docRef, convertTimeStampsJs_Fb(item)!);
      });
      await batch.commit();
    } else {
      items.forEach(async item => {
        await this.afs.collection(collection).doc(item.id).update(convertTimeStampsJs_Fb(item)!);
      });
    }
  }

  async uploadFiles(files: File[], pathExceptFileName: string) {
    if (!files.length) return;
    const uid = this.getLocalUser()?.user?.uid;
    if (!uid) return;
    const uploadTasks = files.map(file => {
      const filePath = `${pathExceptFileName}${file.name}`;
      const fileRef = this.storage.ref(filePath);
      return this.storage.upload(filePath, file);
    });
    return await Promise.all(uploadTasks);
  }

  async deleteFiles(fileNames: string[], pathExceptFileName: string) {
    if (!fileNames.length) return;
    const uid = this.getLocalUser()?.user?.uid;
    if (!uid) return;
    const deleteTasks = fileNames.map(fileName => {
      const filePath = `${pathExceptFileName}${fileName}`;
      const fileRef = this.storage.ref(filePath);
      return fileRef.delete();
    });
    return await Promise.all(deleteTasks);
  }

  getLocalUser() {
    return JSON.parse(localStorage.getItem('user')!);
  }

  getUsername(uid: string) {
    return this.users.find(user => user.value === uid)?.label;
  }

  checkRoleForCurrentUser(role: string) {
    return this.getCurrentUser()?.roles.includes(role);
  }

  isAdmin(): boolean {
    return this.getCurrentUser()?.roles.includes('ADMIN');
  }

  isPo(): boolean {
    return this.getCurrentUser()?.roles.includes('PO');
  }

  isDesigner(): boolean {
    return this.getCurrentUser()?.roles.includes('DESIGNER');
  }

  isDeveloper(): boolean {
    return this.getCurrentUser()?.roles.includes('DEVELOPER');
  }

  isQa(): boolean {
    return this.getCurrentUser()?.roles.includes('QA');
  }

  getSpecStatusOfUser(): string[] {
    const roleStatusMap: { [key: string]: string[] } = {
      'admin': ['Backlog', 'Approve', 'Review'],
      'po': ['Backlog', 'Approve', 'Review'],
      'designer': ['Design', 'Correction'],
      'developer': ['Develop', 'Failed'],
      'qa': ['Test'],
    };
  
    const specStatus: Set<string> = new Set();
  
    // Helper function to add multiple statuses to the Set
    const addStatuses = (statuses: string[]) => {
      statuses.forEach(status => specStatus.add(status));
    };
  
    // Check roles and add corresponding statuses
    if (this.isAdmin() || this.isPo()) {
      addStatuses(roleStatusMap['admin']);
    }
    if (this.isDesigner()) {
      addStatuses(roleStatusMap['designer']);
    }
    if (this.isDeveloper()) {
      addStatuses(roleStatusMap['developer']);
    }
    if (this.isQa()) {
      addStatuses(roleStatusMap['qa']);
    }
  
    return Array.from(specStatus); // Convert Set to Array before returning
  }
}
