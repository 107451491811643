import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { CacheService } from '../_services/cache.service';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { ListSpecsOfViewComponent } from '../list-specs-of-view/list-specs-of-view.component';
import { ViewBlocksOfTaskComponent } from '../view-blocks-of-task/view-blocks-of-task.component';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-spec-type-counts-of-view',
  templateUrl: './spec-type-counts-of-view.component.html',
  styleUrls: ['./spec-type-counts-of-view.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltip
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecTypeCountsOfViewComponent implements OnInit {
  @Input() viewId: string = '';
  total: number = 0;
  state: number = 0;
  action: number = 0;
  rule: number = 0;
  bug: number = 0;
  isEmpty: boolean = true;

  constructor(
    private cc: CacheService,
    private _dialog: DialogManagerService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(change: SimpleChange) {
    // @ts-ignore
    if (change['viewId']) {
      this.update();
    }
  }

  update() {
    this.isEmpty = true;
    this.total = 0;
    this.state = 0;
    this.action = 0;
    this.rule = 0;
    this.bug = 0;
    const widgetIdsByType = this.cc.viewIdsToWidgetIdsGroupedByType[this.viewId];

    if (widgetIdsByType) {
      Object.entries(widgetIdsByType).forEach(([type, widgetIds]) => {
         // @ts-ignore
        this[type] = widgetIds.length;
      });
    
      // Dynamically check if any of the widget types (state, action, rule, bug) have values
       // @ts-ignore
      this.isEmpty = !['state', 'action', 'rule', 'bug'].some(type => this[type]);
    }

    this.total = this.state + this.action + this.rule + this.bug;
  }

  showSpecList(viewId: string, e: any) {
    this._dialog.openDialog(ListSpecsOfViewComponent, {
      data: {
        viewId,
      },
    });
    e.stopPropagation();
  }
}
