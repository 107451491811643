import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { CacheService } from '../_services/cache.service';
import { JsWidget, assignedToKey } from '../_interfaces/Widget';
import { FirebaseService } from '../_services/firebase.service';
import {
  faArrowUpRightFromSquare,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { UserSessionStorageService } from '../_services/user-session-storage.service';
import { SvgViewerModalService } from '../_services/svg-viewer-modal.service';
import { WidgetDetailEditComponent } from '../widget-detail-edit/widget-detail-edit.component';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-diagram-table',
  templateUrl: './diagram-table.component.html',
  styleUrls: ['./diagram-table.component.scss'],
})
export class DiagramTableComponent implements OnInit, OnDestroy {
  public linkIcon = faEdit;
  widgetSubscription: any;
  initialDiagramWidgets: JsWidget[] = [];
  finalDiagrams: JsWidget[] = [];
  tableData: JsWidget[] = [];
  displayedColumns: string[] = [
    'name',
    'category',
    'subStatus',
    'assignedTo',
    'specLink',
    'figjamLink',
    'uid',
    'cloudUpdatedAt',
    'deletedAt',
  ];
  filterValue: string = '';
  filterOptions = {
    groupBy: {
      options: [
        'None',
        'Category',
        'subStatus',
        'assignedTo',
        'uid',
      ],
      selected: 'Category'
    },
  }
  statusStyleMap: { [key: string]: string } = {
    'To Do': 'text-bg-info',
    'In Progress': 'text-bg-secondary',
    Blocked: 'text-bg-danger',
    Done: 'text-bg-success',
  };
  groupValues: string[] = [];
  showGroupValues: any[] = [];
  groupedDiagrams: { [key: string]: JsWidget[] } = {};
  unSubscribe = new Subject<void>();
  sortKey: string = '';

  constructor(
    public cc: CacheService,
    private router: Router,
    public fbs: FirebaseService,
    private uss: UserSessionStorageService,
    private svgViewerModalService: SvgViewerModalService,
    private _dialog: DialogManagerService
  ) {}

  ngOnInit(): void {
    this.widgetSubscription = this.cc.widgets$.subscribe((widgets) => {
      this.initialDiagramWidgets = widgets.filter(
        (widget) =>
          widget.type === 'state' && widget.path.startsWith('taskDiagram')
      );
      this.finalDiagrams = this.initialDiagramWidgets.sort((a, b) => {
        const aPriority =
          a.path.split(':').length > 1 ? Number(a.path.split(':')[1]) : 0;
        const bPriority =
          b.path.split(':').length > 1 ? Number(b.path.split(':')[1]) : 0;
        return aPriority - bPriority;
      });
      this.getGroupedWidgets();
    });
  }

  getGroupedWidgets() {
    this.uss.currentUserSessionStorage$.pipe(takeUntil(this.unSubscribe)).subscribe(snapshot => {
      if (this.filterOptions.groupBy.selected !== snapshot.diagramTableGroupByFilter) {
        this.filterOptions.groupBy.selected = snapshot.diagramTableGroupByFilter;
        this.showGroupValues = [];
        this.onFilterChange();
      } else {
        this.onFilterChange();
      }
    });
  }

  onFilterChange() {
    this.sortKey = '';
    this.groupValues = [];
    let filteredDiagrams = _.cloneDeep(this.finalDiagrams);

    if (this.filterValue) {
      filteredDiagrams = filteredDiagrams.filter((diag: JsWidget) => {
        return (
          diag.path?.toLowerCase().includes(this.filterValue) ||
          diag.status?.toLowerCase().includes(this.filterValue)
        );
      });
    }

    if (this.filterOptions.groupBy.selected === 'None') {
      // Do nothing
    } else {
      this.groupBy(filteredDiagrams, this.filterOptions.groupBy.selected as keyof JsWidget);
    }
    this.uss.setUserSessionStorageItem('diagramTableGroupByFilter', this.filterOptions.groupBy.selected, false);

    this.tableData = filteredDiagrams;
  }

  groupBy(events: JsWidget[], groupKey: keyof JsWidget) {
    this.groupedDiagrams = {};
    if(this.filterOptions.groupBy.selected === 'Category') {
      this.groupedDiagrams = this.groupByCategory(events);
      this.groupValues = Object.keys(this.groupedDiagrams);
    } else {
      const groupedDiagrams = _.groupBy(events, task => task[groupKey] || '');
      this.enrichgroupedEventsAndReorder(groupedDiagrams, groupKey);
    }
  }
  
  enrichgroupedEventsAndReorder(grouped: { [key: string]: JsWidget[] }, groupKey: keyof JsWidget) {
    let enrichedGrouped = _.cloneDeep(grouped);

    if (['uid', 'createdBy', 'assignedTo'].includes(groupKey)) {
      enrichedGrouped = this.enrichgroupedEventsWithEmptyUsers(enrichedGrouped, groupKey);
    }

    this.groupedDiagrams = _.cloneDeep(enrichedGrouped);
    this.groupValues = Object.keys(this.groupedDiagrams);
  }

  groupByCategory(enrichedGrouped: JsWidget[]) {
    const groupedData: any = enrichedGrouped.reduce<Record<string, JsWidget[]>>((acc, item) => {
      let itemPath = this.getCategory(item);
      if (!acc[itemPath]) {
        acc[itemPath] = [];
      }
      acc[itemPath].push(item);
      return acc;
    }, {});
    return groupedData;
  }

  enrichgroupedEventsWithEmptyUsers(grouped: { [key: string]: JsWidget[] }, groupKey: keyof JsWidget) {
    const validUids = this.fbs.orderedUserIds;
    const enrichedGrouped = _.cloneDeep(grouped);
    const updatedGrouped: { [key: string]: JsWidget[] } = {};
    validUids.forEach(uid => {
      if (enrichedGrouped[uid] !== undefined) {
        updatedGrouped[uid] = enrichedGrouped[uid];
      } else {
        updatedGrouped[uid] = [];
      }
    });
    if (enrichedGrouped[''] !== undefined) {
      updatedGrouped[''] = enrichedGrouped[''];
    }
    return updatedGrouped;
  }

  sortData(element: HTMLTableCellElement, sortKey: string) {
    const sortOrder = element.getAttribute('sortOrder');
    this.tableData.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) => {
      let valueA = sortKey === 'name' || sortKey === 'category' ? a['path'] : a[sortKey];
      let valueB = sortKey === 'name' || sortKey === 'category' ? b['path'] : b[sortKey];
  
      // Handle string comparison
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }
  
      // Handle sorting order
      if (sortOrder === 'asc') {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      } else if (sortOrder === 'desc') {
        return 0
      }
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    });
    if(this.filterOptions.groupBy.selected !== 'None') {
      this.groupBy(this.tableData, this.filterOptions.groupBy.selected as keyof JsWidget);
    }
    element.setAttribute('sortOrder', sortOrder === 'asc' ? 'desc' : sortOrder == 'desc' ? 'none' : 'asc');
    this.sortKey = sortKey;
  }

  toggleChildGroup(element: HTMLElement, groupId: string) {
    const isOpened = element.getAttribute('isOpen');
    const groupedElement = document.getElementById(groupId);
    if(!groupedElement){
      return
    }
    if(isOpened === 'false') {
      groupedElement.setAttribute('isGroupOpened', 'true');
      element.setAttribute('isOpen', 'true');
      groupedElement.style.height = '0px'
    } else {
      groupedElement.setAttribute('isGroupOpened', 'false');
      element.setAttribute('isOpen', 'false');
    }
  }

  ngOnDestroy(): void {
    this.widgetSubscription.unsubscribe();
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.onFilterChange();
  }

  getAssignedTo(widget: JsWidget) {
    const assignedToKey = widget.status.toLowerCase() + 'AssignedTo';
    // @ts-ignore
    const userId = widget[assignedToKey] || '';
    return this.fbs.getDisplayName(userId);
  }

  getName(widget: JsWidget) {
    const lastPart = widget.path.split('_state')[1].split(':')[0];
    return _.startCase(lastPart);
  }

  openWidgetLink(item: JsWidget, e: any) {
    e.stopPropagation();
    this.uss.resetUserSessionStorage('widget');

    let widgetPath = item.path;
    this.uss.setUserSessionStorageItem('widgetDetailView', 'info');
    if (!widgetPath) {
      return;
    }
    this.uss.setUserSessionStorageItem('widgetPath', widgetPath);
    this.router.navigate(['path']);
  }

  previewFigma(widget: JsWidget | null) {
    if (!widget?.stateFigmaPreviewUrl) return;
    this.svgViewerModalService
      .show(widget!, true)
      .subscribe(async (res: any) => {
        // if (res && res.action === 'delete') {
        //   await this.resetFigmaPreview();
        // }
      });
  }

  getCategory(widget: JsWidget) {
    const secondPart = widget.path.split('_')[1];
    const category = secondPart.startsWith('state') ? '' : secondPart;
    return _.startCase(category);
  }

  showWidgetDetails(widget: JsWidget, e: any) {
    this._dialog.openDialog(WidgetDetailEditComponent, {
      panelClass: 'widget-detail-edit-bottom-sheet',
      data: {
        widgetId: widget.id,
        hiddenFields: [
          'status',
          'tags',
          'regressions',
          'viewId',
          'viewBlock',
          'taskBlock',
          'fingerPrints',
        ],
      },
    });
    e.stopPropagation();
  }
}
