import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appUrlInput]',
  standalone: true,
})
export class UrlInputDirective {

  // Regex to allow only characters relevant to URLs
  private urlRegex: RegExp = /^[a-zA-Z0-9\-._~:/?#\[\]@!$&'()*+,;=]*$/;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const key = event.key;

    // Allow control keys like Backspace, Delete, Tab, etc.
    if (this.isControlKey(event)) {
      return;
    }

    // Allow only characters that match the URL regex
    if (key.length === 1 && !this.urlRegex.test(key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');

    // Block pasting if the text contains characters not allowed in URLs
    if (!this.urlRegex.test(pastedText)) {
      event.preventDefault();
    }
  }

  // Helper method to check if the pressed key is a control key
  private isControlKey(event: KeyboardEvent): boolean {
    const controlKeys = [
      'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
      'Home', 'End', 'Control', 'Meta', 'Shift', 'Alt'
    ];
    return controlKeys.includes(event.key);
  }
}