import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SnackbarService } from '../_services/snackbar.service';

@Component({
  selector: 'app-view-block',
  templateUrl: './view-block.component.html',
  styleUrls: ['./view-block.component.scss'],
})
export class ViewBlockComponent implements OnInit {
  @Input() viewId: string = '';
  @Input() hideTranslate: boolean = false;
  @Input() hideSpecType: boolean = false;
  @Input() hideSpecStatus: boolean = false;

  constructor(public snackbar: SnackbarService) {}

  ngOnInit(): void {}
}
