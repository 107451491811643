import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmModalComponent,
  ConfirmDialogModel,
} from '../shared/components/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  private confirmDialog: any;
  constructor(public dialog: MatDialog) {}

  confirm(
    header: string,
    message: string,
    yesButtonText = 'Yes',
    noButtonText = 'No',
    showNoButton = true
  ) {
    this.confirmDialog = this.dialog.open(ConfirmModalComponent, {
      maxWidth: '400px',
      data: new ConfirmDialogModel(
        header,
        message,
        yesButtonText,
        noButtonText,
        showNoButton
      ),
    });
    if (!confirm) {
      return Promise.resolve(false);
    }
    return Promise.resolve(this.confirmDialog.afterClosed().toPromise());
  }
}
