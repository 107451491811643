import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectFilterComponent } from '../shared/components/select-filter/select-filter.component';
import { JsUser } from '../_interfaces/User';
import { JsWidget } from '../_interfaces/Widget';
import * as _ from 'lodash';
import { Subscription, combineLatestWith } from 'rxjs';
import { getLastDateStrings, getcurrentLocalDateAsString } from '../shared/utils';
import { FilterPipe } from '@guramrit/ngx-filter-pipe';
import { FirebaseService } from '../_services/firebase.service';
import { IdbServiceService } from '../_services/idb-service.service';
import { JsDaily } from '../_interfaces/Daily';
import { format } from 'date-fns';
import { SpecStatusCounts, TaskStatusCounts } from '../_interfaces/Other';
import { JsActivity } from '../_interfaces/Activity';
import { CacheService } from '../_services/cache.service';
import { MatDialog } from '@angular/material/dialog';
import { UserTaskHoursComponent } from '../user-task-hours/user-task-hours.component';
import { JsTask } from '../_interfaces/Task';
import { WidgetDetailEditComponent } from '../widget-detail-edit/widget-detail-edit.component';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { TaskListModalComponent } from '../task-list-modal/task-list-modal.component';
import { SpecListModalComponent } from '../spec-list-modal/spec-list-modal.component';
import { SharedFunctionService } from '../_services/shared-function.service'

@Component({
  selector: 'app-daily-plan',
  templateUrl: './daily-plan.component.html',
  styleUrls: ['./daily-plan.component.scss']
})
export class DailyPlanComponent implements OnInit, OnDestroy {
  private widgetSubscription: any;
  planWidgets: JsWidget[] = [];
  @ViewChild('statusFilter') statusFilter: SelectFilterComponent | null = null;
  @ViewChild('columnFilter') columnFilter: SelectFilterComponent | null = null;
  @ViewChild('userFilter') userFilter: SelectFilterComponent | null = null;
  // @ts-ignore
  @ViewChild('search') search;
  private subscription: Subscription = new Subscription();
  private statusUpdateSubscription: Subscription = new Subscription();
  private columnSubscription: Subscription = new Subscription();
  private taskSubscription: Subscription = new Subscription();
  public view: 'Daily Plan' | 'Status Updates' = 'Daily Plan';
  public selectedWho: string | null = null;
  public selectedWhen: string | null = null;
  public dailys: JsDaily[] = [];
  public filteredDailys: JsDaily[] = [];
  public users: JsUser[] = [];
  public selectedDaily: JsDaily | null = null;
  public selectedUsers: string[] = [];
  public selectedDateRange: string = 'Today';
  public searchInput: EventEmitter<string> = new EventEmitter<string>();
  public userDailysMap: { [key: string]: JsDaily[] } = {};
  public userDateDailysMap: { [key: string]: { [key: string]: JsDaily[] } } = {};
  public activities: JsActivity[] = [];
  public assignedTasks: { [key: string]: JsTask[] } = {};
  public userDateFromStatusCountsMap: {
    [key: string]: { [key: string]: SpecStatusCounts };
  } = {};
  public userDateToStatusCountsMap: {
    [key: string]: { [key: string]: SpecStatusCounts };
  } = {};
  public userDateCurrentStatusCountsMap: {
    [key: string]: { [key: string]: SpecStatusCounts };
  } = {};
  public userDateTaskFromStatusCountsMap: {
    [key: string]: { [key: string]: TaskStatusCounts };
  } = {};
  public userDateTaskToStatusCountsMap: {
    [key: string]: { [key: string]: TaskStatusCounts };
  } = {};
  public userDateTaskCurrentStatusCountsMap: {
    [key: string]: { [key: string]: TaskStatusCounts };
  } = {};
  public orderedUsersForStandup: any[] = [];
  public currentUserId = this.fbService.getCurrentUserId();

  public filterOptions = {
    status: {
      title: 'Status',
      options: [
        { label: 'Not Started', value: 'Not Started' },
        { label: 'In Progress', value: 'In Progress' },
        { label: 'Blocked', value: 'Blocked' },
        { label: 'Completed', value: 'Completed' }
      ],
      selectedEmitter: new EventEmitter<string[]>()
    },
    dateRange: {
      title: 'Date Range',
      options: [
        { label: 'Today', value: 'Today' },
        { label: 'Last 7 days', value: 'Last 7 days' }
        // { label: 'Last 30 days', value: 'Last 30 days' },
        // { label: 'All', value: 'All' },
      ],
      selectedEmitter: new EventEmitter<string>()
    },
    user: {
      title: 'User',
      selectedEmitter: new EventEmitter<string[]>(),
      initialSelection: [this.currentUserId],
      resetSelection: [this.currentUserId]
    },
    columns: {
      title: 'Feature Details',
      options: [
        { label: 'Specs', value: 'Specs' },
        { label: 'Translations', value: 'Translations' },
        { label: 'Status', value: 'Status' },
        { label: 'Assigned To', value: 'Assigned To' },
        { label: 'Completed', value: 'Completed' }
      ],
      initialSelection: ['Status', 'Assigned To'] as string[],
      selected: ['Status', 'Assigned To'] as string[],
      selectedEmitter: new EventEmitter<string[]>()
    }
  };

  constructor(
    public fbService: FirebaseService,
    private filter: FilterPipe,
    private idb: IdbServiceService,
    public cc: CacheService,
    public dialog: MatDialog,
    private _dialog: DialogManagerService,
    public sharedFunc: SharedFunctionService
  ) {
    this.widgetSubscription = this.cc.widgets$.subscribe(widgets => {
      const planWidgets = widgets.filter(widget => widget.path.startsWith('taskWeeklyPlan'));
      if (_.isEqual(planWidgets, this.planWidgets)) return;
      this.planWidgets = planWidgets;
    });
    this.taskSubscription = this.cc.tasks$.subscribe(tasks => {
      const assignedTasks: { [key: string]: JsTask[] } = {};
      tasks.forEach(task => {
        if (!task.assignedTo) return;
        // Ignore if the is deleted or Approved
        if (task.deletedAt || task.status === 'Approved') return;
        if (!assignedTasks[task.assignedTo]) {
          assignedTasks[task.assignedTo] = [];
        }
        assignedTasks[task.assignedTo].push(task);
      });
      this.assignedTasks = assignedTasks;
    });
    this.subscription = this.searchInput
      .pipe(
        combineLatestWith(
          this.idb.dailys$,
          this.filterOptions.status.selectedEmitter,
          this.filterOptions.dateRange.selectedEmitter,
          this.filterOptions.user.selectedEmitter
        )
      )
      .subscribe(([search, dailys, status, dateRange, user]) => {
        this.selectedUsers = user;
        this.selectedDateRange = dateRange;
        this.dailys = dailys.filter(daily => daily.deletedAt === null);
        let date: string[] = [];
        if (dateRange === 'Today') {
          date = [getcurrentLocalDateAsString()];
        } else if (dateRange === 'Last 7 days') {
          getLastDateStrings(7).forEach(d => date.push(d));
        } else if (dateRange === 'Last 30 days') {
          getLastDateStrings(30).forEach(d => date.push(d));
        } else if (dateRange === 'All') {
          date = [];
        }
        const filterInput = {
          $or: [{ status: search || '' }, { eodNote: search || '' }, { tid: search || '' }, { date: search || '' }],
          status: status.length ? { $or: status } : '',
          assignedTo: user.length ? { $or: user } : '',
          date: date.length ? { $or: date } : ''
        };
        const filteredDailys = this.filter.transform(_.cloneDeep(this.dailys), filterInput);

        this.filteredDailys = _.sortBy(filteredDailys, ['date', 'time']);
        this.filteredDailys = _.orderBy(this.filteredDailys, ['date', 'time'], ['desc', 'desc']);

        this.userDailysMap = {};
        this.userDateDailysMap = {};
        this.filteredDailys.forEach(daily => {
          if (!this.userDailysMap[daily.assignedTo]) {
            this.userDailysMap[daily.assignedTo] = [];
          }
          this.userDailysMap[daily.assignedTo].push(daily);
        });

        // Loop through each user and create a map of date to dailys
        Object.keys(this.userDailysMap).forEach(userId => {
          this.userDateDailysMap[userId] = {};
          this.userDailysMap[userId].forEach(daily => {
            if (!this.userDateDailysMap[userId][daily.date]) {
              this.userDateDailysMap[userId][daily.date] = [];
            }
            this.userDateDailysMap[userId][daily.date].push(daily);
          });
        });
      });
    // Without this combineLatest won't trigger on first load
    this.searchInput.emit('');
    this.filterOptions.status.selectedEmitter.emit([]);
    this.filterOptions.dateRange.selectedEmitter.emit('Today' as any);
    this.filterOptions.user.selectedEmitter.emit([this.currentUserId]);

    this.statusUpdateSubscription = this.idb.statusUpdates$.subscribe((activities: JsActivity[]) => {
      this.activities = activities;
      this.setStatusCountMaps();
    });
  }

  reset() {
    this.search.nativeElement.value = '';
    this.searchInput.emit('');
    this.statusFilter?.reset();
    this.columnFilter?.reset();
    this.userFilter?.reset();
    this.searchInput.emit('');
    this.filterOptions.status.selectedEmitter.emit([]);
    this.filterOptions.dateRange.selectedEmitter.emit('Today' as any);
    this.filterOptions.user.selectedEmitter.emit([this.currentUserId]);
    this.filterOptions.columns.selectedEmitter.emit(this.filterOptions.columns.initialSelection);
  }

  ngOnInit(): void {
    const interval = setInterval(() => {
      this.orderedUsersForStandup = this.fbService.users
        .filter(user => user.standupOrder > 0)
        .sort((a, b) => {
          return a.standupOrder - b.standupOrder;
        });

      if (this.orderedUsersForStandup.length) {
        clearInterval(interval);
      }
    }, 1000);

    this.columnSubscription = this.filterOptions.columns.selectedEmitter.subscribe(selected => {
      this.filterOptions.columns.selected = selected;
    });

    this.subscription = this.cc.dailyDialogClose$.subscribe(daily => {
      this.selectedDaily = daily;
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.statusUpdateSubscription?.unsubscribe();
    this.columnSubscription?.unsubscribe();
    this.widgetSubscription?.unsubscribe();
    this.taskSubscription?.unsubscribe();
  }

  trackByDate(index: number, el: any): number {
    return el.date;
  }

  trackByUserLabel(index: number, el: any): number {
    return el.label;
  }

  onSelect(daily: JsDaily): void {
    this.selectedDaily = daily;
  }

  createNewDaily() {
    this.selectedDaily = this.sharedFunc.createNewDailyTask();
  }

  getUserDailysAll(userId: string) {
    const dailys = this.userDailysMap[userId] || [];
    let totalHours = 0;
    dailys.forEach(daily => {
      totalHours += daily.hours;
    });
    return { dailys, totalHours };
  }

  getUserDailysByDate(userId: string, date: string) {
    const dailys = this.userDateDailysMap[userId]?.[date] || [];
    let totalHours = 0;
    dailys.forEach(daily => {
      totalHours += daily.hours;
    });
    return { dailys, totalHours };
  }

  getDatesFromUserDateDailysMap(userId: string) {
    return Object.keys(this.userDateDailysMap[userId] || {});
  }

  shouldShowUser(userId: string) {
    if (!this.selectedUsers.length) return true;
    if (this.selectedUsers.includes(userId)) return true;
    return false;
  }

  showStatusUpdatesView(who: string, when: string) {
    this.selectedWho = who;
    this.selectedWhen = when;
    this.view = 'Status Updates';
  }

  onCloseStatusUpdates() {
    this.view = 'Daily Plan';
  }

  setStatusCountMaps() {
    this.userDateFromStatusCountsMap = {};
    this.userDateToStatusCountsMap = {};
    this.userDateCurrentStatusCountsMap = {};
    this.userDateTaskFromStatusCountsMap = {};
    this.userDateTaskToStatusCountsMap = {};
    this.userDateTaskCurrentStatusCountsMap = {};
    this.activities.forEach(activity => {
      const { createdBy, createdAt, before, after, uid, entityId } = activity;
      // @ts-ignore
      const tid = activity.after?.tid || activity.before?.tid || null;
      const date = format(new Date(createdAt), 'yyyy-MM-dd');
      // @ts-ignore
      const beforeStatus = before?.status || null;
      // @ts-ignore
      const afterStatus = after?.status || null;
      const entity = activity.entity;
      if (entity === 'widget') {
        if (!this.userDateFromStatusCountsMap[createdBy]) {
          this.userDateFromStatusCountsMap[createdBy] = {};
        }
        if (!this.userDateFromStatusCountsMap[createdBy][date]) {
          this.userDateFromStatusCountsMap[createdBy][date] = {
            Backlog: 0,
            Design: 0,
            Review: 0,
            Correction: 0,
            Develop: 0,
            Test: 0,
            Failed: 0,
            Approve: 0,
            Total: 0
          };
        }
        if (!this.userDateToStatusCountsMap[createdBy]) {
          this.userDateToStatusCountsMap[createdBy] = {};
        }
        if (!this.userDateToStatusCountsMap[createdBy][date]) {
          this.userDateToStatusCountsMap[createdBy][date] = {
            Backlog: 0,
            Design: 0,
            Review: 0,
            Correction: 0,
            Develop: 0,
            Test: 0,
            Failed: 0,
            Approve: 0,
            Total: 0
          };
        }
        if (!this.userDateCurrentStatusCountsMap[createdBy]) {
          this.userDateCurrentStatusCountsMap[createdBy] = {};
        }
        if (!this.userDateCurrentStatusCountsMap[createdBy][date]) {
          this.userDateCurrentStatusCountsMap[createdBy][date] = {
            Backlog: 0,
            Design: 0,
            Review: 0,
            Correction: 0,
            Develop: 0,
            Test: 0,
            Failed: 0,
            Approve: 0,
            Total: 0
          };
        }
        if (beforeStatus) {
          // @ts-ignore
          this.userDateFromStatusCountsMap[createdBy][date][beforeStatus] += 1;
          this.userDateFromStatusCountsMap[createdBy][date].Total += 1;
        }
        if (afterStatus) {
          // @ts-ignore
          this.userDateToStatusCountsMap[createdBy][date][afterStatus] += 1;
          this.userDateToStatusCountsMap[createdBy][date].Total += 1;
        }
        const currentStatus = this.cc.widgetStatusMap[entityId];
        if (currentStatus) {
          // @ts-ignore
          this.userDateCurrentStatusCountsMap[createdBy][date][currentStatus] += 1;
          this.userDateCurrentStatusCountsMap[createdBy][date].Total += 1;
        }
      } else if (entity === 'task') {
        if (!this.userDateTaskFromStatusCountsMap[createdBy]) {
          this.userDateTaskFromStatusCountsMap[createdBy] = {};
        }
        if (!this.userDateTaskFromStatusCountsMap[createdBy][date]) {
          this.userDateTaskFromStatusCountsMap[createdBy][date] = {
            Backlog: 0,
            Design: 0,
            Review: 0,
            Correction: 0,
            Develop: 0,
            Test: 0,
            Failed: 0,
            Mergeable: 0,
            Passed: 0,
            Approved: 0,
            Total: 0
          };
        }
        if (!this.userDateTaskToStatusCountsMap[createdBy]) {
          this.userDateTaskToStatusCountsMap[createdBy] = {};
        }
        if (!this.userDateTaskToStatusCountsMap[createdBy][date]) {
          this.userDateTaskToStatusCountsMap[createdBy][date] = {
            Backlog: 0,
            Design: 0,
            Review: 0,
            Correction: 0,
            Develop: 0,
            Test: 0,
            Failed: 0,
            Mergeable: 0,
            Passed: 0,
            Approved: 0,
            Total: 0
          };
        }
        if (!this.userDateTaskCurrentStatusCountsMap[createdBy]) {
          this.userDateTaskCurrentStatusCountsMap[createdBy] = {};
        }
        if (!this.userDateTaskCurrentStatusCountsMap[createdBy][date]) {
          this.userDateTaskCurrentStatusCountsMap[createdBy][date] = {
            Backlog: 0,
            Design: 0,
            Review: 0,
            Correction: 0,
            Develop: 0,
            Test: 0,
            Failed: 0,
            Mergeable: 0,
            Passed: 0,
            Approved: 0,
            Total: 0
          };
        }
        if (beforeStatus) {
          // @ts-ignore
          this.userDateTaskFromStatusCountsMap[createdBy][date][beforeStatus] += 1;
          this.userDateTaskFromStatusCountsMap[createdBy][date].Total += 1;
        }
        if (afterStatus) {
          // @ts-ignore
          this.userDateTaskToStatusCountsMap[createdBy][date][afterStatus] += 1;
          this.userDateTaskToStatusCountsMap[createdBy][date].Total += 1;
        }
        const currentStatus = this.cc.tidTaskMap[tid]?.status;
        if (currentStatus) {
          // @ts-ignore
          this.userDateTaskCurrentStatusCountsMap[createdBy][date][currentStatus] += 1;
          this.userDateTaskCurrentStatusCountsMap[createdBy][date].Total += 1;
        }
      }
    });
  }

  showUserTaskHours(uid: string) {
    const hoursDialog = this.dialog.open(UserTaskHoursComponent, {
      width: '700px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: { uid },
      autoFocus: false
    });

    return hoursDialog.afterClosed();
  }

  getPlanWidget(displayName: string): JsWidget | null {
    let planWidget: JsWidget | null = null;
    if (displayName === '') {
      planWidget = this.planWidgets.find(widget => widget.path.endsWith('Backlog')) || null;
    } else if (displayName === 'UNASSIGNED') {
      // For non valid users not active in project app
      planWidget = null;
    } else {
      planWidget = this.planWidgets.find(widget => widget.path.endsWith(displayName)) || null;
    }
    return planWidget || null;
  }

  openPlanWidget(planWidget: JsWidget | null, e: any) {
    if (!planWidget) return;
    this._dialog.openDialog(WidgetDetailEditComponent, {
      panelClass: 'widget-detail-edit-bottom-sheet',
      disableClose: true,
      data: {
        widgetId: planWidget.id,
        hiddenFields: ['path', 'status', 'subStatus', 'assignedTo', 'tags', 'regressions', 'viewId', 'viewBlock', 'taskBlock', 'fingerPrints']
      }
    });
    e.stopPropagation();
  }

  showUpdatedTasks(uid: string, date: string) {
    const tids: Set<number> = new Set();
    const tasks: JsTask[] = [];
    this.activities.forEach(activity => {
      if (activity.createdBy === uid && activity.entity === 'task') {
        // @ts-ignore
        // const task = this.cc.tidTaskMap[activity.after?.tid || 0];
        console.log(activity.updatedAt.toDateString(), new Date(date).toDateString());
        // @ts-ignore
        if (activity.updatedAt.toDateString() === new Date(date).toDateString() && activity.after?.tid) {
          // @ts-ignore
          console.log('YES', activity.after?.tid);
          // @ts-ignore
          tids.add(activity.after?.tid);
        }
      }
    });
    tids.forEach(tid => {
      tasks.push(this.cc.tidTaskMap[tid]);
    });
    this._dialog.openDialog(TaskListModalComponent, {
      data: {
        title: `${this.fbService.getDisplayName(uid)}'s updated features - ${date}`,
        tasks
      }
    });
  }

  showUpdatedWidgets(uid: string, date: string) {
    const wids: Set<string> = new Set();
    const widgets: JsWidget[] = [];
    this.activities.forEach(activity => {
      if (activity.createdBy === uid && activity.entity === 'widget') {
        // @ts-ignore
        if (activity.updatedAt.toDateString() === new Date(date).toDateString() && activity.entityId) {
          // @ts-ignore
          wids.add(activity.entityId);
        }
      }
    });
    this.cc.allActiveWidgets.forEach(widget => {
      if (wids.has(widget.id)) {
        widgets.push(widget);
      }
    });
    this._dialog.openDialog(SpecListModalComponent, {
      data: {
        title: `${this.fbService.getDisplayName(uid)}'s updated widgets - ${date}`,
        widgets
      }
    });
  }

  showAssignedTasks(uid: string) {
    this._dialog.openDialog(TaskListModalComponent, {
      data: {
        title: `${this.fbService.getDisplayName(uid)}'s assigned features`,
        tasks: this.assignedTasks[uid] || []
      }
    });
  }
}
