import { ValidatorFn, AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';

export function duplicateValidator(controlName: string): ValidatorFn {
    return (formArray: AbstractControl): { [key: string]: any } | null => {
        const controls = (formArray as FormArray).controls;

        const controlValues = controls.map(control => control.get(controlName)?.value).filter(value => value !== null && value !== undefined && value !== '');

        const hasDuplicates = controlValues.some((value, index) => controlValues.indexOf(value) !== index);

        return hasDuplicates ? { duplicate: true } : null;
    };
}

export function urlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null; // Don't validate if the control is empty
        }

        const urlPattern = /^https:\/\/[^\s/$.?#].[^\s]*$/i;
        const isValid = urlPattern.test(control.value);

        return isValid ? null : { invalidUrl: true };
    };
}

export function releaseParameterValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        let controlValue = _.cloneDeep(control.value);
        if (typeof (controlValue) === 'string') {
            try {
                controlValue = JSON.parse(controlValue);
            } catch (error) {
                return { invalidParameter: true };
            }
        }

        if(Array.isArray(controlValue) || controlValue === 0 || controlValue === null) {
            return { invalidParameter: true };
        }
        // Return null if control is empty
        if (typeof controlValue === 'object' && Object.keys(controlValue).length <= 0) {
            return null;
        }

        const parameterKeys = Object.keys(controlValue);
        const hasError = parameterKeys.some(key => checkInvalidParameter(key, controlValue[key]));
        return hasError ? { invalidParameter: true } : null;
    };
}

export function releaseFeatureFlagValidator(segments: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        let controlValue = _.cloneDeep(control.value);
        if (typeof (controlValue) === 'string') {
            try {
                controlValue = JSON.parse(controlValue);
            } catch (error) {
                return { invalidFeatureFlag: true };
            }
        }

        if(Array.isArray(controlValue) || controlValue === 0 || controlValue === null) {
            return { invalidParameter: true };
        }
        // Return null if control is empty
        if (typeof controlValue === 'object' && Object.keys(controlValue).length <= 0) {
            return null;
        }

        const flagKeys = Object.keys(controlValue);
        const hasError = flagKeys.some(key => checkInvalidFlags(segments, controlValue[key]));
        return hasError ? { invalidFeatureFlag: true } : null;
    };
}

export function checkInvalidFlags(segments: string[], value: any): boolean {
    return !value || !Array.isArray(value) || !value.every((val: any) => typeof val === 'string' && segments.includes(val))
}

export function checkInvalidParameter(key: string, value: any): boolean {
    const validSuffixes = ['_n', '_b', '_s', '_nl', '_bl', '_sl'];

    const hasError = !validSuffixes.some(suffix => key.endsWith(suffix));
    if (hasError) {
        return true;
    }

    if (key.endsWith('_s') && typeof value !== 'string') {
        return true;
    }
    if (key.endsWith('_n') && typeof value !== 'number') {
        return true;
    }
    if (key.endsWith('_b') && typeof value !== 'boolean') {
        return true;
    }
    if (key.endsWith('_sl') && (!Array.isArray(value) || !value.every(v => typeof v === 'string'))) {
        return true;
    }
    if (key.endsWith('_nl') && (!Array.isArray(value) || !value.every(v => typeof v === 'number'))) {
        return true;
    }
    if (key.endsWith('_bl') && (!Array.isArray(value) || !value.every(v => typeof v === 'boolean'))) {
        return true;
    }
    return false; // No invalid cases found
}

export function floatWith3DecimalsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = /^\d+(\.\d{1,3})?$/.test(control.value);
      return valid ? null : { 'invalidFloat': { value: control.value } };
    };
  }