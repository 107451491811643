import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, EventEmitter, Input, model, Output, signal, SimpleChange, WritableSignal } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { JsTodo } from 'src/app/_interfaces/Todo';
import { CacheService } from 'src/app/_services/cache.service';

export type status = 'todo' | 'inProgress' | 'blocked' | 'needClarity' | 'done' | 'approved';

@Component({
  selector: 'app-todo-status-count-of-todo',
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
  templateUrl: './todo-status-count-of-todo.component.html',
  styleUrl: './todo-status-count-of-todo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TodoStatusCountOfTodoComponent {

  @Output() onUpdate = new EventEmitter();
  @Input() isShowPercentage: WritableSignal<boolean> = signal(false);
  todos = model<JsTodo[]>();
  todo: number = 0;
  inProgress: number = 0;
  blocked: number = 0;
  needClarity: number = 0;
  done: number = 0;
  approved: number = 0;
  total: number = 0;
  isEmpty: boolean = true;

  progress = {
    todo: 0,
    inProgress: 0,
    blocked: 0,
    needClarity: 0,
    done: 0,
    approved: 0,
    total: 0
  };

  progressMax: number = 0;
  statusEnum = {
    todo: 'Todo',
    inProgress: 'In Progress',
    blocked: 'Blocked',
    needClarity: 'Need Clarity',
    done: 'Done',
    approved: 'Approved',
    total: 'Total'
  }

  constructor(public cc: CacheService) {
   
  }

  updateCount() {
    const todos = this.todos();
    this.isEmpty = true;
    this.todo = 0;
    this.inProgress = 0;
    this.blocked = 0;
    this.needClarity = 0;
    this.done = 0;
    this.approved = 0;
    this.total = 0;
    this.progress = {
      todo: 0,
      inProgress: 0,
      blocked: 0,
      needClarity: 0,
      done: 0,
      approved: 0,
      total: 0
    };
    if (todos?.length) {
      todos.forEach(task => {
        switch (task.status) {
          case 'Todo':
            this.todo++;
            this.progress.todo = this.progress.todo + 0;
            break;
          case 'In Progress':
            this.inProgress++;
            this.progress.inProgress = this.progress.inProgress + 1;
            break;
          case 'Blocked':
            this.blocked++;
            this.progress.blocked = this.progress.blocked + 2;
            break;
          case 'Need Clarity':
            this.needClarity++;
            this.progress.needClarity = this.progress.needClarity + 3;
            break;
          case 'Done':
            this.done++;
            this.progress.done = this.progress.done + 4;
            break;
          case 'Approved':
            this.approved++;
            this.progress.approved = this.progress.approved + 3;
            break;
          default:
            break;
        }
      });
      this.total = this.todo + this.inProgress + this.blocked + this.needClarity + this.done + this.approved;
      this.progress.total =
        this.progress.todo +
        this.progress.inProgress +
        this.progress.blocked +
        this.progress.needClarity +
        this.progress.done +
        this.progress.approved
      this.progressMax = this.total * 6;
      this.isEmpty = false;
    }
    this.onUpdate.emit();
  }

  ngOnChanges(change: SimpleChange) {
    // @ts-ignore
    if (change['todos']) {
      this.updateCount();
    }
  }

  getPercentage(status: status | 'total'): string {
    const val = this[status];
    const total = this.total;
    if (!total || !val) return `${this.statusEnum[status]} 0%`;
    return `${this.statusEnum[status]} ${((val / total) * 100).toFixed(0)}%`;
  }

  getTotalPercentageProgress(): string {
    const val = this.progress.total;
    const total = this.progressMax;
    if (!total || !val) return `Total 0%`;
    return `Total Progress ${((val / total) * 100).toFixed(0)}%`;
  }

  getPercentageProgressOnly(status: status | 'total'): string {
    const val = this.progress[status];
    const total = this.progressMax;
    if (!total || !val) return `0%`;
    return `${((val / total) * 100).toFixed(0)}%`;
  }
}
