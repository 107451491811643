<main class="json-view px-3 pb-3 ri-w-1000">
    <div class="d-flex justify-content-between mb-3 sticky-top bg-white pt-3">
        <h5 class="fw-semibold mb-0">{{ title }}</h5>
        <button *ngIf="type === 'JSONDIFFLI' || (type === 'JSONDIFF' && diffList && diffList.length !== 0)" class="btn btn-sm" [ngClass]="type === 'JSONDIFF' ? 'btn-info' : 'text-bg-review'" (click)="type = (type === 'JSONDIFFLI' ? 'JSONDIFF' : 'JSONDIFFLI')">View {{ type === 'JSONDIFF' ? 'Table' : 'JSON' }}</button>
    </div>
    
    <div class="ri-dialog-content">
        <ng-container *ngIf="type === 'JSONDIFFLI'">
            <table class="table diff-list">
                <thead class="sticky-top">
                    <tr>
                        <td class="fw-bold title">Title</td>
                        <td *ngIf="!noOldJson" class="fw-bold before">Before</td>
                        <td class="fw-bold after">After</td>
                    </tr>
                </thead>
                <tbody class="ri-min-h-300">
                    <tr class="ri-pointer" *ngFor="let data of diffList">
                        <td valign="middle">{{ data.title }}</td>
                        <td valign="middle" *ngIf="!noOldJson">{{ data.before !== null && data.before !== undefined && data.before !== '' ? data.before : '-' }}</td>
                        <td valign="middle">{{ data.after !== null && data.after !== undefined && data.after !== '' ? data.after : '-' }}</td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <div class="text-danger text-bg-warning text-center" *ngIf="data.errors?.length">
            <div *ngFor="let error of data.errors" class="p-1">{{error}}</div>
          </div>
        <ng-container *ngIf="type === 'JSONDIFF'">
            <div [innerHtml]="outputHtml"></div>
        </ng-container>
        <mat-form-field appearance="fill" class="w-100 mt-3" [class]="{'edited': publishNote.value }" *ngIf="data.mode === 'add'">
            <mat-label>Note (Why this publish?)</mat-label>
            <textarea matInput [formControl]="publishNote" cdkTextareaAutosize placeholder="Add Note" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="9"></textarea>
        </mat-form-field>
        <pre class="pre-json" *ngIf="type === 'JSON'">{{ data.newJson | json }}</pre>
    </div>
    
    <div class="ri-dialog-actions pt-3 d-flex justify-content-between">
        <div class="">
            <button *ngIf="data.mode === 'add'" [disabled]="(data.strict && data.errors?.length)" class="btn btn-review btn-sm ms-1" (click)="download()">
                Download {{data.entity | titlecase}}
            </button>
        </div>
        <div class="">
            <button class="btn btn-secondary btn-sm me-2" *ngIf="data.mode === 'add'" (click)="cancel()">Cancel</button>
            <button class="btn btn-primary btn-sm px-5" (click)="data.mode === 'add' ? publish() : dialogRef.close()" [disabled]="data.mode === 'add' && (!publishNote.value || (data.strict && data.errors?.length))">{{ data.mode === 'add' ? 'Publish ' + (data.entity | titlecase) : 'Close' }}</button>
        </div>
    </div>
</main>