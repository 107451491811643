<div class="ri-w-600 p-3">
  <h1>
    {{title}}
  </h1>

  <div>
    <p>{{message}}</p>
  </div>

  <div class="d-flex justify-content-end pt-3">
    <button class="btn btn-secondary btn-sm px-5" (click)="onDismiss()" *ngIf="showNoButton">{{noButtonText}}</button>
    <button class="btn btn-primary btn-sm ms-1 px-5" (click)="onConfirm()">{{yesButtonText}}</button>
  </div>
</div>