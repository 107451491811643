import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, Optional } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { interval, Subject, takeUntil } from 'rxjs';
import { SelectComponent } from 'src/app/shared/components/select/select.component';
import { entity, FbActivity, JsActivity } from 'src/app/_interfaces/Activity';
import { FirestoreQueryCondition, UserSessionStorage } from 'src/app/_interfaces/Other';
import { ActivityService } from 'src/app/_services/activity.service';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { DateFnsModule } from 'ngx-date-fns';
import { sortObjectKeys } from 'src/app/shared/utils';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserSessionStorageService } from 'src/app/_services/user-session-storage.service';
import { JsonViewComponent } from '../shared/components/json-view/json-view.component';
import _ from 'lodash';
import { CacheService } from '../_services/cache.service';
import { EntityService } from '../_services/entity.service';
import { JsEntity } from '../_interfaces/Entities';
import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/firestore';

@Component({
  selector: 'app-activity',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateFnsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    MatIcon,
    SelectComponent,
  ],
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ActivityComponent implements OnDestroy {

  initialColumns: string[] = ['name', 'action', 'entity', 'createdAt', 'uid', 'JSON diff'];
  displayedColumns: string[] = [...this.initialColumns, 'Changes'];
  public filterOptions = {
    columns: {
      title: 'Columns',
      options: ['hasStatusChanged'],
      formControl: new FormControl([]),
      defaultValue: [],
    },
    entity: {
      title: 'Entity',
      options: [
        {
          label: 'Release',
          value: 'release',
        },
        {
          label: 'Feature',
          value: 'task',
        },
        {
          label: 'Spec',
          value: 'widget',
        },
        {
          label: 'Todo',
          value: 'todo',
        },
        {
          label: 'View',
          value: 'view',
        },
        {
          label: 'Translation',
          value: 'translation',
        },
        {
          label: 'Event',
          value: 'event',
        },
        {
          label: 'Log',
          value: 'log',
        },
        {
          label: 'Param',
          value: 'param',
        },
        {
          label: 'Comment',
          value: 'comment',
        },
        {
          label: 'Daily Plans',
          value: 'daily',
        },
        {
          label: 'Leave',
          value: 'leave',
        },
      ],
      formControl: new FormControl('All'),
      defaultValue: 'All',
    },
    uid: {
      title: 'Activity By',
      formControl: new FormControl('All'),
      defaultValue: 'All',
    },
  };
  filterValue: string = '';
  activityList: any[] = [];
  unSubscribe = new Subject<void>();
  entityId: FormControl = new FormControl(null);

  public mapName: any = {
    task: 'Title',
    view: 'View ID',
    widget: 'Path',
    translation: 'Translation ID',
    release: 'Release Version',
    event: 'Event Name',
    param: 'Param Group Name',
    daily: 'Plan for',
    comment: 'Name',
    leave: 'Staff'
  };
  isDialog: boolean = false;
  dialogTitle: string = '';
  sortKey: string = '';
  entityType: entity = 'task';
  lastDocument?: QueryDocumentSnapshot<FbActivity, DocumentData> | null = null;
  allDataLoaded: boolean = false;

  constructor(
    public fbs: FirebaseService,
    public as: ActivityService,
    private dialog: MatDialog,
    private changeRef: ChangeDetectorRef,
    private uss: UserSessionStorageService,
    public es: EntityService,
    private cc: CacheService,
    @Optional() public dialogRef: MatDialogRef<ActivityComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: { entityType: entity, entity: any },
  ) {
    if (this.data) {
      this.filterOptions.entity.formControl.setValue(this.data.entityType);
      this.entityId.setValue(this.data.entity.id);
      this.getActivities();
      this.isDialog = true;
      this.dialogTitle = this.data.entity === 'comment' ? 'Comment' : this.getActivityName(this.data.entityType, this.data.entity, (this.data.entityType === 'task' || this.data.entityType === 'event' || this.data.entityType === 'daily') ? 'id' : 'name')
    }
  }

  ngOnInit() {
    this.uss.currentUserSessionStorage$.pipe(takeUntil(this.unSubscribe)).subscribe(snapshot => {
      const snapshotKey = this.data && this.data.entityType ? `${this.data.entityType}ActivityTableColumns` as keyof UserSessionStorage : 'activityTableColumns'
      this.filterOptions.columns.formControl.setValue(snapshot[snapshotKey] ? snapshot[snapshotKey] : [] as any);
      this.displayedColumns = [...this.initialColumns, ...snapshot[snapshotKey] ? snapshot[snapshotKey] as string[] : [], 'Changes'];
    });
    interval(1000).pipe(takeUntil(this.unSubscribe)).subscribe(() => {
      this.changeRef.markForCheck(); // Mark the component for check
    });
  }

  colomChange() {
    if (this.filterOptions.columns.formControl.value) {
      this.uss.setUserSessionStorageItem(this.data && this.data.entityType ? `${this.data.entityType}ActivityTableColumns` as keyof UserSessionStorage : 'activityTableColumns', this.filterOptions.columns.formControl.value);
    }
  }

  updateChanges() {
    this.changeRef.detectChanges();
  }

  async getActivities(isLoadMore: boolean = false) {
    if (this.allDataLoaded) {
      return;
    }
    this.entityType = this.filterOptions.entity.formControl.value as entity;
    if(!isLoadMore) {
      this.lastDocument = null;
    }
    if (this.entityType === 'comment') {
      if(!this.displayedColumns.includes('Comments Entity')) {
        this.displayedColumns.unshift('Comments Entity');
      }
    } else {
      let index = this.displayedColumns.findIndex(x => x === 'Comments Entity');
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
    const filters: FirestoreQueryCondition[] = [];
    if (this.filterOptions.uid.formControl.value && this.filterOptions.uid.formControl.value !== 'All') {
      filters.push({
        field: 'uid',
        operator: '==',
        value: this.filterOptions.uid.formControl.value,
      });
    }

    if (this.filterOptions.entity.formControl.value && this.filterOptions.entity.formControl.value !== 'All') {
      filters.push({
        field: 'entity',
        operator: '==',
        value: this.filterOptions.entity.formControl.value,
      });
    } else if(!this.fbs.getCurrentUser()?.roles.includes('AD')) {
      filters.push({
        field: 'entity',
        operator: 'in',
        value: ['release', 'task', 'widget', 'todo', 'view', 'translation', 'event', 'log', 'param', 'daily', 'comment'],
      });
    }

    if (this.entityId.value) {
      filters.push({
        field: 'entityId',
        operator: '==',
        value: this.entityId.value,
      });
    }

    this.fbs.getActivitiesData(50, filters, this.lastDocument).pipe(takeUntil(this.unSubscribe)).subscribe(({ data: activities, lastDocument }) => {
      if (activities.length > 0) {
        this.lastDocument = lastDocument as unknown as QueryDocumentSnapshot<FbActivity, DocumentData>;
        const sortedActivities = _.sortBy(activities, 'cloudUpdatedAt').reverse();
   
        if (isLoadMore) {
          this.activityList = [...this.activityList, ...sortedActivities.map((a: JsActivity) => {
            a.diff = this.as.getActivityDiff(a);
            return a;
          })];
        } else {
          this.activityList = sortedActivities.map((a: JsActivity) => {
            a.diff = this.as.getActivityDiff(a);
            return a;
          });
        }
      } else {
        this.allDataLoaded = true;
      }
      this.updateChanges();
    });
  }

  viewDetails(event: MouseEvent, entityType: entity, entity: JsEntity | any) {
    event.stopPropagation();
    this.es.viewDetails(entityType, entity);
  }

  getActivityName(entityType: entity, entity: any, type: 'name' | 'id' | 'suffix') {
    if (!entity) {
      return
    }

    switch (entityType) {
      case 'task':
        return type == 'name' ? entity.title || '' : `F-${entity.tid || ''}`;
      case 'view':
        return entity.viewId || '';
      case 'widget':
        return entity.path || '';
      case 'translation':
        return entity.translationId || '';
      case 'release':
        return `${entity.rid}.${entity.dbVersion}`;
      case 'event':
        return type == 'name' ? entity.eventName || '' : `${entity.eid || ''}`;
      case 'log':
        return type == 'name' ? entity.logName || '' : `${entity.lid || ''}`;
      case 'todo':
        return entity.title || '';
      case 'param':
        return entity.name || '';
      case 'daily':
        return type == 'name' ? '' || '' : `${entity.planFor === 'Todo' ? 'T' : 'F'}-${entity.tid || ''}`;
      case 'comment':
        return type == 'name' ? '' || '' : type == 'suffix' ? `${entity.entity}` : `F-${entity.tid || ''}`;
      case 'leave':
        return this.fbs.getUserNameFromId(entity.uid) || ''
      default:
        return '';
    }
  }

  getEntityName(entityType: entity, entityId: string | number, entity?: any): string {
    if (!entityType || !entityId) {
      return ''
    }
    switch (entityType) {
      case 'task':
        return `<span class="text-primary pe-1">F-${this.cc.idToTaskMap[entityId]?.tid}</span>${this.cc.idToTaskMap[entityId]?.title}`;
      case 'view':
        return this.cc.idToViewMap[entityId]?.viewId || '';
      case 'widget':
        return entity?.after?.deletedAt ? this.cc.idToDeletedSpecsMap[entityId]?.path : this.cc.idToSpecMap[entityId]?.path || '';
      case 'translation':
        return this.cc.idToTranslationMap[entityId]?.translationId || '';
      case 'release':
        return `${this.cc.idToReleaseMap[entityId]?.rid}.${this.cc.idToReleaseMap[entityId]?.dbVersion}`;
      case 'event':
        return `<span class="text-primary pe-1">${this.cc.idToEventMap[entityId]?.eid}</span>${this.cc.idToEventMap[entityId]?.eventName}`;
      case 'log':
        return `<span class="text-primary pe-1">${this.cc.idToLogMap[entityId]?.lid}</span>${this.cc.idToLogMap[entityId]?.logName}`;
      case 'todo':
        return this.cc.idToTodoMap[entityId]?.title || '';
      case 'param':
        return this.cc.idToParamMap[entityId]?.name || '';
      case 'daily':
        return `<span class="text-primary pe-1">${this.cc.idToDailyMap[entityId]?.tid}</span>${this.cc.tidTaskMap[this.cc.idToDailyMap[entityId]?.tid]?.title}`;
      case 'comment':
        return `${this.getEntityName(entity?.after?.entity, entity?.after?.entityId)}`;
      case 'leave':
        return this.fbs.getUserNameFromId(entity.uid) || ''
      default:
        return '';
    }
  }

  viewJsonDiff(activity: JsActivity, type: 'JSONDIFFLI' | 'JSONDIFF', entityType: entity, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    const jsonDiffDialog = this.dialog.open(JsonViewComponent, {
      autoFocus: false,
      data: {
        entity: 'activity',
        mode: 'view',
        // title: `Diff for ${ activity.entity ==='comment' ? 'Comment' : this.getActivityName(activity.entity, activity.after, (activity.entity === 'task' || activity.entity === 'daily') ? 'id' : 'name')}`,
        title: `Diff for ${entityType === 'task' ? 'Feature' : entityType}`,
        type: type,
        oldJson: activity.before || {},
        newJson: activity.after || {},
        diffList: activity.diff
      },
    });
  }

  getValue(val: any): string | undefined {
    // return as formatted json if its an object or array. Otherwise return as is
    if (typeof val === 'object') {
      return JSON.stringify(sortObjectKeys(val), null, 2);
    } else {
      return val;
    }
  }

  sortData(element: HTMLTableCellElement, sortKey: string) {
    const sortOrder = element.getAttribute('sortOrder');
    this.activityList.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) => {
      let valueA = a[sortKey];
      let valueB = b[sortKey];

      if (sortKey === 'Changes') {
        valueA = a['diff'].length;
        valueB = b['diff'].length;
      } else if (sortKey === 'name' && this.entityType !== 'comment') {
        const entity = this.filterOptions.entity.formControl.value;
        valueA = a['after'][(entity === 'task' || entity === 'daily') ? 'tid' : entity === 'view' ? 'viewId' : entity === 'widget' ? 'path' : entity === 'todo' ? 'title' : entity === 'translation' ? 'translationId' : entity === 'event' ? 'eventName' : entity === 'log' ? 'logName' : 'name'];
        valueB = b['after'][(entity === 'task' || entity === 'daily') ? 'tid' : entity === 'view' ? 'viewId' : entity === 'widget' ? 'path' : entity === 'todo' ? 'title' : entity === 'translation' ? 'translationId' : entity === 'event' ? 'eventName' : entity === 'log' ? 'logName' : 'name'];
      } else if ((sortKey === 'Comments Entity' || sortKey === 'name') && this.entityType === 'comment') {
        valueA = a['after']['entity'];
        valueB = b['after']['entity'];
      }

      // Handle string comparison
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      // Handle sorting order
      if (sortOrder === 'asc') {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      } else if (sortOrder === 'desc') {
        return 0
      }
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    });
    element.setAttribute('sortOrder', sortOrder === 'asc' ? 'desc' : sortOrder == 'desc' ? 'none' : 'asc');
    this.sortKey = sortKey;
  }

  resetFilter() {
    this.filterOptions.columns.formControl.setValue(this.filterOptions.columns.defaultValue);
    this.filterOptions.uid.formControl.setValue(this.filterOptions.uid.defaultValue);
    if (!this.isDialog) {
      this.filterOptions.entity.formControl.setValue(this.filterOptions.entity.defaultValue);
    }
    this.uss.setUserSessionStorageItem('activityTableColumns', [], false);
    this.displayedColumns = [...this.initialColumns];
    this.activityList = [];
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
