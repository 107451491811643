import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Effort } from '../_interfaces/Other';
import { format } from 'date-fns';
import { JsActivity } from '../_interfaces/Activity';
import { FirebaseService } from '../_services/firebase.service';
import { FirebaseOptimisticService } from '../_services/firebase-optimistic.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CacheService } from '../_services/cache.service';
import { JsWidget, status, type } from '../_interfaces/Widget';

@Component({
  selector: 'app-effort-tasks',
  templateUrl: './effort-tasks.component.html',
  styleUrls: ['./effort-tasks.component.scss'],
})
export class EffortTasksComponent implements OnInit, OnChanges {
  @Input() statusChange: Effort | null = null;
  @Input() who: string | null = null; // Name string
  @Input() when: string | null = null; // Date string
  @Output() onClose = new EventEmitter<boolean>();
  faTimes = faTimes;
  groupByOptions = [
    { label: 'From Status', value: 'before' },
    { label: 'To Status', value: 'after' },
  ];
  selectedGroupBy: 'before' | 'after' = 'after';
  statusList = [
    'Backlog',
    'Design',
    'Review',
    'Develop',
    'Test',
    'Failed',
    'Approve',
  ];
  selectedWidgetId: string | null = null;
  selectedWidget: JsWidget | null = null;
  totalCount = 0;

  public mapClass = {
    Backlog: 'bg-info',
    Design: 'bg-secondary',
    Review: 'bg-review',
    Develop: 'bg-primary',
    Test: 'bg-warning',
    Failed: 'bg-danger',
    Approve: 'bg-success',
    null: 'bg-dark',
  };

  public typeClass = {
    bug: 'text-warning',
    state: 'text-danger',
    action: 'text-primary',
    rule: 'text-info',
    translate: 'text-success',
    track: 'text-secondary',
    other: 'text-dark',
  };

  constructor(
    public fbs: FirebaseService,
    public fbo: FirebaseOptimisticService,
    public cc: CacheService
  ) {}

  ngOnInit(): void {
    this.initialise();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['statusChange'].currentValue !== changes['statusChange'].previousValue) {
    //   console.log(changes.statusChange.currentValue);
    // }
  }

  initialise(): void {
    console.log(this.statusChange);
    this.updateTotalCount();
  }

  setSelectedGroupBy($e: any): void {
    this.selectedGroupBy = $e.value;
    this.updateTotalCount();
  }

  getHumanReadableDate(date: string): string {
    // using datefns to check whether ist valid date or not
    if (
      !date ||
      date.split('-').length !== 3 ||
      isNaN(new Date(date).getTime())
    ) {
      return '';
    }
    // using datefns format function
    return format(new Date(date), 'eee, MMM d, yyyy');
  }

  trackByMethod(index: number, item: any): number {
    return item.id;
  }

  async onSelect(activity: JsActivity) {
    console.log(activity);
    if (this.selectedWidgetId === activity.entityId) {
      this.selectedWidgetId = null;
      this.selectedWidget = null;
    } else {
      this.selectedWidgetId = activity.entityId;
      let selectedWidget = await this.cc.getWidgetById(activity.entityId);
      if (!selectedWidget) {
        selectedWidget = await this.cc.getDeletedWidgetById(activity.entityId);
      }
      this.selectedWidget = selectedWidget;
    }
  }

  getTypeClass(activity: JsActivity): string {
    //@ts-ignore
    const type = activity?.before?.type || activity?.after?.type || 'other';
    //@ts-ignore
    return this.typeClass[type];
  }

  getStatusClass(
    activity: JsActivity,
    type: 'before' | 'after' | 'current'
  ): string {
    let status = null;
    if (type === 'current') {
      status = this.cc.getWidgetStatus(activity.entityId);
    } else {
      //@ts-ignore
      status = activity?.[type]?.status;
    }
    if (!status) {
      return 'bg-black';
    } else {
      //@ts-ignore
      return this.mapClass[status] || 'bg-black';
    }
  }

  close() {
    this.selectedWidgetId = null;
    this.onClose.emit(true);
  }

  getCount(pathArrays: { [key: string]: JsActivity[] }): number {
    let count = 0;
    for (const key in pathArrays) {
      if (Object.prototype.hasOwnProperty.call(pathArrays, key)) {
        const element = pathArrays[key];
        count += element.length;
      }
    }
    return count;
  }

  updateTotalCount() {
    let count = 0;
    for (const status in this.statusChange?.[this.selectedGroupBy]) {
      const localCount = this.getCount(
        //@ts-ignore
        this.statusChange?.[this.selectedGroupBy][status]
      );
      count += localCount;
    }
    this.totalCount = count;
  }
}
