import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { CacheService } from '../_services/cache.service';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { ListSpecsOfViewComponent } from '../list-specs-of-view/list-specs-of-view.component';
import { ViewBlocksOfTaskComponent } from '../view-blocks-of-task/view-blocks-of-task.component';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-spec-status-counts-of-view',
  templateUrl: './spec-status-counts-of-view.component.html',
  styleUrls: ['./spec-status-counts-of-view.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltip
  ]
})

export class SpecStatusCountsOfViewComponent implements OnChanges {
  @Input() viewId: string = '';
  Backlog: number = 0;
  Design: number = 0;
  Review: number = 0;
  Develop: number = 0;
  Test: number = 0;
  Failed: number = 0;
  Approve: number = 0;
  Total: number = 0;
  isEmpty: boolean = true;
  constructor(
    private cc: CacheService,
    private _dialog: DialogManagerService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(change: SimpleChanges) {
    // @ts-ignore
    if (change['viewId']) {
      // @ts-ignore
      this.update();
    }
  }

  update() {
    this.isEmpty = true;
    this.Backlog = 0;
    this.Design = 0;
    this.Review = 0;
    this.Develop = 0;
    this.Test = 0;
    this.Failed = 0;
    this.Approve = 0;
    if (this.viewId) {
      const specs = this.cc.allActiveWidgets.filter(
        (widget) => widget.viewId === this.viewId
      );
      if (specs.length) {
        specs.forEach((spec) => {
          switch (spec.status) {
            case 'Backlog':
              this.Backlog++;
              break;
            case 'Design':
              this.Design++;
              break;
            case 'Review':
              this.Review++;
              break;
            case 'Develop':
              this.Develop++;
              break;
            case 'Test':
              this.Test++;
              break;
            case 'Failed':
              this.Failed++;
              break;
            case 'Approve':
              this.Approve++;
              break;
          }
        });
        this.isEmpty = false;
        this.Total =
          this.Backlog +
          this.Design +
          this.Review +
          this.Develop +
          this.Test +
          this.Failed +
          this.Approve;
      }
    }
  }

  showSpecList(viewId: string, e: any) {
    this._dialog.openDialog(ListSpecsOfViewComponent, {
      data: {
        viewId,
      },
    });
    e.stopPropagation();
  }
}
