import { Component, EventEmitter, OnDestroy, OnInit, ViewChild, effect } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faPencil, faPlus, faTrash, faClock, faCircleCheck, faXmark, faBell, faFilter } from '@fortawesome/free-solid-svg-icons';
import { faCopy, faCircleCheck as faCircleCheckEmpty } from '@fortawesome/free-regular-svg-icons';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { FirebaseService } from '../_services/firebase.service';
import { JsWidget, status, type, subStatus, analytics } from '../_interfaces/Widget';
import { FilterPipe } from '@guramrit/ngx-filter-pipe';
import { filterWidgetsWithGlobsPatterns, getNewId, getSessionData, isPathMatchingPattern, setSessionData, sortNestedColors } from '../shared/utils';
import { combineLatestWith, debounceTime } from 'rxjs/operators';
import { JsUser } from '../_interfaces/User';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmService } from '../_services/confirm.service';
import { SelectFilterComponent } from '../shared/components/select-filter/select-filter.component';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BulkUpdateComponent } from '../shared/components/bulk-update/bulk-update.component';
import { IdbServiceService } from '../_services/idb-service.service';
import { FirebaseOptimisticService } from '../_services/firebase-optimistic.service';
import { FieldValue, serverTimestamp } from '@angular/fire/firestore';
import { priority } from '../_interfaces/Widget';
import { UserSessionStorageService } from '../_services/user-session-storage.service';
import { MainViewType, ViewType } from '../_interfaces/Other';
import { CacheService } from '../_services/cache.service';
import { JsComment } from '../_interfaces/Comment';
import { NotificationsComponent } from '../notifications/notifications.component';
import { JsActivity } from '../_interfaces/Activity';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { DownloadJsonService } from '../_services/download-json.service';
import { JsonService } from '../_services/json.service';
import { ListPublicationsComponent } from '../list-publications/list-publications.component';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { AppThemeConfig, ColorScheme, Config } from '../_interfaces/Config';

@Component({
    selector: 'app-widget',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit, OnDestroy {
    @ViewChild('statusFilter') statusFilter: SelectFilterComponent | null = null;
    @ViewChild('subStatusFilter')
    subStatusFilter: SelectFilterComponent | null = null;
    @ViewChild('previewColorsFilter')
    previewColorsFilter: SelectFilterComponent | null = null;
    @ViewChild('analyticsStatusFilter')
    analyticsStatusFilter: SelectFilterComponent | null = null;
    @ViewChild('typeFilter') typeFilter: SelectFilterComponent | null = null;
    @ViewChild('uidFilter') uidFilter: SelectFilterComponent | null = null;
    @ViewChild('tagsFilter') tagsFilter: SelectFilterComponent | null = null;
    @ViewChild('assignedToFilter')
    assignedToFilter: SelectFilterComponent | null = null;
    @ViewChild('viewFilter') viewFilter: SelectFilterComponent | null = null;
    @ViewChild('emptyFilter') emptyFilter: SelectFilterComponent | null = null;
    // @ts-ignore
    @ViewChild('search') search;
    public currentMainView: MainViewType = 'tasks';
    public widgetDetailsMode: 'edit' | 'production' = 'edit';
    public notifications: JsActivity[] = [];
    public isConstructorCalled = false;
    public faImages = faImages;
    public faCopy = faCopy;
    public faPencil = faPencil;
    public faPlus = faPlus;
    public faTrash = faTrash;
    public faClock = faClock;
    public faFilter = faFilter;
    public faCircleCheck = faCircleCheck;
    public faCircleCheckEmpty = faCircleCheckEmpty;
    public faXmark = faXmark;
    public faBell = faBell;
    public searchModel = '';
    private mainWidgetSubscription: any;
    private filterSubscription: any;
    private notificationSubscription: any;
    private configSubscription: any;
    private defaultColorMap: ColorScheme = {};
    public widgets: JsWidget[] = [];
    public filteredWidgets: JsWidget[] = [];
    private today = new Date();
    private currentUserId = this.fbService.getLocalUser()?.user?.uid;
    public bigObj = {
        data: {}
    };
    public searchInput: EventEmitter<string> = new EventEmitter<string>();
    public showChildrenSet = new Set<string>();
    public selectedObj: JsWidget | null = null;
    public selectedObjToEdit: JsWidget | null = null;
    public showRootAddInput = false;
    public rootAddItem = '';
    public addUniqueId = '';
    public addItem = '';
    public errorMessage = '';
    public editUniqueId = '';
    public editUniqueIdNew = '';
    public tooltipDelay = 1000;
    public totalObj = {
        statusTotal: 0,
        statusBacklog: 0,
        statusToDesign: 0,
        statusToReview: 0,
        statusToDevelop: 0,
        statusToTest: 0,
        statusToApprove: 0,
        assignedComments: 0,
        assignedWidgets: 0,
        regressionNotStarted: 0,
        regressionPass: 0,
        regressionFail: 0
    };
    regressionCountViews = new Set<string>();
    public showTotalAsPercentage = false;
    public showingMyTasks = false;
    public widgetIdsWithAssignedComments: string[] = [];
    public filterOptions = {
        forRelease: {
            title: 'For Release',
            initialSelection: null as number | null,
            selectedEmitter: new EventEmitter<number | null>()
        },
        notForRelease: {
            title: 'Not For Release',
            initialSelection: null as number | null,
            selectedEmitter: new EventEmitter<number | null>()
        },
        inDeliverable: {
            title: 'In Deliverable',
            initialSelection: null as string | null,
            selectedEmitter: new EventEmitter<string | null>()
        },
        notInDeliverable: {
            title: 'Not In Deliverable',
            initialSelection: null as string | null,
            selectedEmitter: new EventEmitter<string | null>()
        },
        status: {
            title: 'Status',
            options: [
                { label: 'Backlog', value: 'Backlog' },
                { label: 'Design', value: 'Design' },
                { label: 'Review', value: 'Review' },
                { label: 'Develop', value: 'Develop' },
                { label: 'Test', value: 'Test' },
                { label: 'Failed', value: 'Failed' },
                { label: 'Approve', value: 'Approve' }
            ],
            selectedEmitter: new EventEmitter<string[]>(),
            initialSelection: [] as string[]
        },
        subStatus: {
            title: 'Sub Status',
            options: [
                { label: 'To Do', value: 'To Do' },
                { label: 'In Progress', value: 'In Progress' },
                { label: 'Blocked', value: 'Blocked' },
                { label: 'Done', value: 'Done' }
            ],
            selectedEmitter: new EventEmitter<string[]>(),
            initialSelection: [] as string[]
        },
        emptyFilter: {
            title: 'Empty Filter',
            options: [
                { label: 'specId', value: 'specId' },
                { label: 'Features', value: 'tasks' },
                { label: 'viewId', value: 'viewId' },
                { label: 'Analytics', value: 'analytics' },
                { label: 'Figma Preview', value: 'stateFigmaPreviewUrl' },
                {
                    label: 'Figma Preview Validated',
                    value: 'stateFigmaPreviewValidated'
                },
                { label: 'Figma Url', value: 'stateFigmaFrameUrl' },
                { label: 'Description', value: 'description' },
                { label: 'Backlog Assignee', value: 'backlogAssignedTo' },
                { label: 'Design Assignee', value: 'designAssignedTo' },
                { label: 'Review Assignee', value: 'reviewAssignedTo' },
                { label: 'Develop Assignee', value: 'developAssignedTo' },
                { label: 'Test Assignee', value: 'testAssignedTo' },
                { label: 'Approve Assignee', value: 'approveAssignedTo' },
                { label: 'Rule', value: 'rule' },
                { label: 'Action Type', value: 'actionType' },
                { label: 'Action Result', value: 'actionResult' },
                { label: 'Action Result State', value: 'actionResultStateId' }
            ],
            selectedEmitter: new EventEmitter<string[]>(),
            initialSelection: [] as string[]
        },
        previewColors: {
            title: 'Preview Colors',
            options: [] as { label: string; value: string }[],
            selectedEmitter: new EventEmitter<string[]>(),
            initialSelection: [] as string[]
        },
        analyticsStatus: {
            title: 'Analytics Status',
            options: [
                { label: 'INVALID', value: 'INVALID' },
                { label: 'Draft', value: 'Draft' },
                { label: 'Ready', value: 'Ready' },
                { label: 'Implemented', value: 'Implemented' },
                { label: 'Approved', value: 'Approved' },
                { label: 'Failed', value: 'Failed' },
                { label: 'Deprecated', value: 'Deprecated' }
            ],
            selectedEmitter: new EventEmitter<string[]>(),
            initialSelection: [] as string[]
        },
        type: {
            title: 'Type',
            options: [
                { label: 'Bug', value: 'bug' },
                { label: 'State', value: 'state' },
                { label: 'Action', value: 'action' },
                { label: 'Rule', value: 'rule' },
                { label: 'Translate', value: 'translate' },
                { label: 'Track', value: 'track' },
                { label: 'Other', value: 'other' }
            ],
            selectedEmitter: new EventEmitter<string[]>(),
            initialSelection: [] as string[]
        },
        mid: {
            title: 'Release',
            selectedEmitter: new EventEmitter<string>(),
            initialSelection: ''
        },
        task: {
            title: 'Feature',
            selectedEmitter: new EventEmitter<number>(),
            initialSelection: 0
        },
        viewId: {
            title: 'viewId',
            selectedEmitter: new EventEmitter<string>(),
            initialSelection: ''
        },
        uid: {
            title: 'Last updated By',
            selectedEmitter: new EventEmitter<string[]>(),
            initialSelection: [] as string[]
        },
        assignedTo: {
            title: 'Assigned To',
            selectedEmitter: new EventEmitter<string[]>(),
            initialSelection: [] as string[]
        },
        tags: {
            title: 'Tags',
            selectedEmitter: new EventEmitter<string[]>(),
            initialSelection: [] as string[]
        },
        viewFilter: {
            title: 'View Filter',
            options: [
                { label: 'Assigned Features', value: 'Assigned Features' },
                { label: 'Assigned Comments', value: 'Assigned Comments' },
                { label: 'Detail View', value: 'Detail View' },
                { label: 'Unassigned', value: 'Unassigned' },
                { label: 'Total', value: 'Total' },
                { label: 'Backlog', value: 'Backlog' },
                { label: 'Design', value: 'Design' },
                { label: 'Review', value: 'Review' },
                { label: 'Develop', value: 'Develop' },
                { label: 'Test', value: 'Test' },
                { label: 'Failed', value: 'Failed' },
                { label: 'Approve', value: 'Approve' },
                { label: 'Assigned To', value: 'Assigned To' },
                { label: 'To Do', value: 'To Do' },
                { label: 'In Progress', value: 'In Progress' },
                { label: 'Blocked', value: 'Blocked' },
                { label: 'Done', value: 'Done' },
                { label: 'aDraft', value: 'aDraft' },
                { label: 'aReady', value: 'aReady' },
                { label: 'aImplemented', value: 'aImplemented' },
                { label: 'aApproved', value: 'aApproved' },
                { label: 'aFailed', value: 'aFailed' },
                { label: 'aDeprecated', value: 'aDeprecated' }
                // { label: 'Regression Not Started', value: 'Regression Not Started' },
                // { label: 'Regression Pass', value: 'Regression Pass' },
                // { label: 'Regression Fail', value: 'Regression Fail' },
            ],
            selectedEmitter: new EventEmitter<string[]>(),
            initialSelection: ['Detail View', 'Unassigned', 'Total', 'Backlog', 'Design', 'Review', 'Develop', 'Test', 'Failed', 'Approve', 'Assigned To'],
            resetSelection: ['Detail View', 'Unassigned', 'Total', 'Backlog', 'Design', 'Review', 'Develop', 'Test', 'Failed', 'Approve', 'Assigned To']
        }
    };
    constructor(
        public fbService: FirebaseService,
        private fbo: FirebaseOptimisticService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private filter: FilterPipe,
        private route: ActivatedRoute,
        private router: Router,
        private confirmService: ConfirmService,
        private location: Location,
        private fns: AngularFireFunctions,
        private idb: IdbServiceService,
        public uss: UserSessionStorageService,
        public cc: CacheService,
        private ds: DownloadJsonService,
        private json: JsonService,
        private _dialog: DialogManagerService
    ) {
        // const callable = fns.httpsCallable('helloWorld');
        // const data$ = callable({ name: 'some-data' });
        // data$.subscribe((data) => {
        //   console.log(data);
        // });
        const paths = this.location.path().split('/path/');
        if (paths.length > 1 && !this.isConstructorCalled) {
            // Show widget from URL for deep linking
            this.setSelectedPath(paths[1]);
            this.showFullPath(paths[1]);
        } else {
            // get path from session storage
            const prevPath = this.uss.getUserSessionStorageItem('widgetPath');
            if (prevPath) {
                // THIS IS FOR THE DEEP LINKS
                this.setSelectedPath(prevPath);
                this.showFullPath(prevPath);
            }
        }
        this.isConstructorCalled = true;
        this.filterSubscription = this.filterOptions.viewFilter.selectedEmitter
            .pipe(
                combineLatestWith(
                    this.filterOptions.status.selectedEmitter,
                    this.filterOptions.subStatus.selectedEmitter,
                    this.filterOptions.previewColors.selectedEmitter,
                    this.filterOptions.analyticsStatus.selectedEmitter,
                    this.filterOptions.type.selectedEmitter,
                    this.filterOptions.emptyFilter.selectedEmitter,
                    this.filterOptions.uid.selectedEmitter,
                    this.filterOptions.mid.selectedEmitter,
                    this.filterOptions.task.selectedEmitter,
                    this.filterOptions.viewId.selectedEmitter,
                    this.filterOptions.forRelease.selectedEmitter,
                    this.filterOptions.notForRelease.selectedEmitter,
                    this.filterOptions.inDeliverable.selectedEmitter,
                    this.filterOptions.notInDeliverable.selectedEmitter,
                    this.filterOptions.assignedTo.selectedEmitter,
                    this.filterOptions.tags.selectedEmitter,
                    this.searchInput
                ),
                debounceTime(10)
            )
            .subscribe(
                ([
                    viewFilter,
                    status,
                    subStatus,
                    previewColors,
                    analyticsStatus,
                    type,
                    emptyFilter,
                    uid,
                    mid,
                    task,
                    viewId,
                    forRelease,
                    notForRelease,
                    inDeliverable,
                    notInDeliverable,
                    assignedTo,
                    tags,
                    search
                ]) => {
                    this.uss.setUserSessionStorage({
                        ...this.uss.currentUserSessionStorage,
                        widgetPath: this.uss.currentUserSessionStorage.widgetPath,
                        widgetSearch: search,
                        widgetStatus: status,
                        widgetSubStatus: subStatus,
                        widgetPreviewColors: previewColors,
                        widgetAnalyticsStatus: analyticsStatus,
                        widgetType: type,
                        widgetEmptyFilter: emptyFilter,
                        widgetUid: uid,
                        widgetMid: mid,
                        widgetTask: task,
                        widgetViewId: viewId,
                        widgetForReleaseFilter: forRelease,
                        widgetNotForReleaseFilter: notForRelease,
                        widgetInDeliverable: inDeliverable,
                        widgetNotInDeliverable: notInDeliverable,
                        widgetAssignedTo: assignedTo,
                        widgetTags: tags,
                        widgetViewFilter: viewFilter,
                        widgetDetailView: this.uss.currentUserSessionStorage.widgetDetailView
                    });
                }
            );
        this.mainWidgetSubscription = this.idb.widgets$
            .pipe(combineLatestWith(this.uss.currentUserSessionStorage$, this.fbService.getAssignedComments(), this.cc.currentRegression$), debounceTime(10))
            .subscribe(([widgets, userSessionStorage, assignedComments, currentRegression]) => {
                this.widgets = widgets;
                let filteredPayload = _.cloneDeep(widgets);
                this.widgetIdsWithAssignedComments = assignedComments.map(c => c.entityId);
                this.filterOptions.viewFilter.initialSelection = userSessionStorage.widgetViewFilter;
                const filterInput = {
                    path: userSessionStorage.widgetSearch || '',
                    status: userSessionStorage.widgetStatus.length ? { $or: userSessionStorage.widgetStatus } : '',
                    subStatus: userSessionStorage.widgetSubStatus.length ? { $or: userSessionStorage.widgetSubStatus } : '',
                    type: userSessionStorage.widgetType.length ? { $or: userSessionStorage.widgetType } : '',
                    uid: userSessionStorage.widgetUid.length ? { $or: userSessionStorage.widgetUid } : '',
                    tags: userSessionStorage.widgetTags.length ? { $or: userSessionStorage.widgetTags } : '',
                    // mid: this.getReleaseFilterSetting(userSessionStorage.mid),
                    $or: this.getAssignedToFilterSetting(userSessionStorage.widgetAssignedTo, userSessionStorage.widgetStatus)
                };

                // Filter by viewId
                if (userSessionStorage.widgetViewId === 'INVALID') {
                    filteredPayload = filteredPayload.filter(
                        widget => widget.viewId && (!this.cc.viewsByViewId[widget.viewId] || this.cc.viewsByViewId[widget.viewId].deletedAt)
                    );
                } else if (userSessionStorage.widgetViewId) {
                    filteredPayload = filteredPayload.filter(widget => widget.viewId === userSessionStorage.widgetViewId);
                }

                // Filter by For Release
                if (userSessionStorage.widgetForReleaseFilter != null) {
                    filteredPayload = this.cc.getFilteredSpecsForRelease(filteredPayload, userSessionStorage.widgetForReleaseFilter);
                }

                // Filter by Not For Release
                if (userSessionStorage.widgetNotForReleaseFilter != null) {
                    filteredPayload = this.cc.getFilteredSpecsNotForRelease(filteredPayload, userSessionStorage.widgetNotForReleaseFilter);
                }

                // Filter by In Deliverable
                if (userSessionStorage.widgetInDeliverable != null) {
                    filteredPayload = this.cc.getFilteredSpecsInDeliverable(filteredPayload, userSessionStorage.widgetInDeliverable);
                }

                // Filter by Not In Deliverable
                if (userSessionStorage.widgetNotInDeliverable != null) {
                    filteredPayload = this.cc.getFilteredSpecsNotInDeliverable(filteredPayload, userSessionStorage.widgetNotInDeliverable);
                }

                // Filter out task filter
                if (userSessionStorage.widgetTask) {
                    // Get task using tid
                    const task = this.cc.tidTaskMap[userSessionStorage.widgetTask];
                    if (task) {
                        filteredPayload = filterWidgetsWithGlobsPatterns(filteredPayload, task.includePatterns, task.excludePatterns);
                    }
                }

                // Filter by analytics status
                if (userSessionStorage.widgetAnalyticsStatus.length) {
                    filteredPayload = filteredPayload.filter(widget => {
                        if (!widget.analytics) return false;
                        // INVALID if name is set but paramGroup or params are not or length is 0
                        if (
                            userSessionStorage.widgetAnalyticsStatus.includes('INVALID') &&
                            widget.analytics &&
                            (!widget.analytics.eventName ||
                                !widget.analytics.paramsGroupIds ||
                                !widget.analytics.paramsGroupIds.length ||
                                !widget.analytics.params ||
                                !widget.analytics.params.length)
                        ) {
                            return true;
                        }
                        return userSessionStorage.widgetAnalyticsStatus.includes(widget.analytics.status);
                    });
                }

                // Filter by preview colors
                if (userSessionStorage.widgetPreviewColors?.length) {
                    filteredPayload = filteredPayload.filter(widget => {
                        if (widget.type === 'state' && widget.stateFigmaUsedColors?.length) {
                            return widget?.stateFigmaUsedColors?.some(c => userSessionStorage.widgetPreviewColors.includes(c));
                        }
                        return false;
                    });
                }

                this.filteredWidgets = this.filter.transform(_.cloneDeep(filteredPayload), filterInput);

                // Filter by empty fields
                if (userSessionStorage.widgetEmptyFilter.length) {
                    this.filteredWidgets = this.filteredWidgets.filter(widget => {
                        let empty = false;
                        userSessionStorage.widgetEmptyFilter.forEach((fieldName: string) => {
                            if (fieldName !== 'tasks') {
                                // @ts-ignore
                                if (!widget[fieldName]) {
                                    empty = true;
                                }
                            } else {
                                if (
                                    !this.cc.allTasks.some(t => t.includePatterns && isPathMatchingPattern(widget.path, t.includePatterns, t.excludePatterns))
                                ) {
                                    empty = true;
                                }
                            }
                        });
                        return empty;
                    });
                }
                console.time('Widgets to Big Objects array');
                const widgetObjArray = this.filteredWidgets.map(this.widgetStringToObj);
                this.bigObj.data = widgetObjArray.reduceRight(this.buildBigObjReducer, {});
                console.timeEnd('Widgets to Big Objects array');
                this.calculateTotals(this.bigObj.data);
                if (userSessionStorage.widgetPath) {
                    // EDIT WIDGET FUNCTIONALITY: THIS IS FOR THE UPDATES FROM THE BACKEND FOR UPDATED WIDGET
                    this.setWidgetToEdit(userSessionStorage.widgetPath);
                }
            });

        // Below is outside main subscription because it only needs to run once
        // Without this emits combineLatest won't trigger on first load
        const userSessionStorage = this.uss.getUserSessionStorage();
        this.searchModel = userSessionStorage.widgetSearch;
        this.searchInput.emit(userSessionStorage.widgetSearch);
        this.filterOptions.status.selectedEmitter.emit(userSessionStorage.widgetStatus);
        this.filterOptions.status.initialSelection = userSessionStorage.widgetStatus;
        this.filterOptions.subStatus.selectedEmitter.emit(userSessionStorage.widgetSubStatus);
        this.filterOptions.subStatus.initialSelection = userSessionStorage.widgetSubStatus;

        this.filterOptions.previewColors.selectedEmitter.emit(userSessionStorage.widgetPreviewColors);
        this.filterOptions.previewColors.initialSelection = userSessionStorage.widgetPreviewColors;
        this.filterOptions.analyticsStatus.selectedEmitter.emit(userSessionStorage.widgetAnalyticsStatus);
        this.filterOptions.analyticsStatus.initialSelection = userSessionStorage.widgetAnalyticsStatus;
        this.filterOptions.type.selectedEmitter.emit(userSessionStorage.widgetType);
        this.filterOptions.type.initialSelection = userSessionStorage.widgetType;
        this.filterOptions.emptyFilter.selectedEmitter.emit(userSessionStorage.widgetEmptyFilter);
        this.filterOptions.emptyFilter.initialSelection = userSessionStorage.widgetEmptyFilter;
        this.filterOptions.uid.selectedEmitter.emit(userSessionStorage.widgetUid);
        this.filterOptions.uid.initialSelection = userSessionStorage.widgetUid;
        this.filterOptions.mid.selectedEmitter.emit(userSessionStorage.widgetMid);
        this.filterOptions.mid.initialSelection = userSessionStorage.widgetMid;
        this.filterOptions.viewId.selectedEmitter.emit(userSessionStorage.widgetViewId);
        this.filterOptions.viewId.initialSelection = userSessionStorage.widgetViewId;
        this.filterOptions.forRelease.selectedEmitter.emit(userSessionStorage.widgetForReleaseFilter);
        this.filterOptions.forRelease.initialSelection = userSessionStorage.widgetForReleaseFilter;
        this.filterOptions.notForRelease.selectedEmitter.emit(userSessionStorage.widgetNotForReleaseFilter);
        this.filterOptions.notForRelease.initialSelection = userSessionStorage.widgetNotForReleaseFilter;
        this.filterOptions.inDeliverable.selectedEmitter.emit(userSessionStorage.widgetInDeliverable);
        this.filterOptions.inDeliverable.initialSelection = userSessionStorage.widgetInDeliverable;
        this.filterOptions.notInDeliverable.selectedEmitter.emit(userSessionStorage.widgetNotInDeliverable);
        this.filterOptions.notInDeliverable.initialSelection = userSessionStorage.widgetNotInDeliverable;
        this.filterOptions.task.selectedEmitter.emit(userSessionStorage.widgetTask);
        this.filterOptions.task.initialSelection = userSessionStorage.widgetTask;
        this.filterOptions.assignedTo.selectedEmitter.emit(userSessionStorage.widgetAssignedTo);
        this.filterOptions.tags.selectedEmitter.emit(userSessionStorage.widgetTags);
        this.filterOptions.tags.initialSelection = userSessionStorage.widgetTags;
        this.filterOptions.assignedTo.initialSelection = userSessionStorage.widgetAssignedTo;
        this.filterOptions.viewFilter.selectedEmitter.emit(userSessionStorage.widgetViewFilter);
        this.filterOptions.viewFilter.initialSelection = userSessionStorage.widgetViewFilter;
        if (userSessionStorage.widgetPath) {
            this.setSelectedPath(userSessionStorage.widgetPath);
        }

        this.notificationSubscription = this.idb.notifications$.subscribe((items: JsActivity[]) => {
            this.notifications = items;
        });
    }

    ngAfterViewInit() {
        const urlPath = this.route.snapshot.paramMap.get('urlPath');
        const view = this.route.snapshot.paramMap.get('view');
        if (urlPath) {
            this.uss.setUserSessionStorageItem('widgetPath', urlPath);
        }
        if (view) {
            this.uss.setUserSessionStorageItem('widgetDetailView', view as ViewType);
        }
    }

    showMyTasks() {
        const currentUser = this.fbService.getCurrentUser();
        if (!currentUser) return;
        this.resetFilters();
        this.filterOptions.status.initialSelection = [...currentUser.roles];
        this.filterOptions.viewFilter.initialSelection = [
            ...currentUser.roles,
            'Total',
            'To Do',
            'In Progress',
            'Blocked',
            'Done',
            'Detail View',
            'Assigned To'
        ];
        this.filterOptions.assignedTo.initialSelection = [currentUser.value];
        this.filterOptions.assignedTo.selectedEmitter.emit([currentUser.value]);
        this.filterOptions.status.selectedEmitter.emit([...currentUser.roles]);
        this.filterOptions.viewFilter.selectedEmitter.emit([
            ...currentUser.roles,
            'Total',
            'To Do',
            'In Progress',
            'Blocked',
            'Done',
            'Detail View',
            'Assigned To'
        ]);
    }

    toggleViewFilter(view: string) {
        const hasViewFilter = this.filterOptions.viewFilter.initialSelection.includes(view);
        if (hasViewFilter) {
            this.filterOptions.viewFilter.initialSelection = this.filterOptions.viewFilter.initialSelection.filter(v => v !== view);
            this.filterOptions.viewFilter.selectedEmitter.emit([...this.filterOptions.viewFilter.initialSelection]);
        } else {
            this.filterOptions.viewFilter.initialSelection.push(view);
            this.filterOptions.viewFilter.selectedEmitter.emit([...this.filterOptions.viewFilter.initialSelection]);
        }
    }

    toggleFilterFromHere(uniqueId: string) {
        // If the filter is already selected, remove it
        if (uniqueId === this.searchModel) {
            this.searchModel = '';
            this.searchInput.emit('');
            this.uss.setUserSessionStorageItem('widgetSearch', '', true);
            return;
        } else {
            this.searchModel = uniqueId;
            this.searchInput.emit(uniqueId);
            this.uss.setUserSessionStorageItem('widgetSearch', uniqueId, true);
        }
    }

    getPercent(value: number, total: number, decimalPlaces: number = 0) {
        if (!total || !value) return '0%';
        return ((value / total) * 100).toFixed(decimalPlaces) + '%';
    }

    getPillValue(isLeafNode: boolean, leafNodeValue: string, value: number = 0, total: number) {
        if (isLeafNode) return leafNodeValue;
        if (this.showTotalAsPercentage) return this.getPercent(value, total);
        return value;
    }

    // getReleaseFilterSetting(releaseId: string) {
    //   let result;
    //   if (!releaseId || releaseId === 'All') {
    //     result = '';
    //   } else if (releaseId === 'Unassigned') {
    //     result = ['Unassigned'];
    //   } else if (this.cc.isRelease(releaseId)) {
    //     result = this.cc.getAllReleasesInRelease(releaseId).map((m) => m.id);
    //   } else {
    //     result = [releaseId];
    //   }
    //   if (result && result.length && result.length > 0) {
    //     return {
    //       $or: result,
    //     };
    //   }
    //   return result;
    // }

    getAssignedToFilterSetting(assignedToFilters: string[] = [], statusFilters: string[] = []) {
        const statusFiltersLower = statusFilters.map(s => s.toLowerCase());
        let setting = [
            {
                backlogAssignedTo: ''
            },
            {
                designAssignedTo: ''
            },
            {
                reviewAssignedTo: ''
            },
            {
                developAssignedTo: ''
            },
            {
                testAssignedTo: ''
            },
            {
                failedAssignedTo: ''
            },
            {
                approveAssignedTo: ''
            }
        ];
        if (statusFilters.length) {
            setting = setting.filter(s => {
                const key = Object.keys(s)[0];
                const status = key.split('AssignedTo')[0];
                return statusFiltersLower.includes(status);
            });
        }
        if (assignedToFilters.length && setting.length) {
            // @ts-ignore
            setting = setting.map(s => {
                const key = Object.keys(s)[0];
                return {
                    [key]: {
                        $or: [...assignedToFilters]
                    }
                };
            });
        }
        return setting;
    }

    checkRender() {
        return true;
    }

    ngOnInit(): void {
        this.configSubscription = this.fbService.getConfig().subscribe(c => {
            const config = c[0];
            this.defaultColorMap = config.themeInfo.themes['Green']['Dark'];
            const previewColorOptions = Object.keys(this.defaultColorMap).map(color => {
                return {
                    label: color,
                    // @ts-ignore
                    value: this.defaultColorMap[color] as string
                };
            });

            this.filterOptions.previewColors.options = _.sortBy(previewColorOptions, o => Number(o.label.split(' ')[1]));
        });
    }

    ngOnDestroy() {
        this.filterSubscription?.unsubscribe();
        this.mainWidgetSubscription?.unsubscribe();
        this.notificationSubscription?.unsubscribe();
        this.configSubscription?.unsubscribe();
    }

    resetFilters() {
        this.search.nativeElement.value = '';
        this.searchInput.emit('');
        this.searchModel = '';
        this.statusFilter?.reset();
        this.subStatusFilter?.reset();
        this.analyticsStatusFilter?.reset();
        this.previewColorsFilter?.reset();
        this.typeFilter?.reset();
        this.uidFilter?.reset();
        this.assignedToFilter?.reset();
        this.tagsFilter?.reset();
        this.viewFilter?.reset();
        this.emptyFilter?.reset();
        const path = this.uss.currentUserSessionStorage.widgetPath;
        // this.uss.resetUserSessionStorage();
        this.uss.setUserSessionStorageItem('widgetPath', path);
        this.filterOptions.mid.initialSelection = '';
        this.filterOptions.mid.selectedEmitter.emit('');
        this.uss.setUserSessionStorageItem('widgetMid', '');
        this.filterOptions.task.initialSelection = 0;
        this.filterOptions.task.selectedEmitter.emit(0);
        this.uss.setUserSessionStorageItem('widgetTask', 0);
        this.filterOptions.viewId.initialSelection = '';
        this.filterOptions.viewId.selectedEmitter.emit('');
        this.uss.setUserSessionStorageItem('widgetViewId', '');
        this.filterOptions.forRelease.initialSelection = null;
        this.filterOptions.forRelease.selectedEmitter.emit(null);
        this.uss.setUserSessionStorageItem('widgetForReleaseFilter', null);
        this.filterOptions.notForRelease.initialSelection = null;
        this.filterOptions.notForRelease.selectedEmitter.emit(null);
        this.uss.setUserSessionStorageItem('widgetNotForReleaseFilter', null);
        this.filterOptions.inDeliverable.initialSelection = null;
        this.filterOptions.inDeliverable.selectedEmitter.emit(null);
        this.uss.setUserSessionStorageItem('widgetInDeliverable', null);
        this.filterOptions.notInDeliverable.initialSelection = null;
        this.filterOptions.notInDeliverable.selectedEmitter.emit(null);
        this.uss.setUserSessionStorageItem('widgetNotInDeliverable', null);
    }

    showAssignedComments() {}

    collapseAll() {
        this.showChildrenSet = new Set();
    }

    updateUrlPath(urlPath: string) {
        this.location.replaceState('/path/' + urlPath);
    }

    setSelectedPath(path: string) {
        this.setWidgetToEdit(path);
        this.updateUrlPath(path);
        this.uss.setUserSessionStorageItem('widgetPath', path, false);
    }

    setWidgetToEdit(path: string, showFullPath = false) {
        const selectedObj = this.widgets.find(w => w.path === path) || null;
        this.selectedObj = _.cloneDeep(selectedObj);
        this.selectedObjToEdit = _.cloneDeep(selectedObj);
        // this.setModeIfInProduction(this.selectedObjToEdit);
        if (showFullPath) this.showFullPath(path);
    }

    // setModeIfInProduction(widgetToEdit: JsWidget | null) {
    //   if (widgetToEdit?.mid && this.cc.isReleaseInProduction(widgetToEdit.mid)) {
    //     this.widgetDetailsMode = 'production';
    //   } else {
    //     this.widgetDetailsMode = 'edit';
    //   }
    // }

    toggleShowChildren(id: string) {
        this.showChildrenSet.has(id) ? this.showChildrenSet.delete(id) : this.showChildrenSet.add(id);
    }

    showFullPath(path: string) {
        path.split('_').reduce((acc, curr) => {
            const id = acc + (acc ? '_' : '') + curr;
            this.showChildrenSet.add(id);
            return id;
        }, '');
        this.showChildrenSet.add(path);
    }

    // CALCULATE TOTALS
    calculateTotals(obj: any) {
        const currentRegression = this.cc.currentRegression;
        const totalObj = {
            statusTotal: 0,
            statusBacklog: 0,
            statusToDesign: 0,
            statusToReview: 0,
            statusToDevelop: 0,
            statusToTest: 0,
            statusToFailed: 0,
            statusToApprove: 0,
            statusApproveAndDone: 0,
            ssTask: 0,
            ssInProgress: 0,
            ssBlocked: 0,
            ssDone: 0,
            aDraft: 0,
            aReady: 0,
            aImplemented: 0,
            aApproved: 0,
            aFailed: 0,
            aDeprecated: 0,
            assignedComments: 0,
            assignedWidgets: 0,
            unassignedCount: 0,
            regressionNotStarted: 0,
            regressionPass: 0,
            regressionFail: 0
        };
        _.forIn(obj, function (value, key) {
            // console.log(key, value);
            if (value && value._meta) {
                totalObj.statusTotal += value._meta._statusTotal || 0;
                totalObj.statusBacklog += value._meta._statusBacklog || 0;
                totalObj.statusToDesign += value._meta._statusToDesign || 0;
                totalObj.statusToReview += value._meta._statusToReview || 0;
                totalObj.statusToDevelop += value._meta._statusToDevelop || 0;
                totalObj.statusToTest += value._meta._statusToTest || 0;
                totalObj.statusToFailed += value._meta._statusToFailed || 0;
                totalObj.statusToApprove += value._meta._statusToApprove || 0;
                totalObj.statusApproveAndDone += value._meta._statusApproveAndDone || 0;
                totalObj.ssTask += value._meta._ssTask || 0;
                totalObj.ssInProgress += value._meta._ssInProgress || 0;
                totalObj.ssBlocked += value._meta._ssBlocked || 0;
                totalObj.ssDone += value._meta._ssDone || 0;
                totalObj.aDraft += value._meta._aDraft || 0;
                totalObj.aReady += value._meta._aReady || 0;
                totalObj.aImplemented += value._meta._aImplemented || 0;
                totalObj.aApproved += value._meta._aApproved || 0;
                totalObj.aFailed += value._meta._aFailed || 0;
                totalObj.aDeprecated += value._meta._aDeprecated || 0;
                totalObj.assignedComments += value._meta._assignedComments || 0;
                totalObj.assignedWidgets += value._meta._assignedWidgets || 0;
                totalObj.unassignedCount += value._meta._unassignedCount || 0;
                if (currentRegression) {
                    totalObj.regressionNotStarted += value._meta._regressionNotStarted || 0;
                    totalObj.regressionPass += value._meta._regressionPass || 0;
                    totalObj.regressionFail += value._meta._regressionFail || 0;
                }
            }
        });
        this.totalObj = totalObj;
        const totalCount =
            totalObj.statusBacklog * 1 +
            totalObj.statusToDesign * 2 +
            totalObj.statusToReview * 2 +
            totalObj.statusToDevelop * 3 +
            totalObj.statusToTest * 5 +
            totalObj.statusToFailed * 3 +
            totalObj.statusToApprove * 6 +
            totalObj.statusApproveAndDone * 1;
        this.cc.overallProgressInPercent = this.getPercent(totalCount, totalObj.statusTotal * 7, 2);
    }

    // MERGE AND CREATE BIG OBJECT
    // @ts-ignore
    buildBigObjReducer = (acc, widget, i) => {
        return _.mergeWith(
            acc,
            widget,
            // @ts-ignore
            (objValue, srcValue, key, object, source, stack) => {
                if (key === '_meta' && objValue && srcValue) {
                    return {
                        _someValue: this.addMetaValues('_someValue', objValue, srcValue),
                        _uniqueId: objValue._uniqueId,
                        _isTopLevel: objValue._isTopLevel,
                        _pathIds: [...objValue._pathIds, ...srcValue._pathIds],
                        _statusTotal: this.addMetaValues('_statusTotal', objValue, srcValue),
                        _statusBacklog: this.addMetaValues('_statusBacklog', objValue, srcValue),
                        _statusToDesign: this.addMetaValues('_statusToDesign', objValue, srcValue),
                        _statusToReview: this.addMetaValues('_statusToReview', objValue, srcValue),
                        _statusToDevelop: this.addMetaValues('_statusToDevelop', objValue, srcValue),
                        _statusToTest: this.addMetaValues('_statusToTest', objValue, srcValue),
                        _statusToFailed: this.addMetaValues('_statusToFailed', objValue, srcValue),
                        _statusToApprove: this.addMetaValues('_statusToApprove', objValue, srcValue),
                        _statusApproveAndDone: this.addMetaValues('_statusApproveAndDone', objValue, srcValue),
                        _ssTask: this.addMetaValues('_ssTask', objValue, srcValue),
                        _ssInProgress: this.addMetaValues('_ssInProgress', objValue, srcValue),
                        _ssBlocked: this.addMetaValues('_ssBlocked', objValue, srcValue),
                        _ssDone: this.addMetaValues('_ssDone', objValue, srcValue),
                        _aDraft: this.addMetaValues('_aDraft', objValue, srcValue),
                        _aReady: this.addMetaValues('_aReady', objValue, srcValue),
                        _aImplemented: this.addMetaValues('_aImplemented', objValue, srcValue),
                        _aApproved: this.addMetaValues('_aApproved', objValue, srcValue),
                        _aFailed: this.addMetaValues('_aFailed', objValue, srcValue),
                        _aDeprecated: this.addMetaValues('_aDeprecated', objValue, srcValue),
                        _assignedComments: this.addMetaValues('_assignedComments', objValue, srcValue),
                        _assignedWidgets: this.addMetaValues('_assignedWidgets', objValue, srcValue),
                        _unassignedCount: this.addMetaValues('_unassignedCount', objValue, srcValue),
                        _regressionNotStarted: this.cc.currentRegression ? this.addMetaValues('_regressionNotStarted', objValue, srcValue) : 0,
                        _regressionPass: this.cc.currentRegression ? this.addMetaValues('_regressionPass', objValue, srcValue) : 0,
                        _regressionFail: this.cc.currentRegression ? this.addMetaValues('_regressionFail', objValue, srcValue) : 0
                    };
                }
            }
        );
    };

    toggleRegressionCountViews(val: 'pass' | 'fail' | 'notStarted') {
        if (this.regressionCountViews.has(val)) {
            this.regressionCountViews.delete(val);
        } else {
            this.regressionCountViews.add(val);
        }
    }

    // ADD META VALUES
    // @ts-ignore
    addMetaValues(key, meta1, meta2) {
        return (meta1[key] || 0) + (meta2[key] || 0);
    }

    // CONVERT WIDGET STRING TO OBJECT
    widgetStringToObj = (widgetObj: JsWidget) => {
        let childName = '';
        const obj = widgetObj.path.split('_').reduceRight((acc, subStr, i, arr) => {
            let newAcc = {};

            // IF LEAF NODE
            if (i === arr.length - 1) {
                newAcc = {
                    [subStr]: widgetObj
                };
                const currentAssignee = this.fbService.getCurrentAssigneeNameFromWidget(widgetObj);
                // @ts-ignore
                newAcc[subStr]._meta = {
                    _someValue: 1,
                    _uniqueId: widgetObj.path,
                    _isTopLevel: false,
                    _statusTotal: 1,
                    _statusBacklog: widgetObj.status === 'Backlog' ? 1 : 0,
                    _statusToDesign: widgetObj.status === 'Design' ? 1 : 0,
                    _statusToReview: widgetObj.status === 'Review' ? 1 : 0,
                    _statusToDevelop: widgetObj.status === 'Develop' ? 1 : 0,
                    _statusToTest: widgetObj.status === 'Test' ? 1 : 0,
                    _statusToFailed: widgetObj.status === 'Failed' ? 1 : 0,
                    _statusToApprove: widgetObj.status === 'Approve' ? 1 : 0,
                    _statusApproveAndDone: widgetObj.status === 'Approve' && widgetObj.subStatus === 'Done' ? 1 : 0,
                    _ssTask: widgetObj.subStatus === 'To Do' ? 1 : 0,
                    _ssInProgress: widgetObj.subStatus === 'In Progress' ? 1 : 0,
                    _ssBlocked: widgetObj.subStatus === 'Blocked' ? 1 : 0,
                    _ssDone: widgetObj.subStatus === 'Done' ? 1 : 0,
                    _aDraft: widgetObj.analytics?.status === 'Draft' ? 1 : 0,
                    _aReady: widgetObj.analytics?.status === 'Ready' ? 1 : 0,
                    _aImplemented: widgetObj.analytics?.status === 'Implemented' ? 1 : 0,
                    _aApproved: widgetObj.analytics?.status === 'Approved' ? 1 : 0,
                    _aFailed: widgetObj.analytics?.status === 'Failed' ? 1 : 0,
                    _aDeprecated: widgetObj.analytics?.status === 'Deprecated' ? 1 : 0,
                    _pathIds: [widgetObj.path],
                    _assignedTo: currentAssignee,
                    _assignedWidgets: currentAssignee && currentAssignee === this.fbService.currentUser.label ? 1 : 0,
                    _assignedComments: this.widgetIdsWithAssignedComments.filter(id => id === widgetObj.id).length,
                    _unassignedCount: currentAssignee ? 0 : 1,
                    _regressionNotStarted: this.cc.currentRegression
                        ? widgetObj.regressions.filter(reg => reg.startsWith(this.cc.currentRegression)).length
                            ? 0
                            : 1
                        : 0, // if no regression is selected, then no need to calculate it
                    _regressionPass: this.cc.currentRegression
                        ? widgetObj.regressions.filter(reg => reg === this.cc.currentRegression + '_PASS').length
                            ? 1
                            : 0
                        : 0,
                    _regressionFail: this.cc.currentRegression
                        ? widgetObj.regressions.filter(reg => reg === this.cc.currentRegression + '_FAIL').length
                            ? 1
                            : 0
                        : 0
                };
            } else {
                newAcc = {
                    [subStr]: acc
                };
                // @ts-ignore
                newAcc[subStr]._meta = {
                    // @ts-ignore
                    _someValue: acc[childName]._meta._someValue,
                    _uniqueId:
                        // @ts-ignore
                        acc[childName]._meta._uniqueId.split('_').slice(0, -1).join('_'),
                    //NO NEED OF prefix underscore if its the top level segment
                    // @ts-ignore
                    _isTopLevel: i === 0,
                    // @ts-ignore
                    _pathIds: [acc[childName]._meta._pathIds[0]],
                    // @ts-ignore
                    _statusTotal: acc[childName]._meta._statusTotal,
                    // @ts-ignore
                    _statusBacklog: acc[childName]._meta._statusBacklog,
                    // @ts-ignore
                    _statusToDesign: acc[childName]._meta._statusToDesign,
                    // @ts-ignore
                    _statusToReview: acc[childName]._meta._statusToReview,
                    // @ts-ignore
                    _statusToDevelop: acc[childName]._meta._statusToDevelop,
                    // @ts-ignore
                    _statusToTest: acc[childName]._meta._statusToTest,
                    // @ts-ignore
                    _statusToFailed: acc[childName]._meta._statusToFailed,
                    // @ts-ignore
                    _statusToApprove: acc[childName]._meta._statusToApprove,
                    // @ts-ignore
                    _statusApproveAndDone: acc[childName]._meta._statusApproveAndDone,
                    // @ts-ignore
                    _ssTask: acc[childName]._meta._ssTask,
                    // @ts-ignore
                    _ssInProgress: acc[childName]._meta._ssInProgress,
                    // @ts-ignore
                    _ssBlocked: acc[childName]._meta._ssBlocked,
                    // @ts-ignore
                    _ssDone: acc[childName]._meta._ssDone,
                    // @ts-ignore
                    _aDraft: acc[childName]._meta._aDraft,
                    // @ts-ignore
                    _aReady: acc[childName]._meta._aReady,
                    // @ts-ignore
                    _aImplemented: acc[childName]._meta._aImplemented,
                    // @ts-ignore
                    _aApproved: acc[childName]._meta._aApproved,
                    // @ts-ignore
                    _aFailed: acc[childName]._meta._aFailed,
                    // @ts-ignore
                    _aDeprecated: acc[childName]._meta._aDeprecated,
                    // @ts-ignore
                    _assignedComments: acc[childName]._meta._assignedComments,
                    // @ts-ignore
                    _assignedWidgets: acc[childName]._meta._assignedWidgets,
                    // @ts-ignore
                    _unassignedCount: acc[childName]._meta._unassignedCount,
                    // @ts-ignore
                    _regressionNotStarted: acc[childName]._meta._regressionNotStarted,
                    // @ts-ignore
                    _regressionPass: acc[childName]._meta._regressionPass,
                    // @ts-ignore
                    _regressionFail: acc[childName]._meta._regressionFail
                };
            }
            childName = subStr;
            return newAcc;
        }, {});
        return obj;
    };

    checkIfLeafNode(node: any) {
        const keys = Object.keys(node);
        return keys.includes('path') && keys.includes('type') && keys.includes('status');
    }

    logValue(value: any) {
        console.log(value);
    }

    trackByFn(index: number, item: any): string {
        return item.value._meta._uniqueId;
    }

    // TOGGLE ADD INPUT
    toggleAddInput(item: any) {
        this.addUniqueId = this.addUniqueId === item.value._meta._uniqueId ? '' : item.value._meta._uniqueId;
        this.addItem = '';
        this.editUniqueId = '';
        this.rootAddItem = '';
        this.errorMessage = '';
        this.showRootAddInput = false;
    }

    // TOGGLE ROOT NODE ADD INPUT
    toggleRootAddInput() {
        this.showRootAddInput = !this.showRootAddInput;
        if (!this.showRootAddInput) {
            this.rootAddItem = '';
        }
        this.addUniqueId = this.addItem = '';
        this.editUniqueId = '';
        this.errorMessage = '';
    }

    toggleEditInput(item: any = null) {
        if (!item) {
            this.editUniqueId = '';
            this.editUniqueIdNew = '';
        } else {
            this.editUniqueId = this.editUniqueId === item.value._meta._uniqueId ? '' : item.value._meta._uniqueId;
            this.editUniqueIdNew = this.editUniqueId;
        }
        this.errorMessage = '';
        this.addItem = '';
        this.rootAddItem = '';
        this.showRootAddInput = false;
        this.addUniqueId = '';
    }

    switchMainView(view: MainViewType) {
        this.currentMainView = view;
    }
    // showNotifications() {
    //   this.notificationsDialog = this.dialog.open(NotificationsComponent, {
    //     maxWidth: '1000px',
    //     width: '80vw',
    //     height: '80vh',
    //   });

    //   this.notificationsDialog.afterClosed().subscribe((result: string[]) => {
    //     // console.log('The dialog was closed', result);
    //   });
    // }

    onNotificationsClose() {
        this.switchMainView('tasks');
    }

    addRootItem() {
        this.addNewWidget(this.rootAddItem);
    }

    addNewItem(parentString: any) {
        this.addNewWidget(parentString + '_' + this.addItem);
    }

    async addNewWidget(path: string) {
        if (!this.validateNewPath(path)) {
            return;
        }
        if (this.hasUsedNewPaths([path])) {
            return;
        }
        const uid = this.fbService.getLocalUser()?.user?.uid;
        if (!uid) return;
        this.addItem = '';
        this.errorMessage = '';
        this.rootAddItem = '';
        const type = this.getType(path);
        const newWidget = {
            updatedAt: new Date(),
            cloudUpdatedAt: serverTimestamp(),
            createdAt: new Date(),
            createdBy: uid,
            deletedAt: null,
            uid: uid,
            backlogAssignedTo: uid,
            designAssignedTo: null,
            reviewAssignedTo: null,
            developAssignedTo: null,
            testAssignedTo: null,
            failedAssignedTo: null,
            approveAssignedTo: null,
            id: getNewId(),
            mid: 'Unassigned',
            dmid: 'Unassigned',
            versionFrom: 1,
            versionTo: null,
            path: path,
            type: type,
            priority: 'Default' as priority,
            bugExpected: null,
            bugActual: null,
            rule: null,
            stateFigmaFrameUrl: '',
            stateFigmaPreviewUrl: '',
            stateFigmaPreviewAt: null,
            stateFigmaUsedColors: [],
            stateFigmaPreviewValidated: false,
            stateFigmaDevResourceId: null,
            actionType: null,
            actionResult: null,
            actionResultPath: null,
            actionResultStateId: null,
            analytics: null,
            description: null,
            status: 'Backlog' as status,
            subStatus: 'To Do' as subStatus,
            devBuilds: [],
            testBuilds: [],
            watchers: [uid],
            tags: [],
            regressions: [],
            viewId: null,
            specId: 0,
            skipPreviewCheck: false,
            fromRelease: this.cc.config.nextRelease || null,
            toRelease: null
        };
        await this.fbo.createItemsOptimistic<JsWidget>([newWidget], 'widgets');
        this.setSelectedPath(newWidget.path);
        this.showFullPath(newWidget.path);
    }

    async editPaths(paths: string[], uniqueId: string, newUniqueId: string, isLeafNode: boolean) {
        if (paths.length === 1 && this.getType(uniqueId) !== this.getType(newUniqueId)) {
            this.errorMessage = 'You cannot change the type';
            return;
        }
        const filteredWidgets = this.filterWidgetsByPath(paths);
        const updatedWidgets = filteredWidgets.map(widget => {
            const newWidget = this.removeMetaProperty(widget);
            newWidget.path = newWidget.path.replace(uniqueId, newUniqueId);
            newWidget.updatedAt = new Date();
            return newWidget;
        });
        if (this.hasUsedNewPaths(updatedWidgets.map(w => w.path))) {
            return;
        } else if (updatedWidgets.some(w => !this.validateNewPath(w.path))) {
            return;
        } else {
            this.toggleEditInput();
            this.errorMessage = '';
            await this.fbo.updateItemsOptimistic<JsWidget>(updatedWidgets, 'widgets');

            if (paths.length === 1 && isLeafNode && this.uss.currentUserSessionStorage.widgetPath === paths[0]) {
                // DELAY FOR FETECHING THE NEW SETS OF WIDGETS FROM FIRESTORE
                setTimeout(() => {
                    this.setSelectedPath(updatedWidgets[0].path);
                    this.showFullPath(updatedWidgets[0].path);
                }, 0);

                // const urlPath = this.route.snapshot.paramMap.get('path');
                // if (urlPath === paths[0]) {
                //   this.updateUrlPath(updatedWidgets[0].path);
                // }
            }
        }
    }

    filterWidgetsByPath(paths: string[]) {
        const pathObjs = paths.map(path => {
            return { path: path };
        });
        return _.intersectionBy(this.widgets, pathObjs, 'path').map(w => _.cloneDeep(w));
    }

    async deletePaths(paths: string[]) {
        const confirmation = await this.confirmService.confirm('Delete Paths', `Are you sure you want to delete ${paths.length} paths?`);
        if (!confirmation) return;
        const filteredWidgets = this.filterWidgetsByPath(paths);
        const deletedWidgets = filteredWidgets.map(widget => {
            if (widget.path === this.uss.currentUserSessionStorage.widgetPath) {
                this.setSelectedPath('');
            }
            widget.deletedAt = new Date();
            return this.removeMetaProperty(widget);
        });
        await this.fbo.updateItemsOptimistic<JsWidget>(deletedWidgets, 'widgets');
    }

    async cloneWidgets(paths: string[], uniqueId: string) {
        const confirm = await this.confirmService.confirm('Clone Paths', `Are you sure you want to clone ${paths.length} paths?`);
        if (!confirm) return;
        const uid = this.fbService.getLocalUser()?.user?.uid;
        if (!uid) return;
        let hasInvalidNewPath = false;
        const newWidgets = this.filterWidgetsByPath(paths).map(w => {
            const newPath = this.getSafeNewPath(w.path, uniqueId);
            if (!this.validateNewPath(newPath) || this.hasUsedNewPaths([newPath])) {
                hasInvalidNewPath = true;
            }
            return {
                ...(this.removeMetaProperty(w) as JsWidget),
                path: newPath,
                id: getNewId(),
                status: 'Backlog' as status,
                subStatus: 'To Do' as subStatus,
                inProgress: false,
                uid: uid,
                mid: 'Unassigned',
                dmid: 'Unassigned'
            };
        });
        if (hasInvalidNewPath) {
            alert('Invalid new path');
            return;
        }
        await this.fbo.createItemsOptimistic<JsWidget>(newWidgets, 'widgets');
    }

    removeMetaProperty(widget: any): JsWidget {
        // @ts-ignore
        if (widget._meta) {
            // @ts-ignore
            delete widget._meta;
        }
        return widget;
    }

    getSafeNewPath(path: string, uniqueId: string) {
        let newPath = path;
        let safeLimit = 0;
        do {
            newPath = newPath.replace(uniqueId, uniqueId + 'Clone');
            safeLimit++;
        } while (this.hasUsedNewPaths([newPath]) && safeLimit < 10);
        return newPath;
    }

    hasUsedNewPaths(paths: string[]) {
        if (this.filterWidgetsByPath(paths).length > 0) {
            this.errorMessage = 'This path is already used';
            return true;
        } else {
            this.errorMessage = '';
            return false;
        }
    }

    getType(path: string): type {
        if (path.includes('_') && path.split('_').length > 0) {
            const lastSegment = path.split('_').pop() as string;
            if (lastSegment.startsWith('bug')) {
                return 'bug';
            } else if (lastSegment.startsWith('state')) {
                return 'state';
            } else if (lastSegment.startsWith('action')) {
                return 'action';
            } else if (lastSegment.startsWith('rule')) {
                return 'rule';
            } else if (lastSegment.startsWith('other')) {
                return 'other';
            } else {
                return 'unknown';
            }
        } else {
            return 'unknown';
        }
    }

    validateNewPath(path: string) {
        if (/^[A-Za-z0-9_.:]*$/.test(path) === false) {
            this.errorMessage = 'Only alphanumeric characters and underscore are allowed';
            return false;
        }
        // if (
        //   path.split('_').some((s, i) => {
        //     console.log(s, i, path.split('_').length - 1);
        //     return (
        //       i < path.split('_').length - 1 &&
        //       !(
        //         s.startsWith('screen') ||
        //         s.startsWith('component') ||
        //         s.startsWith('widget')
        //       )
        //     );
        //   })
        // ) {
        //   this.errorMessage =
        //     'Non-leaf node should start with screen, component or widget';
        //   return false;
        // }
        if (path.startsWith('app') || path.startsWith('backend') || path.startsWith('task') || path.startsWith('routine')) {
        } else {
            this.errorMessage = 'Path should start with app, backend, task or routine';
            return false;
        }
        if (path.includes('__')) {
            this.errorMessage = 'Avoid using undersore in the beginning.';
            return false;
        }
        const type = this.getType(path);
        if (type !== 'unknown') {
            this.errorMessage = '';
            return true;
        } else {
            this.errorMessage = 'Leaf node should start with bug, state, action, rule, translate, track or other';
            return false;
        }
    }

    bulkUpdateWidgets() {
        const dialogRef = this.dialog.open(BulkUpdateComponent, {
            data: {
                filteredWidgets: this.filteredWidgets,
                statusOptions: this.filterOptions.status.options,
                subStatusOptions: this.filterOptions.subStatus.options,
                assignedToOptions: this.fbService.users
            }
        });

        const subscription = dialogRef.afterClosed().subscribe(res => {
            subscription.unsubscribe();
        });
    }

    getTypeClass(type: type, selected = false) {
        if (selected) return 'bg-primary text-white';
        switch (type) {
            case 'bug':
                return 'bg-danger text-white';
            case 'state':
                return 'bg-warning text-white';
            case 'action':
                return 'bg-review text-white';
            case 'rule':
                return 'bg-info text-white';
            case 'other':
                return 'bg-secondary text-white';
            default:
                return 'bg-secondary text-white';
        }
    }

    showWidget(widget: JsWidget) {
        this.setSelectedPath(widget.path);
        this.showFullPath(widget.path);
    }

    async publishSpecs() {
        const widgetsToDownload = this.filteredWidgets.map(w => {
            return {
                specId: w.specId || null,
                path: w.path,
                type: w.type,
                status: w.status,
                viewId: w.viewId || null
            };
        });

        // Sort by path
        widgetsToDownload.sort((a, b) => {
            if (a.path < b.path) return -1;
            if (a.path > b.path) return 1;
            return 0;
        });

        // Check whether all the widgets have specId and viewId. If not then show a warning
        const hasMissingSpecId = widgetsToDownload.some(w => !w.specId);
        const hasMissingViewId = widgetsToDownload.some(w => !w.viewId);
        const hasInvalidViewId = widgetsToDownload.some(w => {
            if (!w.viewId) return false;
            return !this.cc.isValidViewId(w.viewId);
        });

        let errorMessage = '';
        if (hasMissingSpecId) {
            errorMessage += 'Some specs are missing specId.';
        } else if (hasMissingViewId) {
            errorMessage += 'Some specs are missing viewId.';
        } else if (hasInvalidViewId) {
            errorMessage += 'Some specs have invalid viewId.';
        }

        if (errorMessage) {
            const confirmResult = await this.confirmService.confirm('Warning', errorMessage, 'I understand... Publish anyway!', 'Cancel', false);
            if (!confirmResult) return;
            this.json.publishJson('specs', widgetsToDownload);
        } else {
            this.json.publishJson('specs', widgetsToDownload);
        }
    }

    showHistory(e: any) {
        this._dialog.openDialog(ListPublicationsComponent, {
            data: {
                type: 'specs'
            }
        });
        e.stopPropagation();
    }
}
