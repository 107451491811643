import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { JsActivity, entity } from '../_interfaces/Activity';
import {
  faTimes,
  faTrash,
  faCircle,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { FirebaseService } from '../_services/firebase.service';
import { IdbServiceService } from '../_services/idb-service.service';
import { ActivityService } from '../_services/activity.service';
import { CacheService } from '../_services/cache.service';
import { UserSessionStorageService } from '../_services/user-session-storage.service';
import { JsEntity } from '../_interfaces/Entities';
import { FirebaseOptimisticService } from '../_services/firebase-optimistic.service';
import { ConfirmService } from '../_services/confirm.service';

interface FormattedNotification {
  id: string;
  type: 'Comment' | 'Task';
  who: string; // who did the action
  when: Date;
  taskPath: string;
  title: string;
  actions: {
    title: string;
    before: string;
    after: string;
  }[];
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnChanges {
  @Input() notifications: JsActivity[] = [];
  @Output() onClose = new EventEmitter<void>();
  faTimes = faTimes;
  faTrash = faTrash;
  faCircle = faCircle;
  faClock = faClock;
  formattedNotifications: FormattedNotification[] = [];
  selectedNotificationId: string | null = null;

  constructor(
    private fbs: FirebaseService,
    private as: ActivityService,
    private cs: CacheService,
    private uss: UserSessionStorageService,
    private fbo: FirebaseOptimisticService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.formatNotifications();
  }

  close() {
    this.onClose.emit();
  }

  formatNotifications() {
    // @ts-ignore
    this.formattedNotifications = this.notifications
      .map((n) => {
        const type = n.entity === 'comment' ? 'Comment' : 'Task';
        const who =
          this.fbs.getUserNameFromId(n.uid)?.split(' ')[0] || 'Unknown';
        const when = n.cloudUpdatedAt;
        const taskPath =
          type === 'Comment'
            ? this.cs.getWidgetPathFromId(n.entityId)
            : // @ts-ignore
            n.after?.path
            ? // @ts-ignore
              n.after?.path
            : // @ts-ignore
              n.before?.path;
        const actions = this.as.getActivityDiff(n, ['uid', 'cloudUpdatedAt'], {
          numberId: '#',
          replies: 'reply',
          text: 'comment',
        });
        return {
          id: n.id,
          when,
          type,
          title: this.getTitle(n),
          who,
          taskPath,
          actions,
        };
      })
      .sort((a, b) => b.when.getTime() - a.when.getTime());
  }

  getTitle(activity: JsActivity): string {
    const isComment = activity.entity === 'comment';
    if (isComment) {
      // @ts-ignore
      const commentType = activity.after?.type || activity.before?.type;
      // @ts-ignore
      const numberId = activity.after?.numberId || activity.before?.numberId;
      return `${commentType} #${numberId}`;
    }
    // @ts-ignore
    return activity.after?.path || activity.before?.path || 'Unknown';
  }

  trackBy(index: number, n: FormattedNotification): string {
    return n.id;
  }

  onClick(notification: FormattedNotification) {
    this.selectedNotificationId = notification.id;
    const isComment = notification.type === 'Comment';
    const widgetPath = notification.taskPath;
    console.log(isComment, widgetPath);
    if (isComment) {
      this.uss.setUserSessionStorageItem('widgetDetailView', 'comment', true);
    } else {
      this.uss.setUserSessionStorageItem('widgetDetailView', 'info', true);
    }
    if (!widgetPath) {
      return;
    }
    this.uss.setUserSessionStorageItem('widgetPath', widgetPath);
  }

  async clearNotification($event: any, activityId: string) {
    $event.stopPropagation();
    const currentUserId = this.fbs.getCurrentUserId();
    const activity = this.notifications.find((n) => n.id === activityId);
    if (!activity) {
      return;
    }
    const updatedWatchers = activity.watchers.filter(
      (w) => w !== currentUserId
    );
    const updatedActivity = {
      ...activity,
      watchers: updatedWatchers,
    };

    await this.fbo.updateItemsOptimistic([updatedActivity], 'notifications');
  }

  async clearAllNotifications() {
    // confirm
    const confirmation = await this.confirmService.confirm(
      `Clear all notifications`,
      `Are you sure? This is irreversible and will clear only a maximum of 500 notifications.`
    );
    if (!confirmation) return;
    const currentUserId = this.fbs.getCurrentUserId();
    // Get notification where I am the watcher
    const myNotifications = this.notifications.filter((n) =>
      n.watchers.includes(currentUserId)
    );
    // Take the first 500 or less
    const notificationsToClear = myNotifications.slice(0, 500);
    // Remove me from the watchers
    const updatedNotifications = notificationsToClear.map((n) => ({
      ...n,
      watchers: n.watchers.filter((w) => w !== currentUserId),
    }));
    await this.fbo.updateItemsOptimistic(updatedNotifications, 'notifications');
  }
}
