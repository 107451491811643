<h1 mat-dialog-title>
  Bulk Update {{filteredWidgets.length}} items (Risky - Are you sure?)
</h1>

<div mat-dialog-content>
  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : selectedRefreshPreviews !== false}">
    <mat-label>Refresh Previews</mat-label>
    <mat-select [(value)]="selectedRefreshPreviews">
      <mat-option [value]="false">No</mat-option>
      <mat-option [value]="true">Yes</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : selectedStatus !== 'doNotUpdate'}"
    *ngIf="canChangeStatus()">
    <mat-label>Status</mat-label>
    <mat-select [(value)]="selectedStatus">
      <mat-option [value]="'doNotUpdate'">DO NOT UPDATE</mat-option>
      <ng-container *ngFor="let status of statusOptions">
        <mat-option [value]="status.value">{{status.label}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : selectedSubStatus !== 'doNotUpdate'}"
    *ngIf="canChangeStatus()">
    <mat-label>Sub Status</mat-label>
    <mat-select [(value)]="selectedSubStatus">
      <mat-option [value]="'doNotUpdate'">DO NOT UPDATE</mat-option>
      <ng-container *ngFor="let subStatus of subStatusOptions">
        <mat-option [value]="subStatus.value">{{subStatus.label}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : selectedBacklogAssignedTo !== 'doNotUpdate'}">
    <mat-label>Backlog Assigned To</mat-label>
    <mat-select [(value)]="selectedBacklogAssignedTo">
      <mat-option [value]="'doNotUpdate'">DO NOT UPDATE</mat-option>
      <mat-option [value]="''">None</mat-option>
      <ng-container *ngFor="let user of assignedToOptions">
        <mat-option [value]="user.value">{{user.label}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : selectedDesignAssignedTo !== 'doNotUpdate'}">
    <mat-label>Design Assigned To</mat-label>
    <mat-select [(value)]="selectedDesignAssignedTo">
      <mat-option [value]="'doNotUpdate'">DO NOT UPDATE</mat-option>
      <mat-option [value]="''">None</mat-option>
      <ng-container *ngFor="let user of assignedToOptions">
        <mat-option [value]="user.value">{{user.label}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : selectedReviewAssignedTo !== 'doNotUpdate'}">
    <mat-label>Review Assigned To</mat-label>
    <mat-select [(value)]="selectedReviewAssignedTo">
      <mat-option [value]="'doNotUpdate'">DO NOT UPDATE</mat-option>
      <mat-option [value]="''">None</mat-option>
      <ng-container *ngFor="let user of assignedToOptions">
        <mat-option [value]="user.value">{{user.label}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : selectedDevelopAssignedTo !== 'doNotUpdate'}">
    <mat-label>Develop Assigned To</mat-label>
    <mat-select [(value)]="selectedDevelopAssignedTo">
      <mat-option [value]="'doNotUpdate'">DO NOT UPDATE</mat-option>
      <mat-option [value]="''">None</mat-option>
      <ng-container *ngFor="let user of assignedToOptions">
        <mat-option [value]="user.value">{{user.label}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : selectedTestAssignedTo !== 'doNotUpdate'}">
    <mat-label>Test Assigned To</mat-label>
    <mat-select [(value)]="selectedTestAssignedTo">
      <mat-option [value]="'doNotUpdate'">DO NOT UPDATE</mat-option>
      <mat-option [value]="''">None</mat-option>
      <ng-container *ngFor="let user of assignedToOptions">
        <mat-option [value]="user.value">{{user.label}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : selectedFailedAssignedTo !== 'doNotUpdate'}">
    <mat-label>Failed Assigned To</mat-label>
    <mat-select [(value)]="selectedFailedAssignedTo">
      <mat-option [value]="'doNotUpdate'">DO NOT UPDATE</mat-option>
      <mat-option [value]="''">None</mat-option>
      <ng-container *ngFor="let user of assignedToOptions">
        <mat-option [value]="user.value">{{user.label}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : selectedApproveAssignedTo !== 'doNotUpdate'}">
    <mat-label>Approve Assigned To</mat-label>
    <mat-select [(value)]="selectedApproveAssignedTo">
      <mat-option [value]="'doNotUpdate'">DO NOT UPDATE</mat-option>
      <mat-option [value]="''">None</mat-option>
      <ng-container *ngFor="let user of assignedToOptions">
        <mat-option [value]="user.value">{{user.label}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>

<div mat-dialog-actions class="d-grid gap-2 d-flex justify-content-end">
  <button mat-button (click)="onDismiss()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">Update</button>
</div>