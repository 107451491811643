import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JsWidget, status, subStatus } from '../../../_interfaces/Widget';
import * as _ from 'lodash';
import { FirebaseOptimisticService } from '../../../_services/firebase-optimistic.service';
import { FirebaseService } from '../../../_services/firebase.service';

type LabelValueType = {
  label: string;
  value: string;
};

type doNotUpdate = 'doNotUpdate';

interface BulkUploadModel {
  filteredWidgets: JsWidget[];
  statusOptions: LabelValueType[];
  subStatusOptions: LabelValueType[];
  assignedToOptions: LabelValueType[];
}

@Component({
  selector: 'app-bulk-update',
  templateUrl: './bulk-update.component.html',
  styleUrls: ['./bulk-update.component.scss'],
})
export class BulkUpdateComponent implements OnInit {
  filteredWidgets: JsWidget[] = [];
  statusOptions: LabelValueType[] = [];
  subStatusOptions: LabelValueType[] = [];
  assignedToOptions: LabelValueType[] = [];
  selectedRefreshPreviews = false;
  selectedStatus: status | doNotUpdate = 'doNotUpdate';
  selectedSubStatus: subStatus | doNotUpdate = 'doNotUpdate';
  selectedBacklogAssignedTo: string = 'doNotUpdate';
  selectedDesignAssignedTo: string = 'doNotUpdate';
  selectedReviewAssignedTo: string = 'doNotUpdate';
  selectedDevelopAssignedTo: string = 'doNotUpdate';
  selectedTestAssignedTo: string = 'doNotUpdate';
  selectedFailedAssignedTo: string = 'doNotUpdate';
  selectedApproveAssignedTo: string = 'doNotUpdate';
  constructor(
    public dialogRef: MatDialogRef<BulkUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BulkUploadModel,
    private fbo: FirebaseOptimisticService,
    private fbService: FirebaseService
  ) {
    this.filteredWidgets = _.cloneDeep(this.data.filteredWidgets);
    this.statusOptions = _.cloneDeep(this.data.statusOptions);
    this.subStatusOptions = _.cloneDeep(this.data.subStatusOptions);
    this.assignedToOptions = _.cloneDeep(this.data.assignedToOptions);
  }

  ngOnInit(): void {}

  async onConfirm() {
    const updatedWidgets = this.filteredWidgets.map((widget) => {
      if (this.selectedRefreshPreviews) {
        widget.stateFigmaPreviewUrl = '';
        widget.stateFigmaPreviewAt = null;
        widget.skipPreviewCheck = true;
      }
      this.selectedStatus === 'doNotUpdate'
        ? null
        : (widget.status = this.selectedStatus);
      this.selectedSubStatus === 'doNotUpdate'
        ? null
        : (widget.subStatus = this.selectedSubStatus);
      this.selectedBacklogAssignedTo === 'doNotUpdate'
        ? null
        : (widget.backlogAssignedTo = this.selectedBacklogAssignedTo);
      this.selectedDesignAssignedTo === 'doNotUpdate'
        ? null
        : (widget.designAssignedTo = this.selectedDesignAssignedTo);
      this.selectedReviewAssignedTo === 'doNotUpdate'
        ? null
        : (widget.reviewAssignedTo = this.selectedReviewAssignedTo);
      this.selectedDevelopAssignedTo === 'doNotUpdate'
        ? null
        : (widget.developAssignedTo = this.selectedDevelopAssignedTo);
      this.selectedTestAssignedTo === 'doNotUpdate'
        ? null
        : (widget.testAssignedTo = this.selectedTestAssignedTo);
      this.selectedFailedAssignedTo === 'doNotUpdate'
        ? null
        : (widget.failedAssignedTo = this.selectedFailedAssignedTo);
      this.selectedApproveAssignedTo === 'doNotUpdate'
        ? null
        : (widget.approveAssignedTo = this.selectedApproveAssignedTo);
      return widget;
    });
    await this.fbo.updateItemsOptimistic(
      updatedWidgets,
      'widgets',
      this.selectedRefreshPreviews
    );
    this.dialogRef.close();
  }

  canChangeStatus() {
    const isPO = this.fbService.currentUser.roles?.includes('PO');
    return isPO;
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
