<span class="ri-pointer" *ngIf="!isEmpty; else noSpecs" (click)="showSpecList(viewId, $event)">
  <span class="badge rounded-pill text-bg-light me-1" matTooltip="Draft" [ngClass]="{ 'opacity-25': !Draft }">
    {{ Draft || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-dark me-1" matTooltip="Paused" [ngClass]="{ 'opacity-25': !Paused }">
    {{ Paused || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-primary me-1" matTooltip="Reviewed" [ngClass]="{ 'opacity-25': !Reviewed }">
    {{ Reviewed || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-primary me-1" matTooltip="Translated" [ngClass]="{ 'opacity-25': !Translated }">
    {{ Translated || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-info me-1" matTooltip="Ready" [ngClass]="{ 'opacity-25': !Ready }">
    {{ Ready || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-warning me-1" matTooltip="Implemented" [ngClass]="{ 'opacity-25': !Implemented }">
    {{ Implemented || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-danger me-1" matTooltip="Failed" [ngClass]="{ 'opacity-25': !Failed }">
    {{ Failed || '-' }}
  </span>
  <!-- passed -->
  <span class="badge rounded-pill text-bg-success me-1" matTooltip="Passed" [ngClass]="{ 'opacity-25': !Passed }">
    {{ Passed || '-' }}
  </span>
  <!-- deprecated -->
  <span class="badge rounded-pill text-bg-secondary me-1" matTooltip="Deprecated" [ngClass]="{ 'opacity-25': !Deprecated }">
    {{ Deprecated || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-dark me-1" matTooltip="Removed" [ngClass]="{ 'opacity-25': !Removed }">
    {{ Removed || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-dark me-1" matTooltip="Total">
    {{ total }}
  </span>
</span>
<span>
  <!-- <span *ngIf="totalTreanslations" class="badge rounded-pill text-bg-secondary ms-2" matTooltip="All">
    {{ totalTreanslations || '-'}}
  </span> -->
  <span *ngIf="incompleteTranslations" class="badge rounded-pill text-bg-danger ms-3" matTooltip="Incomplete">
    {{ incompleteTranslations || '-' }}
  </span>
</span>
<ng-template #noSpecs>
  <span class="ri-pointer" (click)="showSpecList(viewId, $event)">- - -</span>
</ng-template>
