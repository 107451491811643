import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  SimpleChange,
} from '@angular/core';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { CacheService } from '../_services/cache.service';
import { JsTask } from '../_interfaces/Task';
import { ListSpecsOfTaskComponent } from '../list-specs-of-task/list-specs-of-task.component';
import { filterWidgetsWithGlobsPatterns } from '../shared/utils';

@Component({
  selector: 'app-spec-status-counts-of-task',
  templateUrl: './spec-status-counts-of-task.component.html',
  styleUrls: ['./spec-status-counts-of-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecStatusCountsOfTaskComponent implements OnInit {
  @Input() task: JsTask | undefined = undefined;
  Backlog: number = 0;
  Design: number = 0;
  Review: number = 0;
  Develop: number = 0;
  Test: number = 0;
  Failed: number = 0;
  Approve: number = 0;
  Total: number = 0;
  isEmpty: boolean = true;

  constructor(
    private dManager: DialogManagerService,
    private cc: CacheService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(change: SimpleChange) {
    // @ts-ignore
    if (change['task']) {
      this.update();
    }
  }

  update() {
    this.isEmpty = true;
    this.Backlog = 0;
    this.Design = 0;
    this.Review = 0;
    this.Develop = 0;
    this.Test = 0;
    this.Failed = 0;
    this.Approve = 0;
    if (this.cc.allActiveWidgets.length > 0) {
      const filteredWidgets = filterWidgetsWithGlobsPatterns(
        this.cc.allActiveWidgets,
        this.task?.includePatterns,
        this.task?.excludePatterns
      );
      filteredWidgets.forEach((widget) => {
        this[widget.status]++;
      });

      // Calculate the total number of widgets with starting with any of the specPrefixes
      this.Total =
        this.Backlog +
        this.Design +
        this.Review +
        this.Develop +
        this.Test +
        this.Failed +
        this.Approve;
      if (
        this.Backlog ||
        this.Design ||
        this.Review ||
        this.Develop ||
        this.Test ||
        this.Failed ||
        this.Approve
      ) {
        this.isEmpty = false;
      }
    }
  }

  showSpecsListOfTask(task: JsTask | undefined, e: any) {
    if (!task) return;
    // this._dialog.open(ListSpecsOfTaskComponent, {
    //   data: {
    //     task,
    //   },
    // });
    this.dManager.openDialog(ListSpecsOfTaskComponent, {
      data: {
        task,
      },
    });

    e.stopPropagation();
  }
}
