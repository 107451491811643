<main class="event-form p-3 ri-w-600">
    <h3 class="fw-semibold mb-2">{{ title }}</h3>
    
    <div class="ri-dialog-content">
        <pre class="pre-json">{{ jsonData | json }}</pre>
    </div>
    
    <div class="ri-dialog-actions pt-3 text-end">
        <button class="btn btn-primary btn-sm px-5" (click)="dialogRef.close()">Close</button>
    </div>
</main>