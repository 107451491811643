import { Component, EventEmitter, Output, effect, model, output } from '@angular/core';
import { JsTask, status } from '../_interfaces/Task';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CacheService } from '../_services/cache.service';

@Component({
  selector: 'app-task-status-count-of-tasks',
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
  templateUrl: './task-status-count-of-tasks.component.html',
  styleUrl: './task-status-count-of-tasks.component.scss'
})
export class TaskStatusCountOfTasksComponent {
  @Output() onUpdate = new EventEmitter();
  tasks = model<JsTask[]>();
  Backlog: number = 0;
  Design: number = 0;
  Review: number = 0;
  Develop: number = 0;
  Test: number = 0;
  Failed: number = 0;
  Mergeable: number = 0;
  Passed: number = 0;
  Approved: number = 0;
  Total: number = 0;
  isEmpty: boolean = true;

  progress = {
    Backlog: 0,
    Design: 0,
    Review: 0,
    Develop: 0,
    Test: 0,
    Failed: 0,
    Mergeable: 0,
    Passed: 0,
    Approved: 0,
    Total: 0
  };

  progressMax: number = 0;

  constructor(public cc: CacheService) {
    effect(() => {
      const tasks = this.tasks();
      this.isEmpty = true;
      this.Backlog = 0;
      this.Design = 0;
      this.Review = 0;
      this.Develop = 0;
      this.Test = 0;
      this.Failed = 0;
      this.Mergeable = 0;
      this.Passed = 0;
      this.Approved = 0;
      this.Total = 0;
      this.progress = {
        Backlog: 0,
        Design: 0,
        Review: 0,
        Develop: 0,
        Test: 0,
        Failed: 0,
        Mergeable: 0,
        Passed: 0,
        Approved: 0,
        Total: 0
      };
      if (tasks?.length) {
        tasks.forEach(task => {
          switch (task.status) {
            case 'Backlog':
              this.Backlog++;
              this.progress.Backlog = this.progress.Backlog + 0;
              break;
            case 'Design':
              this.Design++;
              this.progress.Design = this.progress.Design + 1;
              break;
            case 'Review':
              this.Review++;
              this.progress.Review = this.progress.Review + 2;
              break;
            case 'Develop':
              this.Develop++;
              this.progress.Develop = this.progress.Develop + 3;
              break;
            case 'Test':
              this.Test++;
              this.progress.Test = this.progress.Test + 4;
              break;
            case 'Failed':
              this.Failed++;
              this.progress.Failed = this.progress.Failed + 3;
              break;
            case 'Mergeable':
              this.Mergeable++;
              this.progress.Mergeable = this.progress.Mergeable + 4;
              break;
            case 'Passed':
              this.Passed++;
              this.progress.Passed = this.progress.Passed + 5;
              break;
            case 'Approved':
              this.Approved++;
              this.progress.Approved = this.progress.Approved + 6;
              break;
            default:
              break;
          }
        });
        this.Total = this.Backlog + this.Design + this.Review + this.Develop + this.Test + this.Failed + this.Mergeable + this.Passed + this.Approved;
        this.progress.Total =
          this.progress.Backlog +
          this.progress.Design +
          this.progress.Review +
          this.progress.Develop +
          this.progress.Test +
          this.progress.Failed +
          this.progress.Mergeable +
          this.progress.Passed +
          this.progress.Approved;
        this.progressMax = this.Total * 6;
        this.isEmpty = false;
      }

      this.onUpdate.emit();
    });
  }

  getPercentage(status: status | 'Total'): string {
    const val = this[status];
    const total = this.Total;
    if (!total || !val) return `${status} 0%`;
    return `${status} ${((val / total) * 100).toFixed(0)}%`;
  }

  getTotalPercentageProgress(): string {
    const val = this.progress.Total;
    const total = this.progressMax;
    if (!total || !val) return `Total 0%`;
    return `Total Progress ${((val / total) * 100).toFixed(0)}%`;
  }

  getPercentageProgressOnly(status: status | 'Total'): string {
    const val = this.progress[status];
    const total = this.progressMax;
    if (!total || !val) return `0%`;
    return `${((val / total) * 100).toFixed(0)}%`;
  }
}
