import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import * as _ from 'lodash';
import { SvgService } from '../_services/svg.service';
import { SafeHtmlPipe } from '../_pipes/safe-html.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-svg-viewer',
  templateUrl: './svg-viewer.component.html',
  styleUrls: ['./svg-viewer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SafeHtmlPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgViewerComponent implements OnChanges {
  
  @Input() srcUrl: string | undefined;
  @Input() currentColorMap: { [key: string]: string } | undefined = undefined;
  @Input() hoverColor: string = '';
  @Output() onCurrentUsedColorsChange = new EventEmitter<Set<string>>();

  originalXml: string | undefined;
  currentXml: string | undefined;
  currentUsedColors: Set<string> = new Set();
  private intervalId: any;

  constructor(
    private svgService: SvgService,
    private cdRef: ChangeDetectorRef,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    console.log(changes);
    if (this.srcUrl === undefined) return;
    if (changes['srcUrl']?.currentValue !== changes['srcUrl']?.previousValue) {
      // if srcUrl changed
      this.clearInterval();
      this.originalXml = await this.svgService.getSvgXmlFromUrl(this.srcUrl);
      this.switchColor(
        this.originalXml,
        this.currentColorMap || this.svgService.originalColorMap
      );
    } else if (
      // if currentColorMap(mode) changed
      this.originalXml !== undefined &&
      !_.isEqual(
        changes['currentColorMap']?.currentValue,
        changes['currentColorMap']?.previousValue
      )
    ) {
      console.log('currentColorMap changed');
      this.clearInterval();
      this.switchColor(
        this.originalXml,
        this.currentColorMap || this.svgService.originalColorMap
      );
    } else if (
      // Check if hoverColor changed and not empty
      this.currentXml &&
      changes['hoverColor']?.currentValue !==
        changes['hoverColor']?.previousValue &&
      this.currentColorMap &&
      this.originalXml
    ) {
      this.clearInterval();
      const hexCode = changes['hoverColor']?.currentValue;
      console.log('hoverColor changed' + hexCode);
      // Change the color of the hovered color in the current colorMap
      let newColorMap = this.currentColorMap;
      if (hexCode) {
        newColorMap = this.replaceValue(
          this.currentColorMap,
          hexCode,
          this.getComplementaryColor(hexCode)
        );

        let isHoverView = true;

        this.intervalId = setInterval(() => {
          if (isHoverView) {
            this.switchColor(this.originalXml!, newColorMap, true);
            isHoverView = false;
          } else {
            this.switchColor(this.originalXml!, this.currentColorMap!, true);
            isHoverView = true;
          }
          this.cdRef.detectChanges();
        }, 500);
      } else {
        this.switchColor(this.originalXml, this.currentColorMap, true);
      }
    }
  }

  switchColor(
    xml: string,
    colorMap: { [key: string]: string },
    isHover = false
  ) {
    this.currentUsedColors = new Set();
    if (xml === undefined || colorMap === undefined) return;

    // List of attributes that can take color values
    const colorAttributes = [
      'fill',
      'stroke',
      'stop-color',
      'flood-color',
      'lighting-color',
    ];

    // Regular expression to match the color attributes but not values that start with url(
    const regex = new RegExp(
      `(${colorAttributes.join('|')})="((?!url\\()[^"]+)"`,
      'g'
    );

    this.currentXml = xml.replace(regex, (match, attribute, capturedColor) => {
      // console.log(match, attribute, capturedColor);
      if (colorMap[capturedColor]) {
        this.currentUsedColors.add(colorMap[capturedColor]);
      } else {
        console.error('FOUND A COLOR NOT IN THE COLOR MAP', capturedColor);
      }

      // check both uppercase and lowercase
      const color = colorMap[capturedColor.toLowerCase()]
        ? colorMap[capturedColor.toLowerCase()]
        : colorMap[capturedColor.toUpperCase()];

      return color
        ? `${attribute}="${color || capturedColor}"` // Fall back to original color if not found. Sometime uses fill="none" to remove color
        : match;
    });

    if (!isHover) {
      this.onCurrentUsedColorsChange.emit(this.currentUsedColors);
    }
  }

  private clearInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  replaceValue<T extends object>(obj: T, targetValue: any, newValue: any): T {
    return _.cloneDeepWith(obj, (value) => {
      if (value === targetValue) {
        return newValue;
      }
    });
  }

  getComplementaryColor(hex: string): string {
    // Ensure the hex code starts with a hash symbol
    if (hex.charAt(0) !== '#') {
      throw new Error('Invalid hex color format');
    }

    // Remove the hash at the start if it's there
    hex = hex.slice(1);

    // Parse the r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    // Compute the complementary color
    r = 255 - r;
    g = 255 - g;
    b = 255 - b;

    // Convert RGB to hex
    let result = (1 << 24) + (r << 16) + (g << 8) + b;
    return '#' + result.toString(16).slice(1).toUpperCase();
  }
}
