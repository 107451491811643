import { Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { TaskDetailsModalComponent } from '../task-details-modal/task-details-modal.component';
import { CacheService } from '../_services/cache.service';
import { LocalUser } from '../_interfaces/User';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FirebaseService } from '../_services/firebase.service';
import { JsDaily } from '../_interfaces/Daily';
import { getcurrentLocalDateAsString } from '../shared/utils';
import { SharedFunctionService } from '../_services/shared-function.service';
import { TodoFormComponent } from '../todos/todo-form/todo-form.component';

type hours = {
  userHours: number;
  totalHours: number;
};

@Component({
  selector: 'app-daily-plan-card',
  standalone: false,
  templateUrl: './daily-plan-card.component.html',
  styleUrl: './daily-plan-card.component.scss'
})

export class DailyPlanCardComponent {

  @Input() filterOptions: any;
  @Input() dailys: JsDaily[] = [];
  @Output() selectEvent = new EventEmitter<JsDaily>();
  
  todayDate = getcurrentLocalDateAsString();
  selectedDailyId: string = '';
  user: LocalUser = this.fbService.currentUser;
  type: string = '';
  public mapClass = {
    'Not Started': 'bg-info',
    'In Progress': 'bg-primary',
    Blocked: 'bg-warning',
    Completed: 'bg-success'
  };
  statusStyleMap: { [key: string]: string } = {
    Backlog: 'text-bg-info',
    Design: 'text-bg-secondary',
    Review: 'text-bg-review',
    Develop: 'text-bg-primary',
    Blocked: 'text-bg-danger',
    Test: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Mergeable: 'text-bg-primary',
    Passed: 'text-bg-success',
    Approved: 'text-bg-success'
  };

  constructor(
    public cc: CacheService, 
    public fbService: FirebaseService,
    public sharedFunc: SharedFunctionService,
    public dialog: MatDialog, 
    @Optional() public dialogRef: MatDialogRef<DailyPlanCardComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: { from: 'modal', dailys: JsDaily[] }
  ) {
    if(this.data) {
      this.type = this.data.from;
      this.dailys = this.data.dailys;
    }
  }

  selectDaily(daily: JsDaily) {
    this.selectEvent.emit(daily);
    this.selectedDailyId = daily.id;
  }

  getTaskUserHours(tid: number, uid: string): hours {
    const result = {
      userHours: 0,
      totalHours: 0
    };
    const task = this.cc.tidTaskMap[tid];
    if (!task) return result;
    const timeLogs = task.timeLogs || [];
    const userHours = timeLogs.find(log => log.uid === uid)?.hours || 0;
    const totalHours = timeLogs.reduce((acc: number, log: any) => {
      return acc + log.hours;
    }, 0);
    return { userHours, totalHours };
  }

  editTask(tid: number, e: any, planFor: 'Feature' | 'Todo') {
    e.stopPropagation();
    if(planFor === 'Todo') {
      const todo = this.cc.tidToTodoMap[tid];
      const todoForm = this.dialog.open(TodoFormComponent, {
        width: '500px !important',
        height: '800px',
        maxWidth: '500px !important',
        maxHeight: '80vh',
        data: {
          mode: 'edit',
          todoValue: todo,
        },
      });
      return todoForm.afterClosed();

    } else {
      const task = this.cc.tidTaskMap[tid];
      // Open the task details modal
      const confirmDialog = this.dialog.open(TaskDetailsModalComponent, {
        width: '800px',
        maxWidth: '90vw',
        maxHeight: '90vh',
        data: task,
        autoFocus: false
      });
      return confirmDialog.afterClosed();
    }
  }

  getTaskTitle(tid: number) {
    const task = this.cc.tidTaskMap[tid];
    if (task) return task.title;
    return '';
  }

  trackById(index: number, el: any): number {
    return el.id;
  }
}
